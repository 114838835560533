import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Img,
  Columns,
  Column,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { Select, SelectIcons } from "../../Components/Select";
import iconsOptions from "../../Components/Iconos/Icons.js";

@observer
class EditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.currentCategory = props.currentCategory.clone();
  }

  handleChangeFiled = (sender, value, name, validation) => {
    this.currentCategory[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    let result = this.currentCategory.icon_path.replace(
      "https://bucket-kasimu.s3.amazonaws.com/images/",
      ""
    );
    this.currentCategory.icon_path = result;
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .putParams("/categories/put_category", this.currentCategory)
        .then(
          (res) => {
            toastManager.add("La categoria se editó exitosamente.", {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onSave && this.props.onSave();
          },
          (error) => {
            toastManager.add(
              "Error, algo salió mal al editar al editar la categoria.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            this.setState({ isSaving: false });
          }
        );
    });
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;
    switch (sender.name) {
      case "selectIcons":
        this.currentCategory.icon_path = value.icon;
        break;
      case "selectRow":
        this.currentCategory.list_view = val;
        break;
      default:
        break;
    }
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={this.currentCategory.name === ""}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const rowOptions = [
      { value: 1, label: "Elegir por tipo de local" },
      { value: 2, label: "Elegir por estilo de música" },
      { value: 3, label: "Elegir por otros criterios" },
    ];
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "340px" : "auto"}
      >
        <ModalHeader>
          <div className="flex items-center">
            <Img
              height={this.props.store.viewMobile ? "180px" : "100px"}
              width={this.props.store.viewMobile ? "180px" : "100px"}
              src={this.currentCategory.icon_path}
            />

            <Text
              className="ml-1"
              upper
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
            >
              {this.props.store.language.label140}
              {"\n"}
              {" Id: " + this.currentCategory.id}
              {"\n"}
              {this.currentCategory.name}
            </Text>
          </div>
        </ModalHeader>
        <ModalContent>
          <Columns className="my-1" isMultiline style={{ gap: 0 }}>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
              <Field
                label={this.props.store.language.label53}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  name="name"
                  onChange={this.handleChangeFiled}
                  value={this.currentCategory.name}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </Column>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
              <Field
                label={"Descripción"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  name="description"
                  onChange={this.handleChangeFiled}
                  value={this.currentCategory.description}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </Column>
          </Columns>
          <Columns style={{ gap: !this.props.store.viewMobile && "0px" }}>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
              <Field
                label={"Fila"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <Select
                  isMobile={!this.props.store.viewMobile}
                  name={"selectRow"}
                  onChange={this.handleSelectChange}
                  options={rowOptions}
                  defaultValue={
                    rowOptions[
                      rowOptions
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(this.currentCategory.list_view)
                    ]
                  }
                  placeholder={this.props.store.language.label385}
                  backgroundColor="black"
                  separatorColor="black"
                  className="is-fullwidth my-1"
                />
              </Field>
            </Column>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
              <Field
                label={"Icono"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <SelectIcons
                  isMobile={!this.props.store.viewMobile}
                  name="selectIcons"
                  onChange={this.handleSelectChange}
                  placeholder={"Icons"}
                  options={iconsOptions}
                  defaultValue={
                    iconsOptions[
                      iconsOptions
                        .map(function (e) {
                          return e.icon;
                        })
                        .indexOf(this.currentCategory.icon_path)
                    ]
                  }
                  backgroundColor="black"
                  separatorColor="black"
                  className="is-fullwidth my-1"
                />
              </Field>
            </Column>
          </Columns>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

EditModal.propTypes = {
  currentCategory: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

EditModal.defaultProps = {
  currentCategory: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(EditModal));
