const es_ES = {
  media_type: {
    1: `Canción`,
    2: `Id de radio`,
    3: `Entre canción`,
    4: `Comercial`,
    5: `Comercial inició`,
    6: `Comercial fin`,
  },
  mood: {
    1: "Muy tranquilas",
    2: "Tranquilas",
    3: "Normales",
    4: "Movidas",
    5: "Muy movidas",
    6: "Muy tranquilas y Tranquilas (1+2)",
    7: "Tranquilas y Normales (2+3)",
    8: "Tranquilas, Normales y Movidas (2+3+4)",
    9: "Normales y Movidas (3+4)",
    10: "Todas (1+2+3+4+5)",
  },
  label1: "Idiomas",
  label2: "Inglés",
  label3: "Español",
  label4: "Francés",
  label5: "Cargando Aplicación...",
  label6: "Gestor de contenido",
  label7: "Usuario",
  label8: "Contraseña",
  label9: "Iniciar Sesión",
  label10: "Error, Por favor proporcione nombre de usuario y contraseña.",
  label11: "Error, Esta cuenta no es administradora.",
  label12:
    "Error, ¡Usuario suspendido!, ponte en contacto con soporte@kasimu.com.",
  label13: "Error, Usuario o contraseña incorrecta.",
  label14: "Error, Su suscripción ha expirado. Ir a www.kasimu.com.",
  label15:
    "Error, Fallo al conectarse al servidor, comprueba tu conexión a internet.",
  label16: "Panel \nde\n Control",
  label17: "Usuarios",
  label18: "Crear, editar y controlar \n usuarios (locales)",
  label19: "Clonar configuración de \n un usuario de otros u otro",
  label20: "Personalizar el logo \n y datos del programa",
  label21: "Crear y editar \n grupos de usuarios",
  label22: "Descargar \n reportes de uso",
  label23: "Preferencias",
  label24: "Crear y editar preferencias \n de un usuario",
  label25: "Crear y editar \n playlist de usuarios",
  label26: "Asignar playlists a cada \n horario de cada día",
  label27: "Administrar estilos musicales",
  label28: "Administrar canciones",
  label29: "Anuncios",
  label30: "Cargar y administrar medios",
  label31: "Crear, editar y configurar relojes",
  label32: "Cargar y administrar anuncios",
  label33: "Crear, editar y configurar pautas",
  label34: "Actividad",
  label35: "Crear y editar preferencias",
  label36: "Grupos de usuarios",
  label37: "Planificador de playlists",
  label38: "Personalización",
  label39: "Planificador de anuncios",
  label40: "Clonado",
  label41: "Cerrar sesión",
  label42: "Cargando Datos...",
  label43: "Leer más",
  label44: "Leer menos",
  label45:
    'En esta sección puedes crear usuarios. Se necesita tener un usuario para cada local, y cada usuario puede utilizarse en un solo sitio a la vez. También puedes borrar usuarios o editarlos (cambiar nombre de usuario, contraseña, etc).\nCada usuario puede ser asignado a uno o varios grupos de usuarios, para hacer más fácil la configuración masiva de usuarios.Los grupos de usuarios los creas tú mismo en la sección "CREAR Y EDITAR GRUPOS DE USUARIOS".\nPuedes también puedes controlar lo que se escucha en cada local desde esta sección.\nPara asignar playlists o pautas publicitarias a un usuario debes ir al apartado correspondiente ("PLAYLISTS"o "ANUNCIOS")',
  label46: "Usuarios contratados",
  label47: "Usuarios creados",
  label48: "Usuarios disponibles",
  label49: "Crear usuario",
  label50: "Escribe los usuarios o grupos de usuarios a buscar...",
  label51: "Filtrar por...",
  label52: "Ordenar por...",
  label53: "Nombre",
  label54: "Email",
  label55: "Grupo",
  label56: "Conectados",
  label57: "Desconectados",
  label58: "Nunca conectados",
  label59:
    "Enero,Febrero,Marzo,Abril,Mayo,Junio,Julio,Agosto,Septiembre,Octubre,Noviembre,Diciembre",
  label60: "Ene,Feb,Mar,Abr,May,Jun,Jul,Ago,Sep,Oct,Nov,Dec",
  label61: "Domingo,Lunes,Martes,Miércoles,Jueves,Viernes,Sabado",
  label62: "Dom,Lun,Mar,Mie,Jue,Vie,Sab",
  label63: "Do,Lu,Ma,Mi,Ju,Vi,Sa",
  label64: "Conectado",
  label65: "Desconectado",
  label66: "Usuario nunca conectado",
  label67: "Suspendido",
  label68: "Activo",
  label69: "Reproduciendo la playlist",
  label70: "Playlist reproducida",
  label71: "Desde",
  label72: "Grupo(s)",
  label73: "No está asignado a un grupo",
  label74: "Crear \n usuario",
  label75:
    "Los nombres de usuario deben incluir el símbolo @. \n Si no lo incluyes, se agregará automáticamente al inicio del nombre de usuario.",
  label76: "Obligatorio",
  label77: "Confirmar contraseña",
  label78: "Opcional",
  label79: "Descripción",
  label80: "Guardar cambios",
  label81: "Guardando cambios...",
  label82: "Crear",
  label83: "Creando...",
  label84:
    "Los nombres de usuarios deben incluir el símbolo @.\n Si no lo incluyes, se agregará automáticamente al inicio del nombre de usuario.",
  label85: "Si",
  label86: "No",
  label87: "Información sobre",
  label88: "Usuario",
  label89: "Sin datos por el momento",
  label90: "Usuario creado",
  label91: "PIN de bloqueo",
  label92: "No se le ha asignado un PIN",
  label93: "Ultimas cinco (5) conexiones",
  label94: "Deslogueado",
  label95: "Logueado",
  label96: "Hibernando",
  label97:
    'Puedes clonar los ajustes de un usuario a otro u otros usuarios, o a un grupo o grupos. Elijes el usuario origen y luego el o los usuarios destino, o al grupo o grupos de destino. Seleccionas los ajustes a clonar y pulsas el botón "CLONAR". IMPORTANTE: Los ajustes que se clonen borrarán los ajustes que tengan los usuarios destino. Por ejemplo, si clonamos las playlists del usuario, se borrarán todas las playlists que haya creado el usuario destino o al grupo de usuarios de destino.',
  label98: "Elegir usuario de origen…",
  label99:
    "(Selecciona el usuario del cuál se clonan los ajustes seleccionados abajo)",
  label100: "Seleccionar usuarios",
  label101: "Seleccionar grupos",
  label102: "Todos los usuarios",
  label103: "Usuario o usuarios destino",
  label104: "Grupo o grupos destino",
  label105:
    "(Selecciona el usuario o usuarios a los cuales se les clonaran los ajustes seleccionados abajo)",
  label106:
    "(Selecciona el grupo o grupos a los cuales se les clonaran los ajustes seleccionados abajo)",
  label107: "Elegir usuario o usuarios de destino…",
  label108: "Elegir grupo o grupos de destino…",
  label109: "Ajustes a clonar",
  label110: "Administrador de contenido",
  label111: "(Estilos del usuario y modificaciones en canciones)",
  label112: "Clonar",
  label113: "Clonando...",
  label114: "Personalizar el logo \n y datos del programa",
  label115:
    'Puedes personalizar el programa de Kasimu con tu logo y tus datos. El logo aparecerá en el login y en el menú, y tus datos aparecerán en la sección "Acerca de esta aplicación".',
  label116: "Selecciona la imagen",
  label117: "Opcional (Reemplaza el logo de Kasimu en el programa)",
  label118: "Subir imagen cuadrada en formato .jpg o .png.",
  label119: "Subir imagen",
  label120: "Restablecer",
  label121: "Datos",
  label122:
    '(Se mostrarán en el sección "Acerca de esta aplicación" del programa)',
  label123: "Nombre de la empresa o comercio",
  label124: "Sitio web",
  label125: "Texto",
  label126: "Opcional (Por ejemplo, teléfono o nombre de contacto)",
  label127: "Guardar",
  label128: "Guardando...",
  label129: "Eliminar",
  label130: "Eliminando...",
  label131: "Logo \n del \n usuario",
  label132: "La personalización fue restablecida exitosamente.",
  label133: "Error, algo salio mal al restablecer la personalización.",
  label134: "¿Deseas restablecer la personalización?",
  label135: "Se eliminará la personalización y se restablecera la de kasimu.",
  label136:
    'Crea los grupos y luego, desde "CREAR, CONFIGURAR Y CONTROLAR USUARIOS (LOCALES)" asignas usuarios al grupo o grupos que quieras. Un usuario puede pertenecer a varios grupos. Los grupos de usuarios sirven para facilitar el manejo de gran cantidad de usuarios al asignar configuraciones como planes de playlists, pautas publicitarias, etc.',
  label137: "Escribe el nombre del grupo a buscar...",
  label138: "Crear grupo",
  label139: "Crear \n grupo",
  label140: "Editar",
  label141:
    "Puedes descargarte los reportes de playlists utilizadas y anuncios publicados según las fechas que desees.",
  label142: "Reportes de anuncios",
  label143: "Reportes de playlists",
  label144:
    "(Se mostrarán solo reportes relacionados con los anuncios publicitarios)",
  label145: "Tipo de reporte",
  label146: "Elegir un tipo de reporte...",
  label147: "Medio",
  label148: "Elegir un medio...",
  label149: "Reporte General (Detallado)",
  label150: "Reporte discriminado por selección de medio (Resumen)",
  label151: "Reporte discriminado por selección de medio (Detallado)",
  label152: "Fecha inicial",
  label153: "Obligatorio (desde)",
  label154: "Fecha final",
  label155: "Obligatorio (hasta)",
  label156:
    "(Se mostrarán solo reportes relacionados con el uso de las playlists)",
  label157: "Descargar",
  label158: "Descargando...",
  label159: "Error, Algo salió mal generando el reporte.",
  label160: "El reporte se generó exitosamente.",
  label161:
    "Error, El reporte no se genero, no hay registros en ese intervalo de tiempo.",
  label162: "Reporte_de_",
  label163: "_(resumen)",
  label164: "_(detallado)",
  label165: "Nombre de usuario",
  label166: "Nombre del medio",
  label167: "Nombre del archivo",
  label168: "Horario desde",
  label169: "Horario hasta",
  label170: "Fecha de reproducción",
  label171: "Numero de reproducción",
  label172: "Rango desde",
  label173: "Rango hasta",
  label174:
    "Asignar pin de bloqueo,\n Quitar canciones con lenguaje inapropiado,\nConfigurar hirbenación.",
  label175: "Elegir un usuario...",
  label176:
    'Puedes configurar distintas playlists para los distintos tramos horarios de cada día de la semana. Selecciona un usuario y luego pulsa el símbolo "+" que existe en el extremo derecho de cada día para hacer tus configuraciones.',
  label177: "Cargar \n y administrar anuncios",
  label178:
    'En esta sección puedes cargar (subir) anuncios y borrar o editar tus anuncios ya cargados. Luego, en "CREAR, EDITAR Y CONFIGURAR PAUTAS" puedes crear tus pautas publicitarias para que los anuncios suenen en los locales.',
  label179: "Escribe el nombre del anuncio a buscar…",
  label180: "Cargar anuncios",
  label181: "Crear, editar \n y configurar pautas",
  label182:
    "Puedes agregar pautas publicitarias de tus propios anuncios (previamente cargados) para que suenen en los locales.",
  label183: "Agregar pauta",
  label184:
    'Crea los grupos y luego, desde "CREAR, CONFIGURAR Y CONTROLAR USUARIOS (LOCALES)" asignas usuarios al grupo o grupos que quieras. Un usuario puede pertenecer a varios grupos.',
  label185: "Cargar medio",
  label186: "Anunciante",
  label187: "Marca o producto",
  label188: "Usuario (Local)",
  label189: "Grupo de usuarios",
  label190: "Prioridad",
  label191: "Cantidad de publicaciones",
  label192: "Tipo de medio",
  label193: "Hasta",
  label194: "Ubicaciones",
  label195: "Versiones",
  label196: "Ilimitada",
  label197: "Crear, editar \n y configurar relojes",
  label198:
    "Puedes agregar pautas publicitarias de tus propios anuncios (previamente cargados).",
  label199: "Relojes",
  label200: "Calendario",
  label201: "Administrar relojes",
  label202: "Asignar relojes a día normal",
  label203: "Asignar relojes a día especial",
  label204: "Calendario activado",
  label205: "Calendario desactivado",
  label206: "Usuario eliminado exitosamente.",
  label207: "Error, algo salió mal al eliminar el usuario.",
  label208: "Desea eliminar el usuario",
  label209: "Se eliminará el usuario",
  label210:
    "Error, el nombre de usuario contiene espacios en blanco (no permitido).",
  label211: "Error, el usuario tiene que ser de 6-19 caracteres.",
  label212: "Error, la contraseña tiene que ser de 6-19 caracteres.",
  label213: "El usuario se creó exitosamente.",
  label214: "Error, la contraseña y su confirmación no son iguales.",
  label215: "Error, la contraseña es obligatoria.",
  label216: "Error, el nombre de usuario es obligatorio.",
  label217: "Editar el usuario",
  label218:
    "Error, el nombre de usuario contiene espacios en blanco (no permitido).",
  label219: "El usuario se editó exitosamente.",
  label220: "Error, algo salió mal al editar el usuario.",
  label221: "El clonado se realizó exitosamente.",
  label222: "Error, algo salió mal realizando el clonado.",
  label223: "Usuario origen",
  label224: "Error, algo salió mal guardando el grupo.",
  label225: "El Grupo se guardó exitosamente.",
  label226: "Error, algo salió mal al editar el grupo.",
  label227: "El Grupo se editó exitosamente.",
  label228: "El grupo",
  label229: "fue eliminado exitosamente.",
  label230: "Error, algo salio mal eliminando el grupo",
  label231: "Deseas eliminar el grupo",
  label232: "Se eliminará el grupo",
  label233: "La personalización se guardó exitosamente.",
  label234: "Error, algo salió mal al guardar personalización.",
  label235: "Mostrar favoritos",
  label236: "Muestra u oculta la barra de favoritos (barra inferior)",
  label237: "Agregar el PIN de bloqueo",
  label238: "Cambiar el PIN de bloqueo",
  label239: "No tienes PIN de bloqueo",
  label240: "Tu PIN de bloqueo es",
  label241: "Agregar PIN",
  label242: "Modificar PIN",
  label243: "Bloquear ahora",
  label244: "Para bloquear kasimu ahora requieren de un PIN",
  label245: "Bloquear Kasimu ahora",
  label246: "Bloquear al iniciar kasimu",
  label247: "Para iniciará Kasimu siempre bloqueado requieren de un PIN",
  label248: "Kasimu se iniciará siempre bloqueado",
  label249: "Cambiar idioma",
  label250: "Cambia el idioma de Kasimu",
  label251: "Mostrar botones en reproduciendo",
  label252:
    "Mostrar u ocultar botones (no programar, programar siempre, editar, agregar)",
  label253: "Activar restrinción a contenido explícito",
  label254:
    "Se restringe la programación de canciones con contenido inapropiado o abusivo",
  label255: "Modo de hibernación",
  label256: "Editar la configuración de hibernación",
  label257: "Agregar una configuración de hibernación",
  label258: "Agregar",
  label259: "El idioma de Kasimu se cambió a Inglés exitosamente.",
  label260: "Error, algo salió mal al cambiar el idioma de Kasimu a Inglés.",
  label261: "El idioma de Kasimu se cambió a Español exitosamente.",
  label262: "Error, algo salió mal al cambiar el idioma de Kasimu a Español.",
  label263: "El idioma de Kasimu se cambió a Francés exitosamente.",
  label264: "Error, algo salió mal al cambiar el idioma de Kasimu a Francés.",
  label265: "Barra de favoritos activada exitosamente.",
  label266: "Barra de favoritos desactivada exitosamente.",
  label267: "Error, algo salió mal al activar la barra de favoritos.",
  label268: "Error, algo salió mal al desactivar la barra de favoritos.",
  label269: "Kasimu bloqueado exitosamente.",
  label270: "Kasimu desbloqueado exitosamente.",
  label271: "Error, algo salió mal al bloquear Kasimu.",
  label272: "Error, algo salió mal al desbloquear Kasimu.",
  label273: "Bloqueo al iniciar Kasimu activado exitosamente.",
  label274: "Bloqueo al iniciar Kasimu desactivado exitosamente.",
  label275: "Error, algo salió mal al activar el bloqueo al iniciar Kasimu.",
  label276: "Error, algo salió mal al desactivar el bloqueo al iniciar Kasimu.",
  label277: "Botones en reproducción activado.",
  label278: "Botones en reproducción desactivado.",
  label279: "Error, algo salió mal al activar botones en reproducción.",
  label280: "Error, algo salió mal al desactivar botones en reproducción.",
  label281:
    "Activada la restringe la programación de canciones con contenido inapropiado o abusivo.",
  label282:
    "Desactivada la restringe la programación de canciones con contenido inapropiado o abusivo.",
  label283:
    "Error, algo salió mal al activar la restrincion a contenido explícito.",
  label284:
    "Error, algo salió mal al desactivar la restrincion a contenido explícito.",
  label285: "Activado el modo de hibernación.",
  label286: "Desactivado el modo de hibernación.",
  label287: "Error, algo salió mal al activar el modo de hibernación.",
  label288: "Error, algo salió mal al desactivar el modo de hibernación.",
  label289: "Agregar \n PIN",
  label290: "Editar \n PIN",
  label291: "PIN Actual",
  label292: "Nuevo PIN",
  label293: "Obligatorio (el PIN debe ser de 4 digitos)",
  label294: "Confirmar tu nuevo PIN",
  label295: "Error, sólo se permiten numeros en tu PIN.",
  label296: "Error, el PIN debe ser de 4 dígitos.",
  label297: "Error, la confirmación no es igual al nuevo PIN.",
  label298:
    "Error, el PIN es igual al anterior. Por favor, ingresa un nuevo PIN.",
  label299: "El PIN se guardó exitosamente.",
  label300: "El PIN se modificó exitosamente.",
  label301: "Error, algo salió mal al guardar el PIN.",
  label302: "Error, algo salió mal al modificar el PIN.",
  label303: "Agregar una configuración \n de hibernación",
  label304: "Editar una configuración \n de hibernación",
  label305: "Estado",
  label306: "Horario de hibernación",
  label307: "En",
  label308: "Cada día",
  label309: "Días especificados",
  label310: "La configuración de hibernación fue guardada exitosamente.",
  label311: "Error, algo salió mal al guardar configuración de hibernación.",
  label312: "La configuración de hibernación fue editada exitosamente.",
  label313: "Error, algo salió mal al editar configuración de hibernación.",
  label314: "Planificador activado exitosamente.",
  label315: "Planificador desactivado exitosamente.",
  label316: "Error, algo salió mal al activar el planificador.",
  label317: "Error, algo salió mal al desactivar el planificador.",
  label318: "Planificador Activado",
  label319: "Planificador Desactivado",
  label320: "Agregar plan \n para el",
  label321: "Playlists de Kasimu Freedom",
  label322: "Playlists de Kasimu Global",
  label323: "Mis playlists",
  label324: "Aleatorio",
  label325: "El plan se guardó exitosamente.",
  label326: "Error, algo salió mal guardando el plan.",
  label327: 'Error, la hora "Desde" es menor a la hora "Hasta".',
  label328: "Error, ya existe un plan en ese tramo horario.",
  label329: "Cargar \n anuncio",
  label330: "El plan se eliminó exitosamente.",
  label331: "Error, algo salió mal eliminado el plan.",
  label332: "Aleatorio activado exitosamente.",
  label333: "Aleatorio desactivado exitosamente.",
  label334: "Error, algo salió mal al activar el aleatorio.",
  label335: "Error, algo salió mal al desactivar el aleatorio.",
  label336: "Arrastra \n tu anuncio \n o haz clic \n en esta área",
  label337: "Tamaño",
  label338: "(Solo mp3).",
  label339: "Subir anuncio",
  label340: "Subiendo anuncio...",
  label341: "Error, (SOLO EN MP3).",
  label342: "El nombre del anuncio debe contener de 1 a 50 caracteres.",
  label343: "Error, algo salió mal subiendo el anuncio.",
  label344: "El anuncio se subió exitosamente.",
  label345: "El anuncio",
  label346: "Error, algo salio mal eliminando el anuncio",
  label347: "Deseas eliminar el anuncio",
  label348: "Se eliminará el anuncio",
  label349: "Todos los días",
  label350: "Lunes a viernes",
  label351: "Sábado y Domingo",
  label352: "Día exacto",
  label353: "Hora exacta",
  label354: "Intervalo",
  label355: "Cada",
  label356: "Frecuencia",
  label357: "canciones",
  label358: "Límite de anuncios por tanda",
  label359: "Inicio de la pauta",
  label360: "Fin de la pauta",
  label361: "Agregar \n pauta",
  label362: "Días",
  label363: "Horario",
  label364: "Rango de tiempo",
  label365: "Límite de anuncios por tanda",
  label366: "Cada x minutos",
  label367: "Cada x canciones",
  label368: "Día o días",
  label369: "Hora",
  label370: "Día",
  label371: "Definir inicio",
  label372: "Definir fin",
  label373: "Error, ya hay una pauta con ese nombre.",
  label374: "Error, ya hay una pauta en ese intervalo de tiempo.",
  label375: "Error, ya hay una pauta para esa hora exacta.",
  label376: "El medio",
  label377: "Error, algo salio mal eliminando el medio",
  label378: "Deseas eliminar el medio",
  label379: "Se eliminará el medio",
  label380: "hasta",
  label381: "Alta",
  label382: "Normal",
  label383:
    "Puedes cargar cualquier tipo de medio (spot, anuncio, entre canción, ID de radio) \n o crear tu propio tipo. Eliges el archivo a cargar, le pones un nombre, indicas qué tipo de medio es, \n asignas todos los valores, si hay versiones las puedes cargar e indicas las ubicaciones dónde sonará.",
  label384: "Definir inicio y fin",
  label385: "Elegir…",
  label386: "Inicio",
  label387: "Fin",
  label388: "Marca o producto",
  label389: "Empresa anunciante",
  label390: "Valores",
  label391: "Infinitas",
  label392: "Lugares donde \n se publicará",
  label393: "Todos los usuarios (locales)",
  label394: "Seleccionar usuarios (locales) o grupos",
  label395: "Usuarios (Locales)",
  label396: "Grupos",
  label397: "Error, algo salió mal cargando el medio.",
  label398: "El medio se guardó exitosamente.",
  label399: `Error, debes seleccionar una prioridad.`,
  label400: `Error, no seleccionaste ninguna ubicación para publicar.`,
  label401: `Error, debes seleccionar una marca o producto.`,
  label402: `Error, debes seleccionar un anunciante.`,
  label403: `Error, debes seleccionar un tipo de medio.`,
  label404: `Error, debes agregar un nombre de medio.`,
  label405: `Filtrar por`,
  label406: `Arrastra aquí los \n usuarios o grupos`,
  label407: `Error, Algo salió mal al editar medio.`,
  label408: `El medio se editó exitosamente.`,
  label409: `Agregar \n tipo de medio`,
  label410: `Error, algo salió mal guardando el tipo de medio.`,
  label411: `El Tipo de medio se guardó exitosamente.`,
  label412: `Error, el nombre del tipo de medio debe contener de 1 a 50 caracteres.`,
  label413: `Agregar \n anunciante`,
  label414: `Error, Algo salió mal guardando el anunciante.`,
  label415: `El anunciante se guardó exitosamente.`,
  label416: `Error, el nombre del anunciante debe contener de 1 a 50 caracteres.`,
  label417: `Agregar \n marca o producto`,
  label418: `Escribir aquí para buscar...`,
  label419: `Error, algo salió mal guardando la marca o producto.`,
  label420: `La marca o producto se guardó exitosamente.`,
  label421: `Error, el nombre de la marca o producto debe contener de 1 a 50 caracteres.`,
  label422: `Cargar \n versiones a`,
  label423: `La Versión`,
  label424: `Error, algo salio mal eliminando la versión`,
  label425: `Deseas eliminar la versión`,
  label426: `Se eliminará la versión`,
  label427: `Cancelar`,
  label428: `Error, algo salio mal cargando la versión.`,
  label429: `La versión se cargo exitosamente.`,
  label430: `Error. no se pudo cargar la versión, cierra la ventana e intenta de nuevo.`,
  label431: `Calendario activado exitosamente.`,
  label432: `Calendario desactivado exitosamente.`,
  label433: `Error, algo salió mal al activar el calendario.`,
  label434: `Error, algo salió mal al desactivar el calendario.`,
  label435: `Crear reloj`,
  label436: `Para crear un reloj, deber arrastrar los medios que aparecen en la parte izquierda hacia la derecha. Luego puedes arrastralos arriba o abajo para ordenalos. El medio "COMERCIAL" tiene por defecto 16 comerciales (spots o anuncios). El resto tiene 1 sólo medio por defecto. En ambos casos, se puede editar esa cifra pulsando el lápiz.`,
  label437: `Buscador de medios`,
  label438: `Arrastra los \n medios aquí`,
  label439: `Error, el nombre del reloj debe contener de 1 a 50 caracteres.`,
  label440: `Error, algo salió mal guardando el reloj.`,
  label441: `El reloj se guardó exitosamente.`,
  label442: `Error, no has agregado ningun medio.`,
  label443: `Administrar \n relojes`,
  label444: `Edita o elimina, si eliminas el reloj desaparece del calendario.`,
  label445: `El reloj`,
  label446: `Error, algo salio mal eliminando el reloj`,
  label447: `Deseas eliminar el reloj`,
  label448: `Se eliminará el reloj`,
  label449: `Cantidad`,
  label450: `Bloques de`,
  label451: `Error, algo salió mal editando`,
  label452: `Para editar un reloj, deber arrastrar los medios que aparecen en la parte izquierda hacia la derecha. Luego puedes arrastralos arriba o abajo para ordenalos. El medio "COMERCIAL" tiene por defecto 16 comerciales (spots o anuncios). El resto tiene 1 sólo medio por defecto. En ambos casos, se puede editar esa cifra pulsando el lápiz.`,
  label453: `El reloj se editó exitosamente.`,
  label454: `Asigna un reloj a cada tramo horario. Puedes dejar tramos sin asignar reloj, en ese caso, sólo se escuchará música (sin medios).`,
  label455: `Sin reloj asignado`,
  label456: `Error, algo salió mal guardando el reloj en un dia normal.`,
  label457: `El reloj en el dia normal se guardó exitosamente.`,
  label458: `Asignar relojes a día especial`,
  label459: `Repeticiones`,
  label460: `Día puntual`,
  label461: `Rango de días`,
  label462: `Error, el nombre del dia especial debe contener de 1 a 50 caracteres.`,
  label463: `El reloj en el día especial se guardó exitosamente.`,
  label464: `Error, Algo salió mal guardando el reloj en un día especial.`,
  label465: `Error, existe un reloj asignado a ese día puntual y su repeticion coincide o esta dentro de otro rango.`,
  label466: `Error, existe un reloj asignado en ese rango.`,
  label467: `Error, la fecha final es mayor a la fecha inicial.`,
  label468: `Editar o eliminar \n estructura del día`,
  label469: `Edita o elimina, al cambiar cualquier valor de los selectores se editara automaticamente, si eliminas el día desaparecera del calendario.`,
  label470: `Se actualizó correctamente este intervalo en la estructura del día.`,
  label471: `Error. algo salio mal editando este intervalo.`,
  label472: `Últimas dos conexiones`,
  label473: `Conectado el`,
  label474: `Finalizar`,
  label475: `Administrar \n medios`,
  label476: `Administrar \n empresas anunciantes`,
  label477: `Administrar \n marcas o productos`,
  label478: `Edita o elimina, no podras eliminar un tipo de medio ya asginado a algun medio ya cargado.`,
  label479: `Edita o elimina, no podras eliminar una empresa o anunciante ya asginado a algun medio ya cargado.`,
  label480: `Edita o elimina, no podras eliminar una marca o producto ya asginado a algun medio ya cargado.`,
  label481: `El Tipo de medio`,
  label482: `La Empresa o anunciante`,
  label483: `La Marca o producto`,
  label484: `Error, algo salio mal eliminando el tipo de medio`,
  label485: `Error, algo salio mal eliminando la empresa o anunciante`,
  label486: `Error, algo salio mal eliminando la marca o producto`,
  label487: `Deseas eliminar el tipo de medio`,
  label488: `Deseas eliminar la empresa o anunciante`,
  label489: `Deseas eliminar la marca o producto`,
  label490: `Se eliminará el tipo de medio`,
  label491: `Se eliminará la empresa o anunciante`,
  label492: `Se eliminará la marca o producto`,
  label493: `Ok`,
  label494: `Aviso`,
  label495: `No se puede eliminar este tipo de medio, ya que esta asignado a uno o varios medios ya cargados, si deseas eliminarlo deberas eliminar primero los medios asociados a el.`,
  label496: `No se puede eliminar esta empresa o anunciante, ya que esta asignada a uno o varios medios ya cargados, si deseas eliminarala deberas eliminar primero los medios asociados a ella.`,
  label497: `No se puede eliminar esta marca o producto, ya que esta asignada a uno o varios medios ya cargados, si deseas eliminarala deberas eliminar primero los medios asociados a ella.`,
  label498: `Has excedido el limite medios a cargar, disponibles para tu suscripción, ponte en contacto con nosotros a info@kasimu.com para amplicar las limitaciones de tu suscripción.`,
  label499: `Has excedido el limite de versiones por medio, disponibles para tu suscripción, ponte en contacto con nosotros a info@kasimu.com para amplicar las limitaciones de tu suscripción.`,
  label500: `Has excedido el limite del tamaño del anuncio disponibles para tu suscripción,  ponte en contacto con nosotros a info@kasimu.com para amplicar las limitaciones de tu suscripción.`,
  label501: `Has excedido el limite de tipo de medios que puedes crear, disponibles para tu suscripción, ponte en contacto con nosotros a info@kasimu.com para amplicar las limitaciones de tu suscripción.`,
  label502: `Actualmente tu límite de creación de tipo de medios es de: `,
  label503: `Actualmente tu límite de carga de medios es de: `,
  label504: `Actualmente tu límite de carga de versiones es de: `,
  label505: `Actualmente tu límite de tamaño por anuncio es de: `,
  label506: `Desea eliminar la pauta`,
  label507: `Se eliminará la pauta`,
  label508: `Agregar / quitar`,
  label509: `Número de anuncios cargado`,
  label510: `Agregar o quitar anuncios`,
  label511: `Agregar o quitar anuncios, debes arrastrar los anuncios que aparecen en la parte izquierda hacia la derecha. Luego puedes arrastrarlos de arriba o abajo para ordenarlos.`,
  label512: `Anuncios para la pauta`,
  label513: `Buscador de anuncios`,
  label514: `Arrastra los \n anuncios aquí`,
  label515: `Error, algo salió mal guardando la pauta.`,
  label516: `La pauta se guardó exitosamente.`,
  label517: `Error, algo salió mal editando la pauta.`,
  label518: `La pauta se editó exitosamente.`,
  label519: `Reporte discriminado por selección de medio ya borrado (Resumen)`,
  label520: `Reporte discriminado por selección de medio ya borrado (Detallado)`,
  label521: `Medio ya borrado`,
  label522: `Administrar \n estilos musicales`,
  label523: `Puedes crearte tus propios estilos musicales para luego ir a "ADMINISTRAR CANCIONES" y asignales las canciones que quieras que pertenezcan a ese o esos estilos.`,
  label524: `Escribe el nombre del estilo a buscar...`,
  label525: `El estilo`,
  label526: `Error, algo salio mal eliminando el estilo`,
  label527: `Deseas eliminar el estilo`,
  label528: `Se eliminará el estilo`,
  label529: `Crear \n estilo`,
  label530: `Crear estilo`,
  label531: `Error, Algo salió mal guardando el estilo.`,
  label532: `El estilo se guardó exitosamente.`,
  label533: `Error, el nombre del estilo debe contener de 1 a 50 caracteres.`,
  label534: `Error, algo salió mal al editar el estilo.`,
  label535: `El estilo se editó exitosamente.`,
  label536: `Editar \n estilo`,
  label537: `Administrar canciones`,
  label538: `Puedes cambiar el estilo de una canción a uno creado por el usuario, puedes cambiarle el nombre a la canción, indicarla como explícita (lenguaje inapropiado) o marcala como favorita o no deseada. También puedes asignarla a una playlist manual.`,
  label539: `Escribe aquí el nombre de la canción o intérprete o comentario o VDJ a buscar...`,
  label540: `Buscar`,
  label541: `Mostrar`,
  label542: `Todas`,
  label543: `Sólo editadas`,
  label544: `Favoritas`,
  label545: `No deseadas`,
  label546: `Editar \n canción`,
  label547: `Predeterminado`,
  label548: `Mis estilos`,
  label549: `Nombre original`,
  label550: `Estilo musical`,
  label551: `Humor`,
  label552: `Preferencia`,
  label553: `Canción explícita`,
  label554: `(contenido inapropiado o abusivo)`,
  label555: `Error, algo salió mal al editar la canción.`,
  label556: `La cancion se editó exitosamente.`,
  label557: `Error, el nombre de la cancion debe contener de 1 a 250 caracteres.`,
  label558: `Deseas desmarcar como favorita esta canción`,
  label559: `Deseas marcar como favorita esta canción`,
  label560: `ya no se incluirá siempre solo de manera normal.`,
  label561: `se incluirá siempre en las playlists asociadas al estilo de esta canción.`,
  label562: `Error, algo salio mal al desmarcar como favorita esta canción.`,
  label563: `Error, algo salio mal al marcar como favorita esta canción.`,
  label564: `La canción se desmarcó como favorita exitosamente.`,
  label565: `La canción se marcó como favorita exitosamente.`,
  label566: `Deseas desmarcar como no deseada esta canción`,
  label567: `Deseas marcar como no deseada esta canción`,
  label568: `se volverá a escuchar de manera normal.`,
  label569: `no se volverá a escuchar.`,
  label570: `Error, algo salio mal al desmarcar como no deseada esta canción.`,
  label571: `Error, algo salio mal al marcar como no deseada esta canción.`,
  label572: `La canción se desmarcó como no deseada exitosamente.`,
  label573: `La canción se marcó como no deseada exitosamente.`,
  label574: `Elegir playlist...`,
  label575: `País`,
  label576: `Ciudad`,
  label577: `Provincia`,
  label578: `Crear y editar \n playlists`,
  label579: `Puedes crear tus propias playlists, de dos formas diferentes. Automática: eliges los estilos y el programa elige las  canciones. Manual: eliges las canciones una a una. También puedes borrar playlists o modificar las existentes.`,
  label580: `La playlist`,
  label581: `Error, algo salio mal eliminando la playlists`,
  label582: `Deseas eliminar la playlist`,
  label583: `Se eliminará la playlist`,
  label584: `Playlist Manual`,
  label585: `Playlist Automática`,
  label586: `Escribe aquí el nombre de la playlist a buscar...`,
  label587: `Crear playlist`,
  label588: `Anuncios de la pauta`,
  label589: `Crear \n playlist`,
  label590: `Elige una opción.`,
  label591: `Automática`,
  label592: `Manual`,
  label593: `eliges los estilos y el programa elige las canciones.`,
  label594: `eliges las canciones una a una.`,
  label595: `Selecciona el tipo de playlist a crear...`,
  label596: `Nombre de la nueva playlist (obligatorio)...`,
  label597: `Descripción (opcional)...`,
  label598: `Siguiente`,
  label599: `Arrastra de izquierda a derecha los estilos que desees. Puedes arrastrar un estilo varias veces, para que \n se programen más canciones de ese estilo. Luego, en el panel de la derecha, puedes arrastra hacia arriba \n y abajo los estilos para dar orden. \n También puedes modificar los ajustes del estilo, para que programe canciones más tranquilas o movidas, etc.`,
  label600: `Escribe aquí el nombre del estilo a buscar...`,
  label601: `Buscador de estilos musicales`,
  label602: `Estilos de kasimu`,
  label603: `Mis estilos`,
  label604: `Duración (en horas)`,
  label605: `Restringir explícitas`,
  label606: `Arrastra los estilos aquí`,
  label607: `Error, algo salio mal creando la playlist automática.`,
  label608: `La playlists automática fue creada exitosamente.`,
  label609: `Error, no hay ningun estilo seleccionado para crear la playlist.`,
  label610: `Editar \n playlists automática`,
  label611: `Arrastra de izquierda a derecha los estilos que desees. Puedes arrastrar un estilo varias veces, para que \n se programen más canciones de ese estilo. Luego, en el panel de la derecha, puedes arrastra hacia arriba \n y abajo los estilos para dar orden. \n También puedes modificar los ajustes del estilo, para que programe canciones más tranquilas o movidas, etc.`,
  label612: `Error, algo salio mal editando la playlist automática.`,
  label613: `La playlist automática fue editada exitosamente.`,
  label614: `Error, el nombre de la playlists debe contener al menos un caracter.`,
  label615: `Modificar`,
  label616: `Solo éxitos`,
  label617: `Éxitos y recomendadas`,
  label618: `Éxitos y recomendadas y variadas`,
  label619: `Busca las canciones que quieras, por su nombre o por el nombre del intérprete. Luego arrastra \n cada canción de izquierda a derecha. En el panel de la derecha, puedes arrastra hacia arriba \n y abajo las canciones para ordenarlas.`,
  label620: `Buscador de canciones`,
  label621: `Arrastra las canciones aquí`,
  label622: `Error, algo salio mal creando la playlist manual.`,
  label623: `La playlists manual fue creada exitosamente.`,
  label624: `Error, el limite de canciones por playlist es de`,
  label625: `Editar \n playlist manual`,
  label626: `Error, algo salio mal editando la playlist manual.`,
  label627: `La playlists manual fue editada exitosamente.`,
  label628: `Estilos`,
  label629: `Número de canciones`,
  label630: `(Administrador) - `,
  label631: `(Usuario) - `,
  label632: `a playlists manuales`,
  label633: `Solo se pueden agregar a playlist manuales creadas por ti.`,
  label634: `Playlists de destino`,
  label635: `Agregando...`,
  label636: `La canción se agregó exitosamente.`,
  label637: `Error, algo salio mal agregando la canción.`,
  label638: `¿Desea cerrar la sesión?`,
  label639: ``,
  label640: ``,
  label641: ``,
  label642: ``,
  label643: ``,
  label644: ``,
  label645: ``,
  label646: ``,
  label647: ``,
  label648: ``,
  label649: ``,
  label650: ``,
  label651: ``,
  label652: ``,
  label653: ``,
  label654: ``,
  label655: ``,
  label656: ``,
  label657: ``,
  label658: ``,
  label659: ``,
  label660: ``,
  label661: ``,
  label662: ``,
};

export default es_ES;
