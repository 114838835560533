import React, { Component } from "react";
import PropTypes from "prop-types";
import { Panel, Button, Text, CardEvents } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class ProviderCard extends Component {
  state = {
    open: false,
  };

  handleView = () => {
    const { provider, onViewClick } = this.props;
    onViewClick && onViewClick(provider);
  };

  handleEdit = () => {
    const { provider, onEditClick } = this.props;
    onEditClick && onEditClick(provider);
  };

  handleDelete = () => {
    const { provider, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(provider);
  };

  render() {
    const { provider } = this.props;

    return (
      <div
        style={{
          lineBreak: "anywhere",
          marginBottom: !this.props.store.viewMobile && "3px",
        }}
        className={this.props.store.viewMobile && "mb-1"}
      >
        <CardEvents
          name={provider.name}
          isMobile={!this.props.store.viewMobile}
          padding={!this.props.store.viewMobile && "22px 20px"}
          style={{
            height: 65,
            backgroundColor: this.state.open && "#D33F20",
            borderRadius: !this.props.store.viewMobile && "5px",
          }}
          upper={false}
          content={
            this.state.open && (
              <div
                style={{
                  display: "flex",
                  gap: 35,
                }}
              >
                <div>
                  <Button
                    icon="search-plus"
                    kind="link"
                    color="basic"
                    size="xl"
                    lead
                    space
                    onClick={this.handleView}
                  />
                </div>
                {this.props.store.loggedInUser.checkPermission(34) ? (
                  <div>
                    <Button
                      kind="link"
                      color="basic"
                      size="xl"
                      icon="pen"
                      onClick={this.handleEdit}
                      space
                    />
                  </div>
                ) : null}
                {this.props.store.loggedInUser.checkPermission(35) ? (
                  <div>
                    <Button
                      kind="link"
                      color={provider.deleted == 1 ? "remove" : "basic"}
                      size="xl"
                      icon="trash"
                      onClick={this.handleDelete}
                      space
                    />
                  </div>
                ) : null}
              </div>
            )
          }
        >
          {provider.id != 1 &&
            (!this.props.store.viewMobile ? (
              <>
                {this.state.open ? (
                  <Button
                    kind="link"
                    color="basic"
                    size={"lg"}
                    iconKasimu={"arrow-left"}
                    space
                    onClick={() => this.setState({ open: false })}
                  />
                ) : (
                  <Button
                    kind="link"
                    color="special"
                    size={"lg"}
                    iconKasimu={"arrow-right"}
                    space
                    onClick={() => this.setState({ open: true })}
                  />
                )}
              </>
            ) : (
              <div className="flex">
                <Button
                  className="ml-3"
                  icon="search-plus"
                  kind="link"
                  color="basic"
                  size="lg"
                  lead
                  space
                  onClick={this.handleView}
                />
                {this.props.store.loggedInUser.checkPermission(34) ? (
                  <Button
                    kind="link"
                    color="basic"
                    size="lg"
                    icon="pen"
                    onClick={this.handleEdit}
                    space
                  />
                ) : null}
                {this.props.store.loggedInUser.checkPermission(35) ? (
                  <Button
                    kind="link"
                    color={provider.deleted == 1 ? "remove" : "basic"}
                    size="lg"
                    icon="trash"
                    onClick={this.handleDelete}
                    space
                  />
                ) : null}
              </div>
            ))}
        </CardEvents>
      </div>
    );
  }
}

ProviderCard.propTypes = {
  provider: PropTypes.object,
  onViewClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

ProviderCard.defaultProps = {
  provider: null,
  onViewClick: null,
  onEditClick: null,
  onDeleteClick: null,
};

export default withStore(withToastManager(ProviderCard));
