import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft, Columns, Column } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class EditTranslationsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.translationCategoriesPlaylists = props.currentTranslation.clone();
  }

  handleChangeFiled = (sender, value, name, validation) => {
    this.translationCategoriesPlaylists[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.translationCategoriesPlaylists
        .save()
        .andThen((res, responseError) => {
          if (responseError) {
            toastManager.add(
              "Error, algo salió mal al editar la traducción en " +
                this.translationCategoriesPlaylists.app_languages.name +
                ".",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          } else {
            toastManager.add(
              "La traducción en " +
                this.translationCategoriesPlaylists.app_languages.name +
                " se editó exitosamente.",
              {
                appearance: "success",
                autoDismiss: true,
              }
            );
          }
          this.handleClose();
        });
    });
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={this.translationCategoriesPlaylists.name === ""}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "350px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            weight="black"
            className="mb-2"
            multiline
            lead
          >
            {"Editar"}
            {"\n"}
            {"Traducción en " +
              this.translationCategoriesPlaylists.app_languages.name}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Field
            label={this.props.store.language.label53}
            labelNote={this.props.store.language.label76}
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            <TextInput
              name="alias"
              onChange={this.handleChangeFiled}
              value={this.translationCategoriesPlaylists.alias}
              autocomplete="nope"
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={this.props.store.viewMobile && "20px"}
            />
          </Field>
          <Field
            label={"Descripción"}
            labelNote={this.props.store.language.label76}
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            <TextInput
              name="description"
              onChange={this.handleChangeFiled}
              value={this.translationCategoriesPlaylists.description}
              autocomplete="nope"
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={this.props.store.viewMobile && "20px"}
            />
          </Field>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

EditTranslationsModal.propTypes = {
  currentTranslation: PropTypes.object,
  onClose: PropTypes.func,
};

EditTranslationsModal.defaultProps = {
  currentTranslation: null,
  onClose: null,
};

export default withToastManager(withStore(EditTranslationsModal));
