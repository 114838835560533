import { Store } from "ks_storybook";
import { User } from "../models";

class UsersStore extends Store {
  model = User;

  fetchSearch(value) {
    return this.search(
      { per_page: 10000, search: value },
      "accounts_search",
      true,
      "/users"
    );
  }

  fetchAccounts() {
    return this.search(
      { per_page: 10000 },
      "accounts",
      true,
      "/users/accounts"
    );
  }

  saveAsAccount(model) {
    var str = model.name;
    var pos = str.indexOf("@");
    if (pos === -1) {
      model.name = `@${model.name}`;
    }

    return this.save(model, "/users/create_account");
  }
}

export default UsersStore;
