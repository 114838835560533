import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

import {
  Text,
  TextInput,
  Button,
  Panel,
  Loader,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../../../Components/Select";
import {
  EmptyContent,
  Pagination,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  CreatePlaylistsModal,
  PlaylistAutomaticNewModal,
  PlaylistAutomaticEditModal,
  ModifyStyleModal,
  InformationModal,
  CSVModal,
  CategoriesPlaylistsModal,
  BlocksModal,
  PlaylistCard,
} from "../../../Components/Playlists";
import ReactTooltip from "react-tooltip";

@observer
class PlaylistsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPlaylist: null,
      showNewModal: false,
      showCSVModal: false,
      showInformationModal: false,
      showCategoriesPlaylistsModal: false,
      showBlocksModal: false,
      showEditModal: false,
      showDeletedModal: false,
      showModalAutomatic: false,
      showModalModifyStyle: false,
      modifyStyle: null,
      page: 1,
      catalogs: this.getCatalogsAll(),
      appLanguages: this.getAppLanguagesAll(),
      playlists: this.getPlaylistsSearch(25, null, null, null),
      filters: {
        _status: null,
        free_rights: -1,
      },
      orders: {
        _status: false,
      },
      orderList: [],
      newPlaylist: null,
    };
    this.per_page = 25;
    this.search = null;
  }

  componentDidMount() {
    this.props.store.menuTitle = "Playlists";
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  getCatalogsAll = () => {
    return this.props.store.catalogs.fetchAll();
  };

  getAppLanguagesAll = () => {
    return this.props.store.appLanguages.fetchAll();
  };

  getOptionsCatalogs = (obj) => {
    return obj.toArray().map((o) => ({ value: o.identifier, label: o.name }));
  };

  getOptionsAppLanguages = (obj) => {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  };

  getPlaylistsSearch = (per_page, filters, orderList, search) => {
    return this.props.store.playlists.fetchAllParams(
      per_page,
      filters,
      orderList,
      search
    );
  };

  validateFilter = (filters) => {
    if (filters._status || filters.free_rights != -1) return filters;
    return null;
  };

  onPageChange = (page) => {
    const { filters, orderList } = this.state;
    this.setState({
      playlists: this.props.store.playlists.fetchPage(
        this.per_page,
        page,
        this.validateFilter(filters),
        orderList.length == 0 ? null : orderList,
        this.search
      ),
      page: page,
    });
  };

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleSaveNewModal = () => {
    this.setState({ showNewModal: false });
    this.reload();
  };

  handleInformationModalOpen = (playlist) => {
    this.setState({ showInformationModal: true, currentPlaylist: playlist });
  };

  handleInformationModalClose = () => {
    this.setState({ showInformationModal: false, currentPlaylist: null });
  };

  handleCategoriesPlaylistsModalOpen = (playlist) => {
    this.setState({
      showCategoriesPlaylistsModal: true,
      currentPlaylist: playlist,
    });
  };

  handleCategoriesPlaylistsModalClose = () => {
    this.setState({
      showCategoriesPlaylistsModal: false,
      currentPlaylist: null,
    });
  };

  handleBlocksModalOpen = (playlist) => {
    this.setState({ showBlocksModal: true, currentPlaylist: playlist });
  };

  handleBlocksModalClose = () => {
    this.setState({ showBlocksModal: false, currentPlaylist: null });
  };

  handleEditModalOpen = (playlist) => {
    this.setState({ showEditModal: true, currentPlaylist: playlist });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false, currentPlaylist: null });
  };

  handleEditModalSave = () => {
    const { filters, orderList } = this.state;
    this.setState({
      showEditModal: false,
      currentPlaylist: null,
      playlists: this.getPlaylistsSearch(
        this.per_page,
        this.validateFilter(filters),
        orderList.length == 0 ? null : orderList,
        this.search
      ),
    });
  };

  handleDeleteConfirmationOpen = (playlist) => {
    this.setState({ showDeletedModal: true, currentPlaylist: playlist });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedModal: false, currentPlaylist: null });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentPlaylist: null });
    this.reload();
  };

  reload = () => {
    this.setState({ playlists: this.getPlaylistsSearch(25, null, null, null) });
  };

  handleCVSOpen = () => {
    this.setState({ showCSVModal: true });
  };

  handleCVSClose = () => {
    this.setState({ showCSVModal: false });
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  handleShowModalAutomatic = () => {
    this.setState({ showModalAutomatic: true });
  };

  handleCloseModalAutomatic = () => {
    this.setState({ showModalAutomatic: false });
  };

  handleSaveModalAutomatic = () => {
    this.handleCloseNewModal();
    this.handleCloseModalAutomatic();
    this.reload();
  };

  handleNext = (newPlaylist) => {
    this.setState({ newPlaylist: newPlaylist });
    this.handleShowModalAutomatic();
  };

  handleShowModalModifyStyle = (modifyStyle) => {
    this.setState({ showModalModifyStyle: true, modifyStyle: modifyStyle });
  };

  handleCloseModalModifyStyle = () => {
    this.setState({ showModalModifyStyle: false, modifyStyle: null });
  };

  handleSaveModalModifyStyle = (modifyStyle) => {
    this.setState({ showModalModifyStyle: false, modifyStyle: modifyStyle });
  };

  onSearch = () => {
    const { filters, orderList } = this.state;
    if (this.search && this.search != " ") {
      this.setState({
        playlists: this.props.store.playlists.fetchAllParams(
          this.per_page,
          this.validateFilter(filters),
          orderList.length == 0 ? null : orderList,
          this.search
        ),
        page: 1,
      });
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  handleSelectChange = (value, sender) => {
    const { filters, orderList } = this.state;
    const val = value.value;
    const newFilters = filters;
    switch (sender.name) {
      case "selectStatus":
        newFilters._status = val;
        break;
      case "selectFreeRights":
        newFilters.free_rights = val;
        break;
      default:
        break;
    }
    this.setState({
      filters: newFilters,
      playlists: this.getPlaylistsSearch(
        this.per_page,
        this.validateFilter(filters),
        orderList.length == 0 ? null : orderList,
        this.search
      ),
    });
  };

  getIndexOrder = (order) => {
    return this.state.orderList.indexOf(order);
  };

  handleDescending = (name) => {
    const { filters, orders, orderList } = this.state;
    let newOrders = orders;
    let orderLists = orderList;
    if (orderList.length == 0) {
      orderList.push("name");
    }
    if (name == "descStatus") {
      if (newOrders._status) {
        var index = this.getIndexOrder("-name");
        if (index !== -1) {
          orderLists[index] = "name";
        }
        newOrders._status = false;
      } else {
        var index = this.getIndexOrder("name");
        if (index !== -1) {
          orderLists[index] = "-name";
        }
        newOrders._status = true;
      }
    }
    this.setState({
      orders: newOrders,
      orderList: orderLists,
      playlists: this.getPlaylistsSearch(
        this.per_page,
        this.validateFilter(filters),
        orderList,
        this.search
      ),
    });
  };

  getPanel = (result, catalogsOptions) => {
    const statusOptions = [
      { value: 1, label: "Cargadas" },
      { value: 2, label: "Publicadas" },
      { value: 3, label: "Modificadas" },
    ];

    return (
      <Columns isMultiline isVCentered className="pb-2">
        <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
          <TextInput
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChangeSearch}
            placeholder={"Escribe aquí el nombre de la playlist a buscar..."}
            value={this.search && this.search}
            iconKasimu="search"
            paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            className="is-fullwidth"
            backgroundColor="grayDark"
            colorIcon="gray"
            invert
          />
        </Column>
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 6 }}
          style={{
            display: "flex",
            gap: this.props.store.viewMobile ? "20px" : "10px",
          }}
        >
          <div style={{ maxWidth: "250px", width: "100%" }}>
            <Select
              isMobile={!this.props.store.viewMobile}
              name="selectStatus"
              onChange={this.handleSelectChange}
              className="is-fullwidth"
              placeholder={"Filtrar por estado"}
              options={statusOptions}
              defaultValue={
                this.state.filters._status
                  ? statusOptions[
                      statusOptions
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(this.state.filters._status)
                    ]
                  : statusOptions[-1]
              }
            />
          </div>
          <div style={{ maxWidth: "250px", width: "100%" }}>
            <Select
              isMobile={!this.props.store.viewMobile}
              name="selectFreeRights"
              onChange={this.handleSelectChange}
              className="is-fullwidth"
              placeholder={"Filtrar por servicio"}
              options={catalogsOptions}
              defaultValue={
                catalogsOptions[
                  catalogsOptions
                    .map(function (e) {
                      return e.value;
                    })
                    .indexOf(this.state.filters.free_rights)
                ]
              }
            />
          </div>
          {/*<Button className="ml-3 is-pulled-right" kind="link" color="orange" size="xl" icon={this.state.orders._status?"sort-alpha-down-alt":"sort-alpha-down"} onClick={()=>{this.handleDescending("descStatus")}} space/>*/}
        </Column>
      </Columns>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderPaginator = (playlists) => {
    if (playlists.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            panel
            totalPages={playlists.totalPages}
            currentPage={playlists.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { playlists } = this.state;
    if (!playlists) return this.renderEmptyContent();
    return !playlists.isOk() ? this.renderLoader() : this.renderBody();
  };

  getService = (free_rights, catalogsOptions) => {
    if (!catalogsOptions) return null;
    const arr = catalogsOptions.filter(function (e) {
      return free_rights == e.value;
    });
    return arr.length > 0 ? arr[0].label : null;
  };

  renderRow = (playlists, catalogsOptions) => {
    const arrayPlaylists = playlists.toArray();
    return (
      <React.Fragment>
        {arrayPlaylists.length === 0 ? (
          <EmptyContent />
        ) : (
          <div className="mt-1">
            {arrayPlaylists.map((playlist) => (
              <PlaylistCard
                playlist={playlist}
                catalogsOptions={catalogsOptions}
                getService={this.getService}
                onViewClick={this.handleInformationModalOpen}
                onCategoryClick={this.handleCategoriesPlaylistsModalOpen}
                onBlockClick={this.handleBlocksModalOpen}
                onEditClick={this.handleEditModalOpen}
                onDeleteClick={this.handleDeleteConfirmationOpen}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  renderBody = () => {
    const {
      playlists,
      catalogs,
      appLanguages,
      currentPlaylist,
      filters,
      orderList,
      showNewModal,
      showInformationModal,
      showCategoriesPlaylistsModal,
      showBlocksModal,
      showEditModal,
      showDeletedModal,
      showCSVModal,
      showModalAutomatic,
      showModalModifyStyle,
      modifyStyle,
      newPlaylist,
    } = this.state;
    const catalogsOptions = this.getOptionsCatalogs(catalogs);
    const appLanguagesOptions = this.getOptionsAppLanguages(appLanguages);
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <Columns className={this.props.store.viewMobile ? "pb-4" : "pb-3"}>
              <Column
                isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
                className="flex"
                style={{
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 14,
                }}
              >
                {this.props.store.viewMobile && (
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    lead
                    weight="black"
                    lineBreak
                  >
                    {"Playlists"}
                  </Text>
                )}
                <div
                  className="flex items-center"
                  style={{ flexWrap: "wrap", gap: 14 }}
                >
                  <Button
                    icon={"file-csv"}
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    onClick={this.handleCVSOpen}
                    className="px-2 mx-0"
                  >
                    <strong>{"Exportar csv"}</strong>
                  </Button>
                  <Button
                    icon={"plus"}
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    onClick={this.handleShowNewModal}
                    className="px-2 mx-0"
                  >
                    <strong>{"Agregar"}</strong>
                  </Button>
                </div>
              </Column>
            </Columns>
            {this.getPanel(playlists.count, catalogsOptions)}

            <Text
              className="is-pulled-right"
              size="md"
              maxLength={this.props.mobile ? 50 : 250}
            >
              {"Resultados: " + playlists.count}
            </Text>
            {this.renderRow(playlists, catalogsOptions)}
            {this.renderPaginator(playlists)}
          </Column>
          {showNewModal && (
            <CreatePlaylistsModal
              catalogsOptions={catalogsOptions}
              appLanguagesOptions={appLanguagesOptions}
              onClose={this.handleCloseNewModal}
              onNext={this.handleNext}
            />
          )}
          {showModalAutomatic && (
            <PlaylistAutomaticNewModal
              newPlaylist={newPlaylist}
              onClose={this.handleCloseModalAutomatic}
              onOpend={this.handleShowModalModifyStyle}
              onSave={this.handleSaveModalAutomatic}
            />
          )}
          {showEditModal && (
            <PlaylistAutomaticEditModal
              currentPlaylist={currentPlaylist}
              onSave={this.handleEditModalSave}
              onOpend={this.handleShowModalModifyStyle}
              onClose={this.handleEditModalClose}
            />
          )}
          {showModalModifyStyle && (
            <ModifyStyleModal
              item={modifyStyle}
              onClose={this.handleCloseModalModifyStyle}
              onSave={this.handleSaveModalModifyStyle}
            />
          )}
          {showCSVModal && (
            <CSVModal
              onClose={this.handleCVSClose}
              filters={filters._status ? filters : null}
              orderList={orderList.length == 0 ? null : orderList}
              search={this.search}
            />
          )}
          {showInformationModal && (
            <InformationModal
              playlist={currentPlaylist}
              catalogsOptions={catalogsOptions}
              onClose={this.handleInformationModalClose}
            />
          )}
          {showCategoriesPlaylistsModal && (
            <CategoriesPlaylistsModal
              currentPlaylist={currentPlaylist}
              appLanguagesOptions={appLanguagesOptions}
              onClose={this.handleCategoriesPlaylistsModalClose}
            />
          )}
          {showBlocksModal && (
            <BlocksModal
              currentPlaylist={currentPlaylist}
              onClose={this.handleBlocksModalClose}
            />
          )}
          {showDeletedModal && (
            <DeleteConfirmationModal
              currentObj={currentPlaylist}
              onClose={this.handleDeleteConfirmationModalClose}
              onAccept={this.handleAcceptDeleteConfirmationModal}
              messageSuccess={
                'La playlist "' +
                currentPlaylist.description +
                '" se elimino exitosamente.'
              }
              messageError={
                'Error, algo salió mal al eliminar la playlist "' +
                currentPlaylist.description +
                '".'
              }
              title={"Deseas eliminar \n" + currentPlaylist.description + "?"}
              subtitle={
                "Se eliminará la playlist " + currentPlaylist.description + "."
              }
            />
          )}
        </Columns>
        <ReactTooltip id="info" place="bottom" type="dark" effect="float">
          <span>{"Más información"}</span>
        </ReactTooltip>
        <ReactTooltip id="catp" place="bottom" type="dark" effect="float">
          <span>{"Categorias playlists"}</span>
        </ReactTooltip>
        <ReactTooltip id="blocks" place="bottom" type="dark" effect="float">
          <span>{"Bloques"}</span>
        </ReactTooltip>
        <ReactTooltip id="edit" place="bottom" type="dark" effect="float">
          <span>{"Editar"}</span>
        </ReactTooltip>
        <ReactTooltip id="remove" place="bottom" type="dark" effect="float">
          <span>{"Eliminar"}</span>
        </ReactTooltip>
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>;
  }
}

export default withToastManager(withStore(PlaylistsPage));
