import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";

import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Loader,
  TextInput,
  Button,
  Column,
  Columns,
  Panel,
} from "ks_storybook";
import { InterpretersNewModal } from "../Interpreters";
import { EmptyContent, Pagination } from "../Common";

@observer
class SingerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      singers: this.getSingersSearch(25),
      page: 1,
      showNewModal: false,
    };
    this.per_page = 25;
    this.search = null;
  }

  getSingersSearch = (per_page) => {
    return this.props.store.singers.fetchAllParams(per_page, null, null, null);
  };

  onPageChange = (page) => {
    this.setState({
      singers: this.props.store.singers.fetchPage(
        this.per_page,
        page,
        null,
        null,
        this.search
      ),
      page: page,
    });
  };

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleSave = (singer) => {
    this.props.onSave && this.props.onSave(singer);
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  onSearch = () => {
    if (this.search && this.search != " ") {
      this.setState({
        singers: this.props.store.singers.fetchAllParams(
          this.per_page,
          null,
          null,
          this.search
        ),
        page: 1,
      });
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  getPanel = (result) => {
    return (
      <Columns isMultiline>
        <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
          <div className="is-row items-center is-fullwidth mb-1">
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              weight="medium"
            >
              {"Búsqueda puntual"}{" "}
            </Text>
            <Text
              className="ml-2"
              size="md"
              maxLength={this.props.mobile ? 50 : 250}
            >
              {"Resultados: " + result}
            </Text>
          </div>
          <TextInput
            iconKasimu="search"
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChangeSearch}
            placeholder={"Escribe aquí el nombre del intérprete a buscar..."}
            value={this.search && this.search}
            autocomplete="nope"
            backgroundColor="blackDark"
            borderColor="black"
            className="is-fullwidth my-1"
            size={this.props.store.viewMobile ? "xl" : "lg"}
            paddingInput={this.props.store.viewMobile && "20px"}
          />
        </Column>
      </Columns>
    );
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <div>
        <Loader
          backgroundColor="transparent"
          icon="sync-alt"
          label={this.props.store.language.label42}
          animation="spin"
        />
      </div>
    );
  };

  renderPaginator = (reports) => {
    if (reports.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={reports.totalPages}
            currentPage={reports.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { singers } = this.state;
    if (!singers) return this.renderEmptyContent();
    return !singers.isOk()
      ? this.renderLoader()
      : this.renderSingers(singers.toArray());
  };

  renderSingers = (singers) => {
    return (
      <React.Fragment>
        {this.getPanel(this.state.singers.count)}
        <div style={this.getContainerStyle()}>
          {singers.length > 0
            ? singers.map((obj) => (
                <Panel
                  color="gray"
                  padding="18px 24px"
                  className="mb-1"
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    overflowWrap: "anywhere",
                  }}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                    lineBreak
                  >
                    {obj.id + " | " + obj.name}
                  </Text>
                  <Button
                    className="is-pulled-right"
                    icon="share"
                    kind="link"
                    color="basic"
                    size="lg"
                    lead
                    space
                    onClick={() => {
                      this.handleSave(obj);
                    }}
                  />
                </Panel>
              ))
            : this.renderEmptyContent()}
        </div>
        <br />
        {this.renderPaginator(this.state.singers)}
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "700px" : "90%"}
          height={this.props.store.viewMobile ? "650px" : "auto"}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              weight="black"
              multiline
              lead
            >
              {"Modificar Intérprete"}
              {"\n"}
              {this.props.singer && this.props.singer.name}
            </Text>
          </ModalHeader>
          <ModalContent>
            <div className="is-pulled-right my-1">
              <Button
                icon={"plus"}
                size={this.props.store.viewMobile ? "lg" : "md"}
                onClick={this.handleShowNewModal}
                className="px-2"
              >
                <strong>{"Agregar"}</strong>
              </Button>
            </div>
            {this.renderHeader()}
          </ModalContent>
          <ModalFooter></ModalFooter>
        </Modal>
        {this.state.showNewModal && (
          <InterpretersNewModal onClose={this.handleCloseNewModal} />
        )}
      </React.Fragment>
    );
  }
}

SingerModal.propTypes = {
  singer: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

SingerModal.defaultProps = {
  singer: null,
  onClose: null,
  onSave: null,
};

export default withStore(SingerModal);
