import React, { Component, useRef, useLayoutEffect } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable, toJS } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Paragraph,
  RadioGroup,
  Radio,
  Toggle,
  Text,
  Columns,
  Column,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FixedSizeList } from "react-window";

function getStyle({ provided, style, isDragging, columnId }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };

  const columnOne = columnId == "column-1";

  const grid = 8;
  const marginBottom = 5;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    background: isDragging ? "#12C889" : columnOne ? "#2E2E2E" : "#1F1F1F",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${grid * 3}px`,
    borderRadius: grid,
    marginBottom,
  };
  return withSpacing;
}

const Item = ({ provided, item, style, isDragging, index, columnId, func }) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({ provided, style, isDragging, columnId })}
      className={`item ${isDragging ? "is-dragging" : ""}`}
    >
      <Text size="xl" multiline>
        {item.content}
      </Text>

      {columnId == "column-1" ? (
        <div className="is-row ml-1" style={{ alignItems: "center" }}>
          {item.remove && (
            <Button
              kind="link"
              color="basic"
              size="xl"
              iconKasimu="trash"
              onClick={() => {
                func(item.key);
              }}
              space
              className="ml-1"
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

const Row = (props) => {
  const { data, index, style } = props;
  const item = data.items[index];
  // We are rendering an extra item for the placeholder
  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={`${item.key}`} index={index} key={item.key}>
      {(provided) => (
        <Item
          provided={provided}
          item={item}
          style={style}
          columnId={data.columnId}
          func={data.func}
        />
      )}
    </Draggable>
  );
};

const ItemList = ({ column, index, func }) => {
  // There is an issue I have noticed with react-window that when reordered
  // react-window sets the scroll back to 0 but does not update the UI
  // I should raise an issue for this.
  // As a work around I am resetting the scroll to 0
  // on any list that changes it's index

  const listRef = useRef();
  useLayoutEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.items[rubric.source.index]}
          columnId={column.id}
        />
      )}
    >
      {(provided, snapshot) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        const itemCount = snapshot.isUsingPlaceholder
          ? column.items.length + 1
          : column.items.length;

        return (
          <FixedSizeList
            height={420}
            itemCount={itemCount}
            itemSize={60}
            width={"100%"}
            outerRef={provided.innerRef}
            itemData={{
              items: column.items,
              columnId: column.id,
              func: func,
            }}
            className="task-list"
            ref={listRef}
          >
            {Row}
          </FixedSizeList>
        );
      }}
    </Droppable>
  );
};

const Empty = ({ label }) => {
  return (
    <div style={{ position: "absolute" }}>
      <Text upper size="xl" weight="strong" lineBreak color="gray13">
        <span style={{ color: "#ccc" }}>{label}</span>
      </Text>
    </div>
  );
};

class ColumnDraggable extends Component {
  getColumnStyle = (index) => ({
    border: index == 1 && "2px solid #e8e8e8",
    borderRadius: "8px",
    ".item": {
      background: "red",
    },
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  render() {
    const { column, index, func, label1, label2 } = this.props;
    return (
      <Draggable draggableId={column.id} index={index}>
        {(provided) => (
          <div
            className={`ks--column is-12-mobile is-6-tablet is-6-desktop`}
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={this.getColumnStyle(index)}
          >
            <div {...provided.dragHandleProps} />
            {index == 0 && column.items.length == 0 && <Empty label={label2} />}
            {index == 1 && (
              <div
                style={{
                  position: "absolute",
                  border: "2px dashed #ccc",
                  padding: "10px 20px",
                  borderRadius: "16px",
                }}
              >
                <Text upper size="xl" weight="strong" lineBreak color="gray13">
                  <span style={{ color: "#ccc" }}>{label1}</span>
                </Text>
              </div>
            )}
            <ItemList column={column} index={index} func={func} />
          </div>
        )}
      </Draggable>
    );
  }
}

@observer
class UserTagSongModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      search: "",
      radioValue: 1,
      messageError: null,
      items: this.getItemsDataUserTags(),
      state: {
        columns: {
          "column-0": {
            id: "column-0",
            title: "First column",
            items: [],
          },
          "column-1": {
            id: "column-1",
            title: "Second column",
            items: [],
          },
        },
        columnOrder: ["column-0", "column-1"],
      },
      itemsRemove: [],
    };

    this.numSongBase = 15;
    this.grid = 4;
  }

  componentDidMount() {
    const { currentSong } = this.props;
    this.getUsersTaggedSongs(currentSong.id);
  }

  getItemsDataUserTags = () => {
    const { userTags } = this.props;
    var arr = [];
    userTags &&
      userTags.map((a) =>
        arr.push({
          key: uuidv4(),
          id: a.id,
          content: a.name,
          user_id: a.user_id,
          new: true,
          remove: true,
        })
      );
    return arr;
  };

  getItemsObj = (obj) => {
    const user = this.props.store.loggedInUser;
    var arr = [];
    obj &&
      obj.map((a) =>
        user.id == a.user_id
          ? arr.push({
              key: uuidv4(),
              id: a.tag_id,
              content: a.tag_name,
              user_id: a.user_id,
              new: false,
              remove: true,
            })
          : arr.push({
              key: uuidv4(),
              id: a.tag_id,
              content: a.tag_name + " - " + a.username,
              user_id: a.user_id,
              new: false,
              remove: false,
            })
      );
    return arr;
  };

  result = (res) => {
    const { items, search, state } = this.state;

    const newSate = state;
    const arrayItems1 = this.filterItems(items, search);
    const arrayItems2 = this.getItemsObj(res.results);

    newSate.columns["column-0"].items = arrayItems1;
    newSate.columns["column-1"].items = arrayItems2;
    this.setState({ state: newSate });
  };

  getUsersTaggedSongs = (song_id) => {
    this.props.store.kasimuAPIClient
      .search("/users_tagged_songs/get_users_tagged_songs", {
        song_id: song_id,
      })
      .then((res) => {
        this.result(res);
      });
  };

  filterItems = (array, search) => {
    array = array.filter(
      (el) => el.content.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
    return array;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  validLength = (value) => {
    if (value.length >= 1) {
      return true;
    } else {
      return false;
    }
  };

  handleSave = () => {
    const { toastManager, currentSong } = this.props;
    const { state, itemsRemove } = this.state;
    const user = this.props.store.loggedInUser;

    const b = state.columns["column-1"].items.map((i) => i);
    const news = b.filter((obj) => {
      return obj.user_id === user.id && obj.new === true;
    });
    let bander = false;
    if (this.validLength(news)) {
      bander = true;
    }
    if (this.validLength(itemsRemove)) {
      bander = true;
    }

    if (bander) {
      const params = {
        user_id: user.id,
        song_id: currentSong.id,
        deleted: 0,
        new_tags: news.map((i) => i),
        remove_tags: itemsRemove.map((i) => i),
      };
      this.setState({ isSaving: true }, () => {
        this.props.store.kasimuAPIClient
          .post("/users_tagged_songs/save_and_remove_users_tag_song", params)
          .then(
            (res) => {
              toastManager.add(
                "La canción fue etiqueta o desetiquetar con éxito.",
                {
                  appearance: "success",
                  autoDismiss: true,
                }
              );
              this.props.onSave && this.props.onSave();
            },
            (error) => {
              toastManager.add(
                "Error, algo salió mal al etiquetar o desetiquetar la canción.",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
              this.handleClose();
            }
          )
          .catch((error) => {
            toastManager.add(
              "Error, algo salió mal al etiquetar o desetiquetar la canción.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          });
      });
    } else
      this.setState({
        messageError:
          "Error, no hay etiquetas nuevas para agregar ni para borrar.",
      });
  };

  handleChangeSearch = (sender, value, name) => {
    const newSate = this.state.state;
    const arrayItems = this.filterItems(this.state.items, value);
    newSate.columns["column-0"].items = arrayItems;
    this.setState({ state: newSate, search: value });
  };

  deleted = (key) => {
    const lists = this.state.state.columns["column-1"].items;
    const itemsRemove = this.state.itemsRemove;

    lists.filter((obj) => {
      if (obj.key === key && obj.new === false) itemsRemove.push(obj);
    });

    var index = lists
      .map((x) => {
        return x.key;
      })
      .indexOf(key);

    lists.splice(index, 1);

    const newSate = this.state.state;
    newSate.columns["column-1"].items = lists;

    this.setState({
      state: newSate,
      messageError: null,
      itemsRemove: itemsRemove,
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];
    const results = destClone.filter((obj) => {
      return obj.user_id === item.user_id && obj.id === item.id;
    });
    if (results.length === 0) {
      destClone.splice(droppableDestination.index, 0, {
        ...item,
        key: uuidv4(),
      });
    }
    return destClone;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  onDragEnd = (result) => {
    const { state } = this.state;

    if (!result.destination) {
      return;
    }

    if (result.type === "column") {
      // if the list is scrolled it looks like there is some strangeness going on
      // with react-window. It looks to be scrolling back to scroll: 0
      // I should log an issue with the project
      const columnOrder = this.reorderList(
        state.columnOrder,
        result.source.index,
        result.destination.index
      );
      this.setState({
        state: {
          ...state,
          columnOrder,
        },
      });
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      const column = state.columns[result.source.droppableId];
      const items = this.reorderList(
        column.items,
        result.source.index,
        result.destination.index
      );

      // updating column entry
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [column.id]: {
            ...column,
            items,
          },
        },
      };
      this.setState({ state: newState });
      return;
    }

    // moving between lists
    const sourceColumn = state.columns[result.source.droppableId];
    const destinationColumn = state.columns[result.destination.droppableId];
    const { source, destination } = result;

    // eliminado de la lista
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case "column-0":
        const result = this.copy(
          sourceColumn.items,
          destinationColumn.items,
          source,
          destination
        );

        const newSate = this.state.state;
        newSate.columns["column-1"].items = result;

        this.setState({
          state: newSate,
          messageError: null,
        });
        break;
    }
  };

  renderDragDrop = () => {
    const { state } = this.state;
    return (
      <React.Fragment>
        {state && state.columnOrder && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="app">
              <Droppable
                droppableId="all-droppables"
                direction="horizontal"
                type="column"
              >
                {(provided) => (
                  <div
                    className="ks--columns"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {state.columnOrder.map((columnId, index) => (
                      <>
                        <ColumnDraggable
                          key={columnId}
                          column={state.columns[columnId]}
                          index={index}
                          func={this.deleted}
                          label1={"Arrastra las equitas aquí"}
                          label2={this.props.store.language.label89}
                        />
                      </>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        )}
      </React.Fragment>
    );
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              /*disabled={
                this.state.state.columns["column-1"].items.length > 0
                  ? false
                  : true
              }*/
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  renderSearch = () => {
    return (
      <Columns className="mb-2" isMultiline>
        <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
          <Field
            label={"Buscador de etiquetas"}
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            <TextInput
              name="search"
              iconKasimu="search"
              onChange={this.handleChangeSearch}
              placeholder={"Escribe aquí el nombre de la etiqueta a buscar…"}
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={this.props.store.viewMobile && "20px"}
            />
          </Field>
        </Column>
        <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            className={this.props.store.viewMobile ? "mt-3" : "mt-0"}
            weight="black"
            lineBreak
            lead
          >
            {"Etiquetas actuales"}
          </Text>
        </Column>
      </Columns>
    );
  };

  render() {
    const { currentSong, indice } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "940px" : "90%"}
        height={this.props.store.viewMobile ? "640px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            weight="black"
            multiline
            lead
          >
            {"Cancion a etiquetar \n " + indice + " | " + currentSong.name}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            color="special"
            lineBreak
          >
            {
              "Arrastra de izquierda a derecha la etiqueta que desees agregar., puedes también eliminar etiquetas propias en una canción, No se puede repetir la misma etiqueta en una misma canción."
            }
          </Text>
          <br />
          {this.renderSearch()}
          {this.renderDragDrop()}
          <br />
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

UserTagSongModal.propTypes = {
  currentSong: PropTypes.object,
  userTags: PropTypes.Array,
  onOpend: PropTypes.func,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

UserTagSongModal.defaultProps = {
  currentSong: null,
  userTags: [],
  onOpend: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(UserTagSongModal));
