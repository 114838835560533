import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../../Components/Select";

@observer
class ModifyStyleModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  moodEnabled = (key, moodMin, moodMax) => {
    if (key == 6 && 1 >= moodMin && 2 <= moodMax) return true;
    if (key == 7 && 2 >= moodMin && 3 <= moodMax) return true;
    if (key == 8 && 2 >= moodMin && 4 <= moodMax) return true;
    if (key == 9 && 3 >= moodMin && 4 <= moodMax) return true;
    if (key == 10 && 1 >= moodMin && 5 <= moodMax) return true;
    if (key >= moodMin && key <= moodMax) return true;
    else return false;
  };

  handleChangeSelect = (value, sender) => {
    const val = !value ? 1 : value.value;
    switch (sender.name) {
      case "mood":
        this.props.item.mood_pre = val;
        break;
      case "preference":
        this.props.item.preference = val;
        break;
      default:
        break;
    }
  };

  handleChangeFiled = (sender, value, name, validation) => {
    switch (name) {
      case "bpm_min":
        this.props.item.bpm_min = value;
        break;
      case "bpm_max":
        this.props.item.bpm_max = value;
        break;
      default:
        break;
    }
  };

  handleSave = () => {
    this.handleClose();
  };

  getOptionsMood = () => {
    const options = [
      { value: 1, label: this.props.store.language.mood[1] },
      { value: 2, label: this.props.store.language.mood[2] },
      { value: 3, label: this.props.store.language.mood[3] },
      { value: 4, label: this.props.store.language.mood[4] },
      { value: 5, label: this.props.store.language.mood[5] },
      { value: 6, label: this.props.store.language.mood[6] },
      { value: 7, label: this.props.store.language.mood[7] },
      { value: 8, label: this.props.store.language.mood[8] },
      { value: 9, label: this.props.store.language.mood[9] },
      { value: 10, label: this.props.store.language.mood[10] },
    ];
    return options;
  };

  getOptionsPreference = () => {
    let items = [];
    for (let i = 1; i <= 6; i++) {
      items.push({ value: i, label: i });
    }
    return items;
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft />
        <LevelRight>
          <Button
            kind="outline"
            color="basic"
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-3"
            onClick={this.handleClose}
          >
            {this.props.store.language.label427}
          </Button>
          <Button
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="is-pulled-right px-3"
            onClick={this.handleSave}
          >
            <strong>{this.props.store.language.label127}</strong>
          </Button>
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { item } = this.props;
    let optionsMood = this.getOptionsMood();
    let optionsPreference = this.getOptionsPreference();
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "700px" : "90%"}
        height={this.props.store.viewMobile ? "300px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            weight="black"
            multiline
            lead
          >
            {this.props.store.language.label615 + "\n" + item.content}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Columns isMultiline>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
              <Field
                label={this.props.store.language.label551 + ":"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <Select
                  isMobile={!this.props.store.viewMobile}
                  isSearchable={false}
                  name={"mood"}
                  onChange={this.handleChangeSelect}
                  options={optionsMood}
                  defaultValue={
                    optionsMood[
                      optionsMood
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(item.mood_pre)
                    ]
                  }
                  isOptionDisabled={(optionsMood) =>
                    true !==
                    this.moodEnabled(
                      optionsMood.value,
                      item.mood_min,
                      item.mood_max
                    )
                  }
                  placeholder={this.props.store.language.label385}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  backgroundColor="black"
                  separatorColor="black"
                  className="is-fullwidth my-1"
                />
              </Field>
              <Field
                label={"Bpm min:"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  type="number"
                  name="bpm_min"
                  value={item.bpm_min}
                  onChange={this.handleChangeFiled}
                  minlength={1}
                  maxlength={200}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </Column>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
              <Field
                label={this.props.store.language.label552 + ":"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <Select
                  isMobile={!this.props.store.viewMobile}
                  isSearchable={false}
                  name={"preference"}
                  onChange={this.handleChangeSelect}
                  options={optionsPreference}
                  defaultValue={
                    optionsPreference[
                      optionsPreference
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(item.preference)
                    ]
                  }
                  placeholder={this.props.store.language.label385}
                  backgroundColor="black"
                  separatorColor="black"
                  className="is-fullwidth my-1"
                />
              </Field>
              <Field
                label={"Bpm max:"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  type="number"
                  name="bpm_max"
                  value={item.bpm_max}
                  onChange={this.handleChangeFiled}
                  minlength={1}
                  maxlength={200}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </Column>
          </Columns>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

ModifyStyleModal.propTypes = {
  item: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

ModifyStyleModal.defaultProps = {
  item: null,
  onSave: null,
  onClose: null,
};

export default withStore(ModifyStyleModal);
