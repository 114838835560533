import React, { PureComponent } from "react";

import PropTypes from "prop-types";
import SelectReact, { components } from "react-select";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";
import { Button } from "ks_storybook";

const animatedComponents = makeAnimated();
const colorLabel = "#FF3F1F";
class SelectIcons extends PureComponent {
  render() {
    const {
      options,
      isMulti,
      isClearable,
      onChange,
      defaultValue,
      isAsync,
      backgroundColor,
      iconColor,
      separatorColor,
      textColor,
      placeholderColor,
      space,
      isMobile,
      size,
      ...otherProps
    } = this.props;

    const SelectComponent = isAsync ? AsyncSelect : SelectReact;

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <div
            style={{
              width: !space && "50px",
              display: "flex",
              justifyContent: "center",
              paddingRight: "10px",
            }}
          >
            <Button
              iconKasimu="arrow-down"
              kind="link"
              size={size}
              color={iconColor || "grayLight"}
              space
            />
          </div>
        </components.DropdownIndicator>
      );
    };

    const { Option } = components;

    const IconOption = (props) => (
      <Option {...props}>
        <img
          src={props.data.icon}
          style={{ width: 36 }}
          alt={props.data.label}
        />
        <div>{props.data.label}</div>
      </Option>
    );

    const colourStyles = {
      input: (styles) => ({
        ...styles,
        color: textColor || "#999999",
        padding: isMobile && "0px",
        "active,:hover": {
          borderColor: colorLabel,
        },
      }),
      placeholder: (styles) => ({
        ...styles,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "90%",
        color: placeholderColor || "#999999",
      }),
      control: (styles) => ({
        ...styles,
        minHeight: "2.25em",
        borderWidth: "2px",
        boxShadow: "none",
        paddingLeft: "10px",
        borderRadius: 20,
        width: "100%",
        backgroundColor: backgroundColor || "#1F1F1F",
        borderColor: "transparent",
        color: "white",
        "&:active,&:hover,:focus": {
          borderStyle: "solid",
          borderWidth: "2px",
          borderColor: colorLabel,
        },
        fontSize: isMobile ? "14px" : "16px",
      }),
      menu: (styles) => ({
        ...styles,
        zIndex: 100,
        borderRadius: 20,
        backgroundColor: "#1B1B1B",
      }),
      indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: separatorColor || "#242424",
        margin: "0px",
        width: "3px",
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        color: iconColor || "#999999",
        padding: "0px",
      }),

      option: (styles, state) => ({
        ...styles,
        backgroundColor: "#1B1B1B",
        borderRadius: 20,
        cursor: state.isDisabled ? "not-allowed" : "default",
        opacity: state.isDisabled ? 0.5 : 1,
        color: "white",
        ":active,:hover": {
          backgroundColor: state.isDisabled ? "transparent" : colorLabel,
        },
      }),
      multiValue: (styles) => {
        return {
          ...styles,
          //borderRadius: 20,
          //backgroundColor: "#1B1B1B",
          backgroundColor: "transparent",
        };
      },
      singleValue: (styles) => ({
        ...styles,
        color: textColor || "#999999",
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: textColor || "#999999",
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        color: colorLabel,
        cursor: "pointer",
        //backgroundColor: "#1B1B1B",
        ":hover": {
          //color: 'white',
          //backgroundColor: "red"
        },
      }),
    };

    return (
      <SelectComponent
        {...otherProps}
        options={options}
        isMulti={isMulti}
        styles={colourStyles}
        components={{
          animatedComponents,
          DropdownIndicator,
          Option: IconOption,
        }}
        //components={{animatedComponents, DropdownIndicator}}
        isClearable={isClearable}
        classNamePrefix="select"
        onChange={onChange}
        defaultValue={defaultValue}
      />
    );
  }
}

SelectIcons.propTypes = {
  classes: PropTypes.any,
  className: PropTypes.string,
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.array,
  isAsync: PropTypes.bool,
  backgroundColor: PropTypes.string,
  iconColor: PropTypes.string,
  separatorColor: PropTypes.string,
  textColor: PropTypes.string,
  placeholderColor: PropTypes.string,
  space: PropTypes.bool,
  size: PropTypes.string,
  isMobile: PropTypes.bool,
};

SelectIcons.defaultProps = {
  classes: null,
  className: null,
  options: [],
  isMulti: false,
  isClearable: false,
  onChange: null,
  defaultValue: [],
  isAsync: false,
  backgroundColor: null,
  iconColor: null,
  separatorColor: null,
  textColor: null,
  placeholderColor: null,
  space: false,
  size: "lg",
  isMobile: false,
};

export default SelectIcons;
