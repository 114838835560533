import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Loader,
  Button,
  Panel,
} from "ks_storybook";
import moment from "moment";
import { withToastManager } from "react-toast-notifications";
import { EmptyContent, Pagination } from "../Common";

@observer
class UsersListenedSongsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRestore: false,
      listened_songs: this.getUsersListenedSongsAll(
        5,
        props.userId,
        props.song.id
      ),
      per_page: 5,
      page: 1,
    };
    this.song = props.song.clone();
  }

  getUsersListenedSongsAll = (per_page, user_id, song_id) => {
    return this.props.store.usersListenedSongs.fetchSearch(
      per_page,
      user_id,
      song_id
    );
  };

  onPageChange = (page) => {
    const { per_page } = this.state;
    this.setState({
      listened_songs: this.props.store.usersListenedSongs.movePage(
        per_page,
        page,
        this.props.userId,
        this.props.song.id
      ),
      page: page,
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        backgroundColor="transparent"
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  renderPaginator = (listenedSongs) => {
    if (listenedSongs.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={listenedSongs.totalPages}
            currentPage={listenedSongs.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { listened_songs } = this.state;
    if (!listened_songs) return this.renderEmptyContent();
    return !listened_songs.isOk()
      ? this.renderLoader()
      : this.renderListenedSongs(listened_songs.toArray());
  };

  renderListenedSongs = (listened_songs) => {
    return (
      <React.Fragment>
        <br />
        <div style={this.getContainerStyle()}>
          {listened_songs.length > 0
            ? listened_songs.map((obj) => (
                <div
                  style={{
                    lineBreak: "anywhere",
                    marginBottom: !this.props.store.viewMobile && "3px",
                  }}
                  className={this.props.store.viewMobile && "mb-1"}
                >
                  <Panel
                    color={"gray"}
                    padding={
                      this.props.store.viewMobile ? "16px 30px" : "14px 10px"
                    }
                    style={{
                      minHeight: 85,
                      justifyContent: "center",
                      backgroundColor: this.state.open && "#D33F20",
                      borderRadius: !this.props.store.viewMobile && "5px",
                    }}
                  >
                    <div
                      className="flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                        lineBreak
                      >
                        {"Fecha de creación: " +
                          moment(obj.created_at).format("DD-MM-YYYY HH:mm:ss") +
                          " | Duración de la reproducción: " +
                          moment
                            .utc(obj.listening_time * 1000)
                            .format("HH:mm:ss")}
                      </Text>
                    </div>
                  </Panel>
                </div>
              ))
            : this.renderEmptyContent()}
        </div>

        <Text className="is-pulled-right mt-1" size="xl" lineBreak>
          {"Resultado: " + this.state.listened_songs.count}
        </Text>

        <br />
        {listened_songs.length > 0 ? (
          <Text className="is-pulled-left mt-1" size="xl" lineBreak>
            {"Duración total de la reproducción: " +
              moment
                .utc(listened_songs[0].listening_time_sum * 1000)
                .format("HH:mm:ss")}
          </Text>
        ) : null}
        <br />
        {this.renderPaginator(this.state.listened_songs)}
      </React.Fragment>
    );
  };

  render() {
    const { song, userName } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "750px" : "90%"}
        height={this.props.store.viewMobile ? "550px" : "auto"}
      >
        <ModalHeader>
          <Text
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            weight="strong"
            lead
          >
            {" Id: " + song.id}
            {"\n"}
            {song.name}
            {"\n"}
            {"Escuchada por: " + userName}
          </Text>
        </ModalHeader>
        <ModalContent>{this.renderHeader()}</ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

UsersListenedSongsModal.propTypes = {
  song: PropTypes.object,
  userId: PropTypes.number,
  userName: PropTypes.number,
  onClose: PropTypes.func,
};

UsersListenedSongsModal.defaultProps = {
  song: null,
  userId: 0,
  userName: "",
  onClose: null,
};

export default withToastManager(withStore(UsersListenedSongsModal));
