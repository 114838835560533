import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Loader,
  Button,
  Img,
  Columns,
  Column,
  Panel,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { EmptyContent, Pagination, DeleteConfirmationModal } from "../Common";
import {
  AddTranslationsModal,
  EditTranslationsModal,
  TranslationCard,
} from ".";

@observer
class TranslationsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTranslation: null,
      showAddTranslation: false,
      showDeletedModal: false,
      showEditModal: false,
      translations: this.getTranslationAll(25, props.currentCategory.id),
      per_page: 25,
      page: 1,
    };
  }

  getTranslationAll = (per_page, category_id) => {
    return this.props.store.translationCategories.fetchSearch(
      per_page,
      category_id
    );
  };

  onPageChange = (page) => {
    const { per_page } = this.state;
    this.setState({
      translations: this.props.store.translationCategories.movePage(
        per_page,
        page,
        this.props.currentCategory.id
      ),
      page: page,
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleDeleteConfirmationOpen = (translation) => {
    this.setState({ showDeletedModal: true, currentTranslation: translation });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedModal: false, currentTranslation: null });
  };

  handleEditModalOpen = (translation) => {
    this.setState({ showEditModal: true, currentTranslation: translation });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false, currentTranslation: null });
  };

  handleEditModalSave = () => {
    this.setState({ showEditModal: false, currentTranslation: null });
    this.reload();
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentTranslation: null });
    this.reload();
  };

  handleTranslationModelOpen = () => {
    this.setState({ showAddTranslation: true });
  };

  handleTranslationModelClose = () => {
    this.setState({ showAddTranslation: false });
  };

  handleTranslationModelSave = () => {
    this.setState({ showAddTranslation: false });
    this.reload();
  };

  reload = () => {
    this.setState({
      translations: this.getTranslationAll(25, this.props.currentCategory.id),
    });
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        backgroundColor="transparent"
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  renderPaginator = (reports) => {
    if (reports.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={reports.totalPages}
            currentPage={reports.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { translations } = this.state;
    if (!translations) return this.renderEmptyContent();
    return !translations.isOk()
      ? this.renderLoader()
      : this.renderTranslations(translations.toArray());
  };

  getGen = (key) => {
    const gen = { 0: "No", 1: "Si" };
    return gen[key];
  };

  renderTranslations = (translations) => {
    return (
      <React.Fragment>
        <br />
        <div style={this.getContainerStyle()}>
          {translations.length > 0
            ? translations.map((obj) => (
                <TranslationCard
                  obj={obj}
                  onEditClick={this.handleEditModalOpen}
                  onDeleteClick={this.handleDeleteConfirmationOpen}
                />
              ))
            : this.renderEmptyContent()}
        </div>
        {
          <Text className="is-pulled-right mt-1" size="xl" lineBreak>
            {"Resultado: " + this.state.translations.count}
          </Text>
        }
        <br />
        {this.renderPaginator(this.state.translations)}
      </React.Fragment>
    );
  };

  render() {
    const {
      currentTranslation,
      showAddTranslation,
      showDeletedModal,
      showEditModal,
      translations,
    } = this.state;
    const arrayTranslations = translations.toArray();
    const { currentCategory, appLanguagesOptions } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "850px" : "90%"}
          height={this.props.store.viewMobile ? "650px" : "auto"}
        >
          <ModalHeader>
            <div className="flex items-center">
              <Img
                height={this.props.store.viewMobile ? "180px" : "100px"}
                width={this.props.store.viewMobile ? "180px" : "100px"}
                src={currentCategory.icon_path}
              />

              <div
                style={{
                  lineBreak: "anywhere",
                }}
              >
                <Text
                  className="ml-1"
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  weight="strong"
                  lineBreak
                  upper
                  lead
                >
                  {"Traducciones"}
                  {"\n"}
                  {" Id: " + currentCategory.id}
                  {"\n"}
                  {currentCategory.name}
                </Text>
              </div>
            </div>
          </ModalHeader>
          <ModalContent>
            {arrayTranslations.length < appLanguagesOptions.length && (
              <Button
                icon={"plus"}
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="is-pulled-right mb-2 px-2"
                onClick={this.handleTranslationModelOpen}
              >
                <strong>{"Agregar"}</strong>
              </Button>
            )}
            {this.renderHeader()}
          </ModalContent>
          <ModalFooter></ModalFooter>
        </Modal>
        {showEditModal && (
          <EditTranslationsModal
            currentTranslation={currentTranslation}
            onSave={this.handleEditModalSave}
            onClose={this.handleEditModalClose}
          />
        )}
        {showDeletedModal && (
          <DeleteConfirmationModal
            currentObj={currentTranslation}
            onClose={this.handleDeleteConfirmationModalClose}
            onAccept={this.handleAcceptDeleteConfirmationModal}
            messageSuccess={
              'La traducción en "' +
              currentTranslation.app_languages.name +
              '" se elimino exitosamente.'
            }
            messageError={
              'Error, algo salió mal al eliminar la traducción en "' +
              currentTranslation.app_languages.name +
              '".'
            }
            title={
              "Deseas eliminar la traducción en \n" +
              currentTranslation.app_languages.name +
              "?"
            }
            subtitle={
              "Se eliminará la traducción en " +
              currentTranslation.app_languages.name +
              "."
            }
          />
        )}
        {showAddTranslation && (
          <AddTranslationsModal
            seeList={false}
            appLanguagesOptions={appLanguagesOptions}
            selected={arrayTranslations}
            currentCategory={currentCategory}
            onClose={this.handleTranslationModelClose}
            onSave={this.handleTranslationModelSave}
          />
        )}
      </React.Fragment>
    );
  }
}

TranslationsModal.propTypes = {
  currentCategory: PropTypes.object,
  onClose: PropTypes.func,
};

TranslationsModal.defaultProps = {
  currentCategory: null,
  onClose: null,
};

export default withToastManager(withStore(TranslationsModal));
