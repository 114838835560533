import React, { Component } from "react";
import PropTypes from "prop-types";
import { Panel, Button, Text, Img } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class CategoryPlaylistCard extends Component {
  state = {
    open: false,
  };

  handleEdit = () => {
    const { obj, onEditClick } = this.props;
    onEditClick && onEditClick(obj);
  };

  handleDelete = () => {
    const { obj, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(obj);
  };

  render() {
    const { obj } = this.props;

    return (
      <div
        style={{
          lineBreak: "anywhere",
          marginBottom: !this.props.store.viewMobile && "3px",
        }}
        className={this.props.store.viewMobile && "mb-1"}
      >
        <Panel
          color={"gray"}
          padding={this.props.store.viewMobile ? "16px 30px" : "14px 10px"}
          style={{
            minHeight: 85,
            justifyContent: "center",
            backgroundColor: this.state.open && "#D33F20",
            borderRadius: !this.props.store.viewMobile && "5px",
          }}
        >
          <div
            className="flex"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {this.state.open ? (
              <div className="flex">
                <Button
                  className="is-pulled-right"
                  icon="pen"
                  kind="link"
                  color="basic"
                  size="xl"
                  lead
                  space
                  onClick={this.handleEdit}
                />
                <Button
                  data-for="trash"
                  className="is-pulled-right ml-3"
                  icon="trash"
                  kind="link"
                  color="basic"
                  size="xl"
                  lead
                  space
                  onClick={this.handleDelete}
                />
              </div>
            ) : (
              <div>
                <Text
                  className="ml-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  lineBreak
                >
                  {"Id: " +
                    obj.id +
                    " | Estilo: " +
                    obj.song_styles.description}
                </Text>

                <Text
                  className="ml-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  lineBreak
                >
                  {"Humor: " +
                    obj.song_mood.description +
                    " | Preferencia: " +
                    obj.preference +
                    " | Duración: " +
                    obj.duration}
                </Text>

                <Text
                  className="ml-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  lineBreak
                >
                  {"BPM min: " + obj.bpm_min + " | BPM max: " + obj.bpm_max}
                </Text>
              </div>
            )}

            {this.props.store.viewMobile ? (
              <div className="flex">
                <Button
                  className="is-pulled-right ml-3"
                  icon="pen"
                  kind="link"
                  color="basic"
                  size="lg"
                  lead
                  space
                  onClick={this.handleEdit}
                />
                <Button
                  data-for="trash"
                  className="is-pulled-right ml-3"
                  icon="trash"
                  kind="link"
                  color="basic"
                  size="lg"
                  lead
                  space
                  onClick={this.handleDelete}
                />
              </div>
            ) : (
              <div className="flex">
                {this.state.open ? (
                  <Button
                    kind="link"
                    color="basic"
                    size={"lg"}
                    iconKasimu={"arrow-left"}
                    space
                    onClick={() => this.setState({ open: false })}
                  />
                ) : (
                  <Button
                    kind="link"
                    color="special"
                    size={"lg"}
                    iconKasimu={"arrow-right"}
                    space
                    onClick={() => this.setState({ open: true })}
                  />
                )}
              </div>
            )}
          </div>
        </Panel>
      </div>
    );
  }
}

CategoryPlaylistCard.propTypes = {
  obj: PropTypes.object,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

CategoryPlaylistCard.defaultProps = {
  obj: null,
  onEditClick: null,
  onDeleteClick: null,
};

export default withStore(withToastManager(CategoryPlaylistCard));
