import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import { Media, MediaLeft, MediaContent, Content } from "bloomer";
import {
  Text,
  TextInput,
  Button,
  Panel,
  Loader,
  Img,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../../../Components/Select";
import {
  EmptyContent,
  Pagination,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  InformationModal,
  EditModal,
  CSVModal,
  TranslationsModal,
  CategoriesNewModal,
} from "../../../Components/Categories";
import ReactTooltip from "react-tooltip";

@observer
class CategoriesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCategory: null,
      showNewModal: false,
      showCSVModal: false,
      showInformationModal: false,
      showTranslationsModal: false,
      showEditModal: false,
      showDeletedModal: false,
      page: 1,
      categories: this.getCategoriesSearch(25, null, null, null),
      catalogs: this.getCatalogsAll(),
      appLanguages: this.getAppLanguagesAll(),
      filters: {
        _status: null,
        list_view: null,
        free_rights: -1,
      },
      orders: {
        _status: false,
      },
      orderList: [],
    };
    this.per_page = 25;
    this.search = null;
  }

  componentDidMount() {
    this.props.store.menuTitle = "Categorías";
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  getCatalogsAll = () => {
    return this.props.store.catalogs.fetchAll();
  };

  getAppLanguagesAll = () => {
    return this.props.store.appLanguages.fetchAll();
  };

  getOptionsCatalogs = (obj) => {
    return obj.toArray().map((o) => ({ value: o.identifier, label: o.name }));
  };

  getOptionsAppLanguages = (obj) => {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  };

  getCategoriesSearch = (per_page, filters, orderList, search) => {
    return this.props.store.categories.fetchAllParams(
      per_page,
      filters,
      orderList,
      search
    );
  };

  validateFilter = (filters) => {
    if (filters._status || filters.list_view || filters.free_rights != -1)
      return filters;
    return null;
  };

  onPageChange = (page) => {
    const { filters, orderList } = this.state;
    this.setState({
      categories: this.props.store.categories.fetchPage(
        this.per_page,
        page,
        this.validateFilter(filters),
        orderList.length == 0 ? null : orderList,
        this.search
      ),
      page: page,
    });
  };

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleSaveNewModal = () => {
    this.setState({ showNewModal: false });
    this.reload();
  };

  handleInformationModalOpen = (category) => {
    this.setState({ showInformationModal: true, currentCategory: category });
  };

  handleInformationModalClose = () => {
    this.setState({ showInformationModal: false, currentCategory: null });
  };

  handleTranslationsModalOpen = (category) => {
    this.setState({ showTranslationsModal: true, currentCategory: category });
  };

  handleTranslationsModalClose = () => {
    this.setState({ showTranslationsModal: false, currentCategory: null });
  };

  handleEditModalOpen = (category) => {
    this.setState({ showEditModal: true, currentCategory: category });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false, currentCategory: null });
  };

  handleEditModalSave = () => {
    const { filters, orderList } = this.state;
    this.setState({
      showEditModal: false,
      currentCategory: null,
      categories: this.getCategoriesSearch(
        this.per_page,
        this.validateFilter(filters),
        orderList.length == 0 ? null : orderList,
        this.search
      ),
    });
  };

  handleDeleteConfirmationOpen = (category) => {
    this.setState({ showDeletedModal: true, currentCategory: category });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedModal: false, currentCategory: null });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentCategory: null });
    this.reload();
  };

  reload = () => {
    this.setState({
      categories: this.getCategoriesSearch(25, null, null, null),
    });
  };

  handleCVSOpen = () => {
    this.setState({ showCSVModal: true });
  };

  handleCVSClose = () => {
    this.setState({ showCSVModal: false });
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  onSearch = () => {
    const { filters, orderList } = this.state;
    if (this.search && this.search != " ") {
      this.setState({
        categories: this.props.store.categories.fetchAllParams(
          this.per_page,
          this.validateFilter(filters),
          orderList.length == 0 ? null : orderList,
          this.search
        ),
        page: 1,
      });
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  handleSelectChange = (value, sender) => {
    const { filters, orderList } = this.state;
    const val = value.value;
    const newFilters = filters;
    switch (sender.name) {
      case "selectStatus":
        newFilters._status = val;
        break;
      case "selectRow":
        newFilters.list_view = val;
        break;
      case "selectFreeRights":
        newFilters.free_rights = val;
        break;
      default:
        break;
    }
    this.setState({
      filters: newFilters,
      categories: this.getCategoriesSearch(
        this.per_page,
        this.validateFilter(filters),
        orderList.length == 0 ? null : orderList,
        this.search
      ),
    });
  };

  getIndexOrder = (order) => {
    return this.state.orderList.indexOf(order);
  };

  handleDescending = (name) => {
    const { filters, orders, orderList } = this.state;
    let newOrders = orders;
    let orderLists = orderList;
    if (orderList.length == 0) {
      orderList.push("name");
    }
    if (name == "descStatus") {
      if (newOrders._status) {
        var index = this.getIndexOrder("-name");
        if (index !== -1) {
          orderLists[index] = "name";
        }
        newOrders._status = false;
      } else {
        var index = this.getIndexOrder("name");
        if (index !== -1) {
          orderLists[index] = "-name";
        }
        newOrders._status = true;
      }
    }
    this.setState({
      orders: newOrders,
      orderList: orderLists,
      categories: this.getCategoriesSearch(
        this.per_page,
        this.validateFilter(filters),
        orderList,
        this.search
      ),
    });
  };

  getPanel = (catalogsOptions) => {
    const statusOptions = [
      { value: 1, label: "Cargadas" },
      { value: 2, label: "Publicadas" },
      { value: 3, label: "Modificadas" },
    ];
    const rowOptions = [
      { value: 1, label: "Elegir por tipo de local" },
      { value: 2, label: "Elegir por estilo de música" },
      { value: 3, label: "Elegir por otros criterios" },
    ];

    return (
      <Columns isMultiline isVCentered className="pb-2">
        <Column isSize={{ mobile: 12, tablet: 12, desktop: 5 }}>
          <TextInput
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChangeSearch}
            placeholder={"Escribe aquí el nombre de la Categoría a buscar..."}
            value={this.search && this.search}
            iconKasimu="search"
            paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            className="is-fullwidth"
            backgroundColor="grayDark"
            colorIcon="gray"
            invert
          />
        </Column>
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 7 }}
          style={{
            display: "flex",
            gap: this.props.store.viewMobile ? "20px" : "10px",
          }}
        >
          <div style={{ maxWidth: "200px", width: "100%" }}>
            <Select
              isMobile={!this.props.store.viewMobile}
              name="selectStatus"
              onChange={this.handleSelectChange}
              className="is-fullwidth"
              placeholder={"Filtrar por estado"}
              options={statusOptions}
              defaultValue={
                this.state.filters._status
                  ? statusOptions[
                      statusOptions
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(this.state.filters._status)
                    ]
                  : statusOptions[-1]
              }
            />
          </div>
          <div style={{ maxWidth: "250px", width: "100%" }}>
            <Select
              isMobile={!this.props.store.viewMobile}
              name="selectFreeRights"
              onChange={this.handleSelectChange}
              className="is-fullwidth"
              placeholder={"Filtrar por servicio"}
              options={catalogsOptions}
              defaultValue={
                catalogsOptions[
                  catalogsOptions
                    .map(function (e) {
                      return e.value;
                    })
                    .indexOf(this.state.filters.free_rights)
                ]
              }
            />
          </div>
          <div style={{ maxWidth: "200px", width: "100%" }}>
            <Select
              isMobile={!this.props.store.viewMobile}
              name="selectRow"
              onChange={this.handleSelectChange}
              className=" is-fullwidth"
              placeholder={"Filtrar por fila"}
              options={rowOptions}
              defaultValue={
                this.state.filters.list_view
                  ? rowOptions[
                      rowOptions
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(this.state.filters.list_view)
                    ]
                  : rowOptions[-1]
              }
            />
          </div>
        </Column>
      </Columns>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderPaginator = (categories) => {
    if (categories.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            panel
            totalPages={categories.totalPages}
            currentPage={categories.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { categories } = this.state;
    if (!categories) return this.renderEmptyContent();
    return !categories.isOk() ? this.renderLoader() : this.renderBody();
  };

  /*renderItemsRow(category) {
        const rowOptions =[
            { value: 1, label: 'Elegir por tipo de local' },
            { value: 2, label: 'Elegir por estilo de música' },
            { value: 3, label: 'Elegir por otros criterios' }
        ]
            return(  <Columns isMobile isVCentered isMarginless isGapless className="ks--box">
                        <Column isSize={{mobile: 1, desktop: 1, tablet: 1}}>
                            <Img height="80px" width="80px" src={ category.icon_path }/>
                        </Column>
                        <Column isSize={{mobile: 10, desktop: 10, tablet: 10}}>
                            <Text color='white' size="sm" lead weight="medium" upper multiline>{category.name}</Text>
                            <Text color='white' size="xl" weight="light" multiline>{category.description}</Text>
                            <Text color='white' size="xl" weight="light" multiline>{'Fila: '+rowOptions[rowOptions.map(function(e) { return e.value; }).indexOf(category.list_view)].label+' | Nº Traducciones: '+(category.translation?category.translation.length:0)}</Text>                             
                        </Column>
                        <Column isSize={{mobile: 1, desktop: 1, tablet: 1}}>
                            <div style={{ display: "flex", padding:"0px"}}>
                                <div style={{display:'flex'}}>
                                    <div data-tip data-for='info'>
                                        <Button
                                            className="ml-3"
                                            icon="search-plus"
                                            kind="link"
                                            color="special"
                                            size="lg"
                                            lead
                                            space
                                            onClick={() => {this.handleInformationModalOpen(category)}}
                                        />
                                    </div>
                                    <div data-tip data-for='language'>
                                        <Button
                                            className="ml-3"
                                            icon="language"
                                            kind="link"
                                            color="special"
                                            size="lg"
                                            lead
                                            space
                                            onClick={() =>{this.handleTranslationsModalOpen(category)}}
                                        />
                                    </div>
                                    <div data-tip data-for='edit'>
                                        <Button
                                            className="ml-3"
                                            kind="link"
                                            color='special'
                                            size='lg'
                                            icon='pen'
                                            onClick={() => {this.handleEditModalOpen(category)}}
                                            space
                                        />
                                    </div>
                                    <div data-tip data-for='remove'>
                                        <Button
                                            className="ml-3"
                                            kind="link"
                                            color={category.deleted==1?"remove":"special"}
                                            size='lg'
                                            icon='trash'
                                            onClick={() => {this.handleDeleteConfirmationOpen(category)}}
                                            space
                                        />     
                                    </div>
                                </div>
                            </div>
                        </Column>
                    </Columns>
            );
    }*/

  renderItemsRow(category) {
    const rowOptions = [
      { value: 1, label: "Elegir por tipo de local" },
      { value: 2, label: "Elegir por estilo de música" },
      { value: 3, label: "Elegir por otros criterios" },
    ];
    return (
      <Panel
        color="gray"
        padding={this.props.store.viewMobile ? "15px 28px" : "22px 20px"}
        style={{ height: "100%", display: "flex", justifyContent: "center" }}
      >
        <div className="flex items-center">
          <div className="mr-2" style={{ flexShrink: 0 }}>
            <div className="flex items-center">
              <Img
                width="100%"
                style={{
                  maxWidth: this.props.store.viewMobile ? "150px" : "120px",
                }}
                src={category.icon_path}
              />
            </div>
          </div>
          <div>
            <Text
              color="white"
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="bold"
              multiline
            >
              {category.name}
            </Text>
            <div className="my-1">
              <Text
                // color="white"
                color="special"
                size={this.props.store.viewMobile ? "lg" : "md"}
                multiline
              >
                {category.description}
              </Text>
              <Text
                color="special"
                size={this.props.store.viewMobile ? "lg" : "md"}
                multiline
              >
                {"Fila: " +
                  rowOptions[
                    rowOptions
                      .map(function (e) {
                        return e.value;
                      })
                      .indexOf(category.list_view)
                  ].label +
                  " | Nº Traducciones: " +
                  (category.translation ? category.translation.length : 0)}
              </Text>
            </div>

            <div style={{ display: "flex" }}>
              <div data-tip data-for="info">
                <Button
                  icon="search-plus"
                  kind="link"
                  color="basic"
                  size="lg"
                  lead
                  space
                  onClick={() => {
                    this.handleInformationModalOpen(category);
                  }}
                />
              </div>
              <div data-tip data-for="language">
                <Button
                  className="ml-3"
                  icon="language"
                  kind="link"
                  color="basic"
                  size="lg"
                  lead
                  space
                  onClick={() => {
                    this.handleTranslationsModalOpen(category);
                  }}
                />
              </div>
              <div data-tip data-for="edit">
                <Button
                  className="ml-3"
                  kind="link"
                  color="basic"
                  size="lg"
                  icon="pen"
                  onClick={() => {
                    this.handleEditModalOpen(category);
                  }}
                  space
                />
              </div>
              <div data-tip data-for="remove">
                <Button
                  className="ml-3"
                  kind="link"
                  color={category.deleted == 1 ? "remove" : "basic"}
                  size="lg"
                  icon="trash"
                  onClick={() => {
                    this.handleDeleteConfirmationOpen(category);
                  }}
                  space
                />
              </div>
            </div>
          </div>
        </div>
      </Panel>
    );
  }

  renderRow = (categories) => {
    const arraycategories = categories.toArray();
    return (
      <React.Fragment>
        {arraycategories.length === 0 ? (
          <EmptyContent />
        ) : (
          <Columns style={{ gap: this.props.store.viewMobile ? 8 : 4 }}>
            {arraycategories.map((category) => (
              <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
                {this.renderItemsRow(category)}
              </Column>
            ))}
          </Columns>
        )}
      </React.Fragment>
    );
  };

  renderBody = () => {
    const {
      categories,
      catalogs,
      appLanguages,
      currentCategory,
      filters,
      orderList,
      showNewModal,
      showInformationModal,
      showEditModal,
      showTranslationsModal,
      showDeletedModal,
      showCSVModal,
    } = this.state;
    const catalogsOptions = this.getOptionsCatalogs(catalogs);
    const appLanguagesOptions = this.getOptionsAppLanguages(appLanguages);
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <Columns className={this.props.store.viewMobile ? "pb-4" : "pb-3"}>
              <Column
                isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
                className="flex"
                style={{
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 14,
                }}
              >
                {this.props.store.viewMobile && (
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    lead
                    weight="black"
                    lineBreak
                  >
                    {"Categorías"}
                  </Text>
                )}
                <div
                  className="flex items-center"
                  style={{ flexWrap: "wrap", gap: 14 }}
                >
                  <Button
                    icon={"file-csv"}
                    className="px-2 mx-0"
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    onClick={this.handleCVSOpen}
                  >
                    <strong>{"Exportar csv"}</strong>
                  </Button>
                  <Button
                    icon={"plus"}
                    className="px-2 mx-0"
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    onClick={this.handleShowNewModal}
                  >
                    <strong>{"Agregar"}</strong>
                  </Button>
                  {/*<Button className="ml-3 is-pulled-right" kind="link" color="orange" size="xl" icon={this.state.orders._status?"sort-alpha-down-alt":"sort-alpha-down"} onClick={()=>{this.handleDescending("descStatus")}} space/>*/}
                </div>
              </Column>
            </Columns>
            {this.getPanel(catalogsOptions)}
            <Text
              className="is-pulled-right"
              size={this.props.store.viewMobile ? "md" : "sm"}
              maxLength={this.props.mobile ? 50 : 250}
            >
              {"Resultados: " + categories.count}
            </Text>
            <div className="my-1">{this.renderRow(categories)}</div>
            {this.renderPaginator(categories)}
          </Column>
          {showNewModal && (
            <CategoriesNewModal
              catalogsOptions={catalogsOptions}
              appLanguagesOptions={appLanguagesOptions}
              onSave={this.handleSaveNewModal}
              onClose={this.handleCloseNewModal}
            />
          )}
          {showCSVModal && (
            <CSVModal
              onClose={this.handleCVSClose}
              filters={filters._status ? filters : null}
              orderList={orderList.length == 0 ? null : orderList}
              search={this.search}
            />
          )}
          {showInformationModal && (
            <InformationModal
              category={currentCategory}
              catalogsOptions={catalogsOptions}
              onClose={this.handleInformationModalClose}
            />
          )}
          {showTranslationsModal && (
            <TranslationsModal
              currentCategory={currentCategory}
              appLanguagesOptions={appLanguagesOptions}
              onClose={this.handleTranslationsModalClose}
            />
          )}
          {showEditModal && (
            <EditModal
              currentCategory={currentCategory}
              onSave={this.handleEditModalSave}
              onClose={this.handleEditModalClose}
            />
          )}
          {showDeletedModal && (
            <DeleteConfirmationModal
              currentObj={currentCategory}
              onClose={this.handleDeleteConfirmationModalClose}
              onAccept={this.handleAcceptDeleteConfirmationModal}
              messageSuccess={
                'La categoría "' +
                currentCategory.name +
                '" se elimino exitosamente.'
              }
              messageError={
                'Error, algo salió mal al eliminar la categoría "' +
                currentCategory.name +
                '".'
              }
              title={"Deseas eliminar \n" + currentCategory.name + "?"}
              subtitle={
                "Se eliminará la categoría " + currentCategory.name + "."
              }
            />
          )}
        </Columns>
        <ReactTooltip id="info" place="bottom" type="dark" effect="float">
          {" "}
          <span>{"Más información"}</span>
        </ReactTooltip>
        <ReactTooltip id="language" place="bottom" type="dark" effect="float">
          {" "}
          <span>{"Traducciones"}</span>
        </ReactTooltip>
        <ReactTooltip id="edit" place="bottom" type="dark" effect="float">
          {" "}
          <span>{"Editar"}</span>
        </ReactTooltip>
        <ReactTooltip id="remove" place="bottom" type="dark" effect="float">
          {" "}
          <span>{"Eliminar"}</span>
        </ReactTooltip>
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>;
  }
}

export default withToastManager(withStore(CategoriesPage));
