import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Field,
  Button,
  Checkbox,
  Columns,
  Column,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import moment from "moment";

@observer
class SongCSVModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGen: false,
      columns: {
        id: false,
        name: false,
        singer__name: false,
        song_mood__description: false,
        song_styles__description: false,
        song_lenguages__description: false,
        record_label__name: false,
        bpm: false,
        preference: false,
        song_path: false,
        duration: false,
        year: false,
        remix: false,
        explicit: false,
        free_rights: false,
        commentary: false,
        load_num: false,
        orig_file_name: false,
        provider__name: false,
        status: false,
        reproductions: false,
        deleted: false,
        created_at: false,
        updated_at: false,
      },
      columnsLists: [],
    };
  }

  getIndex = (column) => {
    return this.state.columnsLists.indexOf(column);
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleChange = (sender, value, name) => {
    let columns = this.state.columns;
    let columnsLists = this.state.columnsLists;
    columns[name] = value;
    if (value) {
      columnsLists.push(name);
    } else {
      columnsLists = columnsLists.filter((i) => name !== i);
    }
    this.setState({ columnsLists: columnsLists, columns: columns });
  };

  downloadCSV = () => {
    const { columnsLists } = this.state;
    const { toastManager, filters, orderList, search } = this.props;
    this.setState({ isGen: true }, () => {
      this.props.store.kasimuAPIClient
        .getCSV(
          "/songs/get_csv/?filters=" +
            JSON.stringify(filters) +
            "&orders=" +
            JSON.stringify(orderList) +
            "&columns=" +
            JSON.stringify(columnsLists) +
            "&search=" +
            search
        )
        .then((result) => {
          if (result.status == 200) {
            result.content.then((res) => {
              const exportName =
                "Songs_" +
                  moment(new Date()).format("DD-MM-YYYY HH:mm:ss") +
                  ".csv" || "export.csv";
              const blob = new Blob([res], { type: "text/csv;charset=ascii;" });
              if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, exportName);
              } else {
                const link = document.createElement("a");
                if (link.download !== undefined) {
                  const url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", exportName);
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }
              toastManager.add("CSV generado y descargado exitosamente.", {
                appearance: "success",
                autoDismiss: true,
              });
              this.handleClose();
            });
          } else {
            toastManager.add(
              "Error, algo salió mal generando y descargando el csv.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            this.setState({ isGen: false });
          }
        })
        .catch((error) => {
          toastManager.add(
            "Error, algo salió mal generando y descargando el csv.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          this.setState({ isGen: false });
        });
    });
  };

  renderButtons = () => {
    const { columns } = this.state;
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isGen ? (
            <Button
              className="is-pulled-right px-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong>{"Descargando..."}</strong>
            </Button>
          ) : (
            <Button
              icon={"file-download"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={
                !(
                  columns.id ||
                  columns.name ||
                  columns.singer__name ||
                  columns.song_mood__description ||
                  columns.song_styles__description ||
                  columns.song_lenguages__description ||
                  columns.record_label__name ||
                  columns.bpm ||
                  columns.preference ||
                  columns.song_path ||
                  columns.duration ||
                  columns.year ||
                  columns.remix ||
                  columns.explicit ||
                  columns.free_rights ||
                  columns.commentary ||
                  columns.load_num ||
                  columns.orig_file_name ||
                  columns.provider__name ||
                  columns.status ||
                  columns.reproductions ||
                  columns.deleted ||
                  columns.created_at ||
                  columns.updated_at
                )
              }
              className="is-pulled-right px-2"
              onClick={this.downloadCSV}
            >
              <strong>{"Descargar csv"}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { columns } = this.state;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "600px" : "90%"}
        height={this.props.store.viewMobile ? "500px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            weight="black"
            lead
          >
            {"Exportar csv de canciones"}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Field
            label={"Columnas"}
            labelNote={"Obligatorio seleccionar al menos una columna."}
            size={this.props.store.viewMobile ? "xl" : "lg"}
          >
            <Columns className="my-2" isMultiline isGapless>
              <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
                <Checkbox
                  invert
                  key={"id"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.id}
                  value={"id"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.id ? this.getIndex("id") + 1 + " - " + "Id" : "Id"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"name"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.name}
                  value={"name"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.name
                      ? this.getIndex("name") + 1 + " - " + "Nombre"
                      : "Nombre"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"singer__name"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.singer__name}
                  value={"singer__name"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.singer__name
                      ? this.getIndex("singer__name") + 1 + " - " + "Interprete"
                      : "Interprete"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"song_mood__description"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.song_mood__description}
                  value={"song_mood__description"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.song_mood__description
                      ? this.getIndex("song_mood__description") +
                        1 +
                        " - " +
                        "Humor"
                      : "Humor"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"song_styles__description"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.song_styles__description}
                  value={"song_styles__description"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.song_styles__description
                      ? this.getIndex("song_styles__description") +
                        1 +
                        " - " +
                        "Estilo"
                      : "Estilo"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"song_lenguages__description"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.song_lenguages__description}
                  value={"song_lenguages__description"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.song_lenguages__description
                      ? this.getIndex("song_lenguages__description") +
                        1 +
                        " - " +
                        "Idioma"
                      : "Idioma"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"record_label__name"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.record_label__name}
                  value={"record_label__name"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.record_label__name
                      ? this.getIndex("record_label__name") +
                        1 +
                        " - " +
                        "Sello discográfico"
                      : "Sello discográfico"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"bpm"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.bpm}
                  value={"bpm"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.bpm
                      ? this.getIndex("bpm") + 1 + " - " + "BPM"
                      : "BPM"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"preference"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.preference}
                  value={"preference"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.preference
                      ? this.getIndex("preference") + 1 + " - " + "Preferencia"
                      : "Preferencia"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"song_path"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.song_path}
                  value={"song_path"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.song_path
                      ? this.getIndex("song_path") + 1 + " - " + "VDJ"
                      : "VDJ"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"duration"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.duration}
                  value={"duration"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.duration
                      ? this.getIndex("duration") + 1 + " - " + "Duración"
                      : "Duración"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"year"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.year}
                  value={"year"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.year
                      ? this.getIndex("year") + 1 + " - " + "Año"
                      : "Año"}
                  </Text>
                </Checkbox>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
                <Checkbox
                  invert
                  key={"remix"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.remix}
                  value={"remix"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.remix
                      ? this.getIndex("remix") + 1 + " - " + "Remix"
                      : "Remix"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"explicit"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.explicit}
                  value={"explicit"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.explicit
                      ? this.getIndex("explicit") +
                        1 +
                        " - " +
                        "Contenido explicito"
                      : "Contenido explicito"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"free_rights"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.free_rights}
                  value={"free_rights"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.free_rights
                      ? this.getIndex("free_rights") + 1 + " - " + "Servicio"
                      : "Servicio"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"commentary"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.commentary}
                  value={"commentary"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.commentary
                      ? this.getIndex("commentary") + 1 + " - " + "Comentario"
                      : "Comentario"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"load_num"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.load_num}
                  value={"load_num"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.load_num
                      ? this.getIndex("load_num") + 1 + " - " + "Id de carga"
                      : "Id de carga"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"orig_file_name"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.orig_file_name}
                  value={"orig_file_name"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.orig_file_name
                      ? this.getIndex("orig_file_name") +
                        1 +
                        " - " +
                        "MP3 orgininal"
                      : "MP3 original"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"provider__name"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.provider__name}
                  value={"provider__name"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.provider__name
                      ? this.getIndex("provider__name") +
                        1 +
                        " - " +
                        "Proveedor"
                      : "Proveedor"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"status"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.status}
                  value={"status"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.status
                      ? this.getIndex("status") + 1 + " - " + "Estado"
                      : "Estado"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"reproductions"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.reproductions}
                  value={"reproductions"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.reproductions
                      ? this.getIndex("reproductions") + 1 + " - " + "Uso"
                      : "Uso"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"deleted"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.deleted}
                  value={"deleted"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.deleted
                      ? this.getIndex("deleted") + 1 + " - " + "Borrada"
                      : "Borrada"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"created_at"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.created_at}
                  value={"created_at"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.created_at
                      ? this.getIndex("created_at") +
                        1 +
                        " - " +
                        "Fecha de creación"
                      : "Fecha de creación"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"updated_at"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.updated_at}
                  value={"updated_at"}
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.updated_at
                      ? this.getIndex("updated_at") +
                        1 +
                        " - " +
                        "Fecha de modificación"
                      : "Fecha de modificación"}
                  </Text>
                </Checkbox>
              </Column>
            </Columns>
          </Field>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

SongCSVModal.propTypes = {
  onClose: PropTypes.func,
};

SongCSVModal.defaultProps = {
  onClose: null,
};

export default withToastManager(withStore(SongCSVModal));
