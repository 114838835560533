import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Field,
} from "ks_storybook";
import { Select } from "../../Components/Select";
import { withToastManager } from "react-toast-notifications";
import { Playlist } from "../../models";
import { AddTranslationsModal } from ".";

@observer
class CreatePlaylistsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      translations: [],
      showAddTranslation: false,
    };

    this.newPlaylist = this.newPlaylists();
  }

  newPlaylists = () => {
    const playlists = this.props.store.playlists;
    const attrs = {
      song_lenguages_id: 1,
      description: "",
      descriptionTwo: "",
      free_rights: -1,
      static: 0,
      explicit: 0,
      num_blocks: 0,
      status: 1,
      deleted: 0,
      duration: 10,
      blocks: [],
      translations: [],
    };

    return new Playlist(attrs, playlists);
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.newPlaylist[name] = value;
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;
    switch (sender.name) {
      case "selectService":
        this.newPlaylist["free_rights"] = val;
        break;
      default:
        break;
    }
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleTranslationModelOpen = () => {
    this.setState({ showAddTranslation: true });
  };

  handleTranslationModelClose = () => {
    this.setState({ showAddTranslation: false });
  };

  handleTranslationModelSave = (translations) => {
    this.newPlaylist["translations"] = translations;
    this.setState({ showAddTranslation: false });
  };

  handleNext = () => {
    this.props.onNext && this.props.onNext(this.newPlaylist);
  };

  renderButtons = () => {
    const { appLanguagesOptions } = this.props;
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft />
        <LevelRight>
          <Button
            disabled={
              this.newPlaylist.description === ""
                ? true
                : !(
                    this.newPlaylist.descriptionTwo &&
                    this.newPlaylist.free_rights != -1 &&
                    this.newPlaylist.translations.length ===
                      appLanguagesOptions.length
                  )
            }
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="is-pulled-right px-3"
            onClick={this.handleNext}
          >
            <strong>{this.props.store.language.label598}</strong>
          </Button>
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { showAddTranslation } = this.state;
    const { appLanguagesOptions, catalogsOptions } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "640px" : "90%"}
          height={this.props.store.viewMobile ? "480px" : "auto"}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
              className="mb-1"
            >
              {this.props.store.language.label589}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Field
              label={"Servicio"}
              labelNote={this.props.store.language.label76}
              marginH={this.props.store.viewMobile && "4px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <Select
                isMobile={!this.props.store.viewMobile}
                name={"selectService"}
                options={catalogsOptions}
                onChange={this.handleSelectChange}
                placeholder={this.props.store.language.label385}
                size={this.props.store.viewMobile ? "lg" : "md"}
                backgroundColor="black"
                separatorColor="black"
                className="is-fullwidth my-1"
              />
            </Field>
            <Field
              label={"Nombre"}
              labelNote={this.props.store.language.label76}
              marginH={this.props.store.viewMobile && "4px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                name="description"
                onChange={this.handleChangeFiled}
                autocomplete="nope"
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={this.props.store.viewMobile && "20px"}
              />
            </Field>
            <Field
              label={"Descripción"}
              labelNote={this.props.store.language.label76}
              marginH={this.props.store.viewMobile && "4px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                name="descriptionTwo"
                onChange={this.handleChangeFiled}
                autocomplete="nope"
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={this.props.store.viewMobile && "20px"}
              />
            </Field>
            <Field
              label={"Traducciones"}
              labelNote={
                this.props.store.language.label76 +
                ". " +
                "Numero de traducciones" +
                ": " +
                this.newPlaylist.translations.length
              }
              marginH={this.props.store.viewMobile && "4px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <Button
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="px-3 my-1"
                onClick={this.handleTranslationModelOpen}
              >
                <strong>{this.props.store.language.label508}</strong>
              </Button>
            </Field>
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
        {showAddTranslation && (
          <AddTranslationsModal
            seeList={true}
            appLanguagesOptions={appLanguagesOptions}
            selected={this.newPlaylist.translations}
            onClose={this.handleTranslationModelClose}
            onSave={this.handleTranslationModelSave}
          />
        )}
      </React.Fragment>
    );
  }
}

CreatePlaylistsModal.propTypes = {
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

CreatePlaylistsModal.defaultProps = {
  onNext: null,
  onClose: null,
};

export default withToastManager(withStore(CreatePlaylistsModal));
