import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import {
  Text,
  TextInput,
  Button,
  Panel,
  Loader,
  CardEvents,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../../../Components/Select";
import {
  EmptyContent,
  Pagination,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  InterpretersNewModal,
  InformationModal,
  EditModal,
  ReportsModal,
  CSVModal,
  InterpreterCard,
} from "../../../Components/Interpreters";

@observer
class InterpretersListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSinger: null,
      showNewModal: false,
      showCSVModal: false,
      showInformationModal: false,
      showReportModal: false,
      showEditModal: false,
      showDeletedModal: false,
      page: 1,
      singers: this.getSingersSearch(25, null, null, null),
      filters: {
        _status: null,
      },
      orders: {
        _status: false,
      },
      orderList: [],
    };
    this.per_page = 25;
    this.search = null;
  }

  componentDidMount() {
    this.props.store.menuTitle = "Intérpretes";
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  getSingersSearch = (per_page, filters, orderList, search) => {
    return this.props.store.singers.fetchAllParams(
      per_page,
      filters,
      orderList,
      search
    );
  };

  onPageChange = (page) => {
    const { filters, orderList } = this.state;
    this.setState({
      singers: this.props.store.singers.fetchPage(
        this.per_page,
        page,
        filters._status ? filters : null,
        orderList.length == 0 ? null : orderList,
        this.search
      ),
      page: page,
    });
  };

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleInformationModalOpen = (singer) => {
    this.setState({ showInformationModal: true, currentSinger: singer });
  };

  handleInformationModalClose = () => {
    this.setState({ showInformationModal: false, currentSinger: null });
  };

  handleReportsModalOpen = (singer) => {
    this.setState({ showReportModal: true, currentSinger: singer });
  };

  handleReportsModalClose = () => {
    this.setState({ showReportModal: false, currentSinger: null });
  };

  handleEditModalOpen = (singer) => {
    this.setState({ showEditModal: true, currentSinger: singer });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false, currentSinger: null });
  };

  handleDeleteConfirmationOpen = (singer) => {
    this.setState({ showDeletedModal: true, currentSinger: singer });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedModal: false, currentSinger: null });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentSinger: null });
    this.reload();
  };

  reload = () => {
    this.setState({ singers: this.getSingersSearch(25, null, null, null) });
  };

  handleCVSOpen = () => {
    this.setState({ showCSVModal: true });
  };

  handleCVSClose = () => {
    this.setState({ showCSVModal: false });
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  onSearch = () => {
    const { filters, orderList } = this.state;
    if (this.search && this.search != " ") {
      this.setState({
        singers: this.props.store.singers.fetchAllParams(
          this.per_page,
          filters._status ? filters : null,
          orderList.length == 0 ? null : orderList,
          this.search
        ),
        page: 1,
      });
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  handleSelectChange = (value, sender) => {
    const { filters, orderList } = this.state;
    const val = value.value;
    const newFilters = filters;
    newFilters._status = val;
    this.setState({
      filters: newFilters,
      singers: this.getSingersSearch(
        this.per_page,
        filters._status ? filters : null,
        orderList.length == 0 ? null : orderList,
        this.search
      ),
    });
  };

  getIndexOrder = (order) => {
    return this.state.orderList.indexOf(order);
  };

  handleDescending = (name) => {
    const { filters, orders, orderList } = this.state;
    let newOrders = orders;
    let orderLists = orderList;
    if (orderList.length == 0) {
      orderList.push("name");
    }
    if (name == "descStatus") {
      if (newOrders._status) {
        var index = this.getIndexOrder("-name");
        if (index !== -1) {
          orderLists[index] = "name";
        }
        newOrders._status = false;
      } else {
        var index = this.getIndexOrder("name");
        if (index !== -1) {
          orderLists[index] = "-name";
        }
        newOrders._status = true;
      }
    }
    this.setState({
      orders: newOrders,
      orderList: orderLists,
      singers: this.getSingersSearch(
        this.per_page,
        filters._status ? filters : null,
        orderList,
        this.search
      ),
    });
  };

  getPanel = (result) => {
    const statusOptions = [
      { value: 1, label: "Cargados" },
      { value: 2, label: "Publicado" },
      { value: 3, label: "Modificado" },
    ];
    return (
      <Columns isMultiline isVCentered className="pb-2">
        <Column
          className="flex items-center"
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
        >
          <div
            style={{
              maxWidth: "700px",
              width: "100%",
              marginRight: this.props.store.viewMobile ? "16px" : "6px",
            }}
          >
            <TextInput
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChangeSearch}
              placeholder={"Escribe aquí el nombre del intérprete a buscar..."}
              value={this.search && this.search}
              iconKasimu="search"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="is-fullwidth"
              backgroundColor="grayDark"
              colorIcon="gray"
              invert
            />
          </div>
          <div style={{ maxWidth: "250px", width: "100%" }}>
            <Select
              isMobile={!this.props.store.viewMobile}
              name="selectStatus"
              onChange={this.handleSelectChange}
              className="is-fullwidth"
              placeholder={"Filtrar por..."}
              options={statusOptions}
            />
          </div>
          <Button
            className="ml-1 is-pulled-right"
            kind="link"
            color="basic"
            size="xl"
            icon={
              this.state.orders._status
                ? "sort-alpha-down-alt"
                : "sort-alpha-down"
            }
            onClick={() => {
              this.handleDescending("descStatus");
            }}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderPaginator = (singers) => {
    if (singers.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            panel
            totalPages={singers.totalPages}
            currentPage={singers.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { singers } = this.state;
    if (!singers) return this.renderEmptyContent();
    return !singers.isOk() ? this.renderLoader() : this.renderBody();
  };

  renderRow = (singers) => {
    const arraySingers = singers.toArray();
    return (
      <React.Fragment>
        {arraySingers.length === 0 ? (
          <EmptyContent />
        ) : (
          <div className="mt-1">
            {arraySingers.map((singer) => (
              <InterpreterCard
                singer={singer}
                onViewClick={this.handleInformationModalOpen}
                onReportClick={this.handleReportsModalOpen}
                onEditClick={this.handleEditModalOpen}
                onDeleteClick={this.handleDeleteConfirmationOpen}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  renderBody = () => {
    const {
      singers,
      currentSinger,
      filters,
      orderList,
      showNewModal,
      showInformationModal,
      showEditModal,
      showReportModal,
      showDeletedModal,
      showCSVModal,
    } = this.state;
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <Columns className={this.props.store.viewMobile ? "pb-4" : "pb-3"}>
              <Column
                isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
                className="flex"
                style={{
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 14,
                }}
              >
                {this.props.store.viewMobile && (
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    lead
                    weight="black"
                    lineBreak
                  >
                    {"Intérpretes"}
                  </Text>
                )}
                <div
                  className="flex items-center"
                  style={{ flexWrap: "wrap", gap: 14 }}
                >
                  {this.props.store.loggedInUser.checkPermission(25) ? (
                    <Button
                      icon={"file-csv"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      className="px-2 mx-0"
                      onClick={this.handleCVSOpen}
                    >
                      <strong>{"Exportar csv"}</strong>
                    </Button>
                  ) : null}
                  {this.props.store.loggedInUser.checkPermission(26) ? (
                    <Button
                      icon={"plus"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      className="px-2 mx-0"
                      onClick={this.handleShowNewModal}
                    >
                      <strong>{"Agregar"}</strong>
                    </Button>
                  ) : null}
                </div>
              </Column>
            </Columns>
            {this.getPanel(singers.count)}

            <Text
              className="is-pulled-right"
              size="md"
              maxLength={this.props.mobile ? 50 : 250}
            >
              {"Resultados: " + singers.count}
            </Text>

            {this.renderRow(singers)}
            {this.renderPaginator(singers)}
          </Column>
          {showNewModal && (
            <InterpretersNewModal onClose={this.handleCloseNewModal} />
          )}
          {showCSVModal && (
            <CSVModal
              onClose={this.handleCVSClose}
              filters={filters._status ? filters : null}
              orderList={orderList.length == 0 ? null : orderList}
              search={this.search}
            />
          )}
          {showInformationModal && (
            <InformationModal
              singer={currentSinger}
              onClose={this.handleInformationModalClose}
            />
          )}
          {showReportModal && (
            <ReportsModal
              singer={currentSinger}
              onClose={this.handleReportsModalClose}
            />
          )}
          {showEditModal && (
            <EditModal
              singer={currentSinger}
              onClose={this.handleEditModalClose}
            />
          )}
          {showDeletedModal && (
            <DeleteConfirmationModal
              currentObj={currentSinger}
              onClose={this.handleDeleteConfirmationModalClose}
              onAccept={this.handleAcceptDeleteConfirmationModal}
              messageSuccess={
                'El intérprete "' +
                currentSinger.name +
                '" se elimino exitosamente.'
              }
              messageError={
                'Error, algo salió mal al eliminar el intérprete "' +
                currentSinger.name +
                '".'
              }
              title={"Deseas eliminar \n" + currentSinger.name + "?"}
              subtitle={
                "Se eliminará el intérprete " + currentSinger.name + "."
              }
            />
          )}
        </Columns>
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>;
  }
}

export default withToastManager(withStore(InterpretersListPage));
