import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  Checkbox,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Columns,
  Column,
  Panel,
} from "ks_storybook";
import { Select } from "../Select";
import {
  SingerModal,
  RecordLabelModal,
  ProviderModal,
  MultipleUserTagSongModal,
} from ".";
import { withToastManager } from "react-toast-notifications";
import { EmptyContent, ConfirmationModal } from "../Common";

@observer
class MultipleEditModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      showOpendSingerModal: false,
      showOpendRecordLabelModal: false,
      showOpendProviderModal: false,
      showConfirmationModal: false,
      showMultipleUserTagSongModal: false,
      params: {
        allQuery: false,
        filters: null,
        search: null,
        arraySongId: this.props.arraySongId,
        song_mood_id: null,
        song_mood: {
          id: null,
          description: null,
        },
        preference: null,
        song_styles_id: null,
        song_styles: {
          id: null,
          description: null,
        },
        singer_id: null,
        singer: {
          id: null,
          name: null,
        },
        free_rights: -1,
        song_lenguages_id: null,
        song_lenguages: {
          id: null,
          description: null,
        },
        explicit: null,
        commentary: null,
        remix: null,
        year: null,
        record_label_id: null,
        record_label: {
          id: null,
          name: null,
        },
        provider_id: null,
        provider: {
          id: null,
          name: null,
        },
        status: null,
        user_tags_ids: [],
      },
      all: false,
    };
  }

  handleMultipleUserTagSongModalOpen = () => {
    this.setState({ showMultipleUserTagSongModal: true });
  };

  handleMultipleUserTagSongModalClose = () => {
    this.setState({ showMultipleUserTagSongModal: false });
  };

  handleMultipleUserTagSongModalSave = (user_tags_ids) => {
    const { params } = this.state;
    let newparams = params;
    newparams.user_tags_ids = user_tags_ids;
    this.setState({ showMultipleUserTagSongModal: false, params: newparams });
  };

  validLength = (value) => {
    if (value.length >= 1 && value.length <= 50) {
      return true;
    } else {
      return false;
    }
  };

  handleChangeFiled = (sender, value, name) => {
    let params = this.state.params;
    params[name] = value;
    this.setState({ params: params });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleDeleted = (song) => {
    const { params, all } = this.state;
    let newparams = params;
    if (!all) {
      let index = this.props.arraySongId.indexOf(song.id);
      if (index > -1) {
        this.props.arraySongId.splice(index, 1);
      }
      newparams.arraySongId = this.props.arraySongId;
    } else {
      let index = newparams.arraySongId.indexOf(song.id);
      if (index > -1) {
        newparams.arraySongId.splice(index, 1);
      }
    }
    this.setState({ params: newparams });
  };

  opendSingerModel = () => {
    this.setState({ showOpendSingerModal: true });
  };

  assignSingerModel = (singer) => {
    let params = this.state.params;
    params["singer_id"] = singer.id;
    params["singer"] = { id: singer.id, name: singer.name };
    this.setState({ showOpendSingerModal: false, params: params });
  };

  closeSingerModel = () => {
    this.setState({ showOpendSingerModal: false });
  };

  opendRecordLabelModel = () => {
    this.setState({ showOpendRecordLabelModal: true });
  };

  assignRecordLabelModel = (record_label) => {
    let params = this.state.params;
    params["record_label_id"] = record_label.id;
    params["record_label"] = { id: record_label.id, name: record_label.name };
    this.setState({ showOpendRecordLabelModal: false, params: params });
  };

  closeRecordLabelModel = () => {
    this.setState({ showOpendRecordLabelModal: false });
  };

  opendProviderModel = () => {
    this.setState({ showOpendProviderModal: true });
  };

  assignProviderModel = (provider) => {
    let params = this.state.params;
    params["provider_id"] = provider.id;
    params["provider"] = { id: provider.id, name: provider.name };
    this.setState({ showOpendProviderModal: false, params: params });
  };

  closeProviderModel = () => {
    this.setState({ showOpendProviderModal: false });
  };

  opendConfirmationModel = () => {
    this.setState({ showConfirmationModal: true });
  };

  closeConfirmationModel = () => {
    let params = this.state.params;
    params["allQuery"] = false;
    params["filters"] = null;
    params["search"] = null;
    this.setState({ showConfirmationModal: false, params: params });
  };

  confirmationAllQuery = () => {
    let params = this.state.params;
    params["arraySongId"] = [];
    this.setState({ showConfirmationModal: false, params: params, all: false });
  };

  handleSelectChange = (value, sender) => {
    let params = this.state.params;
    const val = value.value;

    switch (sender.name) {
      case "selectMoods":
        params["song_mood_id"] = val;
        params["song_mood"] = { id: val, description: value.label };
        break;
      case "selectPreference":
        params["preference"] = val;
        break;
      case "selectStyle":
        params["song_styles_id"] = val;
        params["song_styles"] = { id: val, description: value.label };
        break;
      case "selectService":
        params["free_rights"] = val;
        break;
      case "selectLanguage":
        params["song_lenguages_id"] = val;
        params["song_lenguages"] = { id: val, description: value.label };
        break;
      case "selectExplicit":
        if (val == 2) params["explicit"] = 0;
        else params["explicit"] = 1;
        break;
      case "selectRemix":
        if (val == 2) params["remix"] = 0;
        else params["remix"] = 1;
        break;
      case "selectStatus":
        params["status"] = val;
        break;
      default:
        break;
    }
    this.setState({ params: params });
  };

  handleSave = () => {
    const { toastManager } = this.props;
    let params = this.state.params;
    /*if (params.filters == 'null') {
			toastManager.add('Error. no se puede editar todas las canciones, debe tener un filtro activo en la consulta primero.', {
				appearance: "error",
				autoDismiss: true
			});
			this.props.onClose && this.props.onClose();
		} else {
			this.setState({ isSaving: true }, () => {
            this.props.store.kasimuAPIClient
            .putParams("/songs/put_edit_multi", params)
            .then(
                res => {
					toastManager.add('Las canciones seleccionadas se editaron exitosamente.', {
						appearance: "success",
						autoDismiss: true
					});
					this.props.onSave && this.props.onSave(params);
                },
                error => {
                toastManager.add('Error. algo salio mal al editar las canciones seleccionadas.', {
                    appearance: "error",
                    autoDismiss: true
                });
                this.setState({ isSaving: false});
                }
            )
            .catch(error => {
                toastManager.add('Error. algo salio mal al editar las canciones seleccionadas.', {
                appearance: "error",
                autoDismiss: true
                });
            });
        });
		}*/
    params.user_tags_ids = params.user_tags_ids.map((s) => s.id);

    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .putParams("/songs/put_edit_multi", params)
        .then(
          (res) => {
            toastManager.add(
              "Las canciones seleccionadas se editaron exitosamente.",
              {
                appearance: "success",
                autoDismiss: true,
              }
            );
            this.props.onSave && this.props.onSave(params);
          },
          (error) => {
            toastManager.add(
              "Error. algo salio mal al editar las canciones seleccionadas.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            this.setState({ isSaving: false });
          }
        )
        .catch((error) => {
          toastManager.add(
            "Error. algo salio mal al editar las canciones seleccionadas.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        });
    });
  };

  renderButtons = () => {
    const { isSaving, params } = this.state;
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {isSaving ? (
            <Button
              className="is-pulled-right px-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={
                params.allQuery
                  ? !(
                      params.song_mood_id ||
                      params.user_tags_ids.length > 0 ||
                      params.preference ||
                      params.song_styles_id ||
                      params.free_rights != -1 ||
                      params.song_lenguages_id ||
                      params.explicit ||
                      params.commentary ||
                      params.remix ||
                      params.year ||
                      params.singer_id ||
                      params.record_label_id ||
                      params.status ||
                      params.provider_id
                    )
                  : params.arraySongId.length > 0
                  ? !(
                      params.song_mood_id ||
                      params.user_tags_ids.length > 0 ||
                      params.preference ||
                      params.song_styles_id ||
                      params.free_rights != -1 ||
                      params.song_lenguages_id ||
                      params.explicit ||
                      params.commentary ||
                      params.remix ||
                      params.year ||
                      params.singer_id ||
                      params.record_label_id ||
                      params.status ||
                      params.provider_id
                    )
                  : true
              }
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  getOptions = (obj) => {
    return obj.toArray().map((o) => ({ value: o.id, label: o.description }));
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "380px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderSongs = (params) => {
    return (
      <React.Fragment>
        <div style={this.getContainerStyle()}>
          {params.arraySongId.length > 0
            ? this.props.store.ui.songs.map(
                (song, idx) =>
                  params.arraySongId.includes(song.id) && (
                    <Panel
                      color="gray"
                      padding="18px 24px"
                      className="mb-1"
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        overflowWrap: "anywhere",
                      }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        lineBreak
                      >
                        {idx + 1 + " | " + song.id + " | " + song.name}
                      </Text>
                      <Button
                        className="is-pulled-right ml-1"
                        icon="times"
                        kind="link"
                        color="basic"
                        size="lg"
                        lead
                        space
                        onClick={() => {
                          this.handleDeleted(song);
                        }}
                      />
                    </Panel>
                  )
              )
            : this.renderEmptyContent()}
        </div>
        <Text className="is-pulled-right ml-2" size="xl">
          {"Total de canciones a editar: " + params.arraySongId.length}
        </Text>
      </React.Fragment>
    );
  };

  renderCheckbox1 = (all, params) => {
    return (
      <>
        {all ? (
          () => ({})
        ) : (
          <Checkbox
            invert
            size="lg"
            onChange={(sender, value) => {
              let newparams = params;
              if (value) {
                let array = [];
                this.props.store.ui.songs.map((song) => {
                  array.push(song.id);
                });
                newparams.arraySongId = array;
                newparams.allQuery = false;
                newparams.filters = null;
                newparams.search = null;
              } else {
                newparams.arraySongId = this.props.arraySongId;
              }
              this.setState({ params: newparams, all: value });
            }}
            isChecked={all}
          >
            <Text size="lg">{"Todas las canciones de la pagina."}</Text>
          </Checkbox>
        )}
        {all && (
          <Checkbox
            invert
            size="lg"
            onChange={(sender, value) => {
              let newparams = params;
              if (value) {
                let array = [];
                this.props.store.ui.songs.map((song) => {
                  array.push(song.id);
                });
                newparams.arraySongId = array;
                newparams.allQuery = false;
                newparams.filters = null;
                newparams.search = null;
              } else {
                newparams.arraySongId = this.props.arraySongId;
              }
              this.setState({ params: newparams, all: value });
            }}
            isChecked={all}
          >
            <Text size="lg">{"Todas las canciones de la pagina."}</Text>
          </Checkbox>
        )}
      </>
    );
  };

  renderCheckbox2 = (params, filters, search) => {
    return (
      <>
        {params.allQuery ? (
          () => ({})
        ) : (
          <Checkbox
            invert
            size="lg"
            onChange={(sender, value) => {
              let newparams = params;
              if (value) {
                newparams.allQuery = value;
                newparams.filters = JSON.stringify(filters);
                newparams.search = search;
                this.opendConfirmationModel();
              } else {
                newparams.allQuery = value;
              }
              this.setState({ params: newparams });
            }}
            isChecked={params.allQuery}
          >
            <Text size="lg">{"Todas las canciones de la consulta."}</Text>
          </Checkbox>
        )}
        {params.allQuery && (
          <Checkbox
            invert
            size="lg"
            onChange={(sender, value) => {
              let newparams = params;
              if (value) {
                newparams.allQuery = value;
                newparams.filters = JSON.stringify(filters);
                newparams.search = search;
                this.opendConfirmationModel();
              } else {
                newparams.allQuery = value;
              }
              this.setState({ params: newparams });
            }}
            isChecked={params.allQuery}
          >
            <Text size="lg">{"Todas las canciones de la consulta."}</Text>
          </Checkbox>
        )}
      </>
    );
  };

  getArrayStatus(statusOptions, rolId) {
    let arrayStatus = [];
    if (rolId == 1 || rolId == 2 || rolId == 4) {
      arrayStatus = [2, 3, 7];
    } else if (rolId == 3) {
      arrayStatus = [2, 6, 7];
    }
    return statusOptions.filter((s) => arrayStatus.includes(s.value));
  }

  render() {
    const {
      catalogsOptions,
      moodsOptions,
      stylesOptions,
      preferenceOptions,
      filters,
      search,
      resultAllSongs,
      userTags,
    } = this.props;
    const {
      showOpendSingerModal,
      showOpendRecordLabelModal,
      showOpendProviderModal,
      showConfirmationModal,
      showMultipleUserTagSongModal,
      params,
      all,
    } = this.state;
    const languageOptions = this.getOptions(this.props.language);
    const rolId = this.props.store.loggedInUser.getRolId();
    let statusOptions = [
      { value: 1, label: "Cargadas" },
      { value: 2, label: "Pendientes" },
      { value: 3, label: "Aprobadas" },
      { value: 4, label: "Publicada" },
      { value: 5, label: "Modificada" },
      { value: 6, label: "Pre-Pendientes" },
      { value: 7, label: "Reparar" },
    ];
    const genOptions = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" },
    ];
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "950px" : "90%"}
          height={this.props.store.viewMobile ? "640px" : "auto"}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              weight="black"
              className="mb-2"
              multiline
              lead
            >
              {this.props.store.language.label140}
              {"\n"}
              {"multiples canciones"}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Columns className="my-1" isMultiline>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 4 }}>
                <div className="is-row mb-1">
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {"Canciones a editar"}
                  </Text>
                </div>
                <div className="is-row mb-1">
                  {this.renderCheckbox1(all, params)}
                </div>
                <div className="is-row mb-1">
                  {this.renderCheckbox2(params, filters, search)}
                </div>
                <div className="my-1"></div>
                {this.renderSongs(params)}
              </Column>
              <Column isSize={{ mobile: 12, tablet: 6, desktop: 4 }}>
                <Field
                  label={"Humor"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectMoods"}
                    onChange={this.handleSelectChange}
                    options={moodsOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="my-1"
                  />
                </Field>
                <Field
                  label={"Preferencia"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectPreference"}
                    onChange={this.handleSelectChange}
                    options={preferenceOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="my-1"
                  />
                </Field>
                <Field
                  label={"Estilo"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectStyle"}
                    onChange={this.handleSelectChange}
                    options={stylesOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="my-1"
                  />
                </Field>
                {this.props.store.loggedInUser.checkPermission(11) ? (
                  <Field
                    label={"Servicio"}
                    marginH={this.props.store.viewMobile && "4px"}
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                    weight="medium"
                  >
                    <Select
                      isMobile={!this.props.store.viewMobile}
                      name={"selectService"}
                      onChange={this.handleSelectChange}
                      options={catalogsOptions}
                      placeholder={this.props.store.language.label385}
                      backgroundColor="black"
                      separatorColor="black"
                      className="my-1"
                    />
                  </Field>
                ) : null}
                <Field
                  label={"Idioma"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectLanguage"}
                    onChange={this.handleSelectChange}
                    options={languageOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="my-1"
                  />
                </Field>
                <Field
                  label={"Contenido explícito"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectExplicit"}
                    onChange={this.handleSelectChange}
                    options={genOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="my-1"
                  />
                </Field>
                <Field
                  label={"Etiquetas"}
                  labelNote={
                    "Numero de etiquetas: " + params.user_tags_ids.length
                  }
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Button
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="px-3 my-1"
                    onClick={this.handleMultipleUserTagSongModalOpen}
                  >
                    <strong>{"Agregar / quitar"}</strong>
                  </Button>
                </Field>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 6, desktop: 4 }}>
                <Field
                  label={"Remix"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectRemix"}
                    onChange={this.handleSelectChange}
                    options={genOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="my-1"
                  />
                </Field>
                <Field
                  label={"Año"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <TextInput
                    type="number"
                    name="year"
                    onChange={this.handleChangeFiled}
                    minlength={1900}
                    maxlength={2099}
                    autocomplete="nope"
                    backgroundColor="blackDark"
                    borderColor="black"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </Field>
                <Field
                  label={"Interprete"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <div
                    className="is-row mx-2 my-1"
                    style={{ alignItems: "center" }}
                  >
                    <Text
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      multiline
                    >
                      {params.singer.name
                        ? params.singer.name
                        : this.props.store.language.label385}
                    </Text>
                    <Button
                      className="ml-3"
                      icon="pen"
                      kind="link"
                      color="basic"
                      size="lg"
                      lead
                      space
                      onClick={this.opendSingerModel}
                    />
                  </div>
                </Field>
                {this.props.store.loggedInUser.checkPermission(13) ? (
                  <Field
                    label={"Sello discográfico"}
                    marginH={this.props.store.viewMobile && "4px"}
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                    weight="medium"
                  >
                    <div
                      className="is-row mx-2 my-1"
                      style={{ alignItems: "center" }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        multiline
                      >
                        {params.record_label.name
                          ? params.record_label.name
                          : this.props.store.language.label385}
                      </Text>
                      <Button
                        className="ml-3"
                        icon="pen"
                        kind="link"
                        color="basic"
                        size="lg"
                        lead
                        space
                        onClick={this.opendRecordLabelModel}
                      />
                    </div>
                  </Field>
                ) : null}
                {this.props.store.loggedInUser.checkPermission(14) ? (
                  <Field
                    label={"Proveedores"}
                    marginH={this.props.store.viewMobile && "4px"}
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                    weight="medium"
                  >
                    <div
                      className="is-row mx-2 my-1"
                      style={{ alignItems: "center" }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        multiline
                      >
                        {params.provider.name
                          ? params.provider.name
                          : this.props.store.language.label385}
                      </Text>
                      <Button
                        className="ml-3"
                        icon="pen"
                        kind="link"
                        color="basic"
                        size="lg"
                        lead
                        space
                        onClick={this.opendProviderModel}
                      />
                    </div>
                  </Field>
                ) : null}
                <Field
                  label={"Comentario"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <TextInput
                    name="commentary"
                    onChange={this.handleChangeFiled}
                    autocomplete="nope"
                    backgroundColor="blackDark"
                    borderColor="black"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </Field>
                {this.props.store.loggedInUser.checkPermission(12) ? (
                  <Field
                    label={"Estado"}
                    marginH={this.props.store.viewMobile && "4px"}
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                    weight="medium"
                  >
                    <Select
                      isMobile={!this.props.store.viewMobile}
                      name={"selectStatus"}
                      onChange={this.handleSelectChange}
                      options={this.getArrayStatus(statusOptions, rolId)}
                      placeholder={this.props.store.language.label385}
                      backgroundColor="black"
                      separatorColor="black"
                      className="my-1"
                    />
                  </Field>
                ) : null}
              </Column>
            </Columns>
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
        {showOpendSingerModal && (
          <SingerModal
            onClose={this.closeSingerModel}
            onSave={this.assignSingerModel}
          />
        )}
        {showOpendRecordLabelModal && (
          <RecordLabelModal
            onClose={this.closeRecordLabelModel}
            onSave={this.assignRecordLabelModel}
          />
        )}
        {showOpendProviderModal && (
          <ProviderModal
            onClose={this.closeProviderModel}
            onSave={this.assignProviderModel}
          />
        )}
        {showConfirmationModal && (
          <ConfirmationModal
            title="Editar toda la consulta"
            onClose={this.closeConfirmationModel}
            onAccept={this.confirmationAllQuery}
            subtitle={
              "Deseas editar " +
              resultAllSongs +
              " canciones de la consultadas?"
            }
          />
        )}
        {showMultipleUserTagSongModal && (
          <MultipleUserTagSongModal
            userTags={userTags}
            selected={params.user_tags_ids}
            onClose={this.handleMultipleUserTagSongModalClose}
            onSave={this.handleMultipleUserTagSongModalSave}
          />
        )}
      </React.Fragment>
    );
  }
}

MultipleEditModel.propTypes = {
  arraySongId: PropTypes.array,
  language: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

MultipleEditModel.defaultProps = {
  arraySongId: [],
  language: null,
  onClose: null,
  onSave: null,
};

export default withToastManager(withStore(MultipleEditModel));
