import React, { Component } from "react";
import PropTypes from "prop-types";
import { Panel, Button, Text } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class MusicalStyleCard extends Component {
  state = {
    open: false,
  };

  handleView = () => {
    const { style, onViewClick } = this.props;
    onViewClick && onViewClick(style);
  };

  handleTranslation = () => {
    const { style, onTranslationClick } = this.props;
    onTranslationClick && onTranslationClick(style);
  };

  handleReport = () => {
    const { style, onReportClick } = this.props;
    onReportClick && onReportClick(style);
  };

  handleEdit = () => {
    const { style, onEditClick } = this.props;
    onEditClick && onEditClick(style);
  };

  render() {
    const { style, catalogsOptions, getService } = this.props;

    return (
      <div
        style={{
          lineBreak: "anywhere",
          marginBottom: !this.props.store.viewMobile && "3px",
        }}
        className={this.props.store.viewMobile && "mb-1"}
      >
        <Panel
          color={"gray"}
          padding={this.props.store.viewMobile ? "16px 30px" : "14px 10px"}
          style={{
            height: 65,
            justifyContent: "center",
            backgroundColor: this.state.open && "#D33F20",
            borderRadius: !this.props.store.viewMobile && "5px",
          }}
        >
          <div
            className="flex"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {this.state.open ? (
              <div className="flex">
                <div data-tip data-for="info">
                  <Button
                    icon="search-plus"
                    kind="link"
                    color="basic"
                    size="xl"
                    lead
                    space
                    onClick={this.handleView}
                  />
                </div>
                <div data-tip data-for="language">
                  <Button
                    className="ml-3"
                    icon="language"
                    kind="link"
                    color="basic"
                    size="xl"
                    lead
                    space
                    onClick={this.handleTranslation}
                  />
                </div>
                {this.props.store.loggedInUser.checkPermission(31) ? (
                  <div data-tip data-for="database">
                    <Button
                      className="ml-3"
                      icon="database"
                      kind="link"
                      color="basic"
                      size="xl"
                      lead
                      space
                      onClick={this.handleReport}
                    />
                  </div>
                ) : null}
                {this.props.store.loggedInUser.checkPermission(30) ? (
                  <div data-tip data-for="edit">
                    <Button
                      className="ml-3"
                      kind="link"
                      color="basic"
                      size="xl"
                      icon="pen"
                      onClick={this.handleEdit}
                      space
                    />
                  </div>
                ) : null}
                {/*<Button
                                kind="link"
                                color={style.deleted==1?"remove":"special"}
                                size='lg'
                                icon='trash'
                                onClick={() => {this.handleDeleteConfirmationOpen(style)}}
                                space
                            />*/}
              </div>
            ) : (
              <div>
                <Text
                  weight="medium"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  multiline
                >
                  {style.description}
                </Text>
                <Text
                  weight="regular"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {"ID: " +style.id+" | Servicio: " +
                   getService(style.free_rights, catalogsOptions) +
                   " | Activo: " +
                   (style.enabled == 1 ? "Si" : "No") +
                   " | Nº Traducciones: " +
                   (style.translation ? style.translation.length : 0) +
                   " | Nº Canciones con este estilo: "+style.num_songs}
                </Text>
              </div>
            )}
            {style.id != 1 &&
              (this.props.store.viewMobile ? (
                <div className="flex">
                  <div data-tip data-for="info">
                    <Button
                      className="ml-3"
                      icon="search-plus"
                      kind="link"
                      color="basic"
                      size="lg"
                      lead
                      space
                      onClick={this.handleView}
                    />
                  </div>
                  <div data-tip data-for="language">
                    <Button
                      className="ml-3"
                      icon="language"
                      kind="link"
                      color="basic"
                      size="lg"
                      lead
                      space
                      onClick={this.handleTranslation}
                    />
                  </div>
                  {this.props.store.loggedInUser.checkPermission(31) ? (
                    <div data-tip data-for="database">
                      <Button
                        className="ml-3"
                        icon="database"
                        kind="link"
                        color="basic"
                        size="lg"
                        lead
                        space
                        onClick={this.handleReport}
                      />
                    </div>
                  ) : null}
                  {this.props.store.loggedInUser.checkPermission(30) ? (
                    <div data-tip data-for="edit">
                      <Button
                        className="ml-3"
                        kind="link"
                        color="basic"
                        size="lg"
                        icon="pen"
                        onClick={this.handleEdit}
                        space
                      />
                    </div>
                  ) : null}
                  {/*<Button
                                   kind="link"
                                   color={style.deleted==1?"remove":"special"}
                                   size='lg'
                                   icon='trash'
                                   onClick={() => {this.handleDeleteConfirmationOpen(style)}}
                                   space
                               />*/}
                </div>
              ) : (
                <div className="flex">
                  {this.state.open ? (
                    <Button
                      kind="link"
                      color="basic"
                      size={"lg"}
                      iconKasimu={"arrow-left"}
                      space
                      onClick={() => this.setState({ open: false })}
                    />
                  ) : (
                    <Button
                      kind="link"
                      color="special"
                      size={"lg"}
                      iconKasimu={"arrow-right"}
                      space
                      onClick={() => this.setState({ open: true })}
                    />
                  )}
                </div>
              ))}
          </div>
        </Panel>
      </div>
    );
  }
}

MusicalStyleCard.propTypes = {
  style: PropTypes.object,
  catalogsOptions: PropTypes.object,
  getService: PropTypes.func,
  onViewClick: PropTypes.func,
  onTranslationClick: PropTypes.func,
  onReportClick: PropTypes.func,
  onEditClick: PropTypes.func,
};

MusicalStyleCard.defaultProps = {
  style: null,
  catalogsOptions: null,
  getService: null,
  onViewClick: null,
  onTranslationClick: null,
  onReportClick: null,
  onEditClick: null,
};

export default withStore(withToastManager(MusicalStyleCard));
