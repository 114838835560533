import React, { Component } from "react";
import PropTypes from "prop-types";
import { Panel, Button, Text } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class PlaylistCard extends Component {
  state = {
    open: false,
  };

  handleView = () => {
    const { playlist, onViewClick } = this.props;
    onViewClick && onViewClick(playlist);
  };

  handleCategory = () => {
    const { playlist, onCategoryClick } = this.props;
    onCategoryClick && onCategoryClick(playlist);
  };

  handleBlock = () => {
    const { playlist, onBlockClick } = this.props;
    onBlockClick && onBlockClick(playlist);
  };

  handleEdit = () => {
    const { playlist, onEditClick } = this.props;
    onEditClick && onEditClick(playlist);
  };

  handleDelete = () => {
    const { playlist, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(playlist);
  };

  render() {
    const { playlist, catalogsOptions, getService } = this.props;

    return (
      <div
        style={{
          lineBreak: "anywhere",
          marginBottom: !this.props.store.viewMobile && "3px",
        }}
        className={this.props.store.viewMobile && "mb-1"}
      >
        <Panel
          color={"gray"}
          padding={this.props.store.viewMobile ? "16px 30px" : "14px 10px"}
          style={{
            // height: 85,
            height: 80,
            justifyContent: "center",
            backgroundColor: this.state.open && "#D33F20",
            borderRadius: !this.props.store.viewMobile && "5px",
          }}
        >
          <div
            className="flex"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {this.state.open ? (
              <div className="flex">
                <div data-tip data-for="info">
                  <Button
                    icon="search-plus"
                    kind="link"
                    color="basic"
                    size="xl"
                    lead
                    space
                    onClick={this.handleView}
                  />
                </div>
                <div data-tip data-for="catp">
                  <Button
                    className="ml-3"
                    icon="clipboard-list"
                    kind="link"
                    color="basic"
                    size="xl"
                    lead
                    space
                    onClick={this.handleCategory}
                  />
                </div>
                <div data-tip data-for="blocks">
                  <Button
                    className="ml-3"
                    icon="cubes"
                    kind="link"
                    color="basic"
                    size="xl"
                    lead
                    space
                    onClick={this.handleBlock}
                  />
                </div>
                <div data-tip data-for="edit">
                  <Button
                    className="ml-3"
                    kind="link"
                    color="basic"
                    size="xl"
                    icon="pen"
                    onClick={this.handleEdit}
                    space
                  />
                </div>
                <div data-tip data-for="remove">
                  <Button
                    className="ml-3"
                    kind="link"
                    color={playlist.deleted == 1 ? "remove" : "basic"}
                    size="xl"
                    icon="trash"
                    onClick={this.handleDelete}
                    space
                  />
                </div>
              </div>
            ) : (
              <div>
                <Text
                  color="white"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="medium"
                  multiline
                >
                  {playlist.description}
                </Text>
                <Text
                  color="special"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="regular"
                  multiline
                >
                  {"Servicio: " +
                    getService(playlist.free_rights, catalogsOptions) +
                    " | Nº Categorias playlists: " +
                    playlist.number_categories_playlists +
                    " | Nº Bloques: " +
                    playlist.number_blocks +
                    " | Nº Estilos: " +
                    playlist.styles.length}
                </Text>
              </div>
            )}
            {this.props.store.viewMobile ? (
              <div className="flex">
                <div data-tip data-for="info">
                  <Button
                    icon="search-plus"
                    kind="link"
                    color="basic"
                    size="lg"
                    lead
                    space
                    onClick={this.handleView}
                  />
                </div>
                <div data-tip data-for="catp">
                  <Button
                    className="ml-3"
                    icon="clipboard-list"
                    kind="link"
                    color="basic"
                    size="lg"
                    lead
                    space
                    onClick={this.handleCategory}
                  />
                </div>
                <div data-tip data-for="blocks">
                  <Button
                    className="ml-3"
                    icon="cubes"
                    kind="link"
                    color="basic"
                    size="lg"
                    lead
                    space
                    onClick={this.handleBlock}
                  />
                </div>
                <div data-tip data-for="edit">
                  <Button
                    className="ml-3"
                    kind="link"
                    color="basic"
                    size="lg"
                    icon="pen"
                    onClick={this.handleEdit}
                    space
                  />
                </div>
                <div data-tip data-for="remove">
                  <Button
                    className="ml-3"
                    kind="link"
                    color={playlist.deleted == 1 ? "remove" : "basic"}
                    size="lg"
                    icon="trash"
                    onClick={this.handleDelete}
                    space
                  />
                </div>
              </div>
            ) : (
              <div className="flex">
                {this.state.open ? (
                  <Button
                    kind="link"
                    color="basic"
                    size={"lg"}
                    iconKasimu={"arrow-left"}
                    space
                    onClick={() => this.setState({ open: false })}
                  />
                ) : (
                  <Button
                    kind="link"
                    color="special"
                    size={"lg"}
                    iconKasimu={"arrow-right"}
                    space
                    onClick={() => this.setState({ open: true })}
                  />
                )}
              </div>
            )}
          </div>
        </Panel>
      </div>
    );
  }
}

PlaylistCard.propTypes = {
  playlist: PropTypes.object,
  catalogsOptions: PropTypes.object,
  getService: PropTypes.func,
  onViewClick: PropTypes.func,
  onCategoryClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

PlaylistCard.defaultProps = {
  playlist: null,
  catalogsOptions: null,
  getService: null,
  onViewClick: null,
  onCategoryClick: null,
  onBlockClick: null,
  onEditClick: null,
  onDeleteClick: null,
};

export default withStore(withToastManager(PlaylistCard));
