import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
  Columns,
  Column,
} from "ks_storybook";
import { Select, SelectIcons } from "../Select";
import { withToastManager } from "react-toast-notifications";
import iconsOptions from "../../Components/Iconos/Icons.js";
import { AddTranslationsModal } from ".";

@observer
class CategoriesNewModal extends Component {
  @observable categories;
  constructor(props) {
    super(props);

    this.state = {
      showAddTranslation: false,
      isSaving: false,
      name: null,
      messageError: null,
      translations: [],
      catalogs: this.getCatalogsAll(),
    };
    this.categories = this.newCategories();
  }

  newCategories = () => {
    const attrs = {
      name: "",
      description: "",
      icon_path: "",
      list_view: 0,
      order_view: 0,
      free_rights: -1,
      status: 1,
      deleted: 0,
      translations: [],
    };
    return attrs;
  };

  getCatalogsAll = () => {
    return this.props.store.catalogs.fetchAll();
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.categories[name] = value;
  };

  handleClose = () => {
    this.setState({ isSaving: false });
    this.props.onClose && this.props.onClose();
  };

  handleTranslationModelOpen = () => {
    this.setState({ showAddTranslation: true });
  };

  handleTranslationModelClose = () => {
    this.setState({ showAddTranslation: false });
  };

  handleTranslationModelSave = (translations) => {
    this.setState({ showAddTranslation: false, translations: translations });
  };

  getErrorMessager = (codError) => {
    const messager = {
      1: "Error, algo salio mal al guardar la categoria",
      2: "Error. el nombre de la categoria ya existe.",
    };
    return messager[codError];
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const { translations } = this.state;
    this.categories.translations = translations.map((i) => i);
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .post("/categories/save_params_panel", this.categories)
        .then(
          (res) => {
            toastManager.add("Se guardo exitosamente la categoria", {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onSave && this.props.onSave();
          },
          (error) => {
            let str = error.toString();
            str = str.replace("error: ", "");
            let mydatas = JSON.parse(str);
            toastManager.add(this.getErrorMessager(mydatas["codError"]), {
              appearance: "error",
              autoDismiss: true,
            });
          }
        )
        .catch((error) => {
          toastManager.add(this.getErrorMessager("1"), {
            appearance: "error",
            autoDismiss: true,
          });
        });
    });
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;
    switch (sender.name) {
      case "selectService":
        this.categories["free_rights"] = val;
        break;
      case "selectIcons":
        this.categories["icon_path"] = value.label;
        break;
      case "selectRow":
        this.categories["list_view"] = val;
        break;
      default:
        break;
    }
  };

  renderButtons = () => {
    const { appLanguagesOptions } = this.props;
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              disabled={
                this.categories.name === ""
                  ? true
                  : !(
                      this.categories.description &&
                      this.categories.icon_path &&
                      this.categories.free_rights != -1 &&
                      this.state.translations.length ===
                        appLanguagesOptions.length
                    )
              }
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { showAddTranslation, translations } = this.state;
    const { catalogsOptions, appLanguagesOptions } = this.props;
    const rowOptions = [
      { value: 1, label: "Elegir por tipo de local" },
      { value: 2, label: "Elegir por estilo de música" },
      { value: 3, label: "Elegir por otros criterios" },
    ];
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "700px" : "90%"}
          height={this.props.store.viewMobile ? "400px" : "auto"}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
              className="mb-1"
            >
              {"Agregar Categoría"}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Columns isMultiline>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                <Field
                  label={"Nombre"}
                  labelNote={this.props.store.language.label76}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <TextInput
                    name="name"
                    onChange={this.handleChangeFiled}
                    autocomplete="nope"
                    backgroundColor="blackDark"
                    borderColor="black"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </Field>
                <Field
                  label={"Imagen"}
                  labelNote={this.props.store.language.label76}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <SelectIcons
                    isMobile={!this.props.store.viewMobile}
                    name={"selectIcons"}
                    onChange={this.handleSelectChange}
                    options={iconsOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="is-fullwidth my-1"
                  />
                </Field>
                <Field
                  label={"Servicio"}
                  labelNote={this.props.store.language.label76}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectService"}
                    onChange={this.handleSelectChange}
                    options={catalogsOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="is-fullwidth my-1"
                  />
                </Field>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                <Field
                  label={"Descripción"}
                  labelNote={this.props.store.language.label76}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <TextInput
                    name="description"
                    onChange={this.handleChangeFiled}
                    autocomplete="nope"
                    backgroundColor="blackDark"
                    borderColor="black"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </Field>
                <Field
                  label={"Fila"}
                  labelNote={this.props.store.language.label76}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectRow"}
                    onChange={this.handleSelectChange}
                    options={rowOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="is-fullwidth my-1"
                  />
                </Field>
                <Field
                  label={"Traducciones"}
                  labelNote={
                    this.props.store.language.label76 +
                    ". " +
                    "Numero de traducciones" +
                    ": " +
                    translations.length
                  }
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Button
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="px-3 my-1"
                    onClick={this.handleTranslationModelOpen}
                  >
                    <strong>{this.props.store.language.label508}</strong>
                  </Button>
                </Field>
              </Column>
            </Columns>
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
        {showAddTranslation && (
          <AddTranslationsModal
            seeList={true}
            appLanguagesOptions={appLanguagesOptions}
            selected={translations}
            onClose={this.handleTranslationModelClose}
            onSave={this.handleTranslationModelSave}
          />
        )}
      </React.Fragment>
    );
  }
}

CategoriesNewModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

CategoriesNewModal.defaultProps = {
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(CategoriesNewModal));
