import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import {
  Dropdown,
  DropdownToggle,
  DropdownPanel,
  Avatar,
  Text,
  Button,
} from "ks_storybook";
import withStore from "../../../hocs/withStore";
import { withRouter } from "react-router";
import { SignOutModal } from "../../../Components/Account";

@observer
class LoggedUserDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalSignOut: false,
    };
  }

  handleShowModalSignOut = () => {
    this.setState({ showModalSignOut: true });
  };

  handleCloseModalSignOut = () => {
    this.setState({ showModalSignOut: false });
  };

  handleSignOut = () => {
    this.handleShowModalSignOut();
    this.props.store.signOut();
  };

  render() {
    const user = this.props.store.loggedInUser;
    const { showModalSignOut } = this.state;

    return (
      <div className="flex items-center">
        <Dropdown direction="bottom-right">
          <DropdownToggle>
            {this.props.mobile ? (
              <Avatar
                image={user.image_url}
                size="xs"
                placeholderIcon={
                  this.props.store.viewMobile
                    ? "user-circle"
                    : "user-circle-white"
                }
              />
            ) : (
              <Button
                iconKasimu="user-circle-white"
                size="lg"
                iconSize="28px"
                padding="4px"
                img={user.image_url}
                kind="outline"
                color="basic"
              >
                {!this.props.mobile ? user.username : null}
              </Button>
            )}
          </DropdownToggle>
          <DropdownPanel className="has-text-centered">
            <Avatar
              className="mb-2"
              image={user.image_url}
              size="lg"
              placeholderIcon={
                this.props.store.viewMobile
                  ? "user-circle"
                  : "user-circle-white"
              }
            />
            {this.props.mobile ? (
              <Text size="sm" className="mb-2" lead>
                {user.username}
              </Text>
            ) : null}
            <Text className="mb-1" size="sm" weight="strong">
              {this.props.store.language.label6}
            </Text>
            <Text className="mb-1" size="sm" weight="strong">
              {user.getRolName()}
            </Text>
            <Text weight="bold" size="md">
              {this.props.store.version}
            </Text>

            {this.props.mobile && (
              <Button
                onClick={this.handleShowModalSignOut}
                className="mx-0 mt-2"
                iconSize="22px"
                size="lg"
                iconKasimu="turn-off"
                color="basic"
                kind="outline"
              />
            )}
          </DropdownPanel>
        </Dropdown>

        {!this.props.mobile && (
          <Button
            className="ml-1"
            size="lg"
            iconSize="22px"
            iconKasimu="turn-off"
            color="basic"
            onClick={this.handleShowModalSignOut}
            kind="outline"
          />
        )}
        {showModalSignOut && (
          <SignOutModal
            onClose={this.handleCloseModalSignOut}
            onAccept={this.handleSignOut}
          />
        )}
      </div>
    );
  }
}

LoggedUserDropdown.propTypes = {
  mobile: PropTypes.bool,
};

LoggedUserDropdown.defaultProps = {
  mobile: false,
};

export default withRouter(withStore(LoggedUserDropdown));
