import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import {
  Text,
  TextInput,
  Button,
  Checkbox,
  Panel,
  Loader,
  Field,
  DateTimePicker,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../../../Components/Select";
import {
  EmptyContent,
  Pagination,
  DeleteConfirmationModal,
  ConfirmationModal,
  WarningModal,
} from "../../../Components/Common";
import { AudioPlayer } from "../../../Components/AudioPlayer";
import {
  SongsCard,
  SongInformationModal,
  SongEditModal,
  SongReportsModal,
  MultipleEditModel,
  SongCSVModal,
  UsersListenedSongsCSVModal,
  UsersListenedSongsModal,
  UserTagSongModal,
} from "../../../Components/Songs";
import moment from "moment";

@observer
class SongsListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: this.getAccounts(),
      showSongInformationModal: false,
      showSongEditModal: false,
      showSongReportsModal: false,
      showDeletedModal: false,
      showSongEditMultiModal: false,
      showSongCSVModal: false,
      showUsersListenedSongsCSVModal: false,
      showConfirmationApproveModal: false,
      showConfirmationSlopesModal: false,
      showConfirmationPreSlopesModal: false,
      showConfirmationStartEditingModal: false,
      showConfirmationFinalizeEditingModal: false,
      showWarningEditingModal: false,
      showUsersListenedSongsModal: false,
      isUpdateStatus: false,
      currentSongView: null,
      currentIndiceSongView: 0,
      songs: this.getSongsAll(25, null, null, null),
      catalogs: this.getCatalogsAll(),
      styles: this.getSongStylesAll(),
      language: this.getSongLanguageAll(),
      userTags: this.getUserTagsSearch(),
      userTagsOther: this.getUserTagsOtherSearch(),
      per_page: 25,
      page: 1,
      filtersDateRange: false,
      filtersStyle: false,
      filtersPreferenceRange: false,
      filtersMood: false,
      filtersService: false,
      filtersStatus: false,
      filtersMusicLists: false,
      filtersExplicit: false,
      filtersUserPreference: false,
      filtersNotAssigned: false,
      filtersDeleted: false,
      filtersMarkedSongs: false,
      filtersYear: false,
      filtersUsers: false,
      filtersUsersMyTags: false,
      filtersUsersTagsOther: false,
      currentUsername: null,
      filters: {
        date_type: null,
        date_from: null,
        date_to: null,
        styles_id: null,
        preference_from: null,
        preference_to: null,
        mood_id: null,
        service: -1,
        _status: null,
        music_list: null,
        explicit: null,
        user_preference: null,
        not_assigned: null,
        deleted: null,
        marked_songs: null,
        user_id: null,
        search_type_by_user: null,
        year_from: null,
        year_to: null,
        date_from_user: null,
        date_to_user: null,
        my_tag_id: null,
      },
      orders: {
        ascPreference: false,
        descPreference: false,
        ascSinger: false,
        descSinger: false,
        ascStyle: false,
        descStyle: false,
        ascMood: false,
        descMood: false,
        ascService: false,
        descService: false,
        ascUso: false,
        descUso: false,
        ascA: false,
        descA: false,
        ascCat: false,
        descCat: false,
        ascUat: false,
        descUat: false,
      },
      orderList: [],
    };
    this.arraySongId = [];
    this.search = null;
    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.allYears = this.getYearAll();
    this.resultAllSongs = 0;
  }

  componentDidMount() {
    this.props.store.menuTitle = "Canciones";
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  handleTogglePlay(song, indice) {
    var that = this;
    if (song) {
      if (that.props.store.ui.currentSong) {
        if (song.id != that.props.store.ui.currentSong.id) {
          that.props.store.ui.resetInitialMoment();
          that.props.store.ui.currentSong = song;
          that.props.store.ui.currentIndice = indice;
          if (that.props.store.ui.loadCurrentSong()) that.props.store.ui.play();
        } else {
          if (!this.props.store.ui.isPlaying) {
            this.props.store.ui.play();
          } else {
            this.props.store.ui.pause();
          }
        }
      }
    }
  }

  resetFilters = () => {
    const newFilters = {
      date_type: null,
      date_from: null,
      date_to: null,
      styles_id: null,
      preference_from: null,
      preference_to: null,
      mood_id: null,
      service: -1,
      _status: null,
      music_list: null,
      explicit: null,
      user_preference: null,
      not_assigned: null,
      deleted: null,
      marked_songs: null,
      user_id: null,
      search_type_by_user: null,
      year_from: null,
      year_to: null,
      date_from_user: null,
      date_to_user: null,
      my_tag_id: null,
    };
    const neworders = {
      ascPreference: false,
      descPreference: false,
      ascSinger: false,
      descSinger: false,
      ascStyle: false,
      descStyle: false,
      ascMood: false,
      descMood: false,
      ascService: false,
      descService: false,
      ascUso: false,
      descUso: false,
      ascA: false,
      descA: false,
      ascCat: false,
      descCat: false,
      ascUat: false,
      descUat: false,
    };
    const neworderList = [];
    this.setState({
      filtersDateRange: false,
      filtersStyle: false,
      filtersPreferenceRange: false,
      filtersMood: false,
      filtersService: false,
      filtersStatus: false,
      filtersMusicLists: false,
      filtersExplicit: false,
      filtersUserPreference: false,
      filtersNotAssigned: false,
      filtersDeleted: false,
      filtersMarkedSongs: false,
      filtersYear: false,
      filtersUsersMyTags: false,
      filtersUsersTagsOther: false,
      filtersUsers: false,
      filters: newFilters,
      orders: neworders,
      orderList: neworderList,
    });
  };

  getAccounts() {
    return this.props.store.users.fetchAccounts();
  }

  getOptionsAccounts = (accounts) => {
    return accounts
      .toArray()
      .map((account) => ({ value: account.id, label: account.username }));
  };

  getCatalogsAll = () => {
    return this.props.store.catalogs.fetchAll();
  };

  getUserTagsSearch = () => {
    return this.props.store.usersTag.fetchAll();
  };

  getUserTagsOtherSearch = () => {
    return this.props.store.usersTag.fetchAllTagsFromOtherUsers();
  };

  handleUsersListenedSongsModelOpen = (song) => {
    this.setState({ showUsersListenedSongsModal: true, currentSongView: song });
  };

  handleUsersListenedSongsModelClose = () => {
    this.setState({
      showUsersListenedSongsModal: false,
      currentSongView: null,
    });
  };

  handleSongInformationModalOpen = (song) => {
    this.setState({ showSongInformationModal: true, currentSongView: song });
  };

  handleSongInformationModalClose = () => {
    this.setState({ showSongInformationModal: false, currentSongView: null });
  };

  handleUserTagSongModalOpen = (song, indice) => {
    this.setState({
      showUserTagSongModal: true,
      currentSongView: song,
      currentIndiceSongView: indice,
    });
  };

  handleUserTagSongModalClose = () => {
    this.setState({
      showUserTagSongModal: false,
      currentSongView: null,
      currentIndiceSongView: 0,
    });
  };

  handleSongReportsModalOpen = (song) => {
    this.setState({ showSongReportsModal: true, currentSongView: song });
  };

  handleSongReportsModalClose = () => {
    this.setState({ showSongReportsModal: false, currentSongView: null });
  };

  handleSongEditModalOpen = (song) => {
    this.setState({ showSongEditModal: true, currentSongView: song });
  };

  handleSongEditModalClose = () => {
    this.setState({ showSongEditModal: false, currentSongView: null });
  };

  handleDeleteConfirmationOpen = (song) => {
    this.setState({ showDeletedModal: true, currentSongView: song });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedModal: false, currentSongView: null });
  };

  handleAcceptDeleteConfirmationModal = () => {
    const { currentSongView } = this.state;
    //this.props.store.ui.songs = this.props.store.ui.songs.filter(s => currentSongView.id !== s.id);
    this.props.store.ui.songs.map((song) => {
      if (song.id == currentSongView.id) song.deleted = 1;
    });
    this.setState({ showDeletedModal: false, currentSongView: null });
  };

  handleEditMultiplesOpen = () => {
    this.setState({ showSongEditMultiModal: true });
  };

  handleEditMultiplesClose = () => {
    this.setState({ showSongEditMultiModal: false });
  };

  handleEditMultiplesSave = (params) => {
    /*this.props.store.ui.songs.map((song) => {
            if(this.arraySongId.includes(song.id)){
                if(params["song_mood_id"]){
                    song.song_mood_id = params["song_mood_id"];
                    song.song_mood = params["song_mood"];
                }
                if(params["preference"])
                    song.preference = params["preference"];
                if(params["song_styles_id"]){
                    song.song_styles_id = params["song_styles_id"];
                    song.song_styles = params["song_styles"];
                }
                if(params["free_rights"])
                    song.free_rights = params["free_rights"];
                if(params["song_lenguages_id"]){
                    song.song_lenguages_id = params["song_lenguages_id"];
                    song.song_lenguages = params["song_lenguages"];
                }
                if(params["explicit"])
                    song.explicit = params["explicit"];
                if(params["status"])
                    song.status = params["status"];
                if(params["remix"])
                    song.remix = params["remix"];
                if(params["year"])
                    song.year = params["year"];
                if(params["singer_id"]){
                    song.singer_id = params["singer_id"];
                    song.singer = params["singer"];
                }
                if(params["record_label_id"]){
                    song.record_label_id = params["record_label_id"];
                    song.record_label = params["record_label"];
                }
                if(params["provider_id"]){
                    song.provider_id = params["provider_id"];
                    song.provider = params["provider"];
                }
                if(params["commentary"])
                    song.commentary = params["commentary"];
            }
        });*/
    const { per_page, page, filters, orderList } = this.state;
    this.arraySongId = [];
    this.setState({
      showSongEditMultiModal: false,
      songs: this.props.store.ui.moveSongsPage(
        per_page,
        page,
        filters ? filters : null,
        orderList ? orderList : null,
        this.search
      ),
    });
  };

  handleMarkedSong = () => {
    const { per_page, page, filters, orderList } = this.state;
    this.arraySongId = [];
    this.setState({
      showSongEditMultiModal: false,
      songs: this.props.store.ui.moveSongsPage(
        per_page,
        page,
        filters ? filters : null,
        orderList ? orderList : null,
        this.search
      ),
    });
  };

  handleConfirmationApproveModalOpen = () => {
    this.setState({ showConfirmationApproveModal: true });
  };

  handleConfirmationApproveModalClose = () => {
    this.setState({ showConfirmationApproveModal: false });
  };

  handleConfirmationPreSlopesModalOpen = () => {
    this.setState({ showConfirmationPreSlopesModal: true });
  };

  handleConfirmationPreSlopesModalClose = () => {
    this.setState({ showConfirmationPreSlopesModal: false });
  };

  handleConfirmationSlopesModalOpen = () => {
    this.setState({ showConfirmationSlopesModal: true });
  };

  handleConfirmationSlopesModalClose = () => {
    this.setState({ showConfirmationSlopesModal: false });
  };

  handleConfirmationStartEditingModalOpen = () => {
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .search("/songs/get_count_temporary_song_editing", {
        per_page: 10000,
      })
      .then(
        (res) => {
          if (res.results.count == 0)
            this.setState({ showConfirmationStartEditingModal: true });
          else this.setState({ showWarningEditingModal: true });
        },
        (error) => {
          toastManager.add("Error", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      );
  };

  handleWarningEditingModalAccept = () => {
    this.setState({
      isUpdateStatus: false,
      songs: this.getSongsAll(25, null, null, null),
      showWarningEditingModal: false,
    });
    this.resetFilters();
    this.search = null;
  };

  handleWarningEditingModalClose = () => {
    this.setState({ showWarningEditingModal: false });
  };

  handleConfirmationStartEditingModalClose = () => {
    this.setState({ showConfirmationStartEditingModal: false });
  };

  handleConfirmationFinalizeEditingModalOpen = () => {
    this.setState({ showConfirmationFinalizeEditingModal: true });
  };

  handleConfirmationFinalizeEditingModalClose = () => {
    this.setState({ showConfirmationFinalizeEditingModal: false });
  };

  updateStatus = (type) => {
    const { filters, orderList } = this.state;
    const { toastManager } = this.props;
    this.setState(
      {
        isUpdateStatus: true,
        showConfirmationApproveModal: false,
        showConfirmationSlopesModal: false,
        showConfirmationPreSlopesModal: false,
      },
      () => {
        this.props.store.kasimuAPIClient
          .putOne("/songs/put_status", "?type=" + type)
          .then(
            (res) => {
              toastManager.add(
                type == 1
                  ? "Se han aprobado exitosamente todas las canciones pendientes."
                  : type == 2
                  ? "Se han pasado exitosamente a pendientes todas las canciones pre-pendientes."
                  : "Se han pasado exitosamente a pre-pendientes todas las canciones cargadas.",
                {
                  appearance: "success",
                  autoDismiss: true,
                }
              );
              this.setState({
                isUpdateStatus: false,
                songs: this.getSongsAll(
                  25,
                  filters ? filters : null,
                  orderList ? orderList : null,
                  this.search
                ),
              });
            },
            (error) => {
              toastManager.add(
                "Error, algo salio mal actualizando el estado de las canciones.",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
              this.setState({ isUpdateStatus: false });
            }
          )
          .catch((error) => {
            toastManager.add(
              "Error, algo salio mal actualizando el estado de las canciones.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            this.setState({ isUpdateStatus: false });
          });
      }
    );
  };

  temporarySongsEditing = (type) => {
    const { filters, orderList } = this.state;
    const { toastManager } = this.props;
    let params = {
      search: this.search,
      arraySongId: this.arraySongId,
      type: type,
      filters: filters,
      orders: orderList,
    };

    this.setState(
      {
        isUpdateStatus: true,
        showConfirmationApproveModal: false,
        showConfirmationSlopesModal: false,
        showConfirmationPreSlopesModal: false,
        showConfirmationStartEditingModal: false,
        showConfirmationFinalizeEditingModal: false,
      },
      () => {
        this.props.store.kasimuAPIClient
          .putParams("/songs/temporary_song_editing", params)
          .then(
            (res) => {
              toastManager.add(
                type == 1
                  ? "Se ha iniciado exitosamente la edición de las canciones buscadas."
                  : "Se ha finalizado exitosamente la edición de las canciones.",
                {
                  appearance: "success",
                  autoDismiss: true,
                }
              );
              this.setState({
                isUpdateStatus: false,
                songs: this.getSongsAll(25, null, null, null),
              });
              this.resetFilters();
              this.search = null;
            },
            (error) => {
              toastManager.add(
                "Error, algo salio mal al finalizar la edición canciones.",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
              this.setState({ isUpdateStatus: false });
            }
          )
          .catch((error) => {
            toastManager.add(
              "Error, algo salio mal al finalizar la edición canciones.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            this.setState({ isUpdateStatus: false });
          });
      }
    );
  };

  getSongsAll = (per_page, filters, orders, search) => {
    return this.props.store.ui.getSongsAll(per_page, filters, orders, search);
  };

  handleSongCVSOpen = () => {
    this.setState({ showSongCSVModal: true });
  };

  handleSongCVSClose = () => {
    this.setState({ showSongCSVModal: false });
  };

  handlesUsersListenedSongsCSVOpen = () => {
    this.setState({ showUsersListenedSongsCSVModal: true });
  };

  handleUsersListenedSongsCSVClose = () => {
    this.setState({ showUsersListenedSongsCSVModal: false });
  };

  getSongStylesAll = () => {
    return this.props.store.songStyles.fetchAll();
  };

  getSongLanguageAll = () => {
    return this.props.store.songLanguage.fetchAll();
  };

  onPageChange = (page) => {
    const { per_page, filters, orderList } = this.state;
    this.setState({
      songs: this.props.store.ui.moveSongsPage(
        per_page,
        page,
        filters,
        orderList,
        this.search
      ),
      page: page,
    });
  };

  getOptionsStyles = (obj) => {
    return obj.toArray().map((o) => ({
      value: o.id,
      label: o.description,
      free_rights: o.free_rights,
    }));
  };

  getLabelPreference = (p) => {
    switch (p) {
      case 1:
        return "1. Lista";
      case 2:
        return "2. Destacada";
      case 3:
        return "3. Recomendada";
      case 4:
        return "4. Variedad";
      case 8:
        return "8. No programar";
      default:
        return p;
    }
  };

  getPreferenceOptions = () => {
    let items = [];
    for (let i = 1; i <= 15; i++) {
      items.push({ value: i, label: this.getLabelPreference(i) });
    }
    return items;
  };

  handleChangeDate = (sender, value, name, validation) => {
    const newFilters = this.state.filters;
    switch (name) {
      case "date_from":
        newFilters.date_from = value;
        this.setState({ filters: newFilters });
        break;
      case "date_to":
        newFilters.date_to = value;
        this.setState({ filters: newFilters });
        break;
      case "date_from_user":
        newFilters.date_from_user = value;
        this.setState({ filters: newFilters });
        break;
      case "date_to_user":
        newFilters.date_to_user = value;
        this.setState({ filters: newFilters });
        break;
      default:
        break;
    }
  };

  handleSelectChange = (value, sender) => {
    //const val = !value ? 1 : value.value;
    const val = value.value;
    const newFilters = this.state.filters;
    switch (sender.name) {
      case "selectDateType":
        newFilters.date_type = val;
        break;
      case "selectStyle":
        newFilters.styles_id = val;
        break;
      case "selectPreferenceFrom":
        newFilters.preference_from = val;
        break;
      case "selectPreferenceTo":
        newFilters.preference_to = val;
        break;
      case "selectMood":
        newFilters.mood_id = val;
        break;
      case "selectService":
        newFilters.service = val;
        break;
      case "selectStatus":
        newFilters._status = val;
        break;
      case "selectMyTags":
        newFilters.my_tag_id = val;
        break;
      case "selectTagsOther":
        newFilters.my_tag_id = val;
        break;
      case "selectExplicit":
        newFilters.explicit = val;
        break;
      case "selectUserPreference":
        newFilters.user_preference = val;
        break;
      case "selectNotAssiggned":
        newFilters.not_assigned = val;
        break;
      case "selectYearFrom":
        newFilters.year_from = val;
        break;
      case "selectYearTo":
        newFilters.year_to = val;
        break;
      case "selectUsers":
        newFilters.user_id = val;
        this.setState({ currentUsername: value.label });
        break;
      case "selectSearchTypeByUser":
        newFilters.search_type_by_user = val;
        break;
      default:
        break;
    }
    this.setState({ filters: newFilters });
  };

  handleFiltersChange = (sender, value, name) => {
    const state = this.state;
    const newFilters = this.state.filters;
    if (name == "filtersDateRange") {
      if (value) {
        newFilters.date_from = moment(new Date(), "DD-MM-YYYY").toDate();
        newFilters.date_to = moment(new Date(), "DD-MM-YYYY").toDate();
      } else {
        newFilters.date_from = null;
        newFilters.date_to = null;
      }
    }
    if (name == "filtersStyle") {
      newFilters.styles_id = null;
    }
    if (name == "filtersPreferenceRange") {
      newFilters.preference_from = null;
      newFilters.preference_to = null;
    }
    if (name == "filtersMood") {
      newFilters.mood_id = null;
    }
    if (name == "filtersService") {
      newFilters.service = -1;
    }
    if (name == "filtersStatus") {
      newFilters._status = null;
    }
    if (name == "filtersMusicLists") {
      newFilters.music_list = true;
    }
    if (name == "filtersExplicit") {
      newFilters.explicit = null;
    }
    if (name == "filtersUserPreference") {
      newFilters.user_preference = null;
    }
    if (name == "filtersNotAssigned") {
      newFilters.not_assigned = null;
    }
    if (name == "filtersDeleted") {
      newFilters.deleted = true;
    }
    if (name == "filtersMarkedSongs") {
      newFilters.marked_songs = true;
    }
    if (name == "filtersYear") {
      if (value) {
        newFilters.year_from = moment().year();
        newFilters.year_to = moment().year();
      } else {
        newFilters.year_from = null;
        newFilters.year_to = null;
      }
    }
    if (name == "filtersUsers") {
      this.setState({ currentUsername: null });
      newFilters.user_id = null;
      newFilters.search_type_by_user = null;
      if (value) {
        newFilters.date_from_user = moment(new Date(), "DD-MM-YYYY").toDate();
        newFilters.date_to_user = moment(new Date(), "DD-MM-YYYY").toDate();
      } else {
        newFilters.date_from_user = null;
        newFilters.date_to_user = null;
      }
    }
    if (name == "filtersUsersMyTags") {
      this.setState({ filtersUsersTagsOther: false });
      newFilters.my_tag_id = null;
    }
    if (name == "filtersUsersTagsOther") {
      this.setState({ filtersUsersMyTags: false });
      newFilters.my_tag_id = null;
    }
    state[name] = value;
    this.setState({ state: state, filters: newFilters });
  };

  handleFilters = () => {
    const {
      filters,
      filtersDateRange,
      filtersStyle,
      filtersPreferenceRange,
      filtersMood,
      filtersService,
      filtersStatus,
      filtersMusicLists,
      filtersExplicit,
      filtersUserPreference,
      filtersNotAssigned,
      filtersDeleted,
      filtersMarkedSongs,
      filtersYear,
      filtersUsers,
      filtersUsersMyTags,
      filtersUsersTagsOther,
      orders,
      orderList,
    } = this.state;

    const newFilters = filters;
    if (filtersDateRange) {
      newFilters.date_from = moment(newFilters.date_from, "DD-MM-YYYY").format(
        "DD-MM-YYYY"
      );
      newFilters.date_to = moment(newFilters.date_to, "DD-MM-YYYY").format(
        "DD-MM-YYYY"
      );
    } else {
      newFilters.date_from = null;
      newFilters.date_to = null;
    }
    if (!filtersStyle) {
      newFilters.styles_id = null;
    }
    if (!filtersPreferenceRange) {
      newFilters.preference_from = null;
      newFilters.preference_to = null;
    }
    if (!filtersMood) {
      newFilters.mood_id = null;
    }
    if (!filtersService) {
      newFilters.service = -1;
    }
    if (!filtersStatus) {
      newFilters._status = null;
    }
    if (!filtersMusicLists) {
      newFilters.music_list = null;
    }
    if (!filtersExplicit) {
      newFilters.explicit = null;
    }
    if (!filtersUserPreference) {
      newFilters.user_preference = null;
    }
    if (!filtersNotAssigned) {
      newFilters.not_assigned = null;
    }
    if (!filtersDeleted) {
      newFilters.deleted = null;
    }
    if (!filtersMarkedSongs) {
      newFilters.marked_songs = null;
    }
    if (!filtersYear) {
      newFilters.year_from = null;
      newFilters.year_to = null;
    }
    if (!filtersUsersMyTags && !filtersUsersTagsOther) {
      newFilters.my_tag_id = null;
    }
    if (filtersUsers) {
      newFilters.date_from_user = moment(
        newFilters.date_from_user,
        "DD-MM-YYYY"
      ).format("DD-MM-YYYY");
      newFilters.date_to_user = moment(
        newFilters.date_to_user,
        "DD-MM-YYYY"
      ).format("DD-MM-YYYY");
    } else {
      this.setState({ currentUsername: null });
      newFilters.user_id = null;
      newFilters.search_type_by_user = null;
      newFilters.date_from_user = null;
      newFilters.date_to_user = null;
    }
    if (
      !orders.ascPreference &&
      !orders.ascSinger &&
      !orders.ascStyle &&
      !orders.ascMood &&
      !orders.ascService &&
      !orders.ascUso &&
      !orders.ascA &&
      !orders.ascCat &&
      !orders.ascUat
    ) {
      this.setState({
        songs: this.getSongsAll(25, newFilters, null, this.search),
      });
      return;
    }
    this.setState({
      songs: this.getSongsAll(25, newFilters, orderList, this.search),
    });
  };

  handleOrderChange = (sender, value, name) => {
    let newOrders = this.state.orders;
    let orderList = this.state.orderList;
    let namelist = null;
    if (name == "ascPreference") {
      if (newOrders.descPreference) {
        newOrders.descPreference = false;
        namelist = "-preference";
      } else namelist = "preference";
    }
    if (name == "ascSinger") {
      if (newOrders.descSinger) {
        newOrders.descSinger = false;
        namelist = "-singer__name";
      } else namelist = "singer__name";
    }
    if (name == "ascStyle") {
      if (newOrders.descStyle) {
        newOrders.descStyle = false;
        namelist = "-song_styles__description";
      } else namelist = "song_styles__description";
    }
    if (name == "ascMood") {
      if (newOrders.descMood) {
        newOrders.descMood = false;
        namelist = "-song_mood__description";
      } else namelist = "song_mood__description";
    }
    if (name == "ascService") {
      if (newOrders.descService) {
        newOrders.descService = false;
        namelist = "-free_rights";
      } else namelist = "free_rights";
    }
    if (name == "ascUso") {
      if (newOrders.descUso) {
        newOrders.descUso = false;
        namelist = "-reproductions";
      } else namelist = "reproductions";
    }
    if (name == "ascA") {
      if (newOrders.descA) {
        newOrders.descA = false;
        namelist = "-year";
      } else namelist = "year";
    }
    if (name == "ascCat") {
      if (newOrders.descCat) {
        newOrders.descCat = false;
        namelist = "-created_at";
      } else namelist = "created_at";
    }
    if (name == "ascUat") {
      if (newOrders.descUat) {
        newOrders.descUat = false;
        namelist = "-updated_at";
      } else namelist = "updated_at";
    }
    newOrders[name] = value;

    if (value) {
      orderList.push(namelist);
    } else {
      orderList = orderList.filter((i) => namelist !== i);
    }

    this.setState({ orderList: orderList, orders: newOrders });
  };

  getIndexOrder = (order) => {
    return this.state.orderList.indexOf(order);
  };

  handleOrder = () => {
    const { orderList, filters } = this.state;
    if (
      !filters.date_from &&
      !filters.date_to &&
      !filters.styles_id &&
      !filters.preference_from &&
      !filters.preference_to &&
      !filters.mood_id &&
      filters.service == -1 &&
      !filters._status &&
      !filters.music_list &&
      !filters.explicit &&
      !filters.user_preference &&
      !filters.not_assigned &&
      !filters.deleted &&
      !filters.filtersMarkedSongs &&
      !filters.year_from &&
      !filters.year_to &&
      !filters.user_id &&
      !filters.search_type_by_user &&
      !filters.date_from_user &&
      !filters.date_to_user
    ) {
      this.setState({
        songs: this.getSongsAll(25, null, orderList, this.search),
      });
      return;
    }
    this.setState({
      songs: this.getSongsAll(25, filters, orderList, this.search),
    });
  };

  handleDescending = (name) => {
    let newOrders = this.state.orders;
    let orderList = this.state.orderList;
    if (name == "descPreference") {
      if (newOrders.descPreference) {
        var index = this.getIndexOrder("-preference");
        if (index !== -1) {
          orderList[index] = "preference";
        }
        newOrders.descPreference = false;
      } else {
        var index = this.getIndexOrder("preference");
        if (index !== -1) {
          orderList[index] = "-preference";
        }
        newOrders.descPreference = true;
      }
    }
    if (name == "descSinger") {
      if (newOrders.descSinger) {
        var index = this.getIndexOrder("-singer__name");
        if (index !== -1) {
          orderList[index] = "singer__name";
        }
        newOrders.descSinger = false;
      } else {
        var index = orderList.indexOf("singer__name");
        if (index !== -1) {
          orderList[index] = "-singer__name";
        }
        newOrders.descSinger = true;
      }
    }
    if (name == "descStyle") {
      if (newOrders.descStyle) {
        var index = this.getIndexOrder("-song_styles__description");
        if (index !== -1) {
          orderList[index] = "song_styles__description";
        }
        newOrders.descStyle = false;
      } else {
        var index = this.getIndexOrder("song_styles__description");
        if (index !== -1) {
          orderList[index] = "-song_styles__description";
        }
        newOrders.descStyle = true;
      }
    }
    if (name == "descMood") {
      if (newOrders.descMood) {
        var index = this.getIndexOrder("-song_mood__description");
        if (index !== -1) {
          orderList[index] = "song_mood__description";
        }
        newOrders.descMood = false;
      } else {
        var index = this.getIndexOrder("song_mood__description");
        if (index !== -1) {
          orderList[index] = "-song_mood__description";
        }
        newOrders.descMood = true;
      }
    }
    if (name == "descService") {
      if (newOrders.descService) {
        var index = this.getIndexOrder("-free_rights");
        if (index !== -1) {
          orderList[index] = "free_rights";
        }
        newOrders.descService = false;
      } else {
        var index = this.getIndexOrder("free_rights");
        if (index !== -1) {
          orderList[index] = "-free_rights";
        }
        newOrders.descService = true;
      }
    }
    if (name == "descUso") {
      if (newOrders.descUso) {
        var index = this.getIndexOrder("-reproductions");
        if (index !== -1) {
          orderList[index] = "reproductions";
        }
        newOrders.descUso = false;
      } else {
        var index = this.getIndexOrder("reproductions");
        if (index !== -1) {
          orderList[index] = "-reproductions";
        }
        newOrders.descUso = true;
      }
    }
    if (name == "descA") {
      if (newOrders.descA) {
        var index = this.getIndexOrder("-year");
        if (index !== -1) {
          orderList[index] = "year";
        }
        newOrders.descA = false;
      } else {
        var index = this.getIndexOrder("year");
        if (index !== -1) {
          orderList[index] = "-year";
        }
        newOrders.descA = true;
      }
    }
    if (name == "descCat") {
      if (newOrders.descCat) {
        var index = this.getIndexOrder("-created_at");
        if (index !== -1) {
          orderList[index] = "created_at";
        }
        newOrders.descCat = false;
      } else {
        var index = this.getIndexOrder("created_at");
        if (index !== -1) {
          orderList[index] = "-created_at";
        }
        newOrders.descCat = true;
      }
    }
    if (name == "descUat") {
      if (newOrders.descUat) {
        var index = this.getIndexOrder("-updated_at");
        if (index !== -1) {
          orderList[index] = "updated_at";
        }
        newOrders.descUat = false;
      } else {
        var index = this.getIndexOrder("updated_at");
        if (index !== -1) {
          orderList[index] = "-updated_at";
        }
        newOrders.descUat = true;
      }
    }
    this.setState({ orders: newOrders, orderList: orderList });
  };

  onSearch = () => {
    const { orders, filters, orderList } = this.state;
    if (this.search && this.search != " ") {
      if (
        !filters.date_from &&
        !filters.date_to &&
        !filters.styles_id &&
        !filters.preference_from &&
        !filters.preference_to &&
        !filters.mood_id &&
        filters.service == -1 &&
        !filters._status &&
        !filters.music_list &&
        !filters.explicit &&
        !filters.user_preference &&
        !filters.not_assigned &&
        !filters.deleted &&
        !filters.marked_songs &&
        !filters.year_from &&
        !filters.year_to &&
        !filters.user_id &&
        !filters.search_type_by_user &&
        !filters.date_from_user &&
        !filters.date_to_user
      ) {
        if (
          !orders.ascPreference &&
          !orders.ascSinger &&
          !orders.ascStyle &&
          !orders.ascMood &&
          !orders.ascService &&
          !orders.ascUso &&
          !orders.ascA &&
          !orders.ascCat &&
          !orders.ascUat
        ) {
          this.setState({
            songs: this.getSongsAll(25, null, null, this.search),
            page: 1,
          });
          return;
        } else {
          this.setState({
            songs: this.getSongsAll(25, filters, orderList, this.search),
            page: 1,
          });
        }
      } else {
        if (
          !orders.ascPreference &&
          !orders.ascSinger &&
          !orders.ascStyle &&
          !orders.ascMood &&
          !orders.ascService &&
          !orders.ascUso &&
          !orders.ascA &&
          !orders.ascCat &&
          !orders.ascUat
        ) {
          this.setState({
            songs: this.getSongsAll(25, filters, null, this.search),
            page: 1,
          });
          return;
        } else {
          this.setState({
            songs: this.getSongsAll(25, filters, orderList, this.search),
            page: 1,
          });
        }
      }
    }
  };

  getFilters = () => {
    const { filters } = this.state;
    if (
      !filters.date_from &&
      !filters.date_to &&
      !filters.styles_id &&
      !filters.preference_from &&
      !filters.preference_to &&
      !filters.mood_id &&
      filters.service == -1 &&
      !filters._status &&
      !filters.music_list &&
      !filters.explicit &&
      !filters.user_preference &&
      !filters.not_assigned &&
      !filters.deleted &&
      !filters.marked_songs &&
      !filters.year_from &&
      !filters.year_to &&
      !filters.user_id &&
      !filters.search_type_by_user &&
      !filters.date_from_user &&
      !filters.date_to_user
    ) {
      return null;
    } else {
      return filters;
    }
  };

  getOrders = () => {
    const { orders, orderList } = this.state;
    if (
      !orders.ascPreference &&
      !orders.ascSinger &&
      !orders.ascStyle &&
      !orders.ascMood &&
      !orders.ascService &&
      !orders.ascUso &&
      !orders.ascA &&
      !orders.ascCat &&
      !orders.ascUat
    ) {
      return null;
    } else {
      return orderList;
    }
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  getOptionsCatalogs = (obj) => {
    return obj.toArray().map((o) => ({ value: o.identifier, label: o.name }));
  };

  getYearAll = () => {
    let items = [];
    for (let i = 1700; i <= 2050; i++) {
      items.push({
        value: i,
        label: i,
      });
    }
    return items;
  };

  getArrayStatus(statusOptions, rolId) {
    let arrayStatus = [];
    if (rolId == 1 || rolId == 2 || rolId == 3) {
      return statusOptions;
    } else if (rolId == 4) {
      arrayStatus = [2, 3, 4, 5, 7];
    }
    return statusOptions.filter((s) => arrayStatus.includes(s.value));
  }

  getOptionsMyTags = (obj) => {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  };

  getPanel = (
    accountsOptions,
    catalogsOptions,
    stylesOptions,
    preferenceOptions,
    moodsOptions,
    result,
    editing,
    myTagsOptions,
    tagsOtherOptions
  ) => {
    this.resultAllSongs = result;
    const rolId = this.props.store.loggedInUser.getRolId();
    const {
      filtersDateRange,
      filtersStyle,
      filtersPreferenceRange,
      filtersMood,
      filtersService,
      filtersStatus,
      filtersMusicLists,
      filtersExplicit,
      filtersUserPreference,
      filtersNotAssigned,
      filtersDeleted,
      filtersMarkedSongs,
      filtersYear,
      filtersUsers,
      filtersUsersMyTags,
      filtersUsersTagsOther,
      orders,
    } = this.state;

    const dateOptions = [
      { value: 1, label: "Fecha de creación" },
      { value: 2, label: "Fecha de actualización o edición" },
    ];
    const statusOptions = [
      { value: 1, label: "Cargadas" },
      { value: 6, label: "Pre-Pendientes" },
      { value: 2, label: "Pendientes" },
      { value: 3, label: "Aprobadas" },
      { value: 4, label: "Publicadas" },
      { value: 5, label: "Modificadas" },
      { value: 7, label: "Reparar" },
    ];
    const explicitOptions = [
      { value: 2, label: "Canciones no explicitas" },
      { value: 1, label: "Canciones explicitas" },
    ];
    const userPreferenceOptions = [
      { value: 2, label: "Favoritas" },
      { value: 10, label: "Bloqueadas" },
    ];
    const notAssignedOptions = [
      { value: 1, label: "Interpretes" },
      { value: 2, label: "Estilo Musical" },
      { value: 3, label: "Proveedores" },
      { value: 4, label: "Sellos Discográficos" },
    ];
    const searchTypeByUserOptions = [
      { value: 1, label: "Escuchadas" },
      { value: 2, label: "Modificadas" },
    ];
    return (
      <Columns isMultiline>
        <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
          <Panel
            color="gray"
            padding={this.props.store.viewMobile ? "25px 34px" : "8px 20px"}
            style={{
              borderRadius: !this.props.store.viewMobile && "30px",
              backgroundColor: !this.props.store.viewMobile && "#373636",
            }}
          >
            {this.props.store.viewMobile ? (
              <Text
                size={this.props.store.viewMobile ? "md" : "sm"}
                weight="strong"
                lead
              >
                {"Filtrar por"}
              </Text>
            ) : (
              <div
                className="flex"
                style={{
                  justifyContent:
                    !this.props.store.viewMobile && "space-between",
                }}
                onClick={() => this.setState({ open: !this.state.open })}
              >
                <div></div>

                <Text
                  size={this.props.store.viewMobile ? "md" : "lg"}
                  weight="strong"
                  lead={this.props.store.viewMobile}
                >
                  {"Filtrar por"}
                </Text>
                <Button
                  kind="link"
                  color="basic"
                  iconKasimu={this.state.open ? "arrow-up" : "arrow-down"}
                  onClick={() => this.setState({ open: !this.state.open })}
                  space
                />
              </div>
            )}
            {this.state.open || this.props.store.viewMobile ? (
              <>
                <Columns className="my-2">
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"date_range"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersDateRange}
                      value="filtersDateRange"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Rango de fecha"}
                      </Text>
                    </Checkbox>
                    {filtersDateRange && (
                      <div>
                        <div
                          className="is-row mt-1"
                          style={{ alignItems: "center" }}
                        >
                          <div style={{ marginRight: "5px" }}>
                            <Text
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              lineBreak
                            >
                              {"Tipo"}
                            </Text>
                          </div>
                          <Select
                            isMobile={!this.props.store.viewMobile}
                            name="selectDateType"
                            onChange={this.handleSelectChange}
                            className="is-fullwidth"
                            placeholder={"Elegir un tipo de fecha..."}
                            options={dateOptions}
                            defaultValue={
                              this.state.filters.date_type
                                ? dateOptions[
                                    dateOptions
                                      .map(function (e) {
                                        return e.value;
                                      })
                                      .indexOf(this.state.filters.date_type)
                                  ]
                                : dateOptions[-1]
                            }
                            backgroundColor="#2E2E2E"
                          />
                        </div>
                        {this.state.filters.date_type && (
                          <div
                            className="is-row mt-1"
                            style={{ alignItems: "center" }}
                          >
                            <div style={{ marginRight: "5px" }}>
                              <Text
                                size={this.props.store.viewMobile ? "xl" : "lg"}
                                lineBreak
                              >
                                {"Desde"}
                              </Text>
                            </div>
                            <DateTimePicker
                              name="date_from"
                              value={moment(
                                this.state.filters.date_from,
                                "DD-MM-YYYY"
                              ).toDate()}
                              dateFormat="dd-MM-yyyy"
                              onChange={this.handleChangeDate}
                              className="is-fullwidth"
                              size="xl"
                              iconKasimu="arrow-down"
                              backgroundColor="gray3"
                              paddingInput={
                                this.props.store.viewMobile && "20px"
                              }
                              borderColor="gray3"
                              reversed
                              fullWidth
                            />
                            <div
                              style={{ marginRight: "5px", marginLeft: "10px" }}
                            >
                              <Text
                                size={this.props.store.viewMobile ? "xl" : "lg"}
                                lineBreak
                              >
                                {"Hasta"}
                              </Text>
                            </div>
                            <DateTimePicker
                              name="date_to"
                              value={moment(
                                this.state.filters.date_to,
                                "DD-MM-YYYY"
                              ).toDate()}
                              dateFormat="dd-MM-yyyy"
                              onChange={this.handleChangeDate}
                              className="is-fullwidth"
                              size="xl"
                              iconKasimu="arrow-down"
                              backgroundColor="gray3"
                              paddingInput={
                                this.props.store.viewMobile && "20px"
                              }
                              borderColor="gray3"
                              reversed
                              fullWidth
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"style"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersStyle}
                      value="filtersStyle"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Estilo"}
                      </Text>
                    </Checkbox>
                    {filtersStyle && (
                      <div
                        className="is-row mt-1"
                        style={{ alignItems: "center" }}
                      >
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectStyle"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir un estilo..."}
                          options={stylesOptions}
                          defaultValue={
                            this.state.filters.styles_id
                              ? stylesOptions[
                                  stylesOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.styles_id)
                                ]
                              : stylesOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"preference_range"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersPreferenceRange}
                      value="filtersPreferenceRange"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Rango de preferencia"}
                      </Text>
                    </Checkbox>
                    {filtersPreferenceRange && (
                      <div
                        className="is-row mt-1"
                        style={{ alignItems: "center" }}
                      >
                        <div style={{ marginRight: "5px" }}>
                          <Text
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            lineBreak
                          >
                            {"Desde"}
                          </Text>
                        </div>
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectPreferenceFrom"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir..."}
                          options={preferenceOptions}
                          defaultValue={
                            this.state.filters.preference_from
                              ? preferenceOptions[
                                  preferenceOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.preference_from)
                                ]
                              : preferenceOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                        <div style={{ marginRight: "5px", marginLeft: "10px" }}>
                          <Text
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            lineBreak
                          >
                            {"Hasta"}
                          </Text>
                        </div>
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectPreferenceTo"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir..."}
                          options={preferenceOptions}
                          defaultValue={
                            this.state.filters.preference_to
                              ? preferenceOptions[
                                  preferenceOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.preference_to)
                                ]
                              : preferenceOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"mood"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersMood}
                      value="filtersMood"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Humor"}
                      </Text>
                    </Checkbox>
                    {filtersMood && (
                      <div className="is-row mt-1">
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectMood"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir un humor..."}
                          options={moodsOptions}
                          defaultValue={
                            this.state.filters.mood_id
                              ? moodsOptions[
                                  moodsOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.mood_id)
                                ]
                              : moodsOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"service"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersService}
                      value="filtersService"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Servicio"}
                      </Text>
                    </Checkbox>
                    {filtersService && (
                      <div className="is-row mt-1">
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectService"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir un servicio..."}
                          options={catalogsOptions}
                          defaultValue={
                            this.state.filters.service != -1
                              ? catalogsOptions[
                                  catalogsOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.service)
                                ]
                              : catalogsOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"_status"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersStatus}
                      value="filtersStatus"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Estado"}
                      </Text>
                    </Checkbox>
                    {filtersStatus && (
                      <div className="is-row mt-1">
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectStatus"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir un estado..."}
                          options={this.getArrayStatus(statusOptions, rolId)}
                          defaultValue={
                            this.state.filters._status
                              ? statusOptions[
                                  statusOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters._status)
                                ]
                              : statusOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"my_tag_id"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersUsersMyTags}
                      value="filtersUsersMyTags"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Mis Etiquetas"}
                      </Text>
                    </Checkbox>
                    {filtersUsersMyTags && (
                      <div className="is-row mt-1">
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectMyTags"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir un etiqueta..."}
                          options={myTagsOptions}
                          defaultValue={
                            this.state.filters.my_tag_id
                              ? myTagsOptions[
                                  myTagsOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.my_tag_id)
                                ]
                              : myTagsOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"other_tag_id"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersUsersTagsOther}
                      value="filtersUsersTagsOther"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Otras Etiquetas"}
                      </Text>
                    </Checkbox>
                    {filtersUsersTagsOther && (
                      <div className="is-row mt-1">
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectTagsOther"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir un etiqueta..."}
                          options={tagsOtherOptions}
                          defaultValue={
                            this.state.filters.my_tag_id
                              ? tagsOtherOptions[
                                  tagsOtherOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.my_tag_id)
                                ]
                              : tagsOtherOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"music_list"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersMusicLists}
                      value="filtersMusicLists"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Canciones de lista"}
                      </Text>
                    </Checkbox>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"explicit"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersExplicit}
                      value="filtersExplicit"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Contenido explicito"}
                      </Text>
                    </Checkbox>
                    {filtersExplicit && (
                      <div className="is-row mt-1">
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectExplicit"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir tipo de contenido..."}
                          options={explicitOptions}
                          defaultValue={
                            this.state.filters.explicit != null
                              ? explicitOptions[
                                  explicitOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.explicit)
                                ]
                              : explicitOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"user_preference"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersUserPreference}
                      value="filtersUserPreference"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Preferencia del usuario"}
                      </Text>
                    </Checkbox>
                    {filtersUserPreference && (
                      <div className="is-row mt-1">
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectUserPreference"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir tipo de preferencia..."}
                          options={userPreferenceOptions}
                          defaultValue={
                            this.state.filters.user_preference != null
                              ? userPreferenceOptions[
                                  userPreferenceOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.user_preference)
                                ]
                              : userPreferenceOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"not_assigned"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersNotAssigned}
                      value="filtersNotAssigned"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"(Sin Asignar)"}
                      </Text>
                    </Checkbox>
                    {filtersNotAssigned && (
                      <div className="is-row mt-1">
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectNotAssiggned"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir..."}
                          options={notAssignedOptions}
                          defaultValue={
                            this.state.filters.not_assigned != null
                              ? notAssignedOptions[
                                  notAssignedOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.not_assigned)
                                ]
                              : notAssignedOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"marked_songs"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersMarkedSongs}
                      value="filtersMarkedSongs"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Canciones Marcadas"}
                      </Text>
                    </Checkbox>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <Checkbox
                      invert
                      key={"year"}
                      size="lg"
                      onChange={this.handleFiltersChange}
                      isChecked={filtersYear}
                      value="filtersYear"
                      className="items-center"
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                      >
                        {"Año"}
                      </Text>
                    </Checkbox>
                    {filtersYear && (
                      <div
                        className="is-row mt-1"
                        style={{ alignItems: "center" }}
                      >
                        <div style={{ marginRight: "5px" }}>
                          <Text
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            lineBreak
                          >
                            {"Desde"}
                          </Text>
                        </div>
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectYearFrom"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir un año..."}
                          options={this.allYears}
                          defaultValue={
                            this.state.filters.year_from
                              ? this.allYears[
                                  this.allYears
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.year_from)
                                ]
                              : this.allYears[222]
                          }
                          backgroundColor="#2E2E2E"
                        />
                        <div style={{ marginRight: "5px", marginLeft: "10px" }}>
                          <Text
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            lineBreak
                          >
                            {"Hasta"}
                          </Text>
                        </div>
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectYearTo"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir un año..."}
                          options={this.allYears}
                          defaultValue={
                            this.state.filters.year_to
                              ? this.allYears[
                                  this.allYears
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.year_to)
                                ]
                              : this.allYears[222]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                  </Column>

                  {this.props.store.loggedInUser.checkPermission(19) ? (
                    <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                      <Checkbox
                        invert
                        key={"deleted"}
                        size="lg"
                        onChange={this.handleFiltersChange}
                        isChecked={filtersDeleted}
                        value="filtersDeleted"
                        className="items-center"
                      >
                        <Text
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                        >
                          {"Canciones borradas"}
                        </Text>
                      </Checkbox>
                    </Column>
                  ) : null}
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    {this.props.store.loggedInUser.checkPermission(20) ? (
                      <Checkbox
                        invert
                        key={"user_id"}
                        size="lg"
                        onChange={this.handleFiltersChange}
                        isChecked={filtersUsers}
                        value="filtersUsers"
                        className="items-center"
                      >
                        <Text
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                        >
                          {"Usuarios"}
                        </Text>
                      </Checkbox>
                    ) : null}
                    {filtersUsers && (
                      <div className="is-row mt-1">
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectUsers"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir un usuario..."}
                          options={accountsOptions}
                          defaultValue={
                            this.state.filters.user_id
                              ? accountsOptions[
                                  accountsOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(this.state.filters.user_id)
                                ]
                              : accountsOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                    {this.state.filters.user_id && (
                      <div className="is-row mt-1">
                        <Select
                          isMobile={!this.props.store.viewMobile}
                          name="selectSearchTypeByUser"
                          onChange={this.handleSelectChange}
                          className="is-fullwidth"
                          placeholder={"Elegir un tipo..."}
                          options={searchTypeByUserOptions}
                          defaultValue={
                            this.state.filters.search_type_by_user
                              ? searchTypeByUserOptions[
                                  searchTypeByUserOptions
                                    .map(function (e) {
                                      return e.value;
                                    })
                                    .indexOf(
                                      this.state.filters.search_type_by_user
                                    )
                                ]
                              : searchTypeByUserOptions[-1]
                          }
                          backgroundColor="#2E2E2E"
                        />
                      </div>
                    )}
                    {this.state.filters.search_type_by_user && (
                      <div
                        className="is-row mt-1"
                        style={{ alignItems: "center" }}
                      >
                        <div style={{ marginRight: "5px" }}>
                          <Text
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            lineBreak
                          >
                            {"Desde"}
                          </Text>
                        </div>
                        <DateTimePicker
                          name="date_from_user"
                          value={moment(
                            this.state.filters.date_from_user,
                            "DD-MM-YYYY"
                          ).toDate()}
                          dateFormat="dd-MM-yyyy"
                          onChange={this.handleChangeDate}
                          className="is-fullwidth"
                          size="xl"
                          iconKasimu="arrow-down"
                          backgroundColor="gray3"
                          paddingInput={this.props.store.viewMobile && "20px"}
                          borderColor="gray3"
                          reversed
                          fullWidth
                        />
                        <div style={{ marginRight: "5px", marginLeft: "10px" }}>
                          <Text
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            lineBreak
                          >
                            {"Hasta"}
                          </Text>
                        </div>
                        <DateTimePicker
                          name="date_to_user"
                          value={moment(
                            this.state.filters.date_to_user,
                            "DD-MM-YYYY"
                          ).toDate()}
                          dateFormat="dd-MM-yyyy"
                          onChange={this.handleChangeDate}
                          className="is-fullwidth"
                          size="xl"
                          iconKasimu="arrow-down"
                          backgroundColor="gray3"
                          paddingInput={this.props.store.viewMobile && "20px"}
                          borderColor="gray3"
                          reversed
                          fullWidth
                        />
                      </div>
                    )}
                  </Column>
                </Columns>

                <div className="flex" style={{ justifyContent: "flex-end" }}>
                  <Button
                    icon={"filter"}
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="px-2"
                    onClick={this.handleFilters}
                    disabled={
                      !(
                        filtersDateRange ||
                        filtersStyle ||
                        filtersPreferenceRange ||
                        filtersMood ||
                        filtersService ||
                        filtersStatus ||
                        filtersMusicLists ||
                        filtersExplicit ||
                        filtersUserPreference ||
                        filtersNotAssigned ||
                        filtersDeleted ||
                        filtersMarkedSongs ||
                        filtersYear ||
                        filtersUsers ||
                        filtersUsersMyTags ||
                        filtersUsersTagsOther
                      )
                    }
                    color="basic"
                    kind="outline"
                  >
                    <strong>{"Aplicar Filtro"}</strong>
                  </Button>
                </div>
              </>
            ) : null}
          </Panel>
        </Column>
        <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
          <Panel
            color="gray"
            padding={this.props.store.viewMobile ? "25px 34px" : "8px 20px"}
            style={{
              borderRadius: !this.props.store.viewMobile && "30px",
              backgroundColor: !this.props.store.viewMobile && "#373636",
            }}
          >
            {this.props.store.viewMobile ? (
              <Text
                size={this.props.store.viewMobile ? "md" : "sm"}
                weight="strong"
                lead
              >
                {"Ordenar por"}
              </Text>
            ) : (
              <div
                className="flex"
                style={{
                  justifyContent:
                    !this.props.store.viewMobile && "space-between",
                }}
                onClick={() =>
                  this.setState({ openOrder: !this.state.openOrder })
                }
              >
                <div></div>

                <Text
                  size={this.props.store.viewMobile ? "md" : "lg"}
                  weight="strong"
                  lead={this.props.store.viewMobile}
                >
                  {"Ordenar por"}
                </Text>
                <Button
                  kind="link"
                  color="basic"
                  iconKasimu={this.state.openOrder ? "arrow-up" : "arrow-down"}
                  onClick={() =>
                    this.setState({ openOrder: !this.state.openOrder })
                  }
                  space
                />
              </div>
            )}
            {this.state.openOrder || this.props.store.viewMobile ? (
              <>
                <Columns className="my-2">
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <div
                      className="is-row items-center"
                      style={{ height: "18px" }}
                    >
                      <Checkbox
                        invert
                        key={"order_preference"}
                        size="lg"
                        onChange={this.handleOrderChange}
                        isChecked={orders.ascPreference}
                        value="ascPreference"
                        className="items-center"
                      >
                        <Text
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                        >
                          {orders.ascPreference
                            ? orders.descPreference
                              ? this.getIndexOrder("-preference") +
                                1 +
                                " - " +
                                "Preferencia"
                              : this.getIndexOrder("preference") +
                                1 +
                                " - " +
                                "Preferencia"
                            : "Preferencia"}
                        </Text>
                      </Checkbox>
                      {orders.ascPreference && (
                        <div
                          style={{
                            width: "100%",
                            "text-align": "-webkit-right",
                          }}
                        >
                          <Button
                            kind="link"
                            color="basic"
                            size="xl"
                            icon={
                              orders.descPreference
                                ? "sort-numeric-down-alt"
                                : "sort-numeric-down"
                            }
                            onClick={() => {
                              this.handleDescending("descPreference");
                            }}
                            space
                          />
                        </div>
                      )}
                    </div>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <div
                      className="is-row items-center"
                      style={{ height: "18px" }}
                    >
                      <Checkbox
                        invert
                        key={"order_singer"}
                        size="lg"
                        onChange={this.handleOrderChange}
                        isChecked={orders.ascSinger}
                        value="ascSinger"
                        className="items-center"
                      >
                        <Text
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                        >
                          {orders.ascSinger
                            ? orders.descSinger
                              ? this.getIndexOrder("-singer__name") +
                                1 +
                                " - " +
                                "Intérprete"
                              : this.getIndexOrder("singer__name") +
                                1 +
                                " - " +
                                "Intérprete"
                            : "Intérprete"}
                        </Text>
                      </Checkbox>
                      {orders.ascSinger && (
                        <div
                          style={{
                            width: "100%",
                            "text-align": "-webkit-right",
                          }}
                        >
                          <Button
                            kind="link"
                            color="basic"
                            size="xl"
                            icon={
                              orders.descSinger
                                ? "sort-alpha-down-alt"
                                : "sort-alpha-down"
                            }
                            onClick={() => {
                              this.handleDescending("descSinger");
                            }}
                            space
                          />
                        </div>
                      )}
                    </div>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <div
                      className="is-row items-center"
                      style={{ height: "18px" }}
                    >
                      <Checkbox
                        invert
                        key={"order_style"}
                        size="lg"
                        onChange={this.handleOrderChange}
                        isChecked={orders.ascStyle}
                        value="ascStyle"
                        className="items-center"
                      >
                        <Text
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                        >
                          {orders.ascStyle
                            ? orders.descStyle
                              ? this.getIndexOrder(
                                  "-song_styles__description"
                                ) +
                                1 +
                                " - " +
                                "Estilo"
                              : this.getIndexOrder("song_styles__description") +
                                1 +
                                " - " +
                                "Estilo"
                            : "Estilo"}
                        </Text>
                      </Checkbox>
                      {orders.ascStyle && (
                        <div
                          style={{
                            width: "100%",
                            "text-align": "-webkit-right",
                          }}
                        >
                          <Button
                            kind="link"
                            color="basic"
                            size="xl"
                            icon={
                              orders.descStyle
                                ? "sort-alpha-down-alt"
                                : "sort-alpha-down"
                            }
                            onClick={() => {
                              this.handleDescending("descStyle");
                            }}
                            space
                          />
                        </div>
                      )}
                    </div>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <div
                      className="is-row items-center"
                      style={{ height: "18px" }}
                    >
                      <Checkbox
                        invert
                        key={"order_mood"}
                        size="lg"
                        onChange={this.handleOrderChange}
                        isChecked={orders.ascMood}
                        value="ascMood"
                        className="items-center"
                      >
                        <Text
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                        >
                          {orders.ascMood
                            ? orders.descMood
                              ? this.getIndexOrder("-song_mood__description") +
                                1 +
                                " - " +
                                "Humor"
                              : this.getIndexOrder("song_mood__description") +
                                1 +
                                " - " +
                                "Humor"
                            : "Humor"}
                        </Text>
                      </Checkbox>
                      {orders.ascMood && (
                        <div
                          style={{
                            width: "100%",
                            "text-align": "-webkit-right",
                          }}
                        >
                          <Button
                            kind="link"
                            color="basic"
                            size="xl"
                            icon={
                              orders.descMood
                                ? "sort-alpha-down-alt"
                                : "sort-alpha-down"
                            }
                            onClick={() => {
                              this.handleDescending("descMood");
                            }}
                            space
                          />
                        </div>
                      )}
                    </div>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <div
                      className="is-row items-center"
                      style={{ height: "18px" }}
                    >
                      <Checkbox
                        invert
                        key={"order_service"}
                        size="lg"
                        onChange={this.handleOrderChange}
                        isChecked={orders.ascService}
                        value="ascService"
                        className="items-center"
                      >
                        <Text
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                        >
                          {orders.ascService
                            ? orders.descService
                              ? this.getIndexOrder("-free_rights") +
                                1 +
                                " - " +
                                "Servicio"
                              : this.getIndexOrder("free_rights") +
                                1 +
                                " - " +
                                "Servicio"
                            : "Servicio"}
                        </Text>
                      </Checkbox>
                      {orders.ascService && (
                        <div
                          style={{
                            width: "100%",
                            "text-align": "-webkit-right",
                          }}
                        >
                          <Button
                            kind="link"
                            color="basic"
                            size="xl"
                            icon={
                              orders.descService
                                ? "sort-numeric-down-alt"
                                : "sort-numeric-down"
                            }
                            onClick={() => {
                              this.handleDescending("descService");
                            }}
                            space
                          />
                        </div>
                      )}
                    </div>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <div
                      className="is-row items-center"
                      style={{ height: "18px" }}
                    >
                      <Checkbox
                        invert
                        key={"order_uso"}
                        size="lg"
                        onChange={this.handleOrderChange}
                        isChecked={orders.ascUso}
                        value="ascUso"
                        className="items-center"
                      >
                        <Text
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                        >
                          {orders.ascUso
                            ? orders.descUso
                              ? this.getIndexOrder("-reproductions") +
                                1 +
                                " - " +
                                "Uso"
                              : this.getIndexOrder("reproductions") +
                                1 +
                                " - " +
                                "Uso"
                            : "Uso"}
                        </Text>
                      </Checkbox>
                      {orders.ascUso && (
                        <div
                          style={{
                            width: "100%",
                            "text-align": "-webkit-right",
                          }}
                        >
                          <Button
                            kind="link"
                            color="basic"
                            size="xl"
                            icon={
                              orders.descUso
                                ? "sort-numeric-down-alt"
                                : "sort-numeric-down"
                            }
                            onClick={() => {
                              this.handleDescending("descUso");
                            }}
                            space
                          />
                        </div>
                      )}
                    </div>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <div
                      className="is-row items-center"
                      style={{ height: "18px" }}
                    >
                      <Checkbox
                        invert
                        key={"order_year"}
                        size="lg"
                        onChange={this.handleOrderChange}
                        isChecked={orders.ascA}
                        value="ascA"
                        className="items-center"
                      >
                        <Text
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                        >
                          {orders.ascA
                            ? orders.descA
                              ? this.getIndexOrder("-year") + 1 + " - " + "Año"
                              : this.getIndexOrder("year") + 1 + " - " + "Año"
                            : "Año"}
                        </Text>
                      </Checkbox>
                      {orders.ascA && (
                        <div
                          style={{
                            width: "100%",
                            "text-align": "-webkit-right",
                          }}
                        >
                          <Button
                            kind="link"
                            color="basic"
                            size="xl"
                            icon={
                              orders.descA
                                ? "sort-numeric-down-alt"
                                : "sort-numeric-down"
                            }
                            onClick={() => {
                              this.handleDescending("descA");
                            }}
                            space
                          />
                        </div>
                      )}
                    </div>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <div
                      className="is-row items-center"
                      style={{ height: "18px" }}
                    >
                      <Checkbox
                        invert
                        key={"order_created_at"}
                        size="lg"
                        onChange={this.handleOrderChange}
                        isChecked={orders.ascCat}
                        value="ascCat"
                        className="items-center"
                      >
                        <Text
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                        >
                          {orders.ascCat
                            ? orders.descCat
                              ? this.getIndexOrder("-created_at") +
                                1 +
                                " - " +
                                "Fecha de creación"
                              : this.getIndexOrder("created_at") +
                                1 +
                                " - " +
                                "Fecha de creación"
                            : "Fecha de creación"}
                        </Text>
                      </Checkbox>
                      {orders.ascCat && (
                        <div
                          style={{
                            width: "100%",
                            "text-align": "-webkit-right",
                          }}
                        >
                          <Button
                            kind="link"
                            color="basic"
                            size="xl"
                            icon={
                              orders.descCat
                                ? "sort-numeric-down-alt"
                                : "sort-numeric-down"
                            }
                            onClick={() => {
                              this.handleDescending("descCat");
                            }}
                            space
                          />
                        </div>
                      )}
                    </div>
                  </Column>
                  <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                    <div
                      className="is-row items-center"
                      style={{ height: "18px" }}
                    >
                      <Checkbox
                        invert
                        key={"order_updated_at"}
                        size="lg"
                        onChange={this.handleOrderChange}
                        isChecked={orders.ascUat}
                        value="ascUat"
                        className="items-center"
                      >
                        <Text
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          weight="medium"
                        >
                          {orders.ascUat
                            ? orders.descUat
                              ? this.getIndexOrder("-updated_at") +
                                1 +
                                " - " +
                                "Fecha de actualización o edición"
                              : this.getIndexOrder("updated_at") +
                                1 +
                                " - " +
                                "Fecha de actualización o edición"
                            : "Fecha de actualización o edición"}
                        </Text>
                      </Checkbox>
                      {orders.ascUat && (
                        <div
                          style={{
                            width: "100%",
                            "text-align": "-webkit-right",
                          }}
                        >
                          <Button
                            kind="link"
                            color="basic"
                            size="xl"
                            icon={
                              orders.descUat
                                ? "sort-numeric-down-alt"
                                : "sort-numeric-down"
                            }
                            onClick={() => {
                              this.handleDescending("descUat");
                            }}
                            space
                          />
                        </div>
                      )}
                    </div>
                  </Column>
                </Columns>
                <div
                  className="flex mb-2"
                  style={{ justifyContent: "flex-end" }}
                >
                  <Button
                    icon={"sort"}
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="px-2"
                    onClick={this.handleOrder}
                    disabled={
                      !(
                        orders.ascPreference ||
                        orders.ascSinger ||
                        orders.ascStyle ||
                        orders.ascMood ||
                        orders.ascService ||
                        orders.ascUso ||
                        orders.ascA ||
                        orders.ascCat ||
                        orders.ascUat
                      )
                    }
                    color="basic"
                    kind="outline"
                  >
                    <strong>{"Aplicar orden"}</strong>
                  </Button>
                </div>
                <Text
                  size={this.props.store.viewMobile ? "sm" : "xl"}
                  weight="strong"
                  lineBreak
                  lead={this.props.store.viewMobile}
                >
                  {"Referencias de colores de estado de la canción"}
                </Text>
                <div
                  className="flex mt-2"
                  style={{ flexWrap: "wrap", gap: "1rem" }}
                >
                  <span style={{ color: "#6d4c41", fontWeight: "bold" }}>
                    {"Cargadas"}
                  </span>

                  <span style={{ color: "#f4511e", fontWeight: "bold" }}>
                    {"Pre-Pendientes"}
                  </span>

                  <span style={{ color: "#1976d2", fontWeight: "bold" }}>
                    {"Pendientes"}
                  </span>

                  <span style={{ color: "#512da8", fontWeight: "bold" }}>
                    {"Aprobadas"}
                  </span>

                  <span style={{ color: "#388e3c", fontWeight: "bold" }}>
                    {"Publicadas"}
                  </span>

                  <span style={{ color: "#fdd835", fontWeight: "bold" }}>
                    {"Modificadas"}
                  </span>

                  <span style={{ color: "#b71c1c", fontWeight: "bold" }}>
                    {"Reparar"}
                  </span>
                </div>
              </>
            ) : null}
          </Panel>
        </Column>
        <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
          <Panel
            className="mb-3"
            color="gray"
            padding={!this.props.store.viewMobile && "23px 20px"}
          >
            <Columns className="mb-2" isMultiline>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
                <div className="flex items-center">
                  <TextInput
                    name="search"
                    iconKasimu="search"
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleChangeSearch}
                    placeholder={this.props.store.language.label539}
                    value={this.search && this.search}
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    className="is-fullwidth"
                    backgroundColor="blackDark"
                    borderColor="black"
                    autocomplete="nope"
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </div>
              </Column>
            </Columns>
            <div
              className="is-row"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 8,
              }}
            >
              {editing ? (
                this.state.isUpdateStatus ? (
                  <Button
                    className="px-2 mx-0"
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    disabled
                    icon="spinner"
                    pulse
                    color="basic"
                    kind="outline"
                  >
                    <strong>{"Finalizando..."}</strong>
                  </Button>
                ) : (
                  <Button
                    icon={"edit"}
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="px-2  mx-0"
                    onClick={this.handleConfirmationFinalizeEditingModalOpen}
                    disabled={result == 0}
                    color="basic"
                    kind="outline"
                  >
                    <strong>{"Finalizar edición"}</strong>
                  </Button>
                )
              ) : this.state.isUpdateStatus ? (
                <Button
                  className="px-2  mx-0"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  disabled
                  icon="spinner"
                  pulse
                  color="basic"
                  kind="outline"
                >
                  <strong>{"Iniciando..."}</strong>
                </Button>
              ) : (
                <Button
                  icon={"edit"}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-2  mx-0"
                  onClick={this.handleConfirmationStartEditingModalOpen}
                  disabled={result == 0}
                  color="basic"
                  kind="outline"
                >
                  <strong>{"Iniciar edición"}</strong>
                </Button>
              )}
              {editing && this.props.store.loggedInUser.checkPermission(9) ? (
                <Button
                  icon={"edit"}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-2  mx-0"
                  onClick={this.handleEditMultiplesOpen}
                  disabled={result == 0}
                  color="basic"
                  kind="outline"
                >
                  <strong>{"Editar multiple"}</strong>
                </Button>
              ) : null}
              {this.props.store.loggedInUser.checkPermission(10) ? (
                <Button
                  icon={"file-csv"}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-2  mx-0"
                  onClick={this.handleSongCVSOpen}
                  disabled={result == 0}
                  color="basic"
                  kind="outline"
                >
                  <strong>{"Exportar csv de canciones"}</strong>
                </Button>
              ) : null}
              {(this.state.filters.search_type_by_user
                ? this.state.filters.search_type_by_user == 1
                  ? true
                  : false
                : false) && (
                <Button
                  icon={"file-csv"}
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-2  mx-0"
                  onClick={this.handlesUsersListenedSongsCSVOpen}
                  disabled={result == 0}
                  color="basic"
                  kind="outline"
                >
                  <strong>{"Exportar csv de escuchadas"}</strong>
                </Button>
              )}
              {/*user.id == 2 || user.id == 3 ? this.state.isUpdateStatus ? <Button upper className="is-pulled-right px-3" size={this.props.store.viewMobile?"lg":"md"} disabled icon="spinner" pulse>{'Actualizando...'}</Button>:
                                <Button icon={"thumbs-up"} upper size={this.props.store.viewMobile?"lg":"md"} className="is-pulled-right" onClick={this.handleConfirmationApproveModalOpen}>{'Aprobar canciones'}</Button>:null*/}
              {this.props.store.loggedInUser.checkPermission(16) ? (
                this.state.isUpdateStatus ? (
                  <Button
                    className="px-2  mx-0"
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    disabled
                    icon="spinner"
                    pulse
                    color="basic"
                    kind="outline"
                  >
                    <strong>{"Actualizando..."}</strong>
                  </Button>
                ) : (
                  <Button
                    icon={"thumbs-up"}
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="px-2  mx-0"
                    onClick={this.handleConfirmationPreSlopesModalOpen}
                    color="basic"
                    kind="outline"
                  >
                    <strong>{"Pasar a pre-pendientes"}</strong>
                  </Button>
                )
              ) : null}
              {this.props.store.loggedInUser.checkPermission(17) ? (
                this.state.isUpdateStatus ? (
                  <Button
                    className="px-2  mx-0"
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    disabled
                    icon="spinner"
                    pulse
                    color="basic"
                    kind="outline"
                  >
                    <strong>{"Actualizando..."}</strong>
                  </Button>
                ) : (
                  <Button
                    icon={"thumbs-up"}
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="px-2  mx-0"
                    onClick={this.handleConfirmationSlopesModalOpen}
                    color="basic"
                    kind="outline"
                  >
                    <strong>{"Pasar a pendientes"}</strong>
                  </Button>
                )
              ) : null}
            </div>

            {this.props.store.ui.endDownload &&
              this.renderPaginator(this.state.songs, false)}
          </Panel>
        </Column>
      </Columns>
    );
  };

  renderPaginatorAndButtom = (result, editing) => {
    const user = this.props.store.loggedInUser;
    return (
      <Panel
        className="mb-3"
        color="gray"
        padding={!this.props.store.viewMobile && "23px 20px"}
      >
        <div
          className="is-row"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: 8,
          }}
        >
          {editing ? (
            this.state.isUpdateStatus ? (
              <Button
                className="px-2 mx-0"
                size={this.props.store.viewMobile ? "lg" : "md"}
                disabled
                icon="spinner"
                pulse
                color="basic"
                kind="outline"
              >
                <strong>{"Finalizando..."}</strong>
              </Button>
            ) : (
              <Button
                icon={"edit"}
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="px-2  mx-0"
                onClick={this.handleConfirmationFinalizeEditingModalOpen}
                disabled={result == 0}
                color="basic"
                kind="outline"
              >
                <strong>{"Finalizar edición"}</strong>
              </Button>
            )
          ) : this.state.isUpdateStatus ? (
            <Button
              className="px-2  mx-0"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
              color="basic"
              kind="outline"
            >
              <strong>{"Iniciando..."}</strong>
            </Button>
          ) : (
            <Button
              icon={"edit"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="px-2  mx-0"
              onClick={this.handleConfirmationStartEditingModalOpen}
              disabled={result == 0}
              color="basic"
              kind="outline"
            >
              <strong>{"Iniciar edición"}</strong>
            </Button>
          )}
          {editing && this.props.store.loggedInUser.checkPermission(9) ? (
            <Button
              icon={"edit"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="px-2  mx-0"
              onClick={this.handleEditMultiplesOpen}
              disabled={result == 0}
              color="basic"
              kind="outline"
            >
              <strong>{"Editar multiple"}</strong>
            </Button>
          ) : null}
          {this.props.store.loggedInUser.checkPermission(10) ? (
            <Button
              icon={"file-csv"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="px-2  mx-0"
              onClick={this.handleSongCVSOpen}
              disabled={result == 0}
              color="basic"
              kind="outline"
            >
              <strong>{"Exportar csv de canciones"}</strong>
            </Button>
          ) : null}
          {(this.state.filters.search_type_by_user
            ? this.state.filters.search_type_by_user == 1
              ? true
              : false
            : false) && (
            <Button
              icon={"file-csv"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="px-2  mx-0"
              onClick={this.handlesUsersListenedSongsCSVOpen}
              disabled={result == 0}
              color="basic"
              kind="outline"
            >
              <strong>{"Exportar csv de escuchadas"}</strong>
            </Button>
          )}
          {/*user.id == 2 || user.id == 3 ? this.state.isUpdateStatus ? <Button upper className="is-pulled-right px-3" size={this.props.store.viewMobile?"lg":"md"} disabled icon="spinner" pulse>{'Actualizando...'}</Button>:
                                <Button icon={"thumbs-up"} upper size={this.props.store.viewMobile?"lg":"md"} className="is-pulled-right" onClick={this.handleConfirmationApproveModalOpen}>{'Aprobar canciones'}</Button>:null*/}
          {this.props.store.loggedInUser.checkPermission(16) ? (
            this.state.isUpdateStatus ? (
              <Button
                className="px-2  mx-0"
                size={this.props.store.viewMobile ? "lg" : "md"}
                disabled
                icon="spinner"
                pulse
                color="basic"
                kind="outline"
              >
                <strong>{"Actualizando..."}</strong>
              </Button>
            ) : (
              <Button
                icon={"thumbs-up"}
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="px-2  mx-0"
                onClick={this.handleConfirmationPreSlopesModalOpen}
                color="basic"
                kind="outline"
              >
                <strong>{"Pasar a pre-pendientes"}</strong>
              </Button>
            )
          ) : null}
          {this.props.store.loggedInUser.checkPermission(17) ? (
            this.state.isUpdateStatus ? (
              <Button
                className="px-2  mx-0"
                size={this.props.store.viewMobile ? "lg" : "md"}
                disabled
                icon="spinner"
                pulse
                color="basic"
                kind="outline"
              >
                <strong>{"Actualizando..."}</strong>
              </Button>
            ) : (
              <Button
                icon={"thumbs-up"}
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="px-2  mx-0"
                onClick={this.handleConfirmationSlopesModalOpen}
                color="basic"
                kind="outline"
              >
                <strong>{"Pasar a pendientes"}</strong>
              </Button>
            )
          ) : null}
        </div>

        {this.props.store.ui.endDownload &&
          this.renderPaginator(this.state.songs, false)}
      </Panel>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderPaginator = (songs, panel) => {
    if (songs.totalPages == 1) return null;
    return (
      <div
        className="is-row mt-3"
        style={{
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
        }}
      >
        <Pagination
          panel={panel}
          totalPages={songs.totalPages}
          currentPage={songs.currentPage}
          onPageChangeClick={this.onPageChange}
        />
      </div>
    );
  };

  renderHeader = () => {
    const { styles } = this.state;
    if (!styles) return this.renderEmptyContent();
    return !styles.isOk() ? this.renderLoader() : this.renderLanguage();
  };

  renderLanguage = () => {
    const { language } = this.state;
    if (!language) return this.renderEmptyContent();
    return !language.isOk() ? this.renderLoader() : this.renderSongs();
  };

  renderSongs = () => {
    const { songs } = this.state;
    if (!songs) return this.renderEmptyContent();
    return !songs.isOk() ? this.renderLoader() : this.renderCatalogs();
  };

  renderCatalogs = () => {
    const { catalogs } = this.state;
    if (!catalogs) return this.renderEmptyContent();
    return !catalogs.isOk() ? this.renderLoader() : this.renderBody();
  };

  getOptionsMood = () => {
    const options = [
      { value: 1, label: this.props.store.language.mood[1] },
      { value: 2, label: this.props.store.language.mood[2] },
      { value: 3, label: this.props.store.language.mood[3] },
      { value: 4, label: this.props.store.language.mood[4] },
      { value: 5, label: this.props.store.language.mood[5] },
    ];
    return options;
  };

  getEditingValue = (array) => {
    if (array.length > 0) {
      return array[0].editing;
    }
    return false;
  };

  renderBody = () => {
    const {
      accounts,
      songs,
      catalogs,
      styles,
      language,
      currentSongView,
      showSongInformationModal,
      showSongEditModal,
      showSongReportsModal,
      showDeletedModal,
      showSongEditMultiModal,
      showSongCSVModal,
      showUsersListenedSongsCSVModal,
      showConfirmationApproveModal,
      showConfirmationPreSlopesModal,
      showConfirmationSlopesModal,
      showConfirmationStartEditingModal,
      showConfirmationFinalizeEditingModal,
      showWarningEditingModal,
      showUsersListenedSongsModal,
      showUserTagSongModal,
      userTags,
      userTagsOther,
      currentIndiceSongView,
    } = this.state;
    const songsMemmory = this.props.store.ui.songs;
    const currentSong = this.props.store.ui.currentSong;
    const currentIndice = this.props.store.ui.currentIndice;
    const moodsOptions = this.getOptionsMood();
    const preferenceOptions = this.getPreferenceOptions();
    const stylesOptions = this.getOptionsStyles(styles);
    const catalogsOptions = this.getOptionsCatalogs(catalogs);
    const accountsOptions = this.getOptionsAccounts(accounts);
    const editing = this.getEditingValue(songs.toArray());
    const myTagsOptions = this.getOptionsMyTags(userTags);
    const tagsOtherOptions = this.getOptionsMyTags(userTagsOther);

    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="mb-2"
                weight="black"
                lineBreak
                lead
              >
                {"Canciones"}
              </Text>
            )}

            {this.getPanel(
              accountsOptions,
              catalogsOptions,
              stylesOptions,
              preferenceOptions,
              moodsOptions,
              songs.count,
              editing,
              myTagsOptions,
              tagsOtherOptions
            )}
            <AudioPlayer
              handleTogglePlay={this.handleTogglePlay}
              indice={currentIndice}
              song={currentSong}
            />
            <div className="flex mb-1" style={{ justifyContent: "flex-end" }}>
              <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                {"Resultados: " + songs.count}
              </Text>
            </div>
            {songsMemmory.map((song, idx) => (
              <SongsCard
                indice={idx + 1}
                song={song}
                styles={styles}
                moodsOptions={moodsOptions}
                stylesOptions={stylesOptions}
                preferenceOptions={preferenceOptions}
                arraySongId={this.arraySongId}
                handleTogglePlay={this.handleTogglePlay}
                active={currentSong && song.id == currentSong.id}
                onSongInformationClick={this.handleSongInformationModalOpen}
                onSongEditClick={this.handleSongEditModalOpen}
                onSongReportsClick={this.handleSongReportsModalOpen}
                onSongDeletedClick={this.handleDeleteConfirmationOpen}
                onSongMarkedClick={this.handleMarkedSong}
                onUsersListenedSongsClick={
                  this.handleUsersListenedSongsModelOpen
                }
                onUserTagSongClick={this.handleUserTagSongModalOpen}
                showButtonULS={
                  this.state.filters.search_type_by_user
                    ? this.state.filters.search_type_by_user == 1
                      ? true
                      : false
                    : false
                }
              />
            ))}
            <br />
            {this.renderPaginatorAndButtom(songs.count, editing)}
          </Column>
        </Columns>
        {showSongEditMultiModal && (
          <MultipleEditModel
            resultAllSongs={this.resultAllSongs}
            catalogsOptions={catalogsOptions}
            moodsOptions={moodsOptions}
            stylesOptions={stylesOptions}
            preferenceOptions={preferenceOptions}
            arraySongId={this.arraySongId}
            language={language}
            onClose={this.handleEditMultiplesClose}
            onSave={this.handleEditMultiplesSave}
            filters={this.getFilters()}
            search={this.search}
            userTags={userTags.toArray()}
          />
        )}
        {showSongCSVModal && (
          <SongCSVModal
            onClose={this.handleSongCVSClose}
            filters={this.getFilters()}
            orderList={this.getOrders()}
            search={this.search}
          />
        )}
        {showUsersListenedSongsCSVModal && (
          <UsersListenedSongsCSVModal
            onClose={this.handleUsersListenedSongsCSVClose}
            filters={this.getFilters()}
            currentUsername={this.state.currentUsername}
          />
        )}
        {showUsersListenedSongsModal && (
          <UsersListenedSongsModal
            onClose={this.handleUsersListenedSongsModelClose}
            song={currentSongView}
            userId={this.state.filters.user_id}
            userName={this.state.currentUsername}
          />
        )}
        {showSongInformationModal && (
          <SongInformationModal
            song={currentSongView}
            catalogsOptions={catalogsOptions}
            onClose={this.handleSongInformationModalClose}
          />
        )}
        {showSongEditModal && (
          <SongEditModal
            song={currentSongView}
            catalogsOptions={catalogsOptions}
            language={language}
            onClose={this.handleSongEditModalClose}
          />
        )}
        {showSongReportsModal && (
          <SongReportsModal
            song={currentSongView}
            catalogsOptions={catalogsOptions}
            onClose={this.handleSongReportsModalClose}
          />
        )}
        {showDeletedModal && (
          <DeleteConfirmationModal
            currentObj={currentSongView}
            onClose={this.handleDeleteConfirmationModalClose}
            onAccept={this.handleAcceptDeleteConfirmationModal}
            messageSuccess={
              'La canción "' +
              currentSongView.name +
              '" se elimino exitosamente.'
            }
            messageError={
              'Error, algo salió mal al eliminar la canción "' +
              currentSongView.name +
              '".'
            }
            title={"Deseas eliminar \n" + currentSongView.name + "?"}
            subtitle={"Se eliminará la canción " + currentSongView.name + "."}
          />
        )}
        {showConfirmationApproveModal && (
          <ConfirmationModal
            title="Aprobar canciones"
            onClose={this.handleConfirmationApproveModalClose}
            onAccept={() => this.updateStatus(1)}
            subtitle="Deseas pasar todas las canciones pendientes ah aprobadas?"
          />
        )}
        {showConfirmationPreSlopesModal && (
          <ConfirmationModal
            title="Pasar canciones a pre-pendientes"
            onClose={this.handleConfirmationPreSlopesModalClose}
            onAccept={() => this.updateStatus(3)}
            subtitle="Deseas pasar todas las canciones cargadas ah pre-pendientes?"
          />
        )}
        {showConfirmationSlopesModal && (
          <ConfirmationModal
            title="Pasar canciones a pendientes"
            onClose={this.handleConfirmationSlopesModalClose}
            onAccept={() => this.updateStatus(2)}
            subtitle="Deseas pasar todas las canciones pre-pendientes ah pendientes?"
          />
        )}
        {showConfirmationStartEditingModal && (
          <ConfirmationModal
            title="Iniciando edición de canciones"
            onClose={this.handleConfirmationStartEditingModalClose}
            onAccept={() => this.temporarySongsEditing(1)}
            subtitle={
              "Deseas iniciar la edición de (" +
              (this.arraySongId.length > 0
                ? this.arraySongId.length
                : songs.count) +
              ") canciones?, se finalizara cualquier proceso previo de edición iniciado."
            }
          />
        )}
        {showConfirmationFinalizeEditingModal && (
          <ConfirmationModal
            title="Finalizar edición de canciones"
            onClose={this.handleConfirmationFinalizeEditingModalClose}
            onAccept={() => this.temporarySongsEditing(2)}
            subtitle={
              "Deseas finalizar la edición de (" + songs.count + ") canciones?"
            }
          />
        )}
        {showWarningEditingModal && (
          <WarningModal
            title="Edición de canciones pendiente"
            subtitle={
              "Tienes una edición de canciones pendiente, para iniciar un nuevo proceso de edición debes finalizar un proceso previo."
            }
            btntitle={"Ir edición anterior"}
            onClose={this.handleWarningEditingModalClose}
            onAccept={this.handleWarningEditingModalAccept}
          />
        )}
        {showUserTagSongModal && (
          <UserTagSongModal
            indice={currentIndiceSongView}
            currentSong={currentSongView}
            userTags={userTags.toArray()}
            onClose={this.handleUserTagSongModalClose}
            onSave={this.handleUserTagSongModalClose}
          />
        )}
      </React.Fragment>
    );
  };

  render() {
    return <div>{this.renderHeader()}</div>;
  }
}

export default withToastManager(withStore(SongsListPage));
