import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import ProcessesListPage from "./Processes/ProcessesListPage";
import InterpretersListPage from "./Interpreters/InterpretersListPage";
import MusicalStylesListPage from "./MusicalStyles/MusicalStylesListPage";
import ProvidersListPage from "./Providers/ProvidersListPage";
import ListsListPage from "./Lists/ListsListPage";
import SongsListPage from "./Songs/SongsListPage";
import RecordLabelPage from "./RecordLabel/RecordLabelPage";
import GenerateUpdatePage from "./Update/GenerateUpdatePage";
import TagsPage from "./Users/TagsPage";
import AssignSongsToStylePage from "./Users/AssignSongsToStylePage";
import CategoriesPage from "./Categories/CategoriesPage";
import PlaylistsPage from "./Playlists/PlaylistsPage";
import withStore from "../../hocs/withStore";

class ContentManagerRouter extends Component {
  componentDidMount() {
    this.props.store.ui.showMainSide = true;
  }

  render() {
    const path = this.props.match.path;
    return (
      <div className='ks--container'>
        <Switch>
            <Route
                exact
                path={`${path}/contentmanager/processes`}
                component={ProcessesListPage}
            />,
            <Route
                exact
                path={`${path}/contentmanager/categories`}
                component={CategoriesPage}
            />,
            <Route
                exact
                path={`${path}/contentmanager/playlists`}
                component={PlaylistsPage}
            />,
            <Route
                exact
                path={`${path}/contentmanager/userstags`}
                component={TagsPage}
            />,
            <Route
                exact
                path={`${path}/contentmanager/assign_tagged_songs_user_style`}
                component={AssignSongsToStylePage}
            />,
            <Route
                exact
                path={`${path}/contentmanager/songs`}
                component={SongsListPage}
            />,
            <Route
                exact
                path={`${path}/contentmanager/interpreters`}
                component={InterpretersListPage}
            />,
            <Route
                exact
                path={`${path}/contentmanager/musical_styles`}
                component={MusicalStylesListPage}
            />,
            <Route
                exact
                path={`${path}/contentmanager/providers`}
                component={ProvidersListPage}
            />,
            <Route
                exact
                path={`${path}/contentmanager/lists`}
                component={ListsListPage}
            />,
            <Route
                exact
                path={`${path}/contentmanager/record_label`}
                component={RecordLabelPage}
            />,
            <Route
                exact
                path={`${path}/contentmanager/generate_update`}
                component={GenerateUpdatePage}
            />,
            {this.props.store.loggedInUser.checkPermission(1) ?
            <Redirect
                to={`${path}/contentmanager/processes`}
                component={ProcessesListPage}
            />:
            <Redirect
                to={`${path}/contentmanager/songs`}
                component={SongsListPage}
            />
            }            
        </Switch>
      </div>
    );
  }
}

export default withRouter(withStore(ContentManagerRouter));
