import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import {
  Text,
  TextInput,
  Button,
  Panel,
  Loader,
  CardEvents,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../../../Components/Select";
import {
  EmptyContent,
  Pagination,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  MusicalStylesNewModal,
  InformationModal,
  TranslationsModal,
  EditModal,
  ReportsModal,
  CSVModal,
  MusicalStyleCard,
} from "../../../Components/MusicalStyles";
import ReactTooltip from "react-tooltip";

@observer
class MusicalStylesListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStyle: null,
      showNewModal: false,
      showCSVModal: false,
      showInformationModal: false,
      showReportModal: false,
      showEditModal: false,
      showDeletedModal: false,
      showTranslationsModal: false,
      catalogs: this.getCatalogsAll(),
      moods: this.getSongMoodsAll(),
      languages: this.getSongLanguageAll(),
      appLanguages: this.getAppLanguagesAll(),
      page: 1,
      styles: this.getStylesSearch(25, null, null, null),
      filters: {
        _status: null,
        free_rights: -1,
      },
      orders: {
        _status: false,
      },
      orderList: [],
    };
    this.per_page = 25;
    this.search = null;
  }

  componentDidMount() {
    this.props.store.menuTitle = "Estilos musicales";
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  getCatalogsAll = () => {
    return this.props.store.catalogs.fetchAll();
  };

  getStylesSearch = (per_page, filters, orderList, search) => {
    return this.props.store.songStyles.fetchAllParams(
      per_page,
      filters,
      orderList,
      search
    );
  };

  getSongMoodsAll = () => {
    return this.props.store.songMoods.fetchAll();
  };

  getSongLanguageAll = () => {
    return this.props.store.songLanguage.fetchAll();
  };

  getAppLanguagesAll = () => {
    return this.props.store.appLanguages.fetchAll();
  };

  onPageChange = (page) => {
    const { filters, orderList } = this.state;
    this.setState({
      styles: this.props.store.songStyles.fetchPage(
        this.per_page,
        page,
        this.validateFilter(filters),
        orderList.length == 0 ? null : orderList,
        this.search
      ),
      page: page,
    });
  };

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleInformationModalOpen = (style) => {
    this.setState({ showInformationModal: true, currentStyle: style });
  };

  handleInformationModalClose = () => {
    this.setState({ showInformationModal: false, currentStyle: null });
  };

  handleReportsModalOpen = (style) => {
    this.setState({ showReportModal: true, currentStyle: style });
  };

  handleReportsModalClose = () => {
    this.setState({ showReportModal: false, currentStyle: null });
  };

  handleEditModalOpen = (style) => {
    this.setState({ showEditModal: true, currentStyle: style });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false, currentStyle: null });
  };

  handleDeleteConfirmationOpen = (style) => {
    this.setState({ showDeletedModal: true, currentStyle: style });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedModal: false, currentStyle: null });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentStyle: null });
    this.reload();
  };

  handleTranslationsModalOpen = (style) => {
    this.setState({ showTranslationsModal: true, currentStyle: style });
  };

  handleTranslationsModalClose = () => {
    this.setState({ showTranslationsModal: false, currentStyle: null });
  };

  reload = () => {
    this.setState({ styles: this.getStylesSearch(25, null, null, null) });
  };

  handleCVSOpen = () => {
    this.setState({ showCSVModal: true });
  };

  handleCVSClose = () => {
    this.setState({ showCSVModal: false });
  };

  getOptions = (obj) => {
    return obj.toArray().map((o) => ({ value: o.id, label: o.description }));
  };

  getOptionsCatalogs = (obj) => {
    return obj.toArray().map((o) => ({ value: o.identifier, label: o.name }));
  };

  getOptionsAppLanguages = (obj) => {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  onSearch = () => {
    const { filters, orderList } = this.state;
    if (this.search && this.search != " ") {
      this.setState({
        styles: this.props.store.songStyles.fetchAllParams(
          this.per_page,
          this.validateFilter(filters),
          orderList.length == 0 ? null : orderList,
          this.search
        ),
        page: 1,
      });
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  validateFilter = (filters) => {
    if (filters._status || filters.free_rights != -1)
      return filters;
    return null;
  };

  handleSelectChange = (value, sender) => {
    const { filters, orderList } = this.state;
    const val = value.value;
    const newFilters = filters;
    switch (sender.name) {
      case "selectStatus":
        newFilters._status = val;
        break;
      case "selectFreeRights":
        newFilters.free_rights = val;
        break;
      default:
        break;
    }
    this.setState({
      filters: newFilters,
      styles: this.getStylesSearch(
        this.per_page,
        this.validateFilter(filters),
        orderList.length == 0 ? null : orderList,
        this.search
      ),
    });
  };

  getIndexOrder = (order) => {
    return this.state.orderList.indexOf(order);
  };

  handleDescending = (name) => {
    const { filters, orders, orderList } = this.state;
    let newOrders = orders;
    let orderLists = orderList;
    if (orderList.length == 0) {
      orderList.push("description");
    }
    if (name == "descStatus") {
      if (newOrders._status) {
        var index = this.getIndexOrder("-description");
        if (index !== -1) {
          orderLists[index] = "description";
        }
        newOrders._status = false;
      } else {
        var index = this.getIndexOrder("description");
        if (index !== -1) {
          orderLists[index] = "-description";
        }
        newOrders._status = true;
      }
    }
    this.setState({
      orders: newOrders,
      orderList: orderLists,
      styles: this.getStylesSearch(
        this.per_page,
        this.validateFilter(filters),
        orderList,
        this.search
      ),
    });
  };

  getPanel = (catalogsOptions) => {
    const statusOptions = [
      { value: 1, label: "Cargados" },
      { value: 2, label: "Publicado" },
      { value: 3, label: "Modificado" },
    ];
    return (
      <Columns isMultiline isVCentered className="pb-2">
        <Column isSize={{ mobile: 12, tablet: 12, desktop: 5 }}>
            <TextInput
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChangeSearch}
              placeholder={"Escribe aquí el nombre del estilo a buscar..."}
              value={this.search && this.search}
              iconKasimu="search"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="is-fullwidth"
              backgroundColor="grayDark"
              colorIcon="gray"
              invert
            />
        </Column>
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 7 }}
          style={{
            display: "flex",
            gap: this.props.store.viewMobile ? "20px" : "10px",
          }}
        >
          <div style={{ maxWidth: "250px", width: "100%" }}>
            <Select
              isMobile={!this.props.store.viewMobile}
              name="selectStatus"
              onChange={this.handleSelectChange}
              className="is-fullwidth"
              placeholder={"Filtrar por estado"}
              options={statusOptions}
              defaultValue={
                this.state.filters._status
                  ? statusOptions[
                      statusOptions
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(this.state.filters._status)
                    ]
                  : statusOptions[-1]
              }
            />
          </div>
          <div style={{ maxWidth: "250px", width: "100%" }}>
            <Select
              isMobile={!this.props.store.viewMobile}
              name="selectFreeRights"
              onChange={this.handleSelectChange}
              className="is-fullwidth"
              placeholder={"Filtrar por servicio"}
              options={catalogsOptions}
              defaultValue={
                catalogsOptions[
                  catalogsOptions
                    .map(function (e) {
                      return e.value;
                    })
                    .indexOf(this.state.filters.free_rights)
                ]
              }
            />
          </div>
          <Button
            className="ml-1 is-pulled-right"
            kind="link"
            color="basic"
            size="xl"
            icon={
              this.state.orders._status
                ? "sort-alpha-down-alt"
                : "sort-alpha-down"
            }
            onClick={() => {
              this.handleDescending("descStatus");
            }}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderPaginator = (styles) => {
    if (styles.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            panel
            totalPages={styles.totalPages}
            currentPage={styles.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderCatalogs = () => {
    const { catalogs } = this.state;
    if (!catalogs) return this.renderEmptyContent();
    return !catalogs.isOk() ? this.renderLoader() : this.renderBody();
  };

  renderLanguages = () => {
    const { languages } = this.state;
    if (!languages) return this.renderEmptyContent();
    return !languages.isOk() ? this.renderLoader() : this.renderCatalogs();
  };

  renderMoods = () => {
    const { moods } = this.state;
    if (!moods) return this.renderEmptyContent();
    return !moods.isOk() ? this.renderLoader() : this.renderLanguages();
  };

  renderHeader = () => {
    const { styles } = this.state;
    if (!styles) return this.renderEmptyContent();
    return !styles.isOk() ? this.renderLoader() : this.renderMoods();
  };

  getService = (free_rights, catalogsOptions) => {
    if (!catalogsOptions) return null;
    const arr = catalogsOptions.filter(function (e) {
      return free_rights == e.value;
    });
    return arr.length > 0 ? arr[0].label : null;
  };

  renderItemsRow(style, catalogsOptions) {
    return (
      <Panel
        color={"gray"}
        padding={this.props.store.viewMobile ? "10px 30px" : "10px 20px"}
      >
        <div
          className="is-row is-fullwidth"
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Columns isMobile isVCentered isMarginless isGapless>
            <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
              <Text
                weight="medium"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                multiline
              >
                {style.description}
              </Text>
              <Text
                weight="regular"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {"Servicio: " +
                  this.getService(style.free_rights, catalogsOptions) +
                  " | Activo: " +
                  (style.enabled == 1 ? "Si" : "No") +
                  " | Nº Traducciones: " +
                  (style.translation ? style.translation.length : 0)}
              </Text>
            </Column>
          </Columns>
          {style.id != 1 && (
            <div style={{ display: "flex" }}>
              <div data-tip data-for="info">
                <Button
                  className="ml-3"
                  icon="search-plus"
                  kind="link"
                  color="basic"
                  size="lg"
                  lead
                  space
                  onClick={() => {
                    this.handleInformationModalOpen(style);
                  }}
                />
              </div>
              <div data-tip data-for="language">
                <Button
                  className="ml-3"
                  icon="language"
                  kind="link"
                  color="basic"
                  size="lg"
                  lead
                  space
                  onClick={() => {
                    this.handleTranslationsModalOpen(style);
                  }}
                />
              </div>
              {this.props.store.loggedInUser.checkPermission(31) ? (
                <div data-tip data-for="database">
                  <Button
                    className="ml-3"
                    icon="database"
                    kind="link"
                    color="basic"
                    size="lg"
                    lead
                    space
                    onClick={() => {
                      this.handleReportsModalOpen(style);
                    }}
                  />
                </div>
              ) : null}
              {this.props.store.loggedInUser.checkPermission(30) ? (
                <div data-tip data-for="edit">
                  <Button
                    className="ml-3"
                    kind="link"
                    color="basic"
                    size="lg"
                    icon="pen"
                    onClick={() => {
                      this.handleEditModalOpen(style);
                    }}
                    space
                  />
                </div>
              ) : null}
              {/*<Button
                                    kind="link"
                                    color={style.deleted==1?"remove":"special"}
                                    size='lg'
                                    icon='trash'
                                    onClick={() => {this.handleDeleteConfirmationOpen(style)}}
                                    space
                                />*/}
            </div>
          )}
        </div>
      </Panel>
    );
  }

  renderRow = (styles, catalogsOptions) => {
    const arrayStyles = styles.toArray();
    return (
      <React.Fragment>
        {arrayStyles.length === 0 ? (
          <EmptyContent />
        ) : (
          <div className="mt-1">
            {/* {arrayStyles.map((style) =>
              this.renderItemsRow(style, catalogsOptions)
            )} */}
            {arrayStyles.map((style) => (
              <MusicalStyleCard
                style={style}
                catalogsOptions={catalogsOptions}
                getService={this.getService}
                onViewClick={this.handleInformationModalOpen}
                onTranslationClick={this.handleTranslationsModalOpen}
                onReportClick={this.handleReportsModalOpen}
                onEditClick={this.handleEditModalOpen}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  renderBody = () => {
    const {
      styles,
      moods,
      languages,
      appLanguages,
      catalogs,
      currentStyle,
      filters,
      orderList,
      showNewModal,
      showInformationModal,
      showEditModal,
      showReportModal,
      showDeletedModal,
      showTranslationsModal,
      showCSVModal,
    } = this.state;
    const moodsOptions = this.getOptions(moods);
    const languagesOptions = this.getOptions(languages);
    const catalogsOptions = this.getOptionsCatalogs(catalogs);
    const appLanguagesOptions = this.getOptionsAppLanguages(appLanguages);

    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <Columns className={this.props.store.viewMobile ? "pb-4" : "pb-3"}>
              <Column
                isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
                className="flex"
                style={{
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 14,
                }}
              >
                {this.props.store.viewMobile && (
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    lead
                    weight="black"
                    lineBreak
                  >
                    {"Estilos musicales"}
                  </Text>
                )}
                <div
                  className="flex items-center"
                  style={{ flexWrap: "wrap", gap: 14 }}
                >
                  {this.props.store.loggedInUser.checkPermission(28) ? (
                    <Button
                      icon={"file-csv"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      className="px-2 mx-0"
                      onClick={this.handleCVSOpen}
                    >
                      <strong>{"Exportar csv"}</strong>
                    </Button>
                  ) : null}
                  {this.props.store.loggedInUser.checkPermission(29) ? (
                    <Button
                      icon={"plus"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      className="px-2 mx-0"
                      onClick={this.handleShowNewModal}
                    >
                      <strong>{"Agregar"}</strong>
                    </Button>
                  ) : null}
                </div>
              </Column>
            </Columns>
            {this.getPanel(catalogsOptions)}
            <Text
              className="is-pulled-right"
              size="md"
              maxLength={this.props.mobile ? 50 : 250}
            >
              {"Resultados: " + styles.count}
            </Text>
            {this.renderRow(styles, catalogsOptions)}
            {this.renderPaginator(styles)}
          </Column>
          {showNewModal && (
            <MusicalStylesNewModal
              moodsOptions={moodsOptions}
              languagesOptions={languagesOptions}
              catalogsOptions={catalogsOptions}
              appLanguagesOptions={appLanguagesOptions}
              onClose={this.handleCloseNewModal}
            />
          )}
          {showCSVModal && (
            <CSVModal
              onClose={this.handleCVSClose}
              filters={filters._status ? filters : null}
              orderList={orderList.length == 0 ? null : orderList}
              search={this.search}
            />
          )}
          {showInformationModal && (
            <InformationModal
              style={currentStyle}
              catalogsOptions={catalogsOptions}
              onClose={this.handleInformationModalClose}
            />
          )}
          {showTranslationsModal && (
            <TranslationsModal
              currentStyle={currentStyle}
              appLanguagesOptions={appLanguagesOptions}
              onClose={this.handleTranslationsModalClose}
            />
          )}
          {showReportModal && (
            <ReportsModal
              style={currentStyle}
              catalogsOptions={catalogsOptions}
              onClose={this.handleReportsModalClose}
            />
          )}
          {showEditModal && (
            <EditModal
              style={currentStyle}
              moodsOptions={moodsOptions}
              languagesOptions={languagesOptions}
              catalogsOptions={catalogsOptions}
              onClose={this.handleEditModalClose}
            />
          )}
        </Columns>
        <ReactTooltip id="info" place="bottom" type="dark" effect="float">
          <span>{"Más información"}</span>
        </ReactTooltip>
        <ReactTooltip id="language" place="bottom" type="dark" effect="float">
          <span>{"Traducciones"}</span>
        </ReactTooltip>
        <ReactTooltip id="database" place="bottom" type="dark" effect="float">
          <span>{"Historial de modificaciones"}</span>
        </ReactTooltip>
        <ReactTooltip id="edit" place="bottom" type="dark" effect="float">
          <span>{"Editar"}</span>
        </ReactTooltip>
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>;
  }
}

export default withToastManager(withStore(MusicalStylesListPage));
