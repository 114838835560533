import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../Select";
import { withToastManager } from "react-toast-notifications";
import { Blocks } from "../../models";

@observer
class NewBlocksModal extends Component {
  @observable newBlock;
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.newBlock = this.newBlocks();
  }

  newBlocks = () => {
    const { currentPlaylist } = this.props;
    const blocksStore = this.props.store.blocks;
    const attrs = {
      playlist_id: currentPlaylist.id,
      song_styles_id: null,
      song_mood_id: null,
      bpm_min: 50,
      bpm_max: 200,
      preference: 1,
      duration: 1,
      status: 1,
      deleted: 0,
      mood_max: 1,
      mood_min: 1,
      mood_pre: 1,
    };
    return new Blocks(attrs, blocksStore);
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.newBlock[name] = value;
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;
    switch (sender.name) {
      case "selectStyles":
        this.newBlock.song_styles_id = val;
        this.newBlock.mood_max = value.mood_max;
        this.newBlock.mood_min = value.mood_min;
        this.newBlock.mood_pre = value.mood_pre;
        break;
      case "selectMood":
        this.newBlock.song_mood_id = val;
        break;
      case "selectPreference":
        this.newBlock.preference = val;
        break;
      default:
        break;
    }
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getPreferenceOptions = () => {
    let items = [];
    for (let i = 1; i <= 6; i++) {
      items.push({ value: i, label: i });
    }
    return items;
  };

  moodEnabled = (key, moodMin, moodMax) => {
    if (key == 6 && 1 >= moodMin && 2 <= moodMax) return true;
    if (key == 7 && 2 >= moodMin && 3 <= moodMax) return true;
    if (key == 8 && 2 >= moodMin && 4 <= moodMax) return true;
    if (key == 9 && 3 >= moodMin && 4 <= moodMax) return true;
    if (key == 10 && 1 >= moodMin && 5 <= moodMax) return true;
    if (key >= moodMin && key <= moodMax) return true;
    else return false;
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.newBlock.save().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add("Error, algo salió mal al agregar el bloque.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          toastManager.add("El bloque se agrego exitosamente.", {
            appearance: "success",
            autoDismiss: true,
          });
        }
        this.props.onSave && this.props.onSave();
      });
    });
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={
                !(this.newBlock.song_styles_id && this.newBlock.song_mood_id)
              }
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { stylesOptions, moodsOptions } = this.props;
    const preferenceOptions = this.getPreferenceOptions();
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "450px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            weight="black"
            lead
          >
            {"Agregar \n bloque"}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            color="special"
            lineBreak
          >
            {
              "Primero elige un estilo para que se valide el humor permitido segun el estilo seleccionado."
            }
          </Text>
          <br />
          <Columns isMultiline>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
              <Field
                label={"Estilo"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <Select
                  isMobile={!this.props.store.viewMobile}
                  name={"selectStyles"}
                  onChange={this.handleSelectChange}
                  options={stylesOptions}
                  placeholder={this.props.store.language.label385}
                  backgroundColor="black"
                  separatorColor="black"
                  className="my-1"
                />
              </Field>
              <Field
                label={"Humor"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <Select
                  isMobile={!this.props.store.viewMobile}
                  name={"selectMood"}
                  onChange={this.handleSelectChange}
                  options={moodsOptions}
                  defaultValue={
                    moodsOptions[
                      moodsOptions
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(this.newBlock.song_mood_id)
                    ]
                  }
                  isOptionDisabled={(moodsOptions) =>
                    true !==
                    this.moodEnabled(
                      moodsOptions.value,
                      this.newBlock.mood_min,
                      this.newBlock.mood_max
                    )
                  }
                  placeholder={this.props.store.language.label385}
                  backgroundColor="black"
                  separatorColor="black"
                  className="my-1"
                />
              </Field>
              <Field
                label={"Bpm min:"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  type="number"
                  name="bpm_min"
                  value={this.newBlock.bpm_min}
                  onChange={this.handleChangeFiled}
                  minlength={1}
                  maxlength={200}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </Column>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
              <Field
                label={"Duración:"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  type="number"
                  name="duration"
                  value={this.newBlock.duration}
                  onChange={this.handleChangeFiled}
                  minlength={1}
                  maxlength={400}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
              <Field
                label={"Preferencia"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <Select
                  isMobile={!this.props.store.viewMobile}
                  name={"selectPreference"}
                  onChange={this.handleSelectChange}
                  options={preferenceOptions}
                  defaultValue={
                    preferenceOptions[
                      preferenceOptions
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(this.newBlock.preference)
                    ]
                  }
                  placeholder={this.props.store.language.label385}
                  backgroundColor="black"
                  separatorColor="black"
                  className="my-1"
                />
              </Field>
              <Field
                label={"Bpm max:"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  type="number"
                  name="bpm_max"
                  value={this.newBlock.bpm_max}
                  onChange={this.handleChangeFiled}
                  minlength={1}
                  maxlength={200}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </Column>
          </Columns>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

NewBlocksModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

NewBlocksModal.defaultProps = {
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(NewBlocksModal));
