import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Layout,
  LayoutNavbar,
  LayoutNavbarStart,
  LayoutNavbarEnd,
  LayoutSide,
  LayoutContent,
  Text,
  Button,
  Sidebar,
} from "ks_storybook";
import { SignIn } from "../Session";
import logo from "../../assets/images/logo-kasimu.png";
import { ContentManagerRouter, ContentManagerMenu } from "../ContentManager";
import LoggedUserDropdown from "./components/LoggedUserDropdown";

// remove this later, when all routes exist -----------
//const FakeContent = withRouter(props => <div>{props.location.pathname}</div>);

@observer
class AppRouter extends Component {
  render() {
    const path = this.props.match.path;
    const root = "/contentmanager/";

    if (this.props.store.isLoggedIn || this.props.store.isLoggingIn) {
      const uiSettings = this.props.store.ui.settings;

      if (uiSettings.isEmpty()) return null;

      const user = this.props.store.loggedInUser;

      return (
        <React.Fragment>
          <Layout
            showSide={this.props.store.ui.showMainSide}
            noNavbar
            style={{ background: "#121212" }}
          >
            <Sidebar>
              <Switch>
                <Route
                  path={`${path}${root}`}
                  render={(props) => (
                    <ContentManagerMenu {...props} handleToogle={() => {}} />
                  )}
                />
              </Switch>
            </Sidebar>
            <LayoutContent>
              <LayoutNavbar style={{ background: "#121212" }}>
                <LayoutNavbarStart fillWidth justifyContent />
                <LayoutNavbarEnd fillWidth>
                  <LoggedUserDropdown />
                </LayoutNavbarEnd>
              </LayoutNavbar>
              <Switch>
                <Route
                  exact
                  path={`${path}${root}`}
                  component={ContentManagerRouter}
                />
                <ContentManagerRouter defaultComponent={ContentManagerRouter} />
                <Redirect
                  to={`${path}${root}`}
                  component={ContentManagerRouter}
                />
              </Switch>
            </LayoutContent>
          </Layout>
        </React.Fragment>
      );
    }

    return (
      <Switch>
        <Redirect to={`/session/signIn`} component={SignIn} />
      </Switch>
    );
  }
}

export default withStore(withRouter(AppRouter));
