import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Field,
  Img,
  Columns,
  Column,
} from "ks_storybook";
import moment from "moment";

@observer
class InformationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getStatus = (key) => {
    const status = { 1: "Cargado", 2: "Publicado", 3: "Modificado" };
    return status[key];
  };

  getRow = (key) => {
    const rowOptions = {
      1: "Elegir por tipo de local",
      2: "Elegir por estilo de música",
      3: "Elegir por otros criterios",
    };
    return rowOptions[key];
  };

  getGen = (key) => {
    const gen = { 0: "No", 1: "Si" };
    return gen[key];
  };

  getService = (free_rights) => {
    const { catalogsOptions } = this.props;
    const arr = catalogsOptions.filter(function (e) {
      return free_rights == e.value;
    });
    return arr ? arr[0].label : null;
  };

  render() {
    const { category } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "700px" : "90%"}
        height={this.props.store.viewMobile ? "250px" : "auto"}
      >
        <ModalHeader>
          <div className="flex items-center">
            <Img
              height={this.props.store.viewMobile ? "180px" : "100px"}
              width={this.props.store.viewMobile ? "180px" : "100px"}
              src={category.icon_path}
            />

            <Text
              className="ml-1"
              multiline
              upper
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
            >
              {" Id: " + category.id}
              {"\n"}
              {category.name}
            </Text>
          </div>
        </ModalHeader>
        <ModalContent>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Descripción: "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {category.description}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Estado: "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {this.getStatus(category.status)}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Fila: "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {this.getRow(category.list_view)}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Posición en la vista: "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {category.order_view}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Servicio: "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {this.getService(category.free_rights)}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Imagen: "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {category.icon_path}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Fecha de creación: "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {moment(category.created_at).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Fecha de modificación: "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {moment(category.updated_at).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
          </div>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

InformationModal.propTypes = {
  category: PropTypes.object,
  onClose: PropTypes.func,
};

InformationModal.defaultProps = {
  category: null,
  onClose: null,
};

export default withStore(InformationModal);
