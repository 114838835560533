const bucket = 'https://bucket-kasimu.s3.amazonaws.com/images/';
const iconsOptions =[
    { label: 'c-1.png', icon: bucket+'c-1.png' },
    { label: 'c-2.png', icon: bucket+'c-2.png' },
    { label: 'c-3.png', icon: bucket+'c-3.png' },
    { label: 'c-4.png', icon: bucket+'c-4.png' },
    { label: 'c-5.png', icon: bucket+'c-5.png' },
    { label: 'c-6.png', icon: bucket+'c-6.png' },
    { label: 'c-7.png', icon: bucket+'c-7.png' },
    { label: 'c-8.png', icon: bucket+'c-8.png' },
    { label: 'c-9.png', icon: bucket+'c-9.png' },
    { label: 'c-10.png', icon: bucket+'c-10.png' },
    { label: 'c-11.png', icon: bucket+'c-11.png' },
    { label: 'c-12.png', icon: bucket+'c-12.png' },
    { label: 'c-13.png', icon: bucket+'c-13.png' },
    { label: 'c-14.png', icon: bucket+'c-14.png' },
    { label: 'c-15.png', icon: bucket+'c-15.png' },
    { label: 'c-16.png', icon: bucket+'c-16.png' },
    { label: 'c-17.png', icon: bucket+'c-17.png' },
    { label: 'c-18.png', icon: bucket+'c-18.png' },
    { label: 'c-19.png', icon: bucket+'c-19.png' },
    { label: 'c-20.png', icon: bucket+'c-20.png' },
    { label: 'c-21.png', icon: bucket+'c-21.png' },
    { label: 'c-22.png', icon: bucket+'c-22.png' },
    { label: 'c-pf.png', icon: bucket+'c-pf.png' },
    { label: 'c-24.png', icon: bucket+'c-24.png' },
    { label: 'c-pu.png', icon: bucket+'c-pu.png' },
    { label: 'cp-212.png', icon: bucket+'cp-212.png' },
    { label: 'cp-213.png', icon: bucket+'cp-213.png' },
    { label: 'cp-214.png', icon: bucket+'cp-214.png' },
    { label: 'cp-215.png', icon: bucket+'cp-215.png' },
    { label: 'cp-216.png', icon: bucket+'cp-216.png' },
    { label: 'cp-217.png', icon: bucket+'cp-217.png' },
    { label: 'cp-218.png', icon: bucket+'cp-218.png' },
    { label: 'cp-219.png', icon: bucket+'cp-219.png' },
    { label: 'cp-22.png', icon: bucket+'cp-22.png' },
    { label: 'cp-220.png', icon: bucket+'cp-220.png' },
    { label: 'cp-221.png', icon: bucket+'cp-221.png' },
    { label: 'cp-222.png', icon: bucket+'cp-222.png' },
    { label: 'cp-223.png', icon: bucket+'cp-223.png' },
    { label: 'cp-224.png', icon: bucket+'cp-224.png' },
    { label: 'cp-225.png', icon: bucket+'cp-225.png' },
    { label: 'cp-226.png', icon: bucket+'cp-226.png' },
    { label: 'cp-227.png', icon: bucket+'cp-227.png' },
    { label: 'cp-228.png', icon: bucket+'cp-228.png' },
    { label: 'cp-229.png', icon: bucket+'cp-229.png' },
    { label: 'cp-23.png', icon: bucket+'cp-23.png' },
    { label: 'cp-230.png', icon: bucket+'cp-230.png' },
    { label: 'cp-231.png', icon: bucket+'cp-231.png' },
    { label: 'cp-232.png', icon: bucket+'cp-232.png' },
    { label: 'cp-233.png', icon: bucket+'cp-233.png' },
    { label: 'cp-234.png', icon: bucket+'cp-234.png' },
    { label: 'cp-235.png', icon: bucket+'cp-235.png' },
    { label: 'cp-236.png', icon: bucket+'cp-236.png' },
    { label: 'cp-237.png', icon: bucket+'cp-237.png' },
    { label: 'cp-238.png', icon: bucket+'cp-238.png' },
    { label: 'cp-239.png', icon: bucket+'cp-239.png' },
    { label: 'cp-24.png', icon: bucket+'cp-24.png' },
    { label: 'cp-240.png', icon: bucket+'cp-240.png' },
    { label: 'cp-241.png', icon: bucket+'cp-241.png' },
    { label: 'cp-242.png', icon: bucket+'cp-242.png' },
    { label: 'cp-243.png', icon: bucket+'cp-243.png' },
    { label: 'cp-244.png', icon: bucket+'cp-244.png' },
    { label: 'cp-245.png', icon: bucket+'cp-245.png' },
    { label: 'cp-246.png', icon: bucket+'cp-246.png' },
    { label: 'cp-247.png', icon: bucket+'cp-247.png' },
    { label: 'cp-248.png', icon: bucket+'cp-248.png' },
    { label: 'cp-25.png', icon: bucket+'cp-25.png' },
    { label: 'cp-251.png', icon: bucket+'cp-251.png' },
    { label: 'cp-253.png', icon: bucket+'cp-253.png' },
    { label: 'cp-254.png', icon: bucket+'cp-254.png' },
    { label: 'cp-255.png', icon: bucket+'cp-255.png' },
    { label: 'cp-257.png', icon: bucket+'cp-257.png' },
    { label: 'cp-258.png', icon: bucket+'cp-258.png' },
    { label: 'cp-259.png', icon: bucket+'cp-259.png' },
    { label: 'cp-26.png', icon: bucket+'cp-26.png' },
    { label: 'cp-260.png', icon: bucket+'cp-260.png' },
    { label: 'cp-261.png', icon: bucket+'cp-261.png' },
    { label: 'cp-262.png', icon: bucket+'cp-262.png' },
    { label: 'cp-263.png', icon: bucket+'cp-263.png' },
    { label: 'cp-265.png', icon: bucket+'cp-265.png' },
    { label: 'cp-266.png', icon: bucket+'cp-266.png' },
    { label: 'cp-267.png', icon: bucket+'cp-267.png' },
    { label: 'cp-268.png', icon: bucket+'cp-268.png' },
    { label: 'cp-269.png', icon: bucket+'cp-269.png' },
    { label: 'cp-27.png', icon: bucket+'cp-27.png' },
    { label: 'cp-270.png', icon: bucket+'cp-270.png' },
    { label: 'cp-271.png', icon: bucket+'cp-271.png' },
    { label: 'cp-272.png', icon: bucket+'cp-272.png' },
    { label: 'cp-273.png', icon: bucket+'cp-273.png' },
    { label: 'cp-274.png', icon: bucket+'cp-274.png' },
    { label: 'cp-275.png', icon: bucket+'cp-275.png' },
    { label: 'cp-277.png', icon: bucket+'cp-277.png' },
    { label: 'cp-278.png', icon: bucket+'cp-278.png' },
    { label: 'cp-279.png', icon: bucket+'cp-279.png' },
    { label: 'cp-28.png', icon: bucket+'cp-28.png' },
    { label: 'cp-281.png', icon: bucket+'cp-281.png' },
    { label: 'cp-282.png', icon: bucket+'cp-282.png' },
    { label: 'cp-283.png', icon: bucket+'cp-283.png' },
    { label: 'cp-284.png', icon: bucket+'cp-284.png' },
    { label: 'cp-285.png', icon: bucket+'cp-285.png' },
    { label: 'cp-286.png', icon: bucket+'cp-286.png' },
    { label: 'cp-287.png', icon: bucket+'cp-287.png' },
    { label: 'cp-288.png', icon: bucket+'cp-288.png' },
    { label: 'cp-289.png', icon: bucket+'cp-289.png' },
    { label: 'cp-29.png', icon: bucket+'cp-29.png' },
    { label: 'cp-291.png', icon: bucket+'cp-291.png' },
    { label: 'cp-292.png', icon: bucket+'cp-292.png' },
    { label: 'cp-293.png', icon: bucket+'cp-293.png' },
    { label: 'cp-294.png', icon: bucket+'cp-294.png' },
    { label: 'cp-295.png', icon: bucket+'cp-295.png' },
    { label: 'cp-296.png', icon: bucket+'cp-296.png' },
    { label: 'cp-297.png', icon: bucket+'cp-297.png' },
    { label: 'cp-298.png', icon: bucket+'cp-298.png' },
    { label: 'cp-3.png', icon: bucket+'cp-3.png' },
    { label: 'cp-30.png', icon: bucket+'cp-30.png' },
    { label: 'cp-300.png', icon: bucket+'cp-300.png' },
    { label: 'cp-306.png', icon: bucket+'cp-306.png' },
    { label: 'cp-308.png', icon: bucket+'cp-308.png' },
    { label: 'cp-309.png', icon: bucket+'cp-309.png' },
    { label: 'cp-31.png', icon: bucket+'cp-31.png' },
    { label: 'cp-313.png', icon: bucket+'cp-313.png' },
    { label: 'cp-314.png', icon: bucket+'cp-314.png' },
    { label: 'cp-315.png', icon: bucket+'cp-315.png' },
    { label: 'cp-316.png', icon: bucket+'cp-316.png' },
    { label: 'cp-317.png', icon: bucket+'cp-317.png' },
    { label: 'cp-318.png', icon: bucket+'cp-318.png' },
    { label: 'cp-319.png', icon: bucket+'cp-319.png' },
    { label: 'cp-32.png', icon: bucket+'cp-32.png' },
    { label: 'cp-320.png', icon: bucket+'cp-320.png' },
    { label: 'cp-321.png', icon: bucket+'cp-321.png' },
    { label: 'cp-322.png', icon: bucket+'cp-322.png' },
    { label: 'cp-323.png', icon: bucket+'cp-323.png' },
    { label: 'cp-324.png', icon: bucket+'cp-324.png' },
    { label: 'cp-326.png', icon: bucket+'cp-326.png' },
    { label: 'cp-327.png', icon: bucket+'cp-327.png' },
    { label: 'cp-328.png', icon: bucket+'cp-328.png' },
    { label: 'cp-329.png', icon: bucket+'cp-329.png' },
    { label: 'cp-33.png', icon: bucket+'cp-33.png' },
    { label: 'cp-330.png', icon: bucket+'cp-330.png' },
    { label: 'cp-331.png', icon: bucket+'cp-331.png' },
    { label: 'cp-332.png', icon: bucket+'cp-332.png' },
    { label: 'cp-333.png', icon: bucket+'cp-333.png' },
    { label: 'cp-337.png', icon: bucket+'cp-337.png' },
    { label: 'cp-338.png', icon: bucket+'cp-338.png' },
    { label: 'cp-339.png', icon: bucket+'cp-339.png' },
    { label: 'cp-34.png', icon: bucket+'cp-34.png' },
    { label: 'cp-340.png', icon: bucket+'cp-340.png' },
    { label: 'cp-343.png', icon: bucket+'cp-343.png' },
    { label: 'cp-346.png', icon: bucket+'cp-346.png' },
    { label: 'cp-347.png', icon: bucket+'cp-347.png' },
    { label: 'cp-348.png', icon: bucket+'cp-348.png' },
    { label: 'cp-35.png', icon: bucket+'cp-35.png' },
    { label: 'cp-350.png', icon: bucket+'cp-350.png' },
    { label: 'cp-351.png', icon: bucket+'cp-351.png' },
    { label: 'cp-353.png', icon: bucket+'cp-353.png' },
    { label: 'cp-354.png', icon: bucket+'cp-354.png' },
    { label: 'cp-355.png', icon: bucket+'cp-355.png' },
    { label: 'cp-356.png', icon: bucket+'cp-356.png' },
    { label: 'cp-359.png', icon: bucket+'cp-359.png' },
    { label: 'cp-36.png', icon: bucket+'cp-36.png' },
    { label: 'cp-363.png', icon: bucket+'cp-363.png' },
    { label: 'cp-364.png', icon: bucket+'cp-364.png' },
    { label: 'cp-366.png', icon: bucket+'cp-366.png' },
    { label: 'cp-368.png', icon: bucket+'cp-368.png' },
    { label: 'cp-369.png', icon: bucket+'cp-369.png' },
    { label: 'cp-37.png', icon: bucket+'cp-37.png' },
    { label: 'cp-370.png', icon: bucket+'cp-370.png' },
    { label: 'cp-372.png', icon: bucket+'cp-372.png' },
    { label: 'cp-373.png', icon: bucket+'cp-373.png' },
    { label: 'cp-376.png', icon: bucket+'cp-376.png' },
    { label: 'cp-377.png', icon: bucket+'cp-377.png' },
    { label: 'cp-378.png', icon: bucket+'cp-378.png' },
    { label: 'cp-379.png', icon: bucket+'cp-379.png' },
    { label: 'cp-38.png', icon: bucket+'cp-38.png' },
    { label: 'cp-380.png', icon: bucket+'cp-380.png' },
    { label: 'cp-382.png', icon: bucket+'cp-382.png' },
    { label: 'cp-383.png', icon: bucket+'cp-383.png' },
    { label: 'cp-384.png', icon: bucket+'cp-384.png' },
    { label: 'cp-385.png', icon: bucket+'cp-385.png' },
    { label: 'cp-387.png', icon: bucket+'cp-387.png' },
    { label: 'cp-389.png', icon: bucket+'cp-389.png' },
    { label: 'cp-39.png', icon: bucket+'cp-39.png' },
    { label: 'cp-390.png', icon: bucket+'cp-390.png' },
    { label: 'cp-392.png', icon: bucket+'cp-392.png' },
    { label: 'cp-393.png', icon: bucket+'cp-393.png' },
    { label: 'cp-397.png', icon: bucket+'cp-397.png' },
    { label: 'cp-399.png', icon: bucket+'cp-399.png' },
    { label: 'cp-4.png', icon: bucket+'cp-4.png' },
    { label: 'cp-40.png', icon: bucket+'cp-40.png' },
    { label: 'cp-400.png', icon: bucket+'cp-400.png' },
    { label: 'cp-401.png', icon: bucket+'cp-401.png' },
    { label: 'cp-402.png', icon: bucket+'cp-402.png' },
    { label: 'cp-403.png', icon: bucket+'cp-403.png' },
    { label: 'cp-404.png', icon: bucket+'cp-404.png' },
    { label: 'cp-407.png', icon: bucket+'cp-407.png' },
    { label: 'cp-408.png', icon: bucket+'cp-408.png' },
    { label: 'cp-409.png', icon: bucket+'cp-409.png' },
    { label: 'cp-41.png', icon: bucket+'cp-41.png' },
    { label: 'cp-410.png', icon: bucket+'cp-410.png' },
    { label: 'cp-411.png', icon: bucket+'cp-411.png' },
    { label: 'cp-412.png', icon: bucket+'cp-412.png' },
    { label: 'cp-414.png', icon: bucket+'cp-414.png' },
    { label: 'cp-416.png', icon: bucket+'cp-416.png' },
    { label: 'cp-417.png', icon: bucket+'cp-417.png' },
    { label: 'cp-418.png', icon: bucket+'cp-418.png' },
    { label: 'cp-42.png', icon: bucket+'cp-42.png' },
    { label: 'cp-421.png', icon: bucket+'cp-421.png' },
    { label: 'cp-424.png', icon: bucket+'cp-424.png' },
    { label: 'cp-425.png', icon: bucket+'cp-425.png' },
    { label: 'cp-426.png', icon: bucket+'cp-426.png' },
    { label: 'cp-427.png', icon: bucket+'cp-427.png' },
    { label: 'cp-428.png', icon: bucket+'cp-428.png' },
    { label: 'cp-43.png', icon: bucket+'cp-43.png' },
    { label: 'cp-431.png', icon: bucket+'cp-431.png' },
    { label: 'cp-432.png', icon: bucket+'cp-432.png' },
    { label: 'cp-433.png', icon: bucket+'cp-433.png' },
    { label: 'cp-435.png', icon: bucket+'cp-435.png' },
    { label: 'cp-436.png', icon: bucket+'cp-436.png' },
    { label: 'cp-437.png', icon: bucket+'cp-437.png' },
    { label: 'cp-438.png', icon: bucket+'cp-438.png' },
    { label: 'cp-439.png', icon: bucket+'cp-439.png' },
    { label: 'cp-44.png', icon: bucket+'cp-44.png' },
    { label: 'cp-441.png', icon: bucket+'cp-441.png' },
    { label: 'cp-446.png', icon: bucket+'cp-446.png' },
    { label: 'cp-45.png', icon: bucket+'cp-45.png' },
    { label: 'cp-450.png', icon: bucket+'cp-450.png' },
    { label: 'cp-453.png', icon: bucket+'cp-453.png' },
    { label: 'cp-454.png', icon: bucket+'cp-454.png' },
    { label: 'cp-455.png', icon: bucket+'cp-455.png' },
    { label: 'cp-456.png', icon: bucket+'cp-456.png' },
    { label: 'cp-458.png', icon: bucket+'cp-458.png' },
    { label: 'cp-459.png', icon: bucket+'cp-459.png' },
    { label: 'cp-46.png', icon: bucket+'cp-46.png' },
    { label: 'cp-461.png', icon: bucket+'cp-461.png' },
    { label: 'cp-463.png', icon: bucket+'cp-463.png' },
    { label: 'cp-464.png', icon: bucket+'cp-464.png' },
    { label: 'cp-465.png', icon: bucket+'cp-465.png' },
    { label: 'cp-466.png', icon: bucket+'cp-466.png' },
    { label: 'cp-467.png', icon: bucket+'cp-467.png' },
    { label: 'cp-47.png', icon: bucket+'cp-47.png' },
    { label: 'cp-470.png', icon: bucket+'cp-470.png' },
    { label: 'cp-471.png', icon: bucket+'cp-471.png' },
    { label: 'cp-472.png', icon: bucket+'cp-472.png' },
    { label: 'cp-473.png', icon: bucket+'cp-473.png' },
    { label: 'cp-474.png', icon: bucket+'cp-474.png' },
    { label: 'cp-475.png', icon: bucket+'cp-475.png' },
    { label: 'cp-476.png', icon: bucket+'cp-476.png' },
    { label: 'cp-479.png', icon: bucket+'cp-479.png' },
    { label: 'cp-48.png', icon: bucket+'cp-48.png' },
    { label: 'cp-481.png', icon: bucket+'cp-481.png' },
    { label: 'cp-482.png', icon: bucket+'cp-482.png' },
    { label: 'cp-483.png', icon: bucket+'cp-483.png' },
    { label: 'cp-484.png', icon: bucket+'cp-484.png' },
    { label: 'cp-485.png', icon: bucket+'cp-485.png' },
    { label: 'cp-487.png', icon: bucket+'cp-487.png' },
    { label: 'cp-489.png', icon: bucket+'cp-489.png' },
    { label: 'cp-49.png', icon: bucket+'cp-49.png' },
    { label: 'cp-490.png', icon: bucket+'cp-490.png' },
    { label: 'cp-491.png', icon: bucket+'cp-491.png' },
    { label: 'cp-492.png', icon: bucket+'cp-492.png' },
    { label: 'cp-493.png', icon: bucket+'cp-493.png' },
    { label: 'cp-494.png', icon: bucket+'cp-494.png' },
    { label: 'cp-495.png', icon: bucket+'cp-495.png' },
    { label: 'cp-496.png', icon: bucket+'cp-496.png' },
    { label: 'cp-497.png', icon: bucket+'cp-497.png' },
    { label: 'cp-498.png', icon: bucket+'cp-498.png' },
    { label: 'cp-499.png', icon: bucket+'cp-499.png' },
    { label: 'cp-5.png', icon: bucket+'cp-5.png' },
    { label: 'cp-50.png', icon: bucket+'cp-50.png' },
    { label: 'cp-500.png', icon: bucket+'cp-500.png' },
    { label: 'cp-501.png', icon: bucket+'cp-501.png' },
    { label: 'cp-502.png', icon: bucket+'cp-502.png' },
    { label: 'cp-503.png', icon: bucket+'cp-503.png' },
    { label: 'cp-504.png', icon: bucket+'cp-504.png' },
    { label: 'cp-505.png', icon: bucket+'cp-505.png' },
    { label: 'cp-506.png', icon: bucket+'cp-506.png' },
    { label: 'cp-507.png', icon: bucket+'cp-507.png' },
    { label: 'cp-508.png', icon: bucket+'cp-508.png' },
    { label: 'cp-509.png', icon: bucket+'cp-509.png' },
    { label: 'cp-51.png', icon: bucket+'cp-51.png' },
    { label: 'cp-510.png', icon: bucket+'cp-510.png' },
    { label: 'cp-512.png', icon: bucket+'cp-512.png' },
    { label: 'cp-513.png', icon: bucket+'cp-513.png' },
    { label: 'cp-514.png', icon: bucket+'cp-514.png' },
    { label: 'cp-515.png', icon: bucket+'cp-515.png' },
    { label: 'cp-516.png', icon: bucket+'cp-516.png' },
    { label: 'cp-517.png', icon: bucket+'cp-517.png' },
    { label: 'cp-518.png', icon: bucket+'cp-518.png' },
    { label: 'cp-519.png', icon: bucket+'cp-519.png' },
    { label: 'cp-52.png', icon: bucket+'cp-52.png' },
    { label: 'cp-520.png', icon: bucket+'cp-520.png' },
    { label: 'cp-521.png', icon: bucket+'cp-521.png' },
    { label: 'cp-522.png', icon: bucket+'cp-522.png' },
    { label: 'cp-523.png', icon: bucket+'cp-523.png' },
    { label: 'cp-524.png', icon: bucket+'cp-524.png' },
    { label: 'cp-525.png', icon: bucket+'cp-525.png' },
    { label: 'cp-526.png', icon: bucket+'cp-526.png' },
    { label: 'cp-527.png', icon: bucket+'cp-527.png' },
    { label: 'cp-528.png', icon: bucket+'cp-528.png' },
    { label: 'cp-529.png', icon: bucket+'cp-529.png' },
    { label: 'cp-53.png', icon: bucket+'cp-53.png' },
    { label: 'cp-530.png', icon: bucket+'cp-530.png' },
    { label: 'cp-531.png', icon: bucket+'cp-531.png' },
    { label: 'cp-532.png', icon: bucket+'cp-532.png' },
    { label: 'cp-533.png', icon: bucket+'cp-533.png' },
    { label: 'cp-534.png', icon: bucket+'cp-534.png' },
    { label: 'cp-535.png', icon: bucket+'cp-535.png' },
    { label: 'cp-537.png', icon: bucket+'cp-537.png' },
    { label: 'cp-538.png', icon: bucket+'cp-538.png' },
    { label: 'cp-539.png', icon: bucket+'cp-539.png' },
    { label: 'cp-54.png', icon: bucket+'cp-54.png' },
    { label: 'cp-540.png', icon: bucket+'cp-540.png' },
    { label: 'cp-541.png', icon: bucket+'cp-541.png' },
    { label: 'cp-542.png', icon: bucket+'cp-542.png' },
    { label: 'cp-543.png', icon: bucket+'cp-543.png' },
    { label: 'cp-544.png', icon: bucket+'cp-544.png' },
    { label: 'cp-545.png', icon: bucket+'cp-545.png' },
    { label: 'cp-546.png', icon: bucket+'cp-546.png' },
    { label: 'cp-547.png', icon: bucket+'cp-547.png' },
    { label: 'cp-549.png', icon: bucket+'cp-549.png' },
    { label: 'cp-55.png', icon: bucket+'cp-55.png' },
    { label: 'cp-550.png', icon: bucket+'cp-550.png' },
    { label: 'cp-551.png', icon: bucket+'cp-551.png' },
    { label: 'cp-552.png', icon: bucket+'cp-552.png' },
    { label: 'cp-553.png', icon: bucket+'cp-553.png' },
    { label: 'cp-555.png', icon: bucket+'cp-555.png' },
    { label: 'cp-556.png', icon: bucket+'cp-556.png' },
    { label: 'cp-557.png', icon: bucket+'cp-557.png' },
    { label: 'cp-558.png', icon: bucket+'cp-558.png' },
    { label: 'cp-559.png', icon: bucket+'cp-559.png' },
    { label: 'cp-56.png', icon: bucket+'cp-56.png' },
    { label: 'cp-560.png', icon: bucket+'cp-560.png' },
    { label: 'cp-561.png', icon: bucket+'cp-561.png' },
    { label: 'cp-562.png', icon: bucket+'cp-562.png' },
    { label: 'cp-564.png', icon: bucket+'cp-564.png' },
    { label: 'cp-565.png', icon: bucket+'cp-565.png' },
    { label: 'cp-566.png', icon: bucket+'cp-566.png' },
    { label: 'cp-567.png', icon: bucket+'cp-567.png' },
    { label: 'cp-568.png', icon: bucket+'cp-568.png' },
    { label: 'cp-569.png', icon: bucket+'cp-569.png' },
    { label: 'cp-57.png', icon: bucket+'cp-57.png' },
    { label: 'cp-58.png', icon: bucket+'cp-58.png' },
    { label: 'cp-586.png', icon: bucket+'cp-586.png' },
    { label: 'cp-587.png', icon: bucket+'cp-587.png' },
    { label: 'cp-589.png', icon: bucket+'cp-589.png' },
    { label: 'cp-59.png', icon: bucket+'cp-59.png' },
    { label: 'cp-590.png', icon: bucket+'cp-590.png' },
    { label: 'cp-597.png', icon: bucket+'cp-597.png' },
    { label: 'cp-6.png', icon: bucket+'cp-6.png' },
    { label: 'cp-60.png', icon: bucket+'cp-60.png' },
    { label: 'cp-61.png', icon: bucket+'cp-61.png' },
    { label: 'cp-62.png', icon: bucket+'cp-62.png' },
    { label: 'cp-63.png', icon: bucket+'cp-63.png' },
    { label: 'cp-64.png', icon: bucket+'cp-64.png' },
    { label: 'cp-65.png', icon: bucket+'cp-65.png' },
    { label: 'cp-66.png', icon: bucket+'cp-66.png' },
    { label: 'cp-67.png', icon: bucket+'cp-67.png' },
    { label: 'cp-68.png', icon: bucket+'cp-68.png' },
    { label: 'cp-685.png', icon: bucket+'cp-685.png' },
    { label: 'cp-686.png', icon: bucket+'cp-686.png' },
    { label: 'cp-687.png', icon: bucket+'cp-687.png' },
    { label: 'cp-688.png', icon: bucket+'cp-688.png' },
    { label: 'cp-689.png', icon: bucket+'cp-689.png' },
    { label: 'cp-69.png', icon: bucket+'cp-69.png' },
    { label: 'cp-690.png', icon: bucket+'cp-690.png' },
    { label: 'cp-697.png', icon: bucket+'cp-697.png' },
    { label: 'cp-698.png', icon: bucket+'cp-698.png' },
    { label: 'cp-699.png', icon: bucket+'cp-699.png' },
    { label: 'cp-7.png', icon: bucket+'cp-7.png' },
    { label: 'cp-70.png', icon: bucket+'cp-70.png' },
    { label: 'cp-702.png', icon: bucket+'cp-702.png' },
    { label: 'cp-704.png', icon: bucket+'cp-704.png' },
    { label: 'cp-706.png', icon: bucket+'cp-706.png' },
    { label: 'cp-708.png', icon: bucket+'cp-708.png' },
    { label: 'cp-71.png', icon: bucket+'cp-71.png' },
    { label: 'cp-710.png', icon: bucket+'cp-710.png' },
    { label: 'cp-712.png', icon: bucket+'cp-712.png' },
    { label: 'cp-72.png', icon: bucket+'cp-72.png' },
    { label: 'cp-73.png', icon: bucket+'cp-73.png' },
    { label: 'cp-74.png', icon: bucket+'cp-74.png' },
    { label: 'cp-75.png', icon: bucket+'cp-75.png' },
    { label: 'cp-76.png', icon: bucket+'cp-76.png' },
    { label: 'cp-77.png', icon: bucket+'cp-77.png' },
    { label: 'cp-78.png', icon: bucket+'cp-78.png' },
    { label: 'cp-79.png', icon: bucket+'cp-79.png' },
    { label: 'cp-8.png', icon: bucket+'cp-8.png' },
    { label: 'cp-80.png', icon: bucket+'cp-80.png' },
    { label: 'cp-806.png', icon: bucket+'cp-806.png' },
    { label: 'cp-807.png', icon: bucket+'cp-807.png' },
    { label: 'cp-81.png', icon: bucket+'cp-81.png' },
    { label: 'cp-810.png', icon: bucket+'cp-810.png' },
    { label: 'cp-814.png', icon: bucket+'cp-814.png' },
    { label: 'cp-815.png', icon: bucket+'cp-815.png' },
    { label: 'cp-816.png', icon: bucket+'cp-816.png' },
    { label: 'cp-818.png', icon: bucket+'cp-818.png' },
    { label: 'cp-819.png', icon: bucket+'cp-819.png' },
    { label: 'cp-82.png', icon: bucket+'cp-82.png' },
    { label: 'cp-823.png', icon: bucket+'cp-823.png' },
    { label: 'cp-825.png', icon: bucket+'cp-825.png' },
    { label: 'cp-826.png', icon: bucket+'cp-826.png' },
    { label: 'cp-827.png', icon: bucket+'cp-827.png' },
    { label: 'cp-828.png', icon: bucket+'cp-828.png' },
    { label: 'cp-83.png', icon: bucket+'cp-83.png' },
    { label: 'cp-833.png', icon: bucket+'cp-833.png' },
    { label: 'cp-834.png', icon: bucket+'cp-834.png' },
    { label: 'cp-836.png', icon: bucket+'cp-836.png' },
    { label: 'cp-838.png', icon: bucket+'cp-838.png' },
    { label: 'cp-839.png', icon: bucket+'cp-839.png' },
    { label: 'cp-84.png', icon: bucket+'cp-84.png' },
    { label: 'cp-843.png', icon: bucket+'cp-843.png' },
    { label: 'cp-847.png', icon: bucket+'cp-847.png' },
    { label: 'cp-848.png', icon: bucket+'cp-848.png' },
    { label: 'cp-85.png', icon: bucket+'cp-85.png' },
    { label: 'cp-850.png', icon: bucket+'cp-850.png' },
    { label: 'cp-852.png', icon: bucket+'cp-852.png' },
    { label: 'cp-853.png', icon: bucket+'cp-853.png' },
    { label: 'cp-86.png', icon: bucket+'cp-86.png' },
    { label: 'cp-864.png', icon: bucket+'cp-864.png' },
    { label: 'cp-865.png', icon: bucket+'cp-865.png' },
    { label: 'cp-866.png', icon: bucket+'cp-866.png' },
    { label: 'cp-867.png', icon: bucket+'cp-867.png' },
    { label: 'cp-868.png', icon: bucket+'cp-868.png' },
    { label: 'cp-869.png', icon: bucket+'cp-869.png' },
    { label: 'cp-87.png', icon: bucket+'cp-87.png' },
    { label: 'cp-870.png', icon: bucket+'cp-870.png' },
    { label: 'cp-871.png', icon: bucket+'cp-871.png' },
    { label: 'cp-872.png', icon: bucket+'cp-872.png' },
    { label: 'cp-873.png', icon: bucket+'cp-873.png' },
    { label: 'cp-874.png', icon: bucket+'cp-874.png' },
    { label: 'cp-875.png', icon: bucket+'cp-875.png' },
    { label: 'cp-876.png', icon: bucket+'cp-876.png' },
    { label: 'cp-877.png', icon: bucket+'cp-877.png' },
    { label: 'cp-878.png', icon: bucket+'cp-878.png' },
    { label: 'cp-879.png', icon: bucket+'cp-879.png' },
    { label: 'cp-88.png', icon: bucket+'cp-88.png' },
    { label: 'cp-880.png', icon: bucket+'cp-880.png' },
    { label: 'cp-881.png', icon: bucket+'cp-881.png' },
    { label: 'cp-882.png', icon: bucket+'cp-882.png' },
    { label: 'cp-883.png', icon: bucket+'cp-883.png' },
    { label: 'cp-884.png', icon: bucket+'cp-884.png' },
    { label: 'cp-885.png', icon: bucket+'cp-885.png' },
    { label: 'cp-886.png', icon: bucket+'cp-886.png' },
    { label: 'cp-887.png', icon: bucket+'cp-887.png' },
    { label: 'cp-888.png', icon: bucket+'cp-888.png' },
    { label: 'cp-889.png', icon: bucket+'cp-889.png' },
    { label: 'cp-89.png', icon: bucket+'cp-89.png' },
    { label: 'cp-890.png', icon: bucket+'cp-890.png' },
    { label: 'cp-891.png', icon: bucket+'cp-891.png' },
    { label: 'cp-892.png', icon: bucket+'cp-892.png' },
    { label: 'cp-893.png', icon: bucket+'cp-893.png' },
    { label: 'cp-894.png', icon: bucket+'cp-894.png' },
    { label: 'cp-895.png', icon: bucket+'cp-895.png' },
    { label: 'cp-896.png', icon: bucket+'cp-896.png' },
    { label: 'cp-897.png', icon: bucket+'cp-897.png' },
    { label: 'cp-898.png', icon: bucket+'cp-898.png' },
    { label: 'cp-899.png', icon: bucket+'cp-899.png' },
    { label: 'cp-9.png', icon: bucket+'cp-9.png' },
    { label: 'cp-90.png', icon: bucket+'cp-90.png' },
    { label: 'cp-900.png', icon: bucket+'cp-900.png' },
    { label: 'cp-901.png', icon: bucket+'cp-901.png' },
    { label: 'cp-902.png', icon: bucket+'cp-902.png' },
    { label: 'cp-91.png', icon: bucket+'cp-91.png' },
    { label: 'cp-92.png', icon: bucket+'cp-92.png' },
    { label: 'cp-93.png', icon: bucket+'cp-93.png' },
    { label: 'cp-94.png', icon: bucket+'cp-94.png' },
    { label: 'cp-95.png', icon: bucket+'cp-95.png' },
    { label: 'cp-96.png', icon: bucket+'cp-96.png' },
    { label: 'cp-97.png', icon: bucket+'cp-97.png' },
    { label: 'cp-98.png', icon: bucket+'cp-98.png' },
    { label: 'cp-99.png', icon: bucket+'cp-99.png' },
    { label: 'cp-1.png', icon: bucket+'cp-1.png' },
    { label: 'cp-10.png', icon: bucket+'cp-10.png' },
    { label: 'cp-100.png', icon: bucket+'cp-100.png' },
    { label: 'cp-101.png', icon: bucket+'cp-101.png' },
    { label: 'cp-11.png', icon: bucket+'cp-11.png' },
    { label: 'cp-12.png', icon: bucket+'cp-12.png' },
    { label: 'cp-13.png', icon: bucket+'cp-13.png' },
    { label: 'cp-14.png', icon: bucket+'cp-14.png' },
    { label: 'cp-15.png', icon: bucket+'cp-15.png' },
    { label: 'cp-16.png', icon: bucket+'cp-16.png' },
    { label: 'cp-17.png', icon: bucket+'cp-17.png' },
    { label: 'cp-18.png', icon: bucket+'cp-18.png' },
    { label: 'cp-19.png', icon: bucket+'cp-19.png' },
    { label: 'cp-2.png', icon: bucket+'cp-2.png' },
    { label: 'cp-20.png', icon: bucket+'cp-20.png' },
    { label: 'cp-203.png', icon: bucket+'cp-203.png' },
    { label: 'cp-204.png', icon: bucket+'cp-204.png' },
    { label: 'cp-205.png', icon: bucket+'cp-205.png' },
    { label: 'cp-206.png', icon: bucket+'cp-206.png' },
    { label: 'cp-207.png', icon: bucket+'cp-207.png' },
    { label: 'cp-208.png', icon: bucket+'cp-208.png' },
    { label: 'cp-209.png', icon: bucket+'cp-209.png' },
    { label: 'cp-21.png', icon: bucket+'cp-21.png' },
    { label: 'cp-210.png', icon: bucket+'cp-210.png' },
    { label: 'cp-211.png', icon: bucket+'cp-211.png' },
    { label: 'cp-m.png', icon: bucket+'cp-m.png' },
    { label: 'cp-a.png', icon: bucket+'cp-a.png' }
]

export default iconsOptions;