import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Loader,
  Button,
  Panel,
} from "ks_storybook";
import moment from "moment";
import { withToastManager } from "react-toast-notifications";
import { EmptyContent, Pagination } from "../Common";

@observer
class SongReportsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRestore: false,
      reports_songs: this.getReportsAll(5, props.song.id),
      per_page: 5,
      page: 1,
    };
    this.song = props.song.clone();
  }

  getReportsAll = (per_page, song_id) => {
    return this.props.store.reportsSongs.fetchSearch(per_page, song_id);
  };

  onPageChange = (page) => {
    const { per_page } = this.state;
    this.setState({
      reports_songs: this.props.store.reportsSongs.movePage(
        per_page,
        page,
        this.props.song.id
      ),
      page: page,
    });
  };

  handleRestore = (restore) => {
    const { toastManager } = this.props;
    this.song["name"] = restore.name;
    this.song["singer"] = restore.singer;
    this.song["singer_id"] = restore.singer_id;
    this.song["free_rights"] = restore.free_rights;
    this.song["song_lenguages"] = restore.song_lenguages;
    this.song["song_lenguages_id"] = restore.song_lenguages_id;
    this.song["explicit"] = restore.explicit;
    this.song["remix"] = restore.remix;
    this.song["year"] = restore.year;
    this.song["record_label"] = restore.record_label;
    this.song["record_label_id"] = restore.record_label_id;
    this.song["provider"] = restore.provider;
    this.song["provider_id"] = restore.provider_id;
    this.song["bpm"] = restore.bpm;
    this.song["duration"] = restore.duration;
    this.song["song_mood"] = restore.song_mood;
    this.song["song_mood_id"] = restore.song_mood_id;
    this.song["preference"] = restore.preference;
    this.song["song_styles"] = restore.song_styles;
    this.song["song_styles_id"] = restore.song_styles_id;
    this.song["deleted"] = restore.deleted;

    this.song.save().andThen((res, responseError) => {
      if (responseError) {
        toastManager.add(
          "Error, algo salió mal al restaurar los datos de la canción.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      } else {
        toastManager.add("La canción se restauro exitosamente.", {
          appearance: "success",
          autoDismiss: true,
        });
      }
      this.handleClose();
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        backgroundColor="transparent"
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  renderPaginator = (reports) => {
    if (reports.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={reports.totalPages}
            currentPage={reports.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { reports_songs } = this.state;
    if (!reports_songs) return this.renderEmptyContent();
    return !reports_songs.isOk()
      ? this.renderLoader()
      : this.renderReports(reports_songs.toArray());
  };

  getService = (free_rights) => {
    const { catalogsOptions } = this.props;
    const arr = catalogsOptions.filter(function (e) {
      return free_rights == e.value;
    });
    return arr ? arr[0].label : null;
  };

  getGen = (key) => {
    const gen = { 0: "No", 1: "Si" };
    return gen[key];
  };

  renderReports = (reports_songs) => {
    return (
      <React.Fragment>
        <div style={this.getContainerStyle()}>
          {reports_songs.length > 0
            ? reports_songs.map((obj) => (
                <div
                  style={{
                    lineBreak: "anywhere",
                    marginBottom: !this.props.store.viewMobile && "3px",
                  }}
                  className={this.props.store.viewMobile && "mb-1"}
                >
                  <Panel
                    color={"gray"}
                    padding={
                      this.props.store.viewMobile ? "16px 30px" : "14px 10px"
                    }
                    style={{
                      justifyContent: "center",
                      backgroundColor: this.state.open && "#D33F20",
                      borderRadius: !this.props.store.viewMobile && "5px",
                    }}
                  >
                    <div
                      className="flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        lineBreak
                      >
                        {"Usuario: " +
                          obj.user.username +
                          " | " +
                          obj.name +
                          " | " +
                          obj.singer.name +
                          " | Humor: " +
                          obj.song_mood.description +
                          " | Preferencia: " +
                          obj.preference +
                          " | Estilo: " +
                          obj.song_styles.description +
                          " | Servicio: " +
                          this.getService(obj.free_rights) +
                          " | Idioma: " +
                          obj.song_lenguages.description +
                          " | Explicita: " +
                          this.getGen(obj.explicit) +
                          " | Remix: " +
                          this.getGen(obj.remix) +
                          " | Año: " +
                          obj.year +
                          " | Sello Discográfico: " +
                          obj.record_label.name +
                          " | Proveedor: " +
                          obj.provider.name +
                          " | BPM: " +
                          obj.bpm +
                          " | Duración: " +
                          obj.duration +
                          " | Eliminada: " +
                          this.getGen(obj.deleted) +
                          " | Fecha de creación: " +
                          moment(obj.created_at).format("DD-MM-YYYY HH:mm:ss")}
                      </Text>
                      <Button
                        className="is-pulled-right ml-1"
                        icon="undo-alt"
                        kind="link"
                        color="basic"
                        size="lg"
                        lead
                        space
                        onClick={() => {
                          this.handleRestore(obj);
                        }}
                      />
                    </div>
                  </Panel>
                </div>
              ))
            : this.renderEmptyContent()}
        </div>
        {
          <Text className="is-pulled-right mt-1" size="xl" lineBreak>
            {"Resultado: " + this.state.reports_songs.count}
          </Text>
        }
        <br />
        {this.renderPaginator(this.state.reports_songs)}
      </React.Fragment>
    );
  };

  render() {
    const { song } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "800px" : "90%"}
        height={this.props.store.viewMobile ? "650px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            className={this.props.store.viewMobile ? "mb-2" : "mb-1"}
            weight="black"
            lineBreak
            lead
          >
            {"Reportes de modificaciones"}
            {"\n"}
            {" Id: " + song.id}
            {"\n"}
            {song.name}
          </Text>
        </ModalHeader>
        <ModalContent>{this.renderHeader()}</ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

SongReportsModal.propTypes = {
  song: PropTypes.object,
  onClose: PropTypes.func,
};

SongReportsModal.defaultProps = {
  song: null,
  onClose: null,
};

export default withToastManager(withStore(SongReportsModal));
