import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../hocs/withStore";
import {
  Modal,
  Text,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class WarningModal extends Component {
  constructor(props) {
    super(props);
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleAccept = () => {
    this.props.onAccept && this.props.onAccept();
  };

  render() {
    const { title, subtitle, btntitle } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "240px" : "auto"}
      >
        <ModalHeader></ModalHeader>
        <ModalContent>
          <div
            className="flex flex-col items-center pb-1"
            style={{
              height: "100%",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
              multiline
            >
              {title}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              multiline
              lineBreak
              color="special"
              className="pt-2 pb-3"
            >
              {subtitle}
            </Text>
            <div></div>
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="px-3"
              onClick={this.handleAccept}
            >
              <strong>{btntitle}</strong>
            </Button>
          </div>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

WarningModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  btntitle: PropTypes.string,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
};

WarningModal.defaultProps = {
  title: null,
  subtitle: null,
  btntitle: null,
  onAccept: null,
  onClose: null,
};

export default withStore(withToastManager(WarningModal));
