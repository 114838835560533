import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../Select";
import { withToastManager } from "react-toast-notifications";

@observer
class EditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.style = props.style.clone();
  }

  handleChangeFiled = (sender, value, name, validation) => {
    this.style[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;

    switch (sender.name) {
      case "selectLanguage":
        this.style["song_lenguages_id"] = val;
        this.style["song_lenguages"] = { id: val, description: value.label };
        break;
      case "selectMood":
        this.style["song_mood_id"] = val;
        this.style["song_mood"] = { id: val, description: value.label };
        break;
      case "selectEnabled":
        if (val == 2) this.style["enabled"] = 0;
        else this.style["enabled"] = 1;
        break;
      case "selectService":
        this.style["free_rights"] = val;
        break;
      default:
        break;
    }
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.style.save().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add(
            "Error, algo salió mal al editar el estilo musical.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        } else {
          toastManager.add("El estilo musical se editó exitosamente.", {
            appearance: "success",
            autoDismiss: true,
          });
        }
        this.handleClose();
      });
    });
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={this.style.description === ""}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  getOptions = (obj) => {
    return obj.toArray().map((o) => ({ value: o.identifier, label: o.name }));
  };

  render() {
    const { languagesOptions, catalogsOptions } = this.props;
    const user = this.props.store.loggedInUser;
    const genOptions = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" },
    ];
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "550px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            className="mb-2"
            weight="black"
            lineBreak
            lead
          >
            {this.props.store.language.label140}
            {"\n"}
            {" Id: " + this.style.id}
            {"\n"}
            {this.style.description}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Columns isMultiline>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
              <Field
                label={"Descripción"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  name="description"
                  onChange={this.handleChangeFiled}
                  value={this.style.description}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
              <Field
                label={"Idioma"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <Select
                  isMobile={!this.props.store.viewMobile}
                  name={"selectLanguage"}
                  onChange={this.handleSelectChange}
                  options={languagesOptions}
                  defaultValue={
                    languagesOptions[
                      languagesOptions
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(this.style.song_lenguages_id)
                    ]
                  }
                  placeholder={this.props.store.language.label385}
                  backgroundColor="black"
                  separatorColor="black"
                  className="my-1"
                />
              </Field>
              {/*<Field marginH='10px' label={'Humor'} size="xl" NoteSize="xs" upper>
									<Select
                isMobile={!this.props.store.viewMobile}
										name={'selectMood'}
										onChange={this.handleSelectChange}
										options={moodsOptions}
										defaultValue={moodsOptions[moodsOptions.map(function(e) { return e.value; }).indexOf(this.style.song_mood_id)]}
										placeholder={this.props.store.language.label385}
										backgroundColor='#FFFFFF'
										separatorColor='#f7f7f7'
										iconColor='black'
										textColor='#000000'
									/>
								</Field>*/}
              <Field
                label={"Activo"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <Select
                  isMobile={!this.props.store.viewMobile}
                  name={"selectEnabled"}
                  onChange={this.handleSelectChange}
                  options={genOptions}
                  defaultValue={
                    genOptions[
                      genOptions
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(this.style.enabled == 1 ? 1 : 2)
                    ]
                  }
                  placeholder={this.props.store.language.label385}
                  backgroundColor="black"
                  separatorColor="black"
                  className="my-1"
                />
              </Field>
              <Field
                label={"Servicio"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <Select
                  isMobile={!this.props.store.viewMobile}
                  name={"selectService"}
                  onChange={this.handleSelectChange}
                  options={catalogsOptions}
                  defaultValue={
                    catalogsOptions[
                      catalogsOptions
                        .map(function (e) {
                          return e.value;
                        })
                        .indexOf(this.style.free_rights)
                    ]
                  }
                  placeholder={this.props.store.language.label385}
                  backgroundColor="black"
                  separatorColor="black"
                  className="my-1"
                />
              </Field>
            </Column>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
              <Field
                label={"Humor minimo"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  type="number"
                  name="mood_min"
                  onChange={this.handleChangeFiled}
                  minlength={1}
                  maxlength={10}
                  value={this.style.mood_min}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
              <Field
                label={"Humor maximo"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  type="number"
                  name="mood_max"
                  onChange={this.handleChangeFiled}
                  minlength={1}
                  maxlength={10}
                  value={this.style.mood_max}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
              <Field
                label={"Humor predeterminado"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  type="number"
                  name="mood_pre"
                  onChange={this.handleChangeFiled}
                  minlength={1}
                  maxlength={10}
                  value={this.style.mood_pre}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </Column>
          </Columns>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

EditModal.propTypes = {
  style: PropTypes.object,
  catalogs: PropTypes.array,
  onClose: PropTypes.func,
};

EditModal.defaultProps = {
  style: null,
  catalogs: [],
  onClose: null,
};

export default withToastManager(withStore(EditModal));
