import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import withStore from "../../hocs/withStore";
import {
  Panel,
  Button,
  Text,
  Checkbox,
  Dropdown,
  DropdownToggle,
  DropdownPanel,
  DropdownMenu,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../Select";
import { withToastManager } from "react-toast-notifications";

@observer
class SongsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.song = props.song.clone();
  }

  handleSongInformationModelOpen = () => {
    const { onSongInformationClick, song } = this.props;
    onSongInformationClick && onSongInformationClick(song);
  };

  handleSongEditModelOpen = () => {
    const { onSongEditClick, song } = this.props;
    onSongEditClick && onSongEditClick(song);
  };

  handleSongReportsModelOpen = () => {
    const { onSongReportsClick, song } = this.props;
    onSongReportsClick && onSongReportsClick(song);
  };

  handleSongDeletedModelOpen = () => {
    const { onSongDeletedClick, song } = this.props;
    onSongDeletedClick && onSongDeletedClick(song);
  };

  handleUsersListenedSongsModelOpen = () => {
    const { onUsersListenedSongsClick, song } = this.props;
    onUsersListenedSongsClick && onUsersListenedSongsClick(song);
  };

  handleUsersTagSongModelOpen = () => {
    const { onUserTagSongClick, song, indice } = this.props;
    onUserTagSongClick && onUserTagSongClick(song, indice);
  };

  handleMarkedSong = () => {
    const { toastManager, onSongMarkedClick } = this.props;

    let params = {
      song_id: this.song.id,
    };

    this.props.store.kasimuAPIClient
      .putParams("/songs/put_marked_songs", params)
      .then(
        (res) => {
          toastManager.add(
            res.results.codeResponse == 2
              ? "La canción se marco exitosamente."
              : "La canción se desmarco exitosamente.",
            {
              appearance: "success",
              autoDismiss: true,
            }
          );
          //onSongMarkedClick && onSongMarkedClick();
        },
        (error) => {
          toastManager.add(
            "Error. algo salio mal al marcar o desmarcar la canción.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          this.setState({ isSaving: false });
        }
      )
      .catch((error) => {
        toastManager.add(
          "Error. algo salio mal al marcar o desmarcar la canción.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      });
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.song.save().andThen((res, responseError) => {
      if (responseError) {
        toastManager.add("Error, algo salió mal al editar la canción.", {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        toastManager.add("La canción se editó exitosamente.", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    });
  };

  renderCheckbox = (value, song) => {
    return (
      <>
        {value ? (
          () => ({})
        ) : (
          <Checkbox
            className="ml-3"
            size="lg"
            onChange={(sender, value) => {
              if (value) {
                this.props.arraySongId.push(song.id);
              } else {
                const index = this.props.arraySongId.indexOf(song.id);
                if (index > -1) {
                  this.props.arraySongId.splice(index, 1);
                }
              }
            }}
            isChecked={value}
          />
        )}
        {value && (
          <Checkbox
            className="ml-3"
            size="lg"
            onChange={(sender, value) => {
              if (value) {
                this.props.arraySongId.push(song.id);
              } else {
                const index = this.props.arraySongId.indexOf(song.id);
                if (index > -1) {
                  this.props.arraySongId.splice(index, 1);
                }
              }
            }}
            isChecked={value}
          />
        )}
      </>
    );
  };

  renderButtom = (value) => {
    return (
      <>
        {value ? (
          () => ({})
        ) : (
          <Button
            className="ml-3"
            icon="bookmark"
            kind="link"
            color={value ? "orange" : "special"}
            size="lg"
            lead
            space
            onClick={() => {
              if (value) this.song["marked_songs"] = false;
              else this.song["marked_songs"] = true;

              this.handleMarkedSong();
            }}
            //onClick={this.handleMarkedSong}
          />
        )}
        {value && (
          <Button
            className="ml-3"
            icon="bookmark"
            kind="link"
            color={value ? "orange" : "special"}
            size="lg"
            lead
            space
            onClick={() => {
              if (value) this.song["marked_songs"] = false;
              else this.song["marked_songs"] = true;

              this.handleMarkedSong();
            }}
            //onClick={this.handleMarkedSong}
          />
        )}
      </>
    );
  };

  moodEnabled = (key, moodMin, moodMax) => {
    if (key >= moodMin && key <= moodMax) return true;
    return false;
  };

  getStyle = (id) => {
    const { styles } = this.props;
    return styles.toArray().filter((style) => style.id == id)[0];
  };

  getColorStatus = (status) => {
    /*
      1: Cargada        
      2: Pendiente      
      3: Aprobada       
      4: Publicada      
      5: Modificada     
      6: Pre-Pendientes 
      7: Reparar        
    */
    const colors = {
      1: "statudLoaded",
      2: "statusSlope",
      3: "statusApproved",
      4: "statusPublished",
      5: "statusModified",
      6: "statusPreSlope",
      7: "statusRepair",
    };
    return colors[status];
  };

  render() {
    const {
      song,
      moodsOptions,
      stylesOptions,
      preferenceOptions,
      active,
      handleTogglePlay,
      indice,
      arraySongId,
      showButtonULS,
    } = this.props;
    const sOptions = stylesOptions.filter(
      (style) => style.free_rights == song.free_rights
    );
    return (
      <Panel
        id={indice}
        color={"gray"}
        padding={this.props.store.viewMobile ? "10px 30px" : "10px 20px"}
        className="mb-1"
      >
        <div
          className="is-row is-fullwidth"
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <div className="is-row is-fullwidth" style={{ alignItems: "center" }}>
            <div
              className="is-fullwidth"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="flex items-center">
                {this.props.store.ui.showPlayer && (
                  <Button
                    className={this.props.store.viewMobile ? "mr-3" : "mr-2"}
                    icon={
                      active && this.props.store.ui.isPlaying ? "pause" : "play"
                    }
                    kind="link"
                    color={active ? "primary" : "basic"}
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    onClick={() => handleTogglePlay(song, indice)}
                    id="play-button"
                    lead
                    space
                  />
                )}
                <Text
                  weight="normal"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color={active ? "primary" : "white"}
                  multiline
                >
                  <span style={{ fontWeight: "bold" }}>
                    {indice} | {song.name} -{" "}
                  </span>
                  <span style={{ fontWeight: "lighter", color: "#999999" }}>
                    {song.singer.name}
                  </span>
                </Text>
              </div>

              <Columns
                className="flex items-center mt-1"
                style={{ gap: "1rem" }}
              >
                <Column isSize={{ mobile: 12, tablet: 4, desktop: 4 }}>
                  <div
                    className="is-row is-fullwidth"
                    style={{
                      alignItems: this.props.store.viewMobile && "center",
                      flexDirection: !this.props.store.viewMobile && "column",
                    }}
                  >
                    <div className="mr-1">
                      <Text
                        size={this.props.store.viewMobile ? "lg" : "md"}
                        lineBreak
                      >
                        {"Humor:"}
                      </Text>
                    </div>
                    <Select
                      isMobile={!this.props.store.viewMobile}
                      isDisabled={!song.editing}
                      className="is-fullwidth"
                      placeholder
                      options={moodsOptions}
                      value={
                        moodsOptions[
                          moodsOptions
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(song.song_mood_id)
                        ]
                      }
                      isOptionDisabled={(moodsOptions) =>
                        true !==
                        this.moodEnabled(
                          moodsOptions.value,
                          song.song_styles.mood_min,
                          song.song_styles.mood_max
                        )
                      }
                      onChange={(value, sender) => {
                        var val = value.value;
                        this.song["song_mood_id"] = val;
                        this.handleSave();
                      }}
                      backgroundColor="#2E2E2E"
                    />
                  </div>
                </Column>
                <Column isSize={{ mobile: 12, tablet: 4, desktop: 4 }}>
                  <div
                    className="is-row is-fullwidth"
                    style={{
                      alignItems: this.props.store.viewMobile && "center",
                      flexDirection: !this.props.store.viewMobile && "column",
                    }}
                  >
                    <div className="mr-1">
                      <Text
                        size={this.props.store.viewMobile ? "lg" : "md"}
                        lineBreak
                      >
                        {"Pref:"}
                      </Text>
                    </div>
                    <Select
                      isMobile={!this.props.store.viewMobile}
                      isDisabled={!song.editing}
                      className="is-fullwidth"
                      placeholder
                      options={preferenceOptions}
                      value={
                        preferenceOptions[
                          preferenceOptions
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(song.preference)
                        ]
                      }
                      onChange={(value, sender) => {
                        var val = value.value;
                        this.song["preference"] = val;
                        this.handleSave();
                      }}
                      backgroundColor="#2E2E2E"
                    />
                  </div>
                </Column>
                <Column isSize={{ mobile: 12, tablet: 4, desktop: 4 }}>
                  <div
                    className="is-row is-fullwidth"
                    style={{
                      alignItems: this.props.store.viewMobile && "center",
                      flexDirection: !this.props.store.viewMobile && "column",
                    }}
                  >
                    <div className="mr-1">
                      <Text
                        size={this.props.store.viewMobile ? "lg" : "md"}
                        lineBreak
                      >
                        {"Estilo:"}
                      </Text>
                    </div>
                    <Select
                      isMobile={!this.props.store.viewMobile}
                      isDisabled={!song.editing}
                      className="is-fullwidth"
                      placeholder
                      options={sOptions}
                      value={
                        sOptions[
                          sOptions
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(song.song_styles_id)
                        ]
                      }
                      onChange={(value, sender) => {
                        var val = value.value;
                        this.song["song_styles_id"] = val;
                        var style = this.getStyle(val);
                        this.song["song_styles"].id = style.id;
                        this.song["song_styles"].song_lenguages_id =
                          style.song_lenguages_id;
                        this.song["song_styles"].song_mood_id =
                          style.song_mood_id;
                        this.song["song_styles"].description =
                          style.description;
                        this.song["song_styles"].enabled = style.enabled;
                        this.song["song_styles"].free_rights =
                          style.free_rights;
                        this.song["song_styles"].mood_min = style.mood_min;
                        this.song["song_styles"].mood_max = style.mood_max;
                        this.song["song_styles"].mood_pre = style.mood_pre;
                        this.song["song_styles"].status = style.status;
                        if (
                          this.song["song_mood_id"] >= style.mood_min &&
                          this.song["song_mood_id"] <= style.mood_max
                        ) {
                          this.song["song_mood_id"] = this.song["song_mood_id"];
                        } else {
                          this.song["song_mood_id"] = style.mood_pre;
                        }
                        this.handleSave();
                      }}
                      backgroundColor="#2E2E2E"
                    />
                  </div>
                </Column>
              </Columns>
            </div>
          </div>
          <div
            className="flex items-center"
            style={{
              flexDirection: !this.props.store.viewMobile && "column",
              rowGap: 8,
            }}
          >
            {song.music_lists && (
              <Text
                className="ml-3"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                lineBreak
              >
                {"L"}
              </Text>
            )}

            <div
              style={{ display: "flex", marginLeft: "20px" }}
              className="dropdown-buttons"
            >
              <Dropdown direction="right-center" space>
                <DropdownToggle disabledArrow>
                  <Button
                    kind="link"
                    color={
                      song.deleted == 1
                        ? "special"
                        : this.getColorStatus(song.status)
                    }
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    icon={"ellipsis-v"}
                    space
                  />
                </DropdownToggle>
                <DropdownPanel>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "4px 10px",
                    }}
                  >
                    <div>
                      <Button
                        icon="search-plus"
                        kind="link"
                        color="special"
                        size="lg"
                        lead
                        space
                        onClick={this.handleSongInformationModelOpen}
                      />
                    </div>
                    <div>
                      <Button
                        icon="database"
                        kind="link"
                        color="special"
                        size="lg"
                        lead
                        space
                        onClick={this.handleSongReportsModelOpen}
                      />
                    </div>
                    {song.editing &&
                    this.props.store.loggedInUser.checkPermission(18) ? (
                      <div>
                        <Button
                          icon="pen"
                          kind="link"
                          color="special"
                          size="lg"
                          lead
                          space
                          onClick={this.handleSongEditModelOpen}
                        />
                      </div>
                    ) : null}
                    {this.props.store.loggedInUser.checkPermission(15) ? (
                      <div>
                        <Button
                          icon="trash"
                          kind="link"
                          disabled={song.deleted == 1 ? true : false}
                          color={song.deleted == 1 ? "remove" : "special"}
                          size="lg"
                          lead
                          space
                          onClick={this.handleSongDeletedModelOpen}
                        />
                      </div>
                    ) : null}
                    {showButtonULS && (
                      <div>
                        <Button
                          icon="play"
                          kind="link"
                          disabled={song.deleted == 1 ? true : false}
                          color="special"
                          size="lg"
                          lead
                          space
                          onClick={this.handleUsersListenedSongsModelOpen}
                        />
                      </div>
                    )}
                    <div>
                      <Button
                        icon="tag"
                        kind="link"
                        disabled={song.deleted == 1 ? true : false}
                        color="special"
                        size="lg"
                        lead
                        space
                        onClick={this.handleUsersTagSongModelOpen}
                      />
                    </div>
                  </div>
                </DropdownPanel>
              </Dropdown>
            </div>

            {
              /*<Button
                className="ml-3"
                icon="bookmark"
                kind="link"
                color={song.marked_songs ? "orange" : "special"}
                size="lg"
                lead
                space
                onClick={() => {
                  if (song.marked_songs)
                    this.song["marked_songs"] = false;
                  else
                    this.song["marked_songs"] = true;
                  
                  this.handleMarkedSong();
                }}
                //onClick={this.handleMarkedSong}
              />*/
              this.renderButtom(this.song["marked_songs"])
            }

            {this.props.store.loggedInUser.checkPermission(9)
              ? this.renderCheckbox(arraySongId.includes(song.id), song)
              : null}
          </div>
        </div>
      </Panel>
    );
  }
}

SongsCard.propTypes = {
  song: PropTypes.object,
  styles: PropTypes.object,
  arraySongId: PropTypes.array,
  onSongInformationClick: PropTypes.func,
  onSongEditClick: PropTypes.func,
  onSongReportsClick: PropTypes.func,
  onSongDeletedClick: PropTypes.func,
  onSongMarkedClick: PropTypes.func,
  onUsersListenedSongsClick: PropTypes.func,
  onUserTagSongClick: PropTypes.func,
  showButtonULS: PropTypes.bool,
};

SongsCard.defaultProps = {
  song: null,
  styles: null,
  arraySongId: [],
  onSongInformationClick: null,
  onSongEditClick: null,
  onSongDeletedClick: null,
  onSongMarkedClick: null,
  onUsersListenedSongsClick: null,
  onUserTagSongClick: null,
  showButtonULS: false,
};

export default withStore(withToastManager(SongsCard));
