import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
} from "ks_storybook";
import { ProvidersSongs } from "../../models";
import { withToastManager } from "react-toast-notifications";

@observer
class ProvidersNewModal extends Component {
  @observable provider;
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.provider = this.newProvider();
  }

  newProvider = () => {
    const providersSongsStore = this.props.store.providersSongs;
    const attrs = {
      name: "",
      deleted: 0,
    };
    return new ProvidersSongs(attrs, providersSongsStore);
  };

  handleChange = (sender, value, name, validation) => {
    this.provider[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .search("/providers/get_name_exists", { name: this.provider.name })
      .then((res) => {
        if (res.results.exists) {
          toastManager.add("Error. el nombre del proveedor ya existe.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          this.provider.save().andThen((res, responseError) => {
            if (responseError) {
              toastManager.add(
                "Error. algo salio mal al agregar el proveedor.",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
            } else {
              toastManager.add("El proveedor se agrego exitosamente.", {
                appearance: "success",
                autoDismiss: true,
              });
            }
            this.handleClose();
          });
        }
      });
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              disabled={this.provider.name === ""}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "250px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            className="mb-1"
            weight="black"
            lead
          >
            {"Agregar proveedor"}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Field
            label={this.props.store.language.label53}
            labelNote={this.props.store.language.label76}
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            <TextInput
              name="name"
              onChange={this.handleChange}
              autocomplete="nope"
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={this.props.store.viewMobile && "20px"}
            />
          </Field>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

ProvidersNewModal.propTypes = {
  onClose: PropTypes.func,
};

ProvidersNewModal.defaultProps = {
  onClose: null,
};

export default withToastManager(withStore(ProvidersNewModal));
