import React, { Component } from "react";
import PropTypes from "prop-types";
import { Panel, Button, Text } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class TranslationCard extends Component {
  state = {
    open: false,
  };

  handleEdit = () => {
    const { obj, onEditClick } = this.props;
    onEditClick && onEditClick(obj);
  };

  handleDelete = () => {
    const { obj, onDeleteClick } = this.props;
    onDeleteClick && onDeleteClick(obj);
  };

  render() {
    const { obj } = this.props;

    return (
      <div
        style={{
          lineBreak: "anywhere",
          marginBottom: !this.props.store.viewMobile && "3px",
        }}
        className={this.props.store.viewMobile && "mb-1"}
      >
        <Panel
          color={"gray"}
          padding={this.props.store.viewMobile ? "16px 30px" : "14px 10px"}
          style={{
            minHeight: 85,
            justifyContent: "center",
            backgroundColor: this.state.open && "#D33F20",
            borderRadius: !this.props.store.viewMobile && "5px",
          }}
        >
          <div
            className="flex"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {this.state.open ? (
              <div className="flex">
                <Button
                  icon="pen"
                  kind="link"
                  color="basic"
                  size="xl"
                  lead
                  space
                  onClick={this.handleEdit}
                />
                <Button
                  data-for="trash"
                  icon="trash"
                  kind="link"
                  color="basic"
                  size="xl"
                  lead
                  space
                  onClick={this.handleDelete}
                  className="ml-3"
                />
              </div>
            ) : (
              <div>
                <div className="flex items-center flex-wrap">
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    className="mr-1"
                    weight="medium"
                  >
                    {"Idioma: "}
                  </Text>
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    color="special"
                    multiline
                  >
                    {obj.app_languages.name}
                  </Text>
                </div>
                <div className="flex items-center flex-wrap">
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    className="mr-1"
                    weight="medium"
                  >
                    {"Nombre: "}
                  </Text>
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    color="special"
                    multiline
                  >
                    {obj.alias}
                  </Text>
                </div>
                <div className="flex items-center flex-wrap">
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    className="mr-1"
                    weight="medium"
                  >
                    {"Descripción: "}
                  </Text>
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    color="special"
                    multiline
                  >
                    {obj.description}
                  </Text>
                </div>
              </div>
            )}

            {this.props.store.viewMobile ? (
              <div className="flex">
                <Button
                  icon="pen"
                  kind="link"
                  color="basic"
                  size={"lg"}
                  lead
                  space
                  onClick={this.handleEdit}
                />
                <Button
                  data-for="trash"
                  icon="trash"
                  kind="link"
                  color="basic"
                  size={"lg"}
                  lead
                  space
                  onClick={this.handleDelete}
                />
              </div>
            ) : (
              <div className="flex">
                {this.state.open ? (
                  <Button
                    kind="link"
                    color="basic"
                    size={"lg"}
                    iconKasimu={"arrow-left"}
                    space
                    onClick={() => this.setState({ open: false })}
                  />
                ) : (
                  <Button
                    kind="link"
                    color="special"
                    size={"lg"}
                    iconKasimu={"arrow-right"}
                    space
                    onClick={() => this.setState({ open: true })}
                  />
                )}
              </div>
            )}
          </div>
        </Panel>
      </div>
    );
  }
}

TranslationCard.propTypes = {
  obj: PropTypes.object,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

TranslationCard.defaultProps = {
  obj: null,
  onEditClick: null,
  onDeleteClick: null,
};

export default withStore(withToastManager(TranslationCard));
