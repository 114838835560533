import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import {
  Text,
  TextInput,
  Button,
  Panel,
  Loader,
  CardEvents,
  Columns,
  Column,
} from "ks_storybook";
import {
  EmptyContent,
  Pagination,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  ProvidersNewModal,
  InformationModal,
  EditModal,
  CSVModal,
  ProviderCard,
} from "../../../Components/Providers";

@observer
class ProvidersListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentProvider: null,
      showNewModal: false,
      showCSVModal: false,
      showInformationModal: false,
      showEditModal: false,
      showDeletedModal: false,
      page: 1,
      providers: this.getProvidersSearch(25, null, null),
      orders: {
        name: false,
      },
      orderList: [],
    };

    this.per_page = 25;
    this.search = null;
  }

  componentDidMount() {
    this.props.store.menuTitle = "Proveedores";
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  getProvidersSearch = (per_page, orderList, search) => {
    return this.props.store.providersSongs.fetchAllParams(
      per_page,
      orderList,
      search
    );
  };

  onPageChange = (page) => {
    const { orderList } = this.state;
    this.setState({
      providers: this.props.store.providersSongs.fetchPage(
        this.per_page,
        page,
        orderList.length == 0 ? null : orderList,
        this.search
      ),
      page: page,
    });
  };

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleInformationModalOpen = (provider) => {
    this.setState({ showInformationModal: true, currentProvider: provider });
  };

  handleInformationModalClose = () => {
    this.setState({ showInformationModal: false, currentProvider: null });
  };

  handleReportsModalOpen = (provider) => {
    this.setState({ showReportModal: true, currentProvider: provider });
  };

  handleReportsModalClose = () => {
    this.setState({ showReportModal: false, currentProvider: null });
  };

  handleEditModalOpen = (provider) => {
    this.setState({ showEditModal: true, currentProvider: provider });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false, currentProvider: null });
  };

  handleDeleteConfirmationOpen = (provider) => {
    this.setState({ showDeletedModal: true, currentProvider: provider });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedModal: false, currentProvider: null });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentProvider: null });
    this.reload();
  };

  reload = () => {
    this.setState({ providers: this.getProvidersSearch(25, null, null, null) });
  };

  handleCVSOpen = () => {
    this.setState({ showCSVModal: true });
  };

  handleCVSClose = () => {
    this.setState({ showCSVModal: false });
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  onSearch = () => {
    const { orderList } = this.state;
    if (this.search && this.search != " ") {
      this.setState({
        providers: this.props.store.providersSongs.fetchAllParams(
          this.per_page,
          orderList.length == 0 ? null : orderList,
          this.search
        ),
        page: 1,
      });
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  getIndexOrder = (order) => {
    return this.state.orderList.indexOf(order);
  };

  handleDescending = (name) => {
    const { orders, orderList } = this.state;
    let newOrders = orders;
    let orderLists = orderList;
    if (orderList.length == 0) {
      orderList.push("name");
    }
    if (name == "descName") {
      if (newOrders.name) {
        var index = this.getIndexOrder("-name");
        if (index !== -1) {
          orderLists[index] = "name";
        }
        newOrders.name = false;
      } else {
        var index = this.getIndexOrder("name");
        if (index !== -1) {
          orderLists[index] = "-name";
        }
        newOrders.name = true;
      }
    }
    this.setState({
      orders: newOrders,
      orderList: orderLists,
      providers: this.getProvidersSearch(this.per_page, orderList, this.search),
    });
  };

  getPanel = (result) => {
    return (
      <Columns isMultiline isVCentered className="pb-2">
        <Column
          className="flex items-center"
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
        >
          <div
            style={{
              maxWidth: "700px",
              width: "100%",
              marginRight: this.props.store.viewMobile ? "16px" : "6px",
            }}
          >
            <TextInput
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChangeSearch}
              placeholder={"Escribe aquí el nombre del proveedor a buscar..."}
              value={this.search && this.search}
              iconKasimu="search"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="is-fullwidth"
              backgroundColor="grayDark"
              colorIcon="gray"
              invert
            />
          </div>
          <Button
            className="is-pulled-right"
            kind="link"
            color="basic"
            size="xl"
            icon={
              this.state.orders.name ? "sort-alpha-down-alt" : "sort-alpha-down"
            }
            onClick={() => {
              this.handleDescending("descName");
            }}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderPaginator = (provider) => {
    if (provider.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            panel
            totalPages={provider.totalPages}
            currentPage={provider.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { providers } = this.state;
    if (!providers) return this.renderEmptyContent();
    return !providers.isOk() ? this.renderLoader() : this.renderBody();
  };

  renderRow = (providers) => {
    const arrayProvider = providers.toArray();
    return (
      <React.Fragment>
        {arrayProvider.length === 0 ? (
          <EmptyContent />
        ) : (
          <div className="mt-1">
            {arrayProvider.map((provider) => (
              <ProviderCard
                provider={provider}
                onViewClick={this.handleInformationModalOpen}
                onEditClick={this.handleEditModalOpen}
                onDeleteClick={this.handleDeleteConfirmationOpen}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  renderBody = () => {
    const {
      providers,
      currentProvider,
      orderList,
      showNewModal,
      showInformationModal,
      showEditModal,
      showDeletedModal,
      showCSVModal,
    } = this.state;
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <Columns className={this.props.store.viewMobile ? "pb-4" : "pb-3"}>
              <Column
                isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
                className="flex"
                style={{
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 14,
                }}
              >
                {this.props.store.viewMobile && (
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    lead
                    weight="black"
                    lineBreak
                  >
                    {"Proveedores"}
                  </Text>
                )}
                <div
                  className="flex items-center"
                  style={{ flexWrap: "wrap", gap: 14 }}
                >
                  {this.props.store.loggedInUser.checkPermission(32) ? (
                    <Button
                      icon={"file-csv"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      className="px-2 mx-0"
                      onClick={this.handleCVSOpen}
                    >
                      <strong>{"Exportar csv"}</strong>
                    </Button>
                  ) : null}
                  {this.props.store.loggedInUser.checkPermission(33) ? (
                    <Button
                      icon={"plus"}
                      size={this.props.store.viewMobile ? "lg" : "md"}
                      className="px-2 mx-0"
                      onClick={this.handleShowNewModal}
                    >
                      <strong>{"Agregar"}</strong>
                    </Button>
                  ) : null}
                </div>
              </Column>
            </Columns>
            {this.getPanel(providers.count)}
            <Text
              className="is-pulled-right"
              size="md"
              maxLength={this.props.mobile ? 50 : 250}
            >
              {"Resultados: " + providers.count}
            </Text>
            {this.renderRow(providers)}
            {this.renderPaginator(providers)}
          </Column>
          {showNewModal && (
            <ProvidersNewModal onClose={this.handleCloseNewModal} />
          )}
          {showCSVModal && (
            <CSVModal
              onClose={this.handleCVSClose}
              orderList={orderList.length == 0 ? null : orderList}
              search={this.search}
            />
          )}
          {showInformationModal && (
            <InformationModal
              provider={currentProvider}
              onClose={this.handleInformationModalClose}
            />
          )}
          {showEditModal && (
            <EditModal
              provider={currentProvider}
              onClose={this.handleEditModalClose}
            />
          )}
          {showDeletedModal && (
            <DeleteConfirmationModal
              currentObj={currentProvider}
              onClose={this.handleDeleteConfirmationModalClose}
              onAccept={this.handleAcceptDeleteConfirmationModal}
              messageSuccess={
                'El preveedor "' +
                currentProvider.name +
                '" se elimino exitosamente.'
              }
              messageError={
                'Error, algo salió mal al eliminar el preveedor "' +
                currentProvider.name +
                '".'
              }
              title={"Deseas eliminar \n" + currentProvider.name + "?"}
              subtitle={
                "Se eliminará el preveedor " + currentProvider.name + "."
              }
            />
          )}
        </Columns>
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>;
  }
}

export default withToastManager(withStore(ProvidersListPage));
