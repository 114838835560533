import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
} from "ks_storybook";
import { UsersTag } from "../../models";
import { withToastManager } from "react-toast-notifications";

@observer
class UserTagNewModal extends Component {
  @observable userTag;
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.userTag = this.newUserTag();
  }

  newUserTag = () => {
    const usersTagStore = this.props.store.usersTag;
    const attrs = {
      name: "",
      description: null,
      deleted: 0,
    };
    return new UsersTag(attrs, usersTagStore);
  };

  handleChange = (sender, value, name, validation) => {
    this.userTag[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleReload = () => {
    this.props.onReload && this.props.onReload();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .search("/users_tag/get_name_exists", { name: this.userTag.name })
      .then((res) => {
        if (res.results.exists) {
          toastManager.add("Error. ya existe una etiqueta con ese nombre.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          this.userTag.save().andThen((res, responseError) => {
            if (responseError) {
              toastManager.add(
                "Error. algo salio mal al agregar la etiqueta.",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
            } else {
              toastManager.add("La etiqueta se agrego exitosamente.", {
                appearance: "success",
                autoDismiss: true,
              });
            }
            this.handleReload();
          });
        }
      });
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              disabled={this.userTag.name === ""}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "340px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
            className="mb-1"
          >
            {"Agregar etiqueta"}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Field
            label={this.props.store.language.label53}
            labelNote={this.props.store.language.label76}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            NoteSize="xs"
            marginH={this.props.store.viewMobile && "4px"}
            weight="medium"
          >
            <TextInput
              name="name"
              onChange={this.handleChange}
              autocomplete="nope"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              backgroundColor="blackDark"
              borderColor="black"
              paddingInput={this.props.store.viewMobile && "20px"}
              className="is-fullwidth my-1"
            />
          </Field>

          <Field
            label={this.props.store.language.label79}
            labelNote={this.props.store.language.label78}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            NoteSize="xs"
            marginH={this.props.store.viewMobile && "4px"}
            weight="medium"
          >
            <TextInput
              name="description"
              onChange={this.handleChange}
              autocomplete="nope"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              backgroundColor="blackDark"
              borderColor="black"
              paddingInput={this.props.store.viewMobile && "20px"}
              className="is-fullwidth my-1"
            />
          </Field>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

UserTagNewModal.propTypes = {
  onClose: PropTypes.func,
  onReload: PropTypes.func,
};

UserTagNewModal.defaultProps = {
  onClose: null,
  onReload: null,
};

export default withToastManager(withStore(UserTagNewModal));
