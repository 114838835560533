import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
  Columns,
  Column,
} from "ks_storybook";
import { Select, SelectIcons } from "../Select";
import { withToastManager } from "react-toast-notifications";
import iconsOptions from "../Iconos/Icons.js";
import { AddTranslationsModal } from ".";

@observer
class NewCategoriesPlaylistsModal extends Component {
  @observable categoriesPlaylists;
  constructor(props) {
    super(props);

    this.state = {
      showAddTranslation: false,
      isSaving: false,
      name: null,
      messageError: null,
      translations: [],
      catalogs: this.getCatalogsAll(),
    };
    this.categoriesPlaylists = this.newCategoriesPlaylists();
  }

  newCategoriesPlaylists = () => {
    const { currentPlaylist } = this.props;
    const attrs = {
      playlist_id: currentPlaylist.id,
      category_id: null,
      alias: "",
      description: "",
      icon_path: "",
      free_rights: currentPlaylist.free_rights,
      status: 1,
      deleted: 0,
      translations: [],
    };
    return attrs;
  };

  getCatalogsAll = () => {
    return this.props.store.catalogs.fetchAll();
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.categoriesPlaylists[name] = value;
  };

  handleClose = () => {
    this.setState({ isSaving: false });
    this.props.onClose && this.props.onClose();
  };

  handleTranslationModelOpen = () => {
    this.setState({ showAddTranslation: true });
  };

  handleTranslationModelClose = () => {
    this.setState({ showAddTranslation: false });
  };

  handleTranslationModelSave = (translations) => {
    this.setState({ showAddTranslation: false, translations: translations });
  };

  getErrorMessager = (codError) => {
    const messager = {
      1: "Error, algo salio mal al guardar la categoría playlist",
      2: "Error. el nombre de la categoría playlist ya existe.",
    };
    return messager[codError];
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const { translations } = this.state;
    this.categoriesPlaylists.translations = translations.map((i) => i);
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .post(
          "/categories_playlist/save_params_panel",
          this.categoriesPlaylists
        )
        .then(
          (res) => {
            toastManager.add("Se guardo exitosamente la categoría playlist", {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onSave && this.props.onSave();
          },
          (error) => {
            let str = error.toString();
            str = str.replace("error: ", "");
            let mydatas = JSON.parse(str);
            toastManager.add(this.getErrorMessager(mydatas["codError"]), {
              appearance: "error",
              autoDismiss: true,
            });
          }
        )
        .catch((error) => {
          toastManager.add(this.getErrorMessager("1"), {
            appearance: "error",
            autoDismiss: true,
          });
        });
    });
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;
    switch (sender.name) {
      case "selectIcons":
        this.categoriesPlaylists["icon_path"] = value.label;
        break;
      case "selectCategories":
        this.categoriesPlaylists["category_id"] = val;
        break;
      default:
        break;
    }
  };

  renderButtons = () => {
    const { appLanguagesOptions } = this.props;
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              disabled={
                this.categoriesPlaylists.alias === ""
                  ? true
                  : !(
                      this.categoriesPlaylists.description &&
                      this.categoriesPlaylists.icon_path &&
                      this.categoriesPlaylists.category_id &&
                      this.state.translations.length ===
                        appLanguagesOptions.length
                    )
              }
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { showAddTranslation, translations } = this.state;
    const { appLanguagesOptions, categoriesOptions } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "650px" : "90%"}
          height={this.props.store.viewMobile ? "450px" : "auto"}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
              className="mb-2"
            >
              {"Agregar \n Categoría playlist"}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Field
              label={"Nombre"}
              labelNote={this.props.store.language.label76}
              marginH={this.props.store.viewMobile && "4px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                name="alias"
                onChange={this.handleChangeFiled}
                autocomplete="nope"
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={this.props.store.viewMobile && "20px"}
              />
            </Field>
            <Field
              label={"Descripción"}
              labelNote={this.props.store.language.label76}
              marginH={this.props.store.viewMobile && "4px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                name="description"
                onChange={this.handleChangeFiled}
                autocomplete="nope"
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={this.props.store.viewMobile && "20px"}
              />
            </Field>
            <Columns isMultiline>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                <Field
                  label={"Imagen"}
                  labelNote={this.props.store.language.label76}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <SelectIcons
                    isMobile={!this.props.store.viewMobile}
                    name={"selectIcons"}
                    onChange={this.handleSelectChange}
                    options={iconsOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="my-1"
                  />
                </Field>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                <Field
                  label={"Categoría"}
                  labelNote={this.props.store.language.label76}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectCategories"}
                    onChange={this.handleSelectChange}
                    options={categoriesOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="my-1"
                  />
                </Field>
              </Column>
            </Columns>

            <Field
              label={"Traducciones"}
              labelNote={
                this.props.store.language.label76 +
                ". " +
                "Numero de traducciones" +
                ": " +
                translations.length
              }
              marginH={this.props.store.viewMobile && "4px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <Button
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="px-3 my-1"
                onClick={this.handleTranslationModelOpen}
              >
                <strong>{this.props.store.language.label508}</strong>
              </Button>
            </Field>
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
        {showAddTranslation && (
          <AddTranslationsModal
            seeList={true}
            appLanguagesOptions={appLanguagesOptions}
            selected={translations}
            onClose={this.handleTranslationModelClose}
            onSave={this.handleTranslationModelSave}
          />
        )}
      </React.Fragment>
    );
  }
}

NewCategoriesPlaylistsModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

NewCategoriesPlaylistsModal.defaultProps = {
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(NewCategoriesPlaylistsModal));
