import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Loader,
  Button,
  Img,
  Columns,
  Column,
  Panel,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { EmptyContent, Pagination, DeleteConfirmationModal } from "../Common";
import { EditBlocksModal, NewBlocksModal, BlocksCard } from ".";

@observer
class BlocksModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentBlock: null,
      showNewModal: false,
      showDeletedModal: false,
      showEditModal: false,
      //categories: this.getCategoriesSearch(1000,{_status: null, list_view: null, free_rights: props.currentPlaylist.free_rights},null,null),
      blocks: this.getBlocksAll(50, props.currentPlaylist.id),
      styles: this.getStylesSearchFreeRightsAll(
        1000,
        props.currentPlaylist.free_rights
      ),
      moods: this.getSongMoodsAll(),
      per_page: 50,
      page: 1,
    };
  }

  getBlocksAll = (per_page, playlist_id) => {
    return this.props.store.blocks.fetchSearch(per_page, playlist_id);
  };

  getStylesSearchFreeRightsAll = (per_page, free_rights) => {
    return this.props.store.songStyles.fetchSearchFreeRights(
      per_page,
      free_rights
    );
  };

  getSongMoodsAll = () => {
    return this.props.store.songMoods.fetchAll();
  };

  getCategoriesSearch = (per_page, filters, orderList, search) => {
    return this.props.store.categories.fetchAllParams(
      per_page,
      filters,
      orderList,
      search
    );
  };

  onPageChange = (page) => {
    const { per_page } = this.state;
    this.setState({
      blocks: this.props.store.blocks.movePage(
        per_page,
        page,
        this.props.currentPlaylist.id
      ),
      page: page,
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleDeleteConfirmationOpen = (currentBlock) => {
    this.setState({ showDeletedModal: true, currentBlock: currentBlock });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedModal: false, currentBlock: null });
  };

  handleEditModalOpen = (currentBlock) => {
    this.setState({ showEditModal: true, currentBlock: currentBlock });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false, currentBlock: null });
  };

  handleEditModalSave = () => {
    this.setState({ showEditModal: false, currentBlock: null });
    this.reload();
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentBlock: null });
    this.reload();
  };

  handleNewModelOpen = () => {
    this.setState({ showNewModal: true });
  };

  handleNewModelClose = () => {
    this.setState({ showNewModal: false });
  };

  handleNewModelSave = () => {
    this.setState({ showNewModal: false });
    this.reload();
  };

  reload = () => {
    this.setState({
      blocks: this.getBlocksAll(25, this.props.currentPlaylist.id),
    });
  };

  getOptions = (obj) => {
    return obj.toArray().map((o) => ({
      value: o.id,
      label: o.description,
      mood_max: o.mood_max,
      mood_min: o.mood_min,
      mood_pre: o.mood_pre,
    }));
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <div>
        <Loader
          backgroundColor="transparent"
          icon="sync-alt"
          label={this.props.store.language.label42}
          animation="spin"
        />
      </div>
    );
  };

  renderPaginator = (reports) => {
    if (reports.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={reports.totalPages}
            currentPage={reports.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { blocks } = this.state;
    if (!blocks) return this.renderEmptyContent();

    return !blocks.isOk()
      ? this.renderLoader()
      : this.renderBlocks(blocks.toArray());
  };

  getGen = (key) => {
    const gen = { 0: "No", 1: "Si" };
    return gen[key];
  };

  renderBlocks = (blocks) => {
    return (
      <React.Fragment>
        <div style={this.getContainerStyle()}>
          {blocks.length > 0
            ? blocks.map((obj) => (
                <BlocksCard
                  obj={obj}
                  onEditClick={this.handleEditModalOpen}
                  onDeleteClick={this.handleDeleteConfirmationOpen}
                />
              ))
            : this.renderEmptyContent()}
        </div>
        {
          <Text className="is-pulled-right mt-1" size="xl" lineBreak>
            {"Resultado: " + this.state.blocks.count}
          </Text>
        }
        <br />
        {this.renderPaginator(this.state.blocks)}
      </React.Fragment>
    );
  };

  render() {
    const {
      currentBlock,
      showNewModal,
      showDeletedModal,
      showEditModal,
      styles,
      moods,
    } = this.state;
    const { currentPlaylist } = this.props;
    const stylesOptions = this.getOptions(styles);
    const moodsOptions = this.getOptions(moods);
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "650px" : "90%"}
          height={this.props.store.viewMobile ? "450px" : "auto"}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              weight="black"
              multiline
              lead
            >
              {"Bloques de la Playlist"}
              {"\n"}
              {currentPlaylist.description}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Button
              icon={"plus"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2 mb-2"
              onClick={this.handleNewModelOpen}
            >
              <strong>{"Agregar"}</strong>
            </Button>
            {this.renderHeader()}
          </ModalContent>
          <ModalFooter></ModalFooter>
        </Modal>
        {showNewModal && (
          <NewBlocksModal
            currentPlaylist={currentPlaylist}
            stylesOptions={stylesOptions}
            moodsOptions={moodsOptions}
            onSave={this.handleNewModelSave}
            onClose={this.handleNewModelClose}
          />
        )}
        {showEditModal && (
          <EditBlocksModal
            currentBlock={currentBlock}
            stylesOptions={stylesOptions}
            moodsOptions={moodsOptions}
            onSave={this.handleEditModalSave}
            onClose={this.handleEditModalClose}
          />
        )}
        {showDeletedModal && (
          <DeleteConfirmationModal
            currentObj={currentBlock}
            onClose={this.handleDeleteConfirmationModalClose}
            onAccept={this.handleAcceptDeleteConfirmationModal}
            messageSuccess={
              'El bloque con id "' +
              currentBlock.id +
              '" se elimino exitosamente.'
            }
            messageError={
              'Error, algo salió mal al eliminar el bloque con id "' +
              currentBlock.id +
              '".'
            }
            title={
              "Deseas eliminar el bloque con id \n" + currentBlock.id + "?"
            }
            subtitle={"Se eliminará el bloque con id " + currentBlock.id + "."}
          />
        )}
      </React.Fragment>
    );
  }
}

BlocksModal.propTypes = {
  currentPlaylist: PropTypes.object,
  onClose: PropTypes.func,
};

BlocksModal.defaultProps = {
  currentPlaylist: null,
  onClose: null,
};

export default withToastManager(withStore(BlocksModal));
