import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Columns,
  Column,
} from "ks_storybook";
import { MusicLists } from "../../models";
import { withToastManager } from "react-toast-notifications";

@observer
class ListsNewModal extends Component {
  @observable musicList;
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.musicList = this.newMusicLists();
  }

  newMusicLists = () => {
    const musicListsStore = this.props.store.musicLists;
    const attrs = {
      name: "",
      priority: 1,
      deleted: 0,
    };
    return new MusicLists(attrs, musicListsStore);
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.musicList[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .search("/music_lists/get_name_exists", { name: this.musicList.name })
      .then((res) => {
        if (res.results.exists) {
          toastManager.add(
            "Error. el nombre de la lista de éxitos ya existe.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        } else {
          this.musicList.save().andThen((res, responseError) => {
            if (responseError) {
              toastManager.add(
                "Error. algo salio mal al agregar la lista de éxitos.",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
            } else {
              toastManager.add("La lista de éxitos se agrego exitosamente.", {
                appearance: "success",
                autoDismiss: true,
              });
            }
            this.handleClose();
          });
        }
      });
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              disabled={this.musicList.name === ""}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "250px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            className="mb-1"
            weight="black"
            lead
          >
            {"Agregar \n lista de éxitos"}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Columns isMultiline>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
              <Field
                label={this.props.store.language.label53}
                labelNote={this.props.store.language.label76}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  name="name"
                  onChange={this.handleChangeFiled}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </Column>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
              <Field
                label={"Prioridad"}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  type="number"
                  name="priority"
                  onChange={this.handleChangeFiled}
                  minlength={1}
                  maxlength={100}
                  value={1}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </Column>
          </Columns>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

ListsNewModal.propTypes = {
  lastStyles: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

ListsNewModal.defaultProps = {
  lastStyles: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(ListsNewModal));
