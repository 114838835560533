import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import {
  Text,
  Button,
  Panel,
  Loader,
  CardEvents,
  Columns,
  Column,
} from "ks_storybook";
import {
  EmptyContent,
  Pagination,
  ConfirmationModal,
} from "../../../Components/Common";
import { CSVModal, InformationModal } from "../../../Components/Update";
import { observable } from "mobx";
import { VersionUpdated } from "../../../models";
import moment from "moment";

@observer
class GenerateUpdatePage extends Component {
  @observable versionUpdate;
  constructor(props) {
    super(props);

    this.state = {
      isPreGen: false,
      isGen: false,
      currentUpdated: null,
      showCSVModal: false,
      showInformationModal: false,
      showConfirmationModal: false,
      updates: this.getAll(25),
    };
    this.per_page = 25;
    this.versionUpdate = this.newVersionUpdate();
  }

  componentDidMount() {
    this.props.store.menuTitle = "Generar actualización";
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  newVersionUpdate = () => {
    const versionUpdatedStore = this.props.store.versionUpdated;
    const attrs = {
      upgrade_path: "",
      deleted: 0,
    };
    return new VersionUpdated(attrs, versionUpdatedStore);
  };

  getAll = (per_page) => {
    return this.props.store.versionUpdated.fetchAll(per_page);
  };

  onPageChange = (page) => {
    this.setState({
      updates: this.props.store.versionUpdated.movePage(this.per_page, page),
      page: page,
    });
  };

  preGenerate = () => {
    this.setState({ isPreGen: true }, () => {
      this.genServer(1);
    });
  };

  generate = () => {
    this.handleConfirmationModalClose();
    this.setState({ isGen: true }, () => {
      this.genServer(2);
    });
  };

  genServer = (type) => {
    const { toastManager } = this.props;
    let momenStr = moment(new Date()).format("YYYYMMDDHHmmss");
    this.props.store.kasimuAPIClient
      .getCSV("/version_updated/get_sql_server/")
      .then((result) => {
        if (result.status == 200) {
          result.content.then((res) => {
            const exportName = "S_" + momenStr + ".sql" || "s_export.sql";
            const blob = new Blob([res], { type: "text/plain;charset=utf8;" });
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName);
            } else {
              const link = document.createElement("a");
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportName);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
            toastManager.add(
              "SQL para el servidor generado y descargado exitosamente.",
              {
                appearance: "success",
                autoDismiss: true,
              }
            );
            this.genProgram(type, momenStr);
          });
        } else {
          toastManager.add(
            "Error, algo salió mal generando y descargando el SQL para el servidor, o no hay actualización disponible.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          if (type == 1) {
            this.setState({ isPreGen: false });
          } else {
            this.setState({ isGen: false });
          }
        }
      })
      .catch((error) => {
        toastManager.add(
          "Error, algo salió mal generando y descargando el SQL para el servidor, o no hay actualización disponible.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        if (type == 1) {
          this.setState({ isPreGen: false });
        } else {
          this.setState({ isGen: false });
        }
      });
  };

  genProgram = (type, momenStr) => {
    const { toastManager } = this.props;
    this.props.store.kasimuAPIClient
      .getCSV("/version_updated/get_sql_program/?type=" + type)
      .then((result) => {
        if (result.status == 200) {
          result.content.then((res) => {
            const exportName = "P_" + momenStr + ".sql" || "p_export.sql";
            const blob = new Blob([res], { type: "text/plain;charset=utf8;" });
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName);
            } else {
              const link = document.createElement("a");
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportName);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
            toastManager.add(
              "SQL para el programa generado y descargado exitosamente.",
              {
                appearance: "success",
                autoDismiss: true,
              }
            );
            if (type == 1) {
              this.setState({ isPreGen: false });
            } else {
              this.versionUpdate["upgrade_path"] = momenStr + ".sql";
              this.handleSave();
            }
          });
        } else {
          toastManager.add(
            "Error, algo salió mal generando y descargando el SQL para el programa.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          if (type == 1) {
            this.setState({ isPreGen: false });
          } else {
            this.setState({ isGen: false });
          }
        }
      })
      .catch((error) => {
        toastManager.add(
          "Error, algo salió mal generando y descargando el SQL para el programa.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        if (type == 1) {
          this.setState({ isPreGen: false });
        } else {
          this.setState({ isGen: false });
        }
      });
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.versionUpdate.save().andThen((res, responseError) => {
      if (responseError) {
        toastManager.add(
          "Error. algo salio mal al agregar el la actualización.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      } else {
        toastManager.add("La actualización se agrego exitosamente.", {
          appearance: "success",
          autoDismiss: true,
        });
        this.reload();
      }
      this.setState({ isGen: false });
    });
  };

  handleInformationModalOpen = (updated) => {
    this.setState({ showInformationModal: true, currentUpdated: updated });
  };

  handleInformationModalClose = () => {
    this.setState({ showInformationModal: false, currentUpdated: null });
  };

  handleConfirmationModalOpen = () => {
    this.setState({ showConfirmationModal: true });
  };

  handleConfirmationModalClose = () => {
    this.setState({ showConfirmationModal: false });
  };

  reload = () => {
    this.setState({ updates: this.getAll(25) });
  };

  handleCVSOpen = () => {
    this.setState({ showCSVModal: true });
  };

  handleCVSClose = () => {
    this.setState({ showCSVModal: false });
  };

  getPanel = (result) => {
    return (
      <>
        {this.state.isPreGen ? (
          <Button
            className="px-2 mx-0"
            size={this.props.store.viewMobile ? "lg" : "md"}
            disabled
            icon="spinner"
            pulse
          >
            <strong>{"Generando..."}</strong>
          </Button>
        ) : (
          <Button
            icon={"file-download"}
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-2 mx-0"
            onClick={this.preGenerate}
          >
            <strong>{"Pre Generar"}</strong>
          </Button>
        )}
        {this.state.isGen ? (
          <Button
            className="px-2 mx-0"
            size={this.props.store.viewMobile ? "lg" : "md"}
            disabled
            icon="spinner"
            pulse
          >
            <strong>{"Generando..."}</strong>
          </Button>
        ) : (
          <Button
            icon={"file-download"}
            size={this.props.store.viewMobile ? "lg" : "md"}
            className="px-2 mx-0"
            onClick={this.handleConfirmationModalOpen}
          >
            <strong>{"Generar"}</strong>
          </Button>
        )}
        <Button
          icon={"file-csv"}
          size={this.props.store.viewMobile ? "lg" : "md"}
          className="px-2 mx-0"
          onClick={this.handleCVSOpen}
        >
          <strong>{"Exportar csv"}</strong>
        </Button>
      </>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderPaginator = (updates) => {
    if (updates.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            panel
            totalPages={updates.totalPages}
            currentPage={updates.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { updates } = this.state;
    if (!updates) return this.renderEmptyContent();
    return !updates.isOk() ? this.renderLoader() : this.renderBody();
  };

  renderRow = (updates) => {
    const array = updates.toArray();
    return (
      <React.Fragment>
        {array.length === 0 ? (
          <EmptyContent />
        ) : (
          <div className="mt-1">
            {array.map((obj) => (
              <div
                style={{
                  lineBreak: "anywhere",
                  marginBottom: !this.props.store.viewMobile && "3px",
                }}
                className={this.props.store.viewMobile && "mb-1"}
              >
                <CardEvents
                  name={obj.upgrade_path}
                  isMobile={!this.props.store.viewMobile}
                  padding={!this.props.store.viewMobile && "22px 20px"}
                  style={{
                    height: 65,
                    backgroundColor: this.state.open && "#D33F20",
                    borderRadius: !this.props.store.viewMobile && "5px",
                  }}
                  upper={false}
                >
                  <div className="flex">
                    <Button
                      icon="search-plus"
                      kind="link"
                      color="basic"
                      size={this.props.store.viewMobile ? "lg" : "xl"}
                      lead
                      space
                      onClick={() => {
                        this.handleInformationModalOpen(obj);
                      }}
                    />
                  </div>
                </CardEvents>
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  renderBody = () => {
    const {
      updates,
      currentUpdated,
      showCSVModal,
      showInformationModal,
      showConfirmationModal,
    } = this.state;
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <Columns className={this.props.store.viewMobile ? "pb-4" : "pb-3"}>
              <Column
                isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
                className="flex"
                style={{
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 14,
                }}
              >
                {this.props.store.viewMobile && (
                  <Text
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    lead
                    weight="black"
                    lineBreak
                  >
                    {"Generar actualización"}
                  </Text>
                )}
                <div
                  className="flex items-center"
                  style={{ flexWrap: "wrap", gap: 14 }}
                >
                  {this.getPanel(updates.count)}
                </div>
              </Column>
            </Columns>

            {this.renderRow(updates)}
            {this.renderPaginator(updates)}
          </Column>
          {showCSVModal && <CSVModal onClose={this.handleCVSClose} />}
          {showInformationModal && (
            <InformationModal
              update={currentUpdated}
              onClose={this.handleInformationModalClose}
            />
          )}
          {showConfirmationModal && (
            <ConfirmationModal
              title="Generar actualización"
              onClose={this.handleConfirmationModalClose}
              onAccept={this.generate}
              subtitle="Los datos cambian su estado ha publicado luego de generar la actualización, estas seguro de generar la actualización definitiva?"
            />
          )}
        </Columns>
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>;
  }
}

export default withToastManager(withStore(GenerateUpdatePage));
