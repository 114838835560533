import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  LayoutSideMenuLink,
  LayoutSideMenu,
  Text,
  Button,
  SidebarMenu,
  SidebarMenuLink,
} from "ks_storybook";
import withStore from "../../hocs/withStore";
import logo from "../../assets/images/logo_on_white.png";

class ContentManagerMenu extends Component {
  state = {
    value: "",
    show: true,
  };

  handleChangeShow = (val) => {
    if (this.state.value !== val) this.setState({ value: val });
    else this.setState({ value: "" });
  };

  getPathMusic = () => {
    let path = "";

    if (this.props.store.loggedInUser.checkPermission(48)) {
      path = "categories";
    } else if (this.props.store.loggedInUser.checkPermission(49)) {
      path = "playlists";
    } else if (this.props.store.loggedInUser.checkPermission(3)) {
      path = "songs";
    } else if (this.props.store.loggedInUser.checkPermission(4)) {
      path = "interpreters";
    } else if (this.props.store.loggedInUser.checkPermission(5)) {
      path = "estilos_musicales";
    }
    return path;
  };

  getPathCompanies = () => {
    let path = "";

    if (this.props.store.loggedInUser.checkPermission(6)) {
      path = "providers";
    } else if (this.props.store.loggedInUser.checkPermission(7)) {
      path = "lists";
    } else if (this.props.store.loggedInUser.checkPermission(8)) {
      path = "record_label";
    }
    return path;
  };

  renderMultiUserMenu = () => {
    const { handleChangeShow } = this;
    const { value, show } = this.state;
    const { handleToogle } = this.props;
    const path = this.props.match.path;

    let pathMusic = this.getPathMusic();
    let pathCompanies = this.getPathCompanies();

    return [
      <React.Fragment>
        <div
          className={
            this.props.store.viewMobile
              ? "media-content pb-4 px-2"
              : "media-content pb-3 pt-3 px-2"
          }
        >
          <Text size="md" color="white" weight="strong" lead lineBreak upper>
            {"Gestor \nde\n Contenido"}
          </Text>
        </div>

        {this.props.store.loggedInUser.checkPermission(1) ? (
          <SidebarMenu
            title={"Procesos"}
            to={`${path}processes`}
            titleClick={() => {
              handleChangeShow("procesos");
              handleToogle();
            }}
            show={value == "procesos"}
            icon="spinner"
          />
        ) : null}
        <SidebarMenu
          title={"Etiquetas"}
          to={`${path}userstags`}
          titleClick={() => handleChangeShow("tags")}
          show={value == "tags"}
          icon="tag"
          arrowRight
          activation
        >
          <div className="media-content">
            <SidebarMenuLink
              to={`${path}userstags`}
              titleClick={() => handleChangeShow("tags")}
              onClick={handleToogle}
              show={value == "tags"}
              icon="tag"
            >
              {"Mis etiquetas"}
            </SidebarMenuLink>
            {this.props.store.loggedInUser.checkPermission(50) ? (
              <SidebarMenuLink
                to={`${path}assign_tagged_songs_user_style`}
                titleClick={() => handleChangeShow("assign_tagged")}
                onClick={handleToogle}
                show={value == "assign_tagged"}
                icon="plus"
              >
                {"Asignar canciones"}
              </SidebarMenuLink>
            ) : null}
          </div>
        </SidebarMenu>
        <SidebarMenu
          title={"Musica"}
          to={`${path}${pathMusic}`}
          titleClick={() => handleChangeShow(pathMusic)}
          show={value == pathMusic}
          iconKasimu="playlist-solid"
          arrowRight
          activation
        >
          <div className="media-content">
            {this.props.store.loggedInUser.checkPermission(48) ? (
              <SidebarMenuLink
                to={`${path}categories`}
                titleClick={() => handleChangeShow("categories")}
                onClick={handleToogle}
                show={value == "categories"}
                icon="image"
              >
                {"Categorías"}
              </SidebarMenuLink>
            ) : null}
            {this.props.store.loggedInUser.checkPermission(49) ? (
              <SidebarMenuLink
                to={`${path}playlists`}
                titleClick={() => handleChangeShow("playlists")}
                onClick={handleToogle}
                show={value == "playlists"}
                iconKasimu="playlist-solid"
              >
                {"Playlists"}
              </SidebarMenuLink>
            ) : null}
            {this.props.store.loggedInUser.checkPermission(3) ? (
              <SidebarMenuLink
                to={`${path}songs`}
                titleClick={() => handleChangeShow("canciones")}
                onClick={handleToogle}
                show={value == "canciones"}
                iconKasimu="song"
              >
                {"Canciones"}
              </SidebarMenuLink>
            ) : null}
            {this.props.store.loggedInUser.checkPermission(4) ? (
              <SidebarMenuLink
                to={`${path}interpreters`}
                titleClick={() => handleChangeShow("interpretes")}
                onClick={handleToogle}
                show={value == "interpretes"}
                iconKasimu="user-solid"
              >
                {"Intérpretes"}
              </SidebarMenuLink>
            ) : null}
            {this.props.store.loggedInUser.checkPermission(5) ? (
              <SidebarMenuLink
                to={`${path}musical_styles`}
                titleClick={() => handleChangeShow("estilos_musicales")}
                onClick={handleToogle}
                show={value == "estilos_musicales"}
                iconKasimu="music"
              >
                {"Estilos musicales"}
              </SidebarMenuLink>
            ) : null}
          </div>
        </SidebarMenu>

        <SidebarMenu
          title={"Compañias"}
          to={`${path}${pathCompanies}`}
          titleClick={() => handleChangeShow(pathCompanies)}
          show={value == pathCompanies}
          icon="building"
          arrowRight
          activation
        >
          <div className="media-content">
            {this.props.store.loggedInUser.checkPermission(6) ? (
              <SidebarMenuLink
                to={`${path}providers`}
                titleClick={() => handleChangeShow("proveedores")}
                onClick={handleToogle}
                show={value == "proveedores"}
                icon="building"
              >
                {"Proveedores"}
              </SidebarMenuLink>
            ) : null}
            {this.props.store.loggedInUser.checkPermission(7) ? (
              <SidebarMenuLink
                to={`${path}lists`}
                titleClick={() => handleChangeShow("listas")}
                onClick={handleToogle}
                show={value == "listas"}
                icon="star"
              >
                {"Listas de éxitos"}
              </SidebarMenuLink>
            ) : null}
            {this.props.store.loggedInUser.checkPermission(8) ? (
              <SidebarMenuLink
                to={`${path}record_label`}
                titleClick={() => handleChangeShow("sellos_discográficos")}
                onClick={handleToogle}
                show={value == "sellos_discográficos"}
                icon="dot-circle"
              >
                {"Sellos discográficos"}
              </SidebarMenuLink>
            ) : null}
          </div>
        </SidebarMenu>
        {this.props.store.loggedInUser.checkPermission(2) ? (
          <SidebarMenu
            title={"Generar actualización"}
            to={`${path}generate_update`}
            titleClick={() => {
              handleChangeShow("generar_actualizacion");
              handleToogle();
            }}
            show={value == "generar_actualizacion"}
            iconKasimu="download"
          />
        ) : null}
      </React.Fragment>,
    ];
  };

  render() {
    var menu = [];
    menu.push(this.renderMultiUserMenu());
    return <React.Fragment>{menu}</React.Fragment>;
  }
}

export default withStore(withRouter(ContentManagerMenu));
