import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Field,
  Columns,
  Column,
} from "ks_storybook";
import moment from "moment";

@observer
class InformationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getStatus = (key) => {
    const status = { 1: "Cargado", 2: "Publicado", 3: "Modificado" };
    return status[key];
  };

  getService = (free_rights) => {
    const { catalogsOptions } = this.props;
    const arr = catalogsOptions.filter(function (e) {
      return free_rights == e.value;
    });
    return arr ? arr[0].label : null;
  };

  render() {
    const { style } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "600px" : "90%"}
        height={this.props.store.viewMobile ? "250px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            weight="black"
            lead
          >
            {style.description}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Columns className="my-1" isMultiline isGapless>
            <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Id: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {style.id}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Descripción: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {style.description}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Idioma: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {style.song_lenguages.description}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Activo: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {style.enabled == 1 ? "Si" : "No"}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Servicio: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {this.getService(style.free_rights)}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Estado: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {this.getStatus(style.status)}
                </Text>
              </div>
            </Column>
            <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Humor minimo: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {style.mood_min}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Humor maximo: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {style.mood_max}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Humor predeterminado: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {style.mood_pre}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Fecha de creación: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {moment(style.created_at).format("DD-MM-YYYY HH:mm:ss")}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Fecha de modificación: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {moment(style.updated_at).format("DD-MM-YYYY HH:mm:ss")}
                </Text>
              </div>
            </Column>
          </Columns>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

InformationModal.propTypes = {
  style: PropTypes.object,
  onClose: PropTypes.func,
};

InformationModal.defaultProps = {
  style: null,
  onClose: null,
};

export default withStore(InformationModal);
