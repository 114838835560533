import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class CSVImportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      messageError: null,
      file: null,
    };
  }

  handleFileChange = (e) => {
    if (!e || !e.target || !e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];

    if (file) {
      const fileName = file.name;
      const ext = this.getExtension(fileName);
      if (ext != "csv" && ext != "CSV") {
        this.setState({ messageError: "Error, (Solo formato csv)" });
        return;
      }
      this.setState({ file: file });
    }
  };

  getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .postCSV("/music_lists/post_csv/", this.state.file)
        .then((result) => {
          if (result.status == 200) {
            toastManager.add("CSV generado y descargado exitosamente.", {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            toastManager.add("Error, algo salió mal al subir el csv.", {
              appearance: "error",
              autoDismiss: true,
            });
          }
          this.handleClose();
        })
        .catch((error) => {
          toastManager.add("Error, algo salió mal al subir el csv.", {
            appearance: "error",
            autoDismiss: true,
          });
        });
    });
  };

  getDivUploadStyle = () => ({
    minHeight: "300px",
    border: "2px dashed #ccc",
    borderRadius: "16px",
    position: "relative",
  });

  getInputStyle = () => ({
    position: "absolute",
    margin: 0,
    padding: 0,
    width: "100%",
    height: "100%",
    outline: "none",
    opacity: 0,
  });

  getPStyle = () => ({
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "28px",
    whiteSpace: "pre-line",
    color: "#ccc",
    fontSize: "32px",
    lineHeight: "32px",
    fontWeight: "bold",
  });

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{"Cargando csv..."}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.state.file}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{"Cargar csv"}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "550px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            weight="black"
            lead
          >
            {"Cargar csv"}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            size={this.props.store.viewMobile ? "xl" : "lg"}
            color="special"
            lineBreak
          >
            {
              "El formato del contenido csv debe ser: (semana;fecha;idlista;posicion;idtema). \n Las listas de exitos deben estar registradas previamente antes de cargar su contenido."
            }
          </Text>
          <br />
          <div style={this.getDivUploadStyle()}>
            <input
              style={this.getInputStyle()}
              type="file"
              onChange={this.handleFileChange}
              accept=".csv"
            />
            <p id="p" style={this.getPStyle()}>
              {this.state.file === null
                ? (
                    "+\n" + "Arrastra \n un csv \n o haz clic \n en esta área"
                  ).toUpperCase()
                : (
                    this.props.store.language.label53 +
                    "\n" +
                    this.state.file.name +
                    "\n\n" +
                    this.props.store.language.label337 +
                    "\n" +
                    this.state.file.size +
                    "MB"
                  ).toUpperCase()}
              <span
                className="ml-1"
                style={{ fontSize: "18px", fontWeight: "normal" }}
              >
                {this.state.file === null ? "(solo csv)" : null}
              </span>
            </p>
          </div>
          {this.state.messageError && (
            <>
              <br />
              <Panel color="error" className="mt-2" invert>
                <Text multiline>{this.state.messageError}</Text>
              </Panel>
            </>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

CSVImportModal.propTypes = {
  onClose: PropTypes.func,
};

CSVImportModal.defaultProps = {
  onClose: null,
};

export default withToastManager(withStore(CSVImportModal));
