import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Columns,
  Column,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class EditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.singer = props.singer.clone();
  }

  handleChangeFiled = (sender, value, name, validation) => {
    this.singer[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.singer.save().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add("Error, algo salió mal al editar el intérprete.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          toastManager.add("El intérprete se editó exitosamente.", {
            appearance: "success",
            autoDismiss: true,
          });
        }
        this.handleClose();
      });
    });
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={this.singer.name === ""}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "300px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            className="mb-2"
            weight="black"
            lineBreak
            lead
          >
            {this.props.store.language.label140}
            {"\n"}
            {" Id: " + this.singer.id}
            {"\n"}
            {this.singer.name}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Columns isMultiline>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
              <Field
                label={this.props.store.language.label53}
                marginH={this.props.store.viewMobile && "4px"}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                weight="medium"
              >
                <TextInput
                  name="name"
                  onChange={this.handleChangeFiled}
                  value={this.singer.name}
                  autocomplete="nope"
                  backgroundColor="blackDark"
                  borderColor="black"
                  className="is-fullwidth my-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  paddingInput={this.props.store.viewMobile && "20px"}
                />
              </Field>
            </Column>
          </Columns>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

EditModal.propTypes = {
  singer: PropTypes.object,
  onClose: PropTypes.func,
};

EditModal.defaultProps = {
  singer: null,
  onClose: null,
};

export default withToastManager(withStore(EditModal));
