import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Loader,
  Button,
  Img,
  Columns,
  Column,
  Panel,
} from "ks_storybook";
import { Image, Media, MediaLeft, MediaContent, Content } from "bloomer";
import { withToastManager } from "react-toast-notifications";
import { EmptyContent, Pagination, DeleteConfirmationModal } from "../Common";
import {
  TranslationsModal,
  EditCategoriesPlaylistsModal,
  NewCategoriesPlaylistsModal,
  CategoryPlaylistCard,
} from ".";

@observer
class CategoriesPlaylistsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCategoriesPlaylists: null,
      showTranslationsModal: false,
      showNewModal: false,
      showDeletedModal: false,
      showEditModal: false,
      categories: this.getCategoriesSearch(
        1000,
        {
          _status: null,
          list_view: null,
          free_rights: props.currentPlaylist.free_rights,
        },
        null,
        null
      ),
      categoriesPlaylists: this.getCategoriesPlaylistsAll(
        25,
        props.currentPlaylist.id
      ),
      per_page: 25,
      page: 1,
    };
  }

  getCategoriesPlaylistsAll = (per_page, playlist_id) => {
    return this.props.store.categoriesPlaylists.fetchSearch(
      per_page,
      playlist_id
    );
  };

  getCategoriesSearch = (per_page, filters, orderList, search) => {
    return this.props.store.categories.fetchAllParams(
      per_page,
      filters,
      orderList,
      search
    );
  };

  onPageChange = (page) => {
    const { per_page } = this.state;
    this.setState({
      categoriesPlaylists: this.props.store.categoriesPlaylists.movePage(
        per_page,
        page,
        this.props.currentPlaylist.id
      ),
      page: page,
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleTranslationsModalOpen = (currentCategoriesPlaylists) => {
    this.setState({
      showTranslationsModal: true,
      currentCategoriesPlaylists: currentCategoriesPlaylists,
    });
  };

  handleTranslationsModalClose = () => {
    this.setState({
      showTranslationsModal: false,
      currentCategoriesPlaylists: null,
    });
  };

  handleDeleteConfirmationOpen = (currentCategoriesPlaylists) => {
    this.setState({
      showDeletedModal: true,
      currentCategoriesPlaylists: currentCategoriesPlaylists,
    });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({
      showDeletedModal: false,
      currentCategoriesPlaylists: null,
    });
  };

  handleEditModalOpen = (currentCategoriesPlaylists) => {
    this.setState({
      showEditModal: true,
      currentCategoriesPlaylists: currentCategoriesPlaylists,
    });
  };

  handleEditModalClose = () => {
    this.setState({ showEditModal: false, currentCategoriesPlaylists: null });
  };

  handleEditModalSave = () => {
    this.setState({ showEditModal: false, currentCategoriesPlaylists: null });
    this.reload();
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({
      showDeletedModal: false,
      currentCategoriesPlaylists: null,
    });
    this.reload();
  };

  handleNewModelOpen = () => {
    this.setState({ showNewModal: true });
  };

  handleNewModelClose = () => {
    this.setState({ showNewModal: false });
  };

  handleNewModelSave = () => {
    this.setState({ showNewModal: false });
    this.reload();
  };

  reload = () => {
    this.setState({
      categoriesPlaylists: this.getCategoriesPlaylistsAll(
        25,
        this.props.currentPlaylist.id
      ),
    });
  };

  getOptionsCategories = (obj) => {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        backgroundColor="transparent"
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  renderPaginator = (reports) => {
    if (reports.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={reports.totalPages}
            currentPage={reports.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { categoriesPlaylists } = this.state;
    if (!categoriesPlaylists) return this.renderEmptyContent();
    return !categoriesPlaylists.isOk()
      ? this.renderLoader()
      : this.renderCategoriesPlaylists(categoriesPlaylists.toArray());
  };

  getGen = (key) => {
    const gen = { 0: "No", 1: "Si" };
    return gen[key];
  };

  renderCategoriesPlaylists = (categoriesPlaylists) => {
    return (
      <React.Fragment>
        <br />
        <br />
        <div style={this.getContainerStyle()}>
          {/* {categoriesPlaylists.length > 0
            ? categoriesPlaylists.map((obj) => (
                <Panel
                  color="gray"
                  padding="18px 24px"
                  className="mb-1"
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    overflowWrap: "anywhere",
                  }}
                >
                  <div className="flex items-center">
                    <div
                      className="flex mr-2"
                      style={{ flexGrow: 0, flexShrink: 0 }}
                    >
                      <Img
                        width={this.props.store.viewMobile ? "124px" : "54px"}
                        height={this.props.store.viewMobile ? "124px" : "54px"}
                        src={obj.icon_path}
                      />
                    </div>
                    <div>
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        lineBreak
                      >
                        {"Id: " + obj.id + " | Categoria: " + obj.category.name}
                      </Text>
                      <Text
                        ssize={this.props.store.viewMobile ? "xl" : "lg"}
                        lineBreak
                      >
                        {"Nombre: " + obj.alias}
                      </Text>
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        lineBreak
                      >
                        {"Descripción: " + obj.description}
                      </Text>
                    </div>
                  </div>

                  <div className="flex">
                    <Button
                      className="is-pulled-right ml-3"
                      icon="language"
                      kind="link"
                      color="basic"
                      size="lg"
                      lead
                      space
                      onClick={() => {
                        this.handleTranslationsModalOpen(obj);
                      }}
                    />
                    <Button
                      className="is-pulled-right ml-3"
                      icon="pen"
                      kind="link"
                      color="basic"
                      size="lg"
                      lead
                      space
                      onClick={() => {
                        this.handleEditModalOpen(obj);
                      }}
                    />
                    <Button
                      data-for="trash"
                      className="is-pulled-right ml-3"
                      icon="trash"
                      kind="link"
                      color="basic"
                      size="lg"
                      lead
                      space
                      onClick={() => {
                        this.handleDeleteConfirmationOpen(obj);
                      }}
                    />
                  </div>
                </Panel>
              ))
            : this.renderEmptyContent()} */}
          {categoriesPlaylists.length > 0
            ? categoriesPlaylists.map((obj) => (
                <CategoryPlaylistCard
                  obj={obj}
                  onTranslationClick={this.handleTranslationsModalOpen}
                  onEditClick={this.handleEditModalOpen}
                  onDeleteClick={this.handleDeleteConfirmationOpen}
                />
              ))
            : this.renderEmptyContent()}
        </div>

        <Text className="is-pulled-right mt-1" size="xl" lineBreak>
          {"Resultado: " + this.state.categoriesPlaylists.count}
        </Text>

        {this.renderPaginator(this.state.categoriesPlaylists)}
      </React.Fragment>
    );
  };

  render() {
    const {
      currentCategoriesPlaylists,
      showTranslationsModal,
      showNewModal,
      showDeletedModal,
      showEditModal,
      categories,
    } = this.state;
    const { currentPlaylist, appLanguagesOptions } = this.props;
    const categoriesOptions = this.getOptionsCategories(categories);
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "650px" : "90%"}
          height={this.props.store.viewMobile ? "500px" : "auto"}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
              className="mb-1"
              multiline
              lineBreak
            >
              {"Categorias Playlists"}
              {"\n"}
              {"Playlist: " + currentPlaylist.description}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Button
              icon={"plus"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              onClick={this.handleNewModelOpen}
            >
              <strong>{"Agregar"}</strong>
            </Button>
            {this.renderHeader()}
          </ModalContent>
          <ModalFooter></ModalFooter>
        </Modal>
        {showNewModal && (
          <NewCategoriesPlaylistsModal
            currentPlaylist={currentPlaylist}
            categoriesOptions={categoriesOptions}
            appLanguagesOptions={appLanguagesOptions}
            onSave={this.handleNewModelSave}
            onClose={this.handleNewModelClose}
          />
        )}
        {showEditModal && (
          <EditCategoriesPlaylistsModal
            currentCategoriesPlaylists={currentCategoriesPlaylists}
            categoriesOptions={categoriesOptions}
            onSave={this.handleEditModalSave}
            onClose={this.handleEditModalClose}
          />
        )}
        {showTranslationsModal && (
          <TranslationsModal
            currentCategoriesPlaylists={currentCategoriesPlaylists}
            appLanguagesOptions={appLanguagesOptions}
            onClose={this.handleTranslationsModalClose}
          />
        )}
        {showDeletedModal && (
          <DeleteConfirmationModal
            currentObj={currentCategoriesPlaylists}
            onClose={this.handleDeleteConfirmationModalClose}
            onAccept={this.handleAcceptDeleteConfirmationModal}
            messageSuccess={
              'La categoria playlist "' +
              currentCategoriesPlaylists.alias +
              '" se elimino exitosamente.'
            }
            messageError={
              'Error, algo salió mal al eliminar la categoria playlist "' +
              currentCategoriesPlaylists.alias +
              '".'
            }
            title={
              "Deseas eliminar la categoria playlist \n" +
              currentCategoriesPlaylists.alias +
              "?"
            }
            subtitle={
              "Se eliminará la categoria playlist " +
              currentCategoriesPlaylists.alias +
              "."
            }
          />
        )}
      </React.Fragment>
    );
  }
}

CategoriesPlaylistsModal.propTypes = {
  currentPlaylist: PropTypes.object,
  onClose: PropTypes.func,
};

CategoriesPlaylistsModal.defaultProps = {
  currentPlaylist: null,
  onClose: null,
};

export default withToastManager(withStore(CategoriesPlaylistsModal));
