import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Loader,
  Button,
  Panel,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { EmptyContent, Pagination } from "../Common";
import { CSVExportContentsModal } from ".";

@observer
class ContentsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRestore: false,
      showCSVModal: false,
      music_lists_contents: this.getMusicListsContentsAll(
        10,
        this.props.musicList.id
      ),
      per_page: 10,
      page: 1,
    };
  }

  getMusicListsContentsAll = (per_page, music_lists_id) => {
    return this.props.store.musicListsContents.fetchSearch(
      per_page,
      music_lists_id
    );
  };

  onPageChange = (page) => {
    const { per_page } = this.state;
    this.setState({
      music_lists_contents: this.props.store.musicListsContents.movePage(
        per_page,
        page,
        this.props.musicList.id
      ),
      page: page,
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleCVSOpen = () => {
    this.setState({ showCSVModal: true });
  };

  handleCVSClose = () => {
    this.setState({ showCSVModal: false });
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <div>
        <Loader
          backgroundColor="transparent"
          icon="sync-alt"
          label={this.props.store.language.label42}
          animation="spin"
        />
      </div>
    );
  };

  renderPaginator = (music_lists_contents) => {
    if (music_lists_contents.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={music_lists_contents.totalPages}
            currentPage={music_lists_contents.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { music_lists_contents } = this.state;
    if (!music_lists_contents) return this.renderEmptyContent();
    return !music_lists_contents.isOk()
      ? this.renderLoader()
      : this.renderMusicListsContents(music_lists_contents.toArray());
  };

  getGen = (key) => {
    const gen = { 0: "No", 1: "Si" };
    return gen[key];
  };

  renderMusicListsContents = (music_lists_contents) => {
    return (
      <React.Fragment>
        <div style={this.getContainerStyle()}>
          {music_lists_contents.length > 0
            ? music_lists_contents.map((obj) => (
                <div
                  style={{
                    lineBreak: "anywhere",
                    marginBottom: !this.props.store.viewMobile && "3px",
                  }}
                  className={this.props.store.viewMobile && "mb-1"}
                >
                  <Panel
                    color={"gray"}
                    padding={
                      this.props.store.viewMobile ? "16px 30px" : "14px 10px"
                    }
                    style={{
                      minHeight: 65,
                      justifyContent: "center",
                      backgroundColor: this.state.open && "#D33F20",
                      borderRadius: !this.props.store.viewMobile && "5px",
                    }}
                  >
                    <div
                      className="flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        weight="medium"
                        lineBreak
                      >
                        {"Fecha: " +
                          obj.date +
                          " | Semana: " +
                          obj.week +
                          " | Posición: " +
                          obj.position +
                          " | Canción: " +
                          obj.song.name}
                      </Text>
                    </div>
                  </Panel>
                </div>
              ))
            : this.renderEmptyContent()}
        </div>
        {this.state.music_lists_contents.count > 0 && (
          <Text
            className="is-pulled-right mt-1"
            size={this.props.store.viewMobile ? "xl" : "lg"}
            lineBreak
          >
            {"Resultado: " + this.state.music_lists_contents.count}
          </Text>
        )}
        <br />
        {this.renderPaginator(this.state.music_lists_contents)}
      </React.Fragment>
    );
  };

  render() {
    const { musicList } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "800px" : "90%"}
          height={this.props.store.viewMobile ? "650px" : "auto"}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              weight="black"
              lineBreak
              lead
            >
              {"Contenido de la lista de éxitos"}
              {"\n"}
              {" Id: " + musicList.id}
              {"\n"}
              {musicList.name}
            </Text>
          </ModalHeader>
          <ModalContent>
            {this.props.store.loggedInUser.checkPermission(40)
              ? this.state.music_lists_contents.count > 0 && (
                  <Button
                    icon={"file-csv"}
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="is-pulled-right px-2 mb-2"
                    onClick={this.handleCVSOpen}
                  >
                    <strong>{"Exportar csv"}</strong>
                  </Button>
                )
              : null}
            {this.renderHeader()}
          </ModalContent>
          <ModalFooter></ModalFooter>
        </Modal>
        {this.state.showCSVModal && (
          <CSVExportContentsModal
            onClose={this.handleCVSClose}
            music_lists_id={musicList.id}
          />
        )}
      </React.Fragment>
    );
  }
}

ContentsModal.propTypes = {
  musicList: PropTypes.object,
  onClose: PropTypes.func,
};

ContentsModal.defaultProps = {
  musicList: null,
  onClose: null,
};

export default withToastManager(withStore(ContentsModal));
