import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Panel,
} from "ks_storybook";
import { TranslationCategories } from "../../models";
import { Select } from "../Select";
import { withToastManager } from "react-toast-notifications";

@observer
class AddTranslationsModal extends Component {
  @observable translation;
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      name: null,
      messageError: null,
      translations: this.props.selected,
      language: null,
    };
    this.translation = this.newTranslation();
  }

  newTranslation = () => {
    const { seeList, currentCategory } = this.props;
    if (seeList) {
      const attrs = {
        app_languages_id: -1,
        alias: "",
        description: "",
      };
      return attrs;
    } else {
      const translationCategoriesStore = this.props.store.translationCategories;
      const attrs = {
        app_languages_id: -1,
        category_id: currentCategory.id,
        alias: "",
        description: "",
        deleted: 0,
      };
      return new TranslationCategories(attrs, translationCategoriesStore);
    }
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.translation[name] = value;
    this.setState({ isSaving: false });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleAdd = () => {
    const { translations } = this.state;
    const newTranslations = translations;
    newTranslations.push(this.translation);
    this.translation = this.newTranslation();
    this.setState({ translations: newTranslations, language: null });
  };

  handleSave = () => {
    const { seeList, toastManager } = this.props;
    if (seeList === true) {
      const { translations } = this.state;
      const newTranslations = translations;
      this.props.onSave && this.props.onSave(newTranslations);
    } else {
      this.translation.save().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add("Error. algo salio mal al agregar la traducción.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          toastManager.add("La traducción se agrego exitosamente.", {
            appearance: "success",
            autoDismiss: true,
          });
        }
        this.props.onSave && this.props.onSave();
      });
    }
  };

  arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele.app_languages_id != value;
    });
  };

  handleRemove = (app_languages_id) => {
    const { translations } = this.state;
    const newTranslations = translations;
    var temp = this.arrayRemove(newTranslations, app_languages_id);
    this.setState({ translations: temp });
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;
    const l = value.label;

    switch (sender.name) {
      case "selectLanguage":
        this.translation["app_languages_id"] = val;
        this.setState({ language: value });
        break;
      default:
        break;
    }
    this.setState({ isSaving: false });
  };

  renderButtons = () => {
    const { seeList } = this.props;
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {seeList &&
            (this.state.isSaving ? (
              <Button
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="is-pulled-right px-2"
                disabled
                icon="spinner"
                pulse
              >
                <strong>{"Agregando..."}</strong>
              </Button>
            ) : (
              <Button
                icon={"plus"}
                disabled={
                  this.translation.alias === ""
                    ? true
                    : !(
                        this.translation.description &&
                        this.translation.app_languages_id != -1
                      )
                }
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="is-pulled-right px-2"
                onClick={this.handleAdd}
              >
                <strong>{"Agregar"}</strong>
              </Button>
            ))}
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  getNameLang = (app_languages_id) => {
    const { appLanguagesOptions } = this.props;
    const arr = appLanguagesOptions.filter(function (e) {
      return app_languages_id == e.value;
    });
    return arr ? arr[0].label : null;
  };

  renderTranslations = (translations) => {
    return (
      <React.Fragment>
        <br />
        {translations.length > 0
          ? translations.map((obj) => (
              <Panel
                color="gray"
                padding="18px 24px"
                className="mb-1"
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  overflowWrap: "anywhere",
                }}
              >
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="medium"
                  lineBreak
                >
                  {"Idioma: " +
                    this.getNameLang(obj.app_languages_id) +
                    " | Nombre: " +
                    obj.alias +
                    " | Descripción: " +
                    obj.description}
                </Text>
                <div style={{ display: "flex" }}>
                  <Button
                    data-for="trash"
                    className="is-pulled-right ml-1"
                    icon="trash"
                    kind="link"
                    color="basic"
                    size={this.props.store.viewMobile ? "lg" : "lg"}
                    lead
                    space
                    onClick={() => {
                      this.handleRemove(obj.app_languages_id);
                    }}
                  />
                </div>
              </Panel>
            ))
          : null}
        <br />
      </React.Fragment>
    );
  };

  filterLang = (arr1, arr2) => {
    var intersection = arr1.filter(function (e) {
      return (
        arr2
          .map(function (e) {
            return e.app_languages_id;
          })
          .indexOf(e.value) === -1
      );
    });
    return intersection;
  };

  render() {
    const { appLanguagesOptions, seeList } = this.props;
    const { translations, language } = this.state;

    let temp = this.filterLang(appLanguagesOptions, translations);

    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "400px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            className="mb-1"
            weight="black"
            lead
          >
            {"Agregar traducción"}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Field
            label={"Idioma"}
            labelNote={this.props.store.language.label76}
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            <Select
              isMobile={!this.props.store.viewMobile}
              name={"selectLanguage"}
              onChange={this.handleSelectChange}
              options={temp}
              placeholder={this.props.store.language.label385}
              value={language}
              backgroundColor="black"
              separatorColor="black"
              className="is-fullwidth my-1"
            />
          </Field>
          <Field
            label={"Nombre"}
            labelNote={this.props.store.language.label76}
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            <TextInput
              name="alias"
              value={this.translation.alias}
              onChange={this.handleChangeFiled}
              autocomplete="nope"
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={this.props.store.viewMobile && "20px"}
            />
          </Field>
          <Field
            label={"Descripción"}
            labelNote={this.props.store.language.label76}
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            <TextInput
              name="description"
              value={this.translation.description}
              onChange={this.handleChangeFiled}
              autocomplete="nope"
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={this.props.store.viewMobile && "20px"}
            />
          </Field>
          <Field
            label={"Traducciones"}
            labelNote={
              this.props.store.language.label76 +
              ". " +
              "Numero de traducciones" +
              ": " +
              translations.length
            }
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            {seeList && this.renderTranslations(translations)}
          </Field>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

AddTranslationsModal.propTypes = {
  selected: PropTypes.Array,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

AddTranslationsModal.defaultProps = {
  selected: [],
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(AddTranslationsModal));
