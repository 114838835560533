import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import { Columns, Column } from "bloomer";
import {
  Text,
  TextInput,
  Button,
  Panel,
  Loader,
  CardEvents,
} from "ks_storybook";
import {
  EmptyContent,
  Pagination,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  InformationModal,
  UserTagNewModal,
  TagCard,
} from "../../../Components/UserTags";

@observer
class TagPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUserTag: null,
      showNewModal: false,
      showInformationModal: false,
      showDeletedModal: false,
      page: 1,
      userTags: this.getUserTagsSearch(25, null, null),
      filters: {
        _status: null,
      },
      orders: {
        _status: false,
      },
      orderList: [],
    };
    this.per_page = 25;
    this.search = null;
  }

  componentDidMount() {
    this.props.store.menuTitle = "Mis etiquetas";
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  getUserTagsSearch = (per_page, orderList, search) => {
    return this.props.store.usersTag.fetchAllParams(
      per_page,
      orderList,
      search
    );
  };

  onPageChange = (page) => {
    const { orderList } = this.state;
    this.setState({
      userTags: this.props.store.usersTag.fetchPage(
        this.per_page,
        page,
        orderList.length == 0 ? null : orderList,
        this.search
      ),
      page: page,
    });
  };

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleInformationModalOpen = (userTag) => {
    this.setState({ showInformationModal: true, currentUserTag: userTag });
  };

  handleInformationModalClose = () => {
    this.setState({ showInformationModal: false, currentUserTag: null });
  };

  handleDeleteConfirmationOpen = (userTag) => {
    this.setState({ showDeletedModal: true, currentUserTag: userTag });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedModal: false, currentUserTag: null });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentUserTag: null });
    this.reload();
  };

  reload = () => {
    this.setState({
      userTags: this.getUserTagsSearch(25, null, null),
      showNewModal: false,
    });
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  onSearch = () => {
    const { orderList } = this.state;
    if (this.search && this.search != " ") {
      this.setState({
        userTags: this.props.store.usersTag.fetchAllParams(
          this.per_page,
          orderList.length == 0 ? null : orderList,
          this.search
        ),
        page: 1,
      });
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  getIndexOrder = (order) => {
    return this.state.orderList.indexOf(order);
  };

  handleDescending = (name) => {
    const { filters, orders, orderList } = this.state;
    let newOrders = orders;
    let orderLists = orderList;
    if (orderList.length == 0) {
      orderList.push("name");
    }
    if (name == "descStatus") {
      if (newOrders._status) {
        var index = this.getIndexOrder("-name");
        if (index !== -1) {
          orderLists[index] = "name";
        }
        newOrders._status = false;
      } else {
        var index = this.getIndexOrder("name");
        if (index !== -1) {
          orderLists[index] = "-name";
        }
        newOrders._status = true;
      }
    }
    this.setState({
      orders: newOrders,
      orderList: orderLists,
      userTags: this.getUserTagsSearch(this.per_page, orderList, this.search),
    });
  };

  getPanel = (result) => {
    return (
      <Columns className="pb-2">
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
          className="flex"
        >
          <div className="mr-2" style={{ maxWidth: "700px", width: "100%" }}>
            <TextInput
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChangeSearch}
              placeholder={"Escribe aquí el nombre de la etiqueta a buscar..."}
              value={this.search && this.search}
              iconKasimu="search"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="is-fullwidth"
              backgroundColor="grayDark"
              colorIcon="gray"
              invert
            />
          </div>

          <Button
            icon={"plus"}
            size={this.props.store.viewMobile ? "lg" : "md"}
            onClick={this.handleShowNewModal}
            className="px-2"
          >
            <strong>{"Agregar"}</strong>
          </Button>
          <Button
            kind="link"
            color="basic"
            size="xl"
            icon={
              this.state.orders._status
                ? "sort-alpha-down-alt"
                : "sort-alpha-down"
            }
            onClick={() => {
              this.handleDescending("descStatus");
            }}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderPaginator = (userTags) => {
    if (userTags.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            panel
            totalPages={userTags.totalPages}
            currentPage={userTags.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { userTags } = this.state;
    if (!userTags) return this.renderEmptyContent();
    return !userTags.isOk() ? this.renderLoader() : this.renderBody();
  };

  getDescription = (description) => {
    if (description) return description;
    return "Sin Descripción";
  };

  renderRow = (userTags) => {
    const arrayUserTags = userTags.toArray();
    return (
      <React.Fragment>
        {arrayUserTags.length === 0 ? (
          <EmptyContent />
        ) : (
          <div className="mt-1">
            {arrayUserTags.map((tag) => (
              <TagCard
                onViewClick={this.handleInformationModalOpen}
                onDeleteClick={this.handleDeleteConfirmationOpen}
                getDescription={this.getDescription}
                tag={tag}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  renderBody = () => {
    const {
      userTags,
      currentUserTag,
      orderList,
      showNewModal,
      showInformationModal,
      showDeletedModal,
    } = this.state;
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="mb-2"
                weight="black"
                lineBreak
                lead
              >
                {"Mis etiquetas"}
              </Text>
            )}

            {this.getPanel(userTags.count)}
            <Text
              className="is-pulled-right"
              size="md"
              maxLength={this.props.mobile ? 50 : 250}
            >
              {"Resultados: " + userTags.count}
            </Text>
            {this.renderRow(userTags)}
            {this.renderPaginator(userTags)}
          </Column>
          {showNewModal && (
            <UserTagNewModal
              onClose={this.handleCloseNewModal}
              onReload={this.reload}
            />
          )}
          {showInformationModal && (
            <InformationModal
              userTag={currentUserTag}
              onClose={this.handleInformationModalClose}
            />
          )}
          {showDeletedModal && (
            <DeleteConfirmationModal
              currentObj={currentUserTag}
              onClose={this.handleDeleteConfirmationModalClose}
              onAccept={this.handleAcceptDeleteConfirmationModal}
              messageSuccess={
                'La etiqueta "' +
                currentUserTag.name +
                '" se elimino exitosamente.'
              }
              messageError={
                'Error, algo salió mal al eliminar la etiqueta "' +
                currentUserTag.name +
                '".'
              }
              title={"Deseas eliminar \n" + currentUserTag.name + "?"}
              subtitle={
                "Se eliminará la etiqueta " +
                currentUserTag.name +
                " y de todas las canciones asociadas."
              }
            />
          )}
        </Columns>
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>;
  }
}

export default withToastManager(withStore(TagPage));
