import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { observable } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../Select";
import { SongStyles } from "../../models";
import { withToastManager } from "react-toast-notifications";
import { AddTranslationsModal } from ".";

@observer
class MusicalStylesNewModal extends Component {
  @observable songStyle;
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      name: null,
      translations: [],
      messageError: null,
    };
    this.songStyle = this.newSongStyle();
  }

  newSongStyle = () => {
    const songStylesStore = this.props.store.songStyles;
    const attrs = {
      song_lenguages_id: 1,
      song_mood_id: 10,
      description: "",
      enabled: 1,
      free_rights: -1,
      mood_min: 1,
      mood_max: 1,
      mood_pre: 1,
      status: 1,
      deleted: 0,
      translations: [],
    };
    return new SongStyles(attrs, songStylesStore);
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.songStyle[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleTranslationModelOpen = () => {
    this.setState({ showAddTranslation: true });
  };

  handleTranslationModelClose = () => {
    this.setState({ showAddTranslation: false });
  };

  handleTranslationModelSave = (translations) => {
    this.setState({ showAddTranslation: false, translations: translations });
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const { translations } = this.state;
    this.songStyle.translations = translations.map((i) => i);
    this.props.store.kasimuAPIClient
      .search("/song_styles/get_description_exists", {
        description: this.songStyle.description,
      })
      .then((res) => {
        if (res.results.exists) {
          toastManager.add("Error. el nombre del estilo musical ya existe.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          this.songStyle.save().andThen((res, responseError) => {
            if (responseError) {
              toastManager.add(
                "Error. algo salio mal al agregar el estilo musical.",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
            } else {
              toastManager.add("El estilo musical se agrego exitosamente.", {
                appearance: "success",
                autoDismiss: true,
              });
            }
            this.handleClose();
          });
        }
      });
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;

    switch (sender.name) {
      case "selectLanguage":
        this.songStyle["song_lenguages_id"] = val;
        break;
      case "selectMood":
        this.songStyle["song_mood_id"] = val;
        break;
      case "selectEnabled":
        if (val == 2) this.songStyle["enabled"] = 0;
        else this.songStyle["enabled"] = 1;
        break;
      case "selectService":
        this.songStyle["free_rights"] = val;
        break;
      default:
        break;
    }
  };

  renderButtons = () => {
    const { appLanguagesOptions } = this.props;
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              disabled={
                this.songStyle.description === ""
                  ? true
                  : !(
                      this.songStyle.song_mood_id &&
                      this.songStyle.free_rights != -1 &&
                      this.state.translations.length ===
                        appLanguagesOptions.length
                    )
              }
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { showAddTranslation, translations } = this.state;
    const { languagesOptions, catalogsOptions, appLanguagesOptions } =
      this.props;
    const genOptions = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" },
    ];
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "650px" : "90%"}
          height={this.props.store.viewMobile ? "500px" : "auto"}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              claasName="mb-2"
              weight="black"
              lead
            >
              {"Agregar \n estilo musical"}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Columns isMultiline>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                <Field
                  label={this.props.store.language.label53}
                  labelNote={this.props.store.language.label76}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <TextInput
                    name="description"
                    onChange={this.handleChangeFiled}
                    autocomplete="nope"
                    backgroundColor="blackDark"
                    borderColor="black"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </Field>
                <Field
                  label={"Idioma"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectLanguage"}
                    onChange={this.handleSelectChange}
                    options={languagesOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    claasName="my-1"
                  />
                </Field>
                {/*<Field marginH='10px' label={'Humor'} size="xl" NoteSize="xs" upper>
                                    <Select
                isMobile={!this.props.store.viewMobile}
                                        name={'selectMood'}
                                        onChange={this.handleSelectChange}
                                        options={moodsOptions}
                                        placeholder={this.props.store.language.label385}
                                        backgroundColor='#FFFFFF'
                                        separatorColor='#f7f7f7'
                                        iconColor='black'
                                        textColor='#000000'
                                        />
                                </Field>*/}
                <Field
                  label={"Activo"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectEnabled"}
                    onChange={this.handleSelectChange}
                    options={genOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    claasName="my-1"
                  />
                </Field>
                <Field
                  label={"Servicio"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectService"}
                    onChange={this.handleSelectChange}
                    options={catalogsOptions}
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    claasName="my-1"
                  />
                </Field>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                <Field
                  label={"Humor minimo"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <TextInput
                    type="number"
                    name="mood_min"
                    onChange={this.handleChangeFiled}
                    minlength={1}
                    maxlength={10}
                    value={1}
                    autocomplete="nope"
                    backgroundColor="blackDark"
                    borderColor="black"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </Field>
                <Field
                  label={"Humor maximo"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <TextInput
                    type="number"
                    name="mood_max"
                    onChange={this.handleChangeFiled}
                    minlength={1}
                    maxlength={10}
                    value={1}
                    autocomplete="nope"
                    backgroundColor="blackDark"
                    borderColor="black"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </Field>
                <Field
                  label={"Humor predeterminado"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <TextInput
                    type="number"
                    name="mood_pre"
                    onChange={this.handleChangeFiled}
                    minlength={1}
                    maxlength={10}
                    value={1}
                    autocomplete="nope"
                    backgroundColor="blackDark"
                    borderColor="black"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </Field>
                <Field
                  label={"Traducciones"}
                  labelNote={
                    this.props.store.language.label76 +
                    ". " +
                    "Numero de traducciones" +
                    ": " +
                    translations.length
                  }
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Button
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    className="px-3 my-1"
                    onClick={this.handleTranslationModelOpen}
                  >
                    <strong>{this.props.store.language.label508}</strong>
                  </Button>
                </Field>
              </Column>
            </Columns>
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
        {showAddTranslation && (
          <AddTranslationsModal
            seeList={true}
            appLanguagesOptions={appLanguagesOptions}
            selected={translations}
            onClose={this.handleTranslationModelClose}
            onSave={this.handleTranslationModelSave}
          />
        )}
      </React.Fragment>
    );
  }
}

MusicalStylesNewModal.propTypes = {
  onClose: PropTypes.func,
};

MusicalStylesNewModal.defaultProps = {
  onClose: null,
};

export default withToastManager(withStore(MusicalStylesNewModal));
