import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import { Columns, Column } from "bloomer";
import {
  Text,
  TextInput,
  Button,
  Panel,
  Loader,
  CardEvents,
} from "ks_storybook";
import { Select } from "../../../Components/Select";
import {
  EmptyContent,
  Pagination,
  DeleteConfirmationModal,
} from "../../../Components/Common";
import {
  InformationModal,
  AssignSongsUserStylesModal,
} from "../../../Components/UserTags";

@observer
class AssignSongsToStylePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSongsAssigned: null,
      showNewModal: false,
      showInformationModal: false,
      showDeletedModal: false,
      page: 1,
      songsAssigned: this.getSongsAssignedSearch(25, null, null),
      userTags: this.getUserTagsSearch(),
      historyConsultedUsers: this.getHistoryConsultedUsers(),
      filters: {
        _status: null,
        apip_username: null,
      },
      orders: {
        _status: false,
      },
      orderList: [],
    };
    this.per_page = 25;
    this.search = null;
  }

  componentDidMount() {
    this.props.store.menuTitle = "Asignar Canciones";
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  getSongsAssignedSearch = (per_page, filters, orderList, search) => {
    return this.props.store.songsAssignedUserStyles.fetchAllParams(
      per_page,
      filters,
      orderList,
      search
    );
  };

  getUserTagsSearch = () => {
    return this.props.store.usersTag.fetchAll();
  };

  getHistoryConsultedUsers = () => {
    return this.props.store.apipHistoryConsultedUsers.fetchAll();
  };

  getOptionsMyTags = (obj) => {
    return obj.toArray().map((o) => ({ value: o.id, label: o.name }));
  };

  getOptionsAPIPUsername = (obj) => {
    return obj
      .toArray()
      .map((o) => ({ value: o.apip_username, label: o.apip_username }));
  };

  onPageChange = (page) => {
    const { orderList } = this.state;
    this.setState({
      songsAssigned: this.props.store.songsAssignedUserStyles.fetchPage(
        this.per_page,
        page,
        this.validateFilter(filters),
        orderList.length == 0 ? null : orderList,
        this.search
      ),
      page: page,
    });
  };

  handleShowNewModal = () => {
    this.setState({ showNewModal: true });
  };

  handleCloseNewModal = () => {
    this.setState({ showNewModal: false });
  };

  handleInformationModalOpen = (currentSongsAssigned) => {
    this.setState({
      showInformationModal: true,
      currentSongsAssigned: currentSongsAssigned,
    });
  };

  handleInformationModalClose = () => {
    this.setState({ showInformationModal: false, currentSongsAssigned: null });
  };

  handleDeleteConfirmationOpen = (currentSongsAssigned) => {
    this.setState({
      showDeletedModal: true,
      currentSongsAssigned: currentSongsAssigned,
    });
  };

  handleDeleteConfirmationModalClose = () => {
    this.setState({ showDeletedModal: false, currentSongsAssigned: null });
  };

  handleAcceptDeleteConfirmationModal = () => {
    this.setState({ showDeletedModal: false, currentSongsAssigned: null });
    this.reload();
  };

  reload = () => {
    this.setState({
      songsAssigned: this.getSongsAssignedSearch(25, null, null, null),
      showNewModal: false,
    });
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  onSearch = () => {
    const { orderList } = this.state;
    if (this.search && this.search != " ") {
      this.setState({
        songsAssigned: this.props.store.songsAssignedUserStyles.fetchAllParams(
          this.per_page,
          orderList.length == 0 ? null : orderList,
          this.search
        ),
        page: 1,
      });
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  getIndexOrder = (order) => {
    return this.state.orderList.indexOf(order);
  };

  handleDescending = (name) => {
    const { filters, orders, orderList } = this.state;
    let newOrders = orders;
    let orderLists = orderList;
    if (orderList.length == 0) {
      orderList.push("name");
    }
    if (name == "descStatus") {
      if (newOrders._status) {
        var index = this.getIndexOrder("-name");
        if (index !== -1) {
          orderLists[index] = "name";
        }
        newOrders._status = false;
      } else {
        var index = this.getIndexOrder("name");
        if (index !== -1) {
          orderLists[index] = "-name";
        }
        newOrders._status = true;
      }
    }
    this.setState({
      orders: newOrders,
      orderList: orderLists,
      songsAssigned: this.getSongsAssignedSearch(
        this.per_page,
        orderList,
        this.search
      ),
    });
  };

  validateFilter = (filters) => {
    if (filters._status || filters.apip_username) return filters;
    return null;
  };

  handleSelectChange = (value, sender) => {
    const { filters, orderList } = this.state;
    const val = value.value;
    const newFilters = filters;
    switch (sender.name) {
      case "selectStatus":
        newFilters._status = val;
        break;
      case "selectAPIPUsername":
        newFilters.apip_username = val;
        break;
      default:
        break;
    }
    this.setState({
      filters: newFilters,
      songsAssigned: this.getSongsAssignedSearch(
        this.per_page,
        this.validateFilter(filters),
        orderList.length == 0 ? null : orderList,
        this.search
      ),
    });
  };

  getPanel = (result, historyConsultedUsersOptions) => {
    const statusOptions = [
      { value: 1, label: "Cargadas" },
      { value: 2, label: "Publicadas" },
    ];
    return (
      <div
        className="flex pb-2"
        style={{ gap: this.props.store.viewMobile ? 16 : 8 }}
      >
        {/*<Columns>
                    <Column isSize={{mobile:12,tablet:12,desktop:12}}>
                        <div className="is-row mb-1">
                            <Text upper size="xl">{"Búsqueda puntual"} </Text>
                            <Text className="ml-2" size="md" maxLength={this.props.mobile?50:250}>{'Resultados: '+result}</Text>
                        </div>
                        <TextInput
                            className="is-fullwidth"
                            size="xl"
                            iconKasimu="search"
                            backgroundColor='black'
                            colorIcon='gray'
                            invert
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChangeSearch}
                            placeholder={'Escribe aquí el nombre de la canción a buscar...'}
                            value={this.search && this.search}
                        />                    
                    </Column>
                </Columns>*/}

        <Button
          icon={"arrow-right"}
          size={this.props.store.viewMobile ? "lg" : "md"}
          onClick={this.handleShowNewModal}
          className="px-2"
        >
          <strong>{"Asignar"}</strong>
        </Button>
        {/*<Button className="ml-3 is-pulled-right" kind="link" color="orange" size="xl" icon={this.state.orders._status?"sort-alpha-down-alt":"sort-alpha-down"} onClick={()=>{this.handleDescending("descStatus")}} space/>*/}
        <div style={{ maxWidth: "250px", width: "100%" }}>
          <Select
            isMobile={!this.props.store.viewMobile}
            name="selectStatus"
            onChange={this.handleSelectChange}
            className="is-fullwidth"
            placeholder={"Filtrar por estado"}
            options={statusOptions}
            defaultValue={
              this.state.filters._status
                ? statusOptions[
                    statusOptions
                      .map(function (e) {
                        return e.value;
                      })
                      .indexOf(this.state.filters._status)
                  ]
                : statusOptions[-1]
            }
          />
        </div>
        <div style={{ maxWidth: "250px", width: "100%" }}>
          <Select
            isMobile={!this.props.store.viewMobile}
            name="selectAPIPUsername"
            onChange={this.handleSelectChange}
            className="is-fullwidth"
            placeholder={"Filtrar por usuario"}
            options={historyConsultedUsersOptions}
            defaultValue={
              historyConsultedUsersOptions[
                historyConsultedUsersOptions
                  .map(function (e) {
                    return e.value;
                  })
                  .indexOf(this.state.filters.apip_username)
              ]
            }
          />
        </div>
      </div>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderPaginator = (songsAssigned) => {
    if (songsAssigned.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            panel
            totalPages={songsAssigned.totalPages}
            currentPage={songsAssigned.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { songsAssigned } = this.state;
    if (!songsAssigned) return this.renderEmptyContent();
    return !songsAssigned.isOk() ? this.renderLoader() : this.renderBody();
  };

  getDescription = (description) => {
    if (description) return description;
    return "Sin Descripción";
  };

  renderRow = (songsAssigned) => {
    const arraySongsAssigned = songsAssigned.toArray();
    // const arraySongsAssigned = [
    //   {
    //     apip_username: "user",
    //     apip_song_styles_name: "estilo",
    //     song: { name: "cancion" },
    //   },
    // ];
    return (
      <React.Fragment>
        {arraySongsAssigned.length === 0 ? (
          <EmptyContent />
        ) : (
          <div className="mt-1">
            {arraySongsAssigned.map((a) => (
              <div
                style={{
                  lineBreak: "anywhere",
                  marginBottom: !this.props.store.viewMobile && "3px",
                }}
                className={this.props.store.viewMobile && "mb-1"}
              >
                <CardEvents
                  isMobile={!this.props.store.viewMobile}
                  padding={!this.props.store.viewMobile && "22px 20px"}
                  name={
                    "Usuario: " +
                    a.apip_username +
                    " | Estilo: " +
                    a.apip_song_styles_name +
                    " | Canción: " +
                    a.song.name
                  }
                  upper={false}
                >
                  {/*<Button
                            className="ml-3"
                            icon="search-plus"
                            kind="link"
                            color="special"
                            size="lg"
                            lead
                            space
                            onClick={() => {this.handleInformationModalOpen(a)}}
                        />*/}
                  {this.props.store.loggedInUser.checkPermission(47) ? (
                    <Button
                      className="ml-1"
                      kind="link"
                      color={a.deleted == 1 ? "remove" : "basic"}
                      size={this.props.store.viewMobile ? "lg" : "xl"}
                      icon="trash"
                      onClick={() => {
                        this.handleDeleteConfirmationOpen(a);
                      }}
                      space
                    />
                  ) : null}
                </CardEvents>
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  renderBody = () => {
    const {
      songsAssigned,
      userTags,
      historyConsultedUsers,
      currentSongsAssigned,
      orderList,
      showNewModal,
      showInformationModal,
      showDeletedModal,
    } = this.state;
    const myTagsOptions = this.getOptionsMyTags(userTags);
    const historyConsultedUsersOptions = this.getOptionsAPIPUsername(
      historyConsultedUsers
    );
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="mb-2"
                weight="black"
                lineBreak
                lead
              >
                {"Asignar canciones"}
              </Text>
            )}

            {this.getPanel(songsAssigned.count, historyConsultedUsersOptions)}
            {this.renderRow(songsAssigned)}
            {this.renderPaginator(songsAssigned)}
          </Column>
          {showNewModal && (
            <AssignSongsUserStylesModal
              myTagsOptions={myTagsOptions}
              onClose={this.handleCloseNewModal}
              onReload={this.reload}
            />
          )}
          {showInformationModal && (
            <InformationModal
              userTag={currentSongsAssigned}
              onClose={this.handleInformationModalClose}
            />
          )}
          {showDeletedModal && (
            <DeleteConfirmationModal
              currentObj={currentSongsAssigned}
              onClose={this.handleDeleteConfirmationModalClose}
              onAccept={this.handleAcceptDeleteConfirmationModal}
              messageSuccess={
                'La canción asignada "' +
                currentSongsAssigned.song.name +
                '", se elimino exitosamente.'
              }
              messageError={
                'Error, algo salió mal al eliminar la canción asignada "' +
                currentSongsAssigned.song.name +
                '".'
              }
              title={
                "Deseas eliminar la canción asignada \n" +
                currentSongsAssigned.song.name +
                "?"
              }
              subtitle={
                "Se eliminará la canción asignada " +
                currentSongsAssigned.song.name +
                "."
              }
            />
          )}
        </Columns>
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>;
  }
}

export default withToastManager(withStore(AssignSongsToStylePage));
