import React, { Component, useRef, useLayoutEffect } from "react";
import { observer, PropTypes } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Paragraph,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
  Text,
  Column,
  Columns,
} from "ks_storybook";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withToastManager } from "react-toast-notifications";
import { FixedSizeList } from "react-window";

function getStyle({ provided, style, isDragging, columnId }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };

  const columnOne = columnId == "column-1";

  const grid = 8;
  const marginBottom = 5;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    background: isDragging ? "#12C889" : columnOne ? "#2E2E2E" : "#1F1F1F",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${grid * 3}px`,
    borderRadius: grid,
    marginBottom,
  };
  return withSpacing;
}

const Item = ({
  provided,
  item,
  style,
  isDragging,
  index,
  columnId,
  func,
  currentItems,
}) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({ provided, style, isDragging, columnId })}
      className={`item ${isDragging ? "is-dragging" : ""}`}
    >
      <div
        className="is-row is-fullwidth"
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="is-row ml-1" style={{ alignItems: "center" }}>
          <Text size="xl" multiline>
            {currentItems && item.id == currentItems.id ? (
              <span style={{ fontWeight: "bold", color: "#FF3F1F" }}>
                {item.name}
              </span>
            ) : (
              item.name
            )}
          </Text>
        </div>
        {columnId == "column-1" ? (
          <div className="is-row ml-1" style={{ alignItems: "center" }}>
            <Button
              kind="link"
              color="basic"
              size="xl"
              iconKasimu="trash"
              onClick={() => {
                func(item.key);
              }}
              space
              className="ml-1"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const Row = (props) => {
  const { data, index, style } = props;
  const item = data.items[index];
  // We are rendering an extra item for the placeholder
  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={`${item.key}`} index={index} key={item.key}>
      {(provided) => (
        <Item
          provided={provided}
          item={item}
          style={style}
          columnId={data.columnId}
          func={data.func}
          currentItems={data.currentItems}
        />
      )}
    </Draggable>
  );
};

const ItemList = ({ column, index, func, currentItems }) => {
  // There is an issue I have noticed with react-window that when reordered
  // react-window sets the scroll back to 0 but does not update the UI
  // I should raise an issue for this.
  // As a work around I am resetting the scroll to 0
  // on any list that changes it's index

  const listRef = useRef();
  useLayoutEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.items[rubric.source.index]}
          columnId={column.id}
        />
      )}
    >
      {(provided, snapshot) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        const itemCount = snapshot.isUsingPlaceholder
          ? column.items.length + 1
          : column.items.length;

        return (
          <FixedSizeList
            height={420}
            itemCount={itemCount}
            itemSize={60}
            width={"100%"}
            outerRef={provided.innerRef}
            itemData={{
              items: column.items,
              columnId: column.id,
              func: func,
              currentItems: currentItems,
            }}
            className="task-list"
            ref={listRef}
          >
            {Row}
          </FixedSizeList>
        );
      }}
    </Droppable>
  );
};

class ColumnDraggable extends Component {
  getColumnStyle = (index) => ({
    border: index == 1 && "2px solid #e8e8e8",
    borderRadius: "8px",
    ".item": {
      background: "red",
    },
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  render() {
    const { column, index, func, currentItems, label } = this.props;
    return (
      <Draggable draggableId={column.id} index={index}>
        {(provided) => (
          <div
            className={`ks--column is-12-mobile is-12-tablet is-6-desktop`}
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={this.getColumnStyle(index)}
          >
            <div {...provided.dragHandleProps} />
            {index == 1 && (
              <div
                style={{
                  position: "absolute",
                  border: "2px dashed #ccc",
                  padding: "10px 20px",
                  borderRadius: "16px",
                }}
              >
                <Text upper size="xl" weight="strong" lineBreak color="gray13">
                  <span style={{ color: "#ccc" }}>{label}</span>
                </Text>
              </div>
            )}
            <ItemList
              column={column}
              index={index}
              func={func}
              currentItems={currentItems}
            />
          </div>
        )}
      </Draggable>
    );
  }
}

@observer
class MultipleUserTagSongModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      name: null,
      messageError: null,
      isSaving: false,
      currentItems: null,
      items: this.getItemsData(this.props.userTags),
      state: {},
    };
    this.grid = 4;
  }

  componentDidMount() {
    const { items, search } = this.state;
    const arrayItems = this.filterItems(items, search);
    const state = {
      columns: {
        "column-0": {
          id: "column-0",
          title: "First column",
          items: arrayItems,
        },
        "column-1": {
          id: "column-1",
          title: "Second column",
          items: this.getItemsData(this.props.selected),
        },
      },
      columnOrder: ["column-0", "column-1"],
    };
    this.setState({ state: state });
  }

  getItemsData = (obj) => {
    var arr = [];
    obj.map((a) => arr.push({ key: uuidv4(), id: a.id, name: a.name }));
    return arr;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  deleted = (key) => {
    const lists = this.state.state.columns["column-1"].items;
    var index = lists
      .map((x) => {
        return x.key;
      })
      .indexOf(key);

    lists.splice(index, 1);

    const newSate = this.state.state;
    newSate.columns["column-1"].items = lists;

    this.setState({
      state: newSate,
      messageError: null,
    });
  };

  /*updatedQuantity = (key,value) => {
        const lists = this.state.state.columns['column-1'].items
        var index = lists.map(x => {
            return x.key;
        }).indexOf(key);

        lists[index].quantity=value;
        
        const newSate = this.state.state
        newSate.columns['column-1'].items = lists;

        this.setState({
            state: newSate,
            messageError: null
        });
    };*/

  handleSave = () => {
    const { toastManager } = this.props;
    const { state } = this.state;
    this.props.onSave && this.props.onSave(state.columns["column-1"].items);
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "250px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  getListOriginStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#1b1b1b" : "#1b1b1b",
    padding: this.grid,
  });

  getListDestinationStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#1b1b1b" : "#1b1b1b",
    padding: this.grid,
    border: "1px dashed #F7F7F7",
  });

  getItemStyle = (isDragging, draggableStyle) => ({
    // estilos que necesitamos aplicar en draggables
    ...draggableStyle,
    // algunos estilos básicos para que los artículos se vean un poco más bonitos
    userSelect: "none",
    position: "static",
    padding: 8 * 2,
    margin: "0 0 8px 0",
    alignItems: "flex-start",
    alignContent: "flex-start",
    borderRadius: "3px",
    border:
      "1px ${(props) => (props.isDragging ? 'dashed #000' : 'solid #ddd')}",
    // cambiar el color de fondo si arrastra
    background: isDragging ? "#12C889" : "#141414",
  });

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];
    const results = destClone.filter((obj) => {
      return obj.user_id === item.user_id && obj.id === item.id;
    });
    if (results.length === 0) {
      destClone.splice(droppableDestination.index, 0, {
        ...item,
        key: uuidv4(),
      });
    }
    return destClone;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    const { state } = this.state;

    if (!result.destination) {
      return;
    }

    if (result.type === "column") {
      // if the list is scrolled it looks like there is some strangeness going on
      // with react-window. It looks to be scrolling back to scroll: 0
      // I should log an issue with the project
      const columnOrder = this.reorderList(
        state.columnOrder,
        result.source.index,
        result.destination.index
      );
      this.setState({
        state: {
          ...state,
          columnOrder,
        },
      });
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      const column = state.columns[result.source.droppableId];
      const items = this.reorderList(
        column.items,
        result.source.index,
        result.destination.index
      );

      // updating column entry
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [column.id]: {
            ...column,
            items,
          },
        },
      };
      this.setState({ state: newState });
      return;
    }

    // moving between lists
    const sourceColumn = state.columns[result.source.droppableId];
    const destinationColumn = state.columns[result.destination.droppableId];
    const { source, destination } = result;

    // eliminado de la lista
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case "column-0":
        const result = this.copy(
          sourceColumn.items,
          destinationColumn.items,
          source,
          destination
        );

        const newSate = this.state.state;
        newSate.columns["column-1"].items = result;

        this.setState({
          state: newSate,
          messageError: null,
        });
        break;
    }
  };

  handleChangeSearch = (sender, value, name) => {
    const newSate = this.state.state;
    const arrayItems = this.filterItems(this.state.items, value);
    newSate.columns["column-0"].items = arrayItems;
    this.setState({ state: newSate, search: value });
  };

  filterItems = (array, search) => {
    array = array.filter(
      (el) => el.name.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
    return array;
  };

  @computed
  get canSave() {
    if (this.state.messageError) return false;
    return true;
  }

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label128}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.canSave}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label127}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  renderDragDrop = () => {
    const { state, currentItems } = this.state;
    return (
      <React.Fragment>
        {state && state.columnOrder && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="app">
              <Droppable
                droppableId="all-droppables"
                direction="horizontal"
                type="column"
              >
                {(provided) => (
                  <div
                    className="ks--columns"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {state.columnOrder.map((columnId, index) => (
                      <>
                        <ColumnDraggable
                          key={columnId}
                          column={state.columns[columnId]}
                          index={index}
                          func={this.deleted}
                          currentItems={currentItems}
                          label={"Arrastra las equitas aquí"}
                        />
                      </>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        )}
      </React.Fragment>
    );
  };

  renderSearch = () => {
    return (
      <Columns className="mb-2" isMultiline>
        <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
          <Field
            label={"Buscador de etiquetas"}
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            <TextInput
              name="search"
              iconKasimu="search"
              onChange={this.handleChangeSearch}
              placeholder={"Escribe aquí el nombre de la etiqueta a buscar…"}
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={this.props.store.viewMobile && "20px"}
            />
          </Field>
        </Column>
        <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            className="mt-3"
            weight="black"
            lineBreak
            lead
          >
            {"Etiquetas actuales"}
          </Text>
        </Column>
      </Columns>
    );
  };

  render() {
    const { currentItems } = this.state;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "940px" : "90%"}
        height={this.props.store.viewMobile ? "640px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            weight="black"
            lead
          >
            {"Agregar o quitar etiquetas"}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text
            size={this.props.store.viewMobile ? "lg" : "md"}
            color="special"
            lineBreak
          >
            {
              "Arrastra de izquierda a derecha la etiqueta que desees agregar., puedes también eliminar etiquetas propias en una canción, No se puede repetir la misma etiqueta en una misma canción."
            }
          </Text>
          <br />
          {this.renderSearch()}
          {this.renderDragDrop()}
          <br />
          {this.state.messageError && (
            <Panel color="error" className="mt-2" invert>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} multiline>
                {this.state.messageError}
              </Text>
            </Panel>
          )}
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}
MultipleUserTagSongModal.propTypes = {
  userTags: PropTypes.Array,
  selected: PropTypes.Array,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

MultipleUserTagSongModal.defaultProps = {
  userTags: [],
  selected: [],
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(MultipleUserTagSongModal));
