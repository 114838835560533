import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Field,
  Columns,
  Column,
  Panel,
} from "ks_storybook";
import moment from "moment";

@observer
class SongInformationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getStatus = (key) => {
    const status = {
      1: "Cargada",
      2: "Pendiente",
      3: "Aprobada",
      4: "Publicada",
      5: "Modificada",
      6: "Pre-Pendientes",
      7: "Reparar",
    };
    return status[key];
  };

  millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  getContainerStyle = () => ({
    width: "95%",
    height: "200px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderMusicLists = (music_lists) => {
    return (
      <React.Fragment>
        <div className="ml-2" style={this.getContainerStyle()}>
          {music_lists.map((obj) => (
            <Panel
              color="gray"
              padding="18px 24px"
              className="mb-1"
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                overflowWrap: "anywhere",
              }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                lineBreak
              >
                {"Id: " +
                  obj.music_lists.id +
                  " | Nombre: " +
                  obj.music_lists.name +
                  " | Prioridad: " +
                  obj.music_lists.priority +
                  " | Posición: " +
                  obj.position +
                  " | Fecha: " +
                  obj.date +
                  " | Semana: " +
                  obj.week}
              </Text>
            </Panel>
          ))}
        </div>
      </React.Fragment>
    );
  };

  getService = (free_rights) => {
    const { catalogsOptions } = this.props;
    const arr = catalogsOptions.filter(function (e) {
      return free_rights == e.value;
    });
    return arr ? arr[0].label : null;
  };

  render() {
    const { song } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "550px" : "auto"}
      >
        <ModalHeader></ModalHeader>
        <ModalContent>
          <Columns isMultiline>
            <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
              <Text
                size={this.props.store.viewMobile ? "md" : "sm"}
                weight="black"
                lead
                className="mb-2"
              >
                {song.name}
              </Text>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Id: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.id}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Intérprete: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.singer.name}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Servicio: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {this.getService(song.free_rights)}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Idioma: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.song_lenguages.description}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Contenido explícito: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.explicit == 1 ? "Si" : "No"}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Remix: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.remix == 1 ? "Si" : "No"}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Estado: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {this.getStatus(song.status)}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Año: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.year}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Sello discográfico: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.record_label.name}
                </Text>
              </div>
            </Column>
            <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
              <Text
                size={this.props.store.viewMobile ? "md" : "sm"}
                className="mb-2"
                weight="black"
                lead
              >
                {song.published ? "Publicada" : "Cargada"}
              </Text>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Proveedor: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.provider.name}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Reproducciones: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.reproductions}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Archivo original: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.orig_file_name
                    ? song.orig_file_name
                    : "Sin datos por le momento"}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Archivo vdj: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.song_path}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"BPM: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.bpm}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Duración: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {this.millisToMinutesAndSeconds(song.duration)}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Id de carga: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {song.load_num ? song.load_num : "Sin datos por el momento"}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Fecha de creación: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {moment(song.created_at).format("DD-MM-YYYY HH:mm:ss")}
                </Text>
              </div>
              <div className="flex flex-col mb-1">
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  className="mr-1"
                  weight="medium"
                  upper
                >
                  {"Fecha de modificación: "}
                </Text>
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  color="special"
                  multiline
                >
                  {moment(song.updated_at).format("DD-MM-YYYY HH:mm:ss")}
                </Text>
              </div>
            </Column>
          </Columns>
          <div className="flex flex-col mb-1">
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Comentario: "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {song.commentary ? song.commentary : "Sin datos por le momento"}
            </Text>
          </div>

          {song.music_lists && (
            <Field
              label={"Listas de éxitos"}
              size="xl"
              NoteSize="xs"
              marginH="10px"
              upper
            >
              {this.renderMusicLists(song.music_lists)}
            </Field>
          )}
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

SongInformationModal.propTypes = {
  song: PropTypes.object,
  onClose: PropTypes.func,
};

SongInformationModal.defaultProps = {
  song: null,
  onClose: null,
};

export default withStore(SongInformationModal);
