import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Button, Text } from "ks_storybook";
import { Columns, Column } from "bloomer";

@observer
class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleStartPlayingClick = this.handleStartPlayingClick.bind(this);
  }

  handleStartPlayingClick() {
    this.props.store.ui.startPlaying();
  }

  handleNextClick() {
    this.props.store.ui.next();
  }

  handlePrevClick() {
    this.props.store.ui.prev();
  }

  renderKasimu(mobileStyle, desktopStyle) {
    const { handleTogglePlay, song, indice } = this.props;
    const that = this;

    return (
      <div
        className="is-v-centered is-centered ks--panel mb-3"
        style={this.props.mobile ? mobileStyle : desktopStyle}
      >
        <Columns style={{ margin: 0 }}>
          <Column
            isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
            style={{ paddingBottom: 0 }}
          >
            <div
              className="is-row"
              style={{
                justifyContent: "space-between",
                alignItems: this.props.store.viewMobile && "center",
                flexDirection: !this.props.store.viewMobile && "column",
              }}
            >
              {that.props.store.ui.showPlayer ? (
                <div className="is-row flex-wrap">
                  {this.props.store.viewMobile && (
                    <>
                      <Button
                        icon="step-backward"
                        kind="link"
                        color="basic"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        onClick={this.handlePrevClick}
                        space
                      />
                      <Button
                        icon={this.props.store.ui.isPlaying ? "pause" : "play"}
                        kind="link"
                        color="basic"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        onClick={() => handleTogglePlay(song)}
                        id="play-button"
                        space
                      />
                      <Button
                        className="mr-2"
                        icon="step-forward"
                        kind="link"
                        color="basic"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        onClick={this.handleNextClick}
                        space
                      />
                    </>
                  )}
                  <div className="flex">
                    <div className="flex">
                      <Button
                        icon="cloud-download-alt"
                        kind="link"
                        color="basic"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        space
                      />
                      <div>
                        <Text
                          size={this.props.store.viewMobile ? "md" : "sm"}
                          maxLength={this.props.mobile ? 50 : 250}
                        >
                          {this.props.store.ui.downloadedSongs.length +
                            "/" +
                            this.props.store.ui.songs.length}
                        </Text>
                      </div>
                    </div>
                    <div
                      className={
                        this.props.mobile ? "ml-4 is-row" : "ml-2 is-row"
                      }
                      style={{ alignItems: "center" }}
                      maxLength={this.props.mobile ? 50 : 250}
                    >
                      {song && (
                        <Text
                          weight="normal"
                          size={this.props.mobile ? "xs" : "xl"}
                          lead={this.props.mobile}
                          color={"primary"}
                          multiline
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {indice} | {song.name}
                          </span>
                          - {song.singer.name}
                        </Text>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="is-row">
                  <Button
                    icon="cloud-download-alt"
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    onClick={this.handleStartPlayingClick}
                    disabled={this.props.store.ui.songsCount == 0}
                    className="px-2"
                  >
                    <strong>{"Descargar"}</strong>
                  </Button>
                </div>
              )}
              {that.props.store.ui.showPlayer && (
                <div>
                  {this.props.store.viewMobile ? (
                    <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                      {this.props.store.ui.currentDuration == "NaN:NaN"
                        ? "00:00 / 00:00"
                        : this.props.store.ui.currentProgressTime +
                          " / " +
                          this.props.store.ui.currentDuration}
                    </Text>
                  ) : (
                    <div
                      className="flex items-center py-1"
                      style={{ justifyContent: "space-between" }}
                    >
                      <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                        {this.props.store.ui.currentDuration == "NaN:NaN"
                          ? "00:00"
                          : this.props.store.ui.currentProgressTime}
                      </Text>
                      <div className="mx-2">
                        <Button
                          icon="step-backward"
                          kind="link"
                          color="basic"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          onClick={this.handlePrevClick}
                          space
                        />
                        <Button
                          icon={
                            this.props.store.ui.isPlaying ? "pause" : "play"
                          }
                          kind="link"
                          color="basic"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          onClick={() => handleTogglePlay(song)}
                          id="play-button"
                          space
                        />
                        <Button
                          icon="step-forward"
                          kind="link"
                          color="basic"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          onClick={this.handleNextClick}
                          space
                        />
                      </div>
                      <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                        {this.props.store.ui.currentDuration == "NaN:NaN"
                          ? "00:00"
                          : this.props.store.ui.currentDuration}
                      </Text>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Column>
        </Columns>
        <Columns style={{ margin: 0 }}>
          <Column
            isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
            style={{ paddingBottom: 0, paddingTop: 0 }}
          >
            <div
              id="waveform"
              style={{ height: that.props.store.ui.showPlayer ? 100 : 0 }}
            ></div>
            {/* <Progress value={this.props.store.ui.currentProgress || '0'} max={this.props.store.ui.durationSecs}  style={{height: "8px"}} /> */}
          </Column>
        </Columns>
        {/*<div style={{display: 'flex', 'justify-content': 'flex-end'}}>
            <Text size="md" maxLength={this.props.mobile?50:250}>{'Resultados: '+this.props.store.ui.songsCount}</Text>
        </div>*/}
      </div>
    );
  }

  render() {
    const mobileStyle = {
      //height: "86px",
      width: "100%",
      borderRadius: "26px 26px 0px 0px",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      backdropFilter: "blur(86.985px)",
      position: "fixed",
      bottom: "0px",
      left: "0px",
      zIndex: 2,
    };

    const desktopStyle = {
      padding: "16px 20px",
      backgroundColor: "#242424",
      width: "100%",
      // height: this.props.store.ui.showPlayer ? "160px" : "100px",
      height: "auto",
      // position: "sticky",
      top: "0px",
      zIndex: 2,
      justifyContent: "center",
    };

    return this.renderKasimu(mobileStyle, desktopStyle);
  }
}

AudioPlayer.protoTypes = {
  className: PropTypes.string,
  mobile: PropTypes.bool,
};

AudioPlayer.defaultProps = {
  className: null,
  mobile: false,
};

export default withStore(AudioPlayer);
