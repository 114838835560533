import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Columns,
  Column,
} from "ks_storybook";
import { Select } from "../Select";
import { SingerModal, RecordLabelModal, ProviderModal } from ".";
import { withToastManager } from "react-toast-notifications";

@observer
class SongEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      showOpendSingerModal: false,
      showOpendRecordLabelModal: false,
      showOpendProviderModal: false,
    };

    this.song = props.song.clone();
  }

  validLength = (value) => {
    if (value.length >= 1 && value.length <= 255) {
      return true;
    } else {
      return false;
    }
  };

  handleChangeFiled = (sender, value, name) => {
    this.song[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  opendSingerModel = () => {
    this.setState({ showOpendSingerModal: true });
  };

  assignSingerModel = (singer) => {
    this.song["singer_id"] = singer.id;
    this.song["singer"] = { id: singer.id, name: singer.name };
    this.setState({ showOpendSingerModal: false });
  };

  closeSingerModel = () => {
    this.setState({ showOpendSingerModal: false });
  };

  opendRecordLabelModel = () => {
    this.setState({ showOpendRecordLabelModal: true });
  };

  assignRecordLabelModel = (record_label) => {
    this.song["record_label_id"] = record_label.id;
    this.song["record_label"] = {
      id: record_label.id,
      name: record_label.name,
    };
    this.setState({ showOpendRecordLabelModal: false });
  };

  closeRecordLabelModel = () => {
    this.setState({ showOpendRecordLabelModal: false });
  };

  opendProviderModel = () => {
    this.setState({ showOpendProviderModal: true });
  };

  assignProviderModel = (provider) => {
    this.song["provider_id"] = provider.id;
    this.song["provider"] = { id: provider.id, name: provider.name };
    this.setState({ showOpendProviderModal: false });
  };

  closeProviderModel = () => {
    this.setState({ showOpendProviderModal: false });
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;

    switch (sender.name) {
      case "selectService":
        this.song["free_rights"] = val;
        break;
      case "selectLanguage":
        this.song["song_lenguages_id"] = val;
        this.song["song_lenguages"] = { id: val, description: value.label };
        break;
      case "selectExplicit":
        if (val == 2) this.song["explicit"] = 0;
        else this.song["explicit"] = 1;
        break;
      case "selectRemix":
        if (val == 2) this.song["remix"] = 0;
        else this.song["remix"] = 1;
        break;
      case "selectStatus":
        this.song["status"] = val;
        break;
      default:
        break;
    }
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.song.save().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add("Error, algo salió mal al editar la canción.", {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          toastManager.add("La canción se editó exitosamente.", {
            appearance: "success",
            autoDismiss: true,
          });
        }
        this.handleClose();
      });
    });
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={this.song.name === ""}
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  getOptions = (obj) => {
    return obj.toArray().map((o) => ({ value: o.id, label: o.description }));
  };

  exists(arr, value) {
    return arr.some(function (el) {
      return el.value === value;
    });
  }

  getArrayStatus(statusOptions, rolId, status) {
    let arrayStatus = [];
    if (status == 4 || status == 5) arrayStatus = [7];
    if (rolId == 1 || rolId == 2 || rolId == 4) {
      if (status == 2) arrayStatus = [3, 7];
      if (status == 3) arrayStatus = [2, 7];
    } else if (rolId == 3) {
      if (status == 1) arrayStatus = [2, 6];
      if (status == 2) arrayStatus = [6, 7];
      if (status == 3) arrayStatus = [2, 7];
      if (status == 6) arrayStatus = [2];
    }
    return statusOptions.filter(
      (s) => arrayStatus.includes(s.value) && s.value != status
    );
  }

  render() {
    const {
      showOpendSingerModal,
      showOpendRecordLabelModal,
      showOpendProviderModal,
    } = this.state;
    const { catalogsOptions } = this.props;
    const languageOptions = this.getOptions(this.props.language);
    const rolId = this.props.store.loggedInUser.getRolId();
    let statusOptions = [
      { value: 1, label: "Cargadas" },
      { value: 2, label: "Pendientes" },
      { value: 3, label: "Aprobadas" },
      { value: 4, label: "Publicada" },
      { value: 5, label: "Modificada" },
      { value: 6, label: "Pre-Pendientes" },
      { value: 7, label: "Reparar" },
    ];
    const genOptions = [
      { value: 1, label: "Si" },
      { value: 2, label: "No" },
    ];
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "700px" : "90%"}
          height={this.props.store.viewMobile ? "250px" : "auto"}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              weight="black"
              lead
            >
              {this.props.store.language.label140}
              {"\n"}
              {" Id: " + this.song.id}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Columns isMultiline>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                <Text
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  weight="black"
                  lead
                  className="mb-2"
                >
                  {this.song.name}
                </Text>
                <Field
                  label={this.props.store.language.label53}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <TextInput
                    name="name"
                    onChange={this.handleChangeFiled}
                    value={this.song.name}
                    autocomplete="nope"
                    backgroundColor="blackDark"
                    borderColor="black"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </Field>
                <Field
                  label={"Interprete"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <div
                    className="is-row my-1 mx-2"
                    style={{ alignItems: "center" }}
                  >
                    <Text size="xl" multiline>
                      {this.song.singer.name}
                    </Text>
                    <Button
                      className="ml-3"
                      icon="pen"
                      kind="link"
                      color="basic"
                      size="lg"
                      lead
                      space
                      onClick={this.opendSingerModel}
                    />
                  </div>
                </Field>
                {this.props.store.loggedInUser.checkPermission(11) ? (
                  <Field
                    label={"Servicio"}
                    marginH={this.props.store.viewMobile && "4px"}
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                    weight="medium"
                  >
                    <Select
                      isMobile={!this.props.store.viewMobile}
                      name={"selectService"}
                      onChange={this.handleSelectChange}
                      options={catalogsOptions}
                      defaultValue={
                        catalogsOptions[
                          catalogsOptions
                            .map(function (e) {
                              return e.value;
                            })
                            .indexOf(this.song.free_rights)
                        ]
                      }
                      placeholder={this.props.store.language.label385}
                      backgroundColor="black"
                      separatorColor="black"
                      className="my-1"
                    />
                  </Field>
                ) : null}
                <Field
                  label={"Idioma"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectLanguage"}
                    onChange={this.handleSelectChange}
                    options={languageOptions}
                    defaultValue={
                      languageOptions[
                        languageOptions
                          .map(function (e) {
                            return e.value;
                          })
                          .indexOf(this.song.song_lenguages_id)
                      ]
                    }
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="my-1"
                  />
                </Field>
                {this.props.store.loggedInUser.checkPermission(21) ? (
                  <Field
                    label={"BPM"}
                    marginH={this.props.store.viewMobile && "4px"}
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                    weight="medium"
                  >
                    <TextInput
                      type="number"
                      name="bpm"
                      onChange={this.handleChangeFiled}
                      minlength={0}
                      maxlength={500}
                      value={this.song.bpm}
                      autocomplete="nope"
                      backgroundColor="blackDark"
                      borderColor="black"
                      className="is-fullwidth my-1"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      paddingInput={this.props.store.viewMobile && "20px"}
                    />
                  </Field>
                ) : null}
                <Field
                  label={"Contenido explícito"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectExplicit"}
                    onChange={this.handleSelectChange}
                    options={genOptions}
                    defaultValue={
                      genOptions[
                        genOptions
                          .map(function (e) {
                            return e.value;
                          })
                          .indexOf(this.song.explicit == 1 ? 1 : 2)
                      ]
                    }
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="my-1"
                  />
                </Field>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
                <Text
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  weight="black"
                  lead
                  className="mb-2"
                >
                  {this.song.published ? "Publicada" : "Cargada"}
                </Text>
                <Field
                  label={"Remix"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <Select
                    isMobile={!this.props.store.viewMobile}
                    name={"selectRemix"}
                    onChange={this.handleSelectChange}
                    options={genOptions}
                    defaultValue={
                      genOptions[
                        genOptions
                          .map(function (e) {
                            return e.value;
                          })
                          .indexOf(this.song.remix == 1 ? 1 : 2)
                      ]
                    }
                    placeholder={this.props.store.language.label385}
                    backgroundColor="black"
                    separatorColor="black"
                    className="my-1"
                  />
                </Field>
                <Field
                  label={"Año"}
                  marginH={this.props.store.viewMobile && "4px"}
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                  weight="medium"
                >
                  <TextInput
                    type="number"
                    name="year"
                    onChange={this.handleChangeFiled}
                    minlength={1900}
                    maxlength={2099}
                    value={this.song.year}
                    autocomplete="nope"
                    backgroundColor="blackDark"
                    borderColor="black"
                    className="is-fullwidth my-1"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    paddingInput={this.props.store.viewMobile && "20px"}
                  />
                </Field>
                {this.props.store.loggedInUser.checkPermission(13) ? (
                  <Field
                    label={"Sello discográfico"}
                    marginH={this.props.store.viewMobile && "4px"}
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                    weight="medium"
                  >
                    <div
                      className="is-row my-1 mx-2"
                      style={{ alignItems: "center" }}
                    >
                      <Text size="xl" multiline>
                        {this.song.record_label.name}
                      </Text>
                      <Button
                        className="ml-3"
                        icon="pen"
                        kind="link"
                        color="basic"
                        size="lg"
                        lead
                        space
                        onClick={this.opendRecordLabelModel}
                      />
                    </div>
                  </Field>
                ) : null}
                {this.props.store.loggedInUser.checkPermission(14) ? (
                  <Field
                    label={"Proveedores"}
                    marginH={this.props.store.viewMobile && "4px"}
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                    weight="medium"
                  >
                    <div
                      className="is-row my-1 mx-2"
                      style={{ alignItems: "center" }}
                    >
                      <Text size="xl" multiline>
                        {this.song.provider.name}
                      </Text>
                      <Button
                        className="ml-3"
                        icon="pen"
                        kind="link"
                        color="basic"
                        size="lg"
                        lead
                        space
                        onClick={this.opendProviderModel}
                      />
                    </div>
                  </Field>
                ) : null}
                {this.props.store.loggedInUser.checkPermission(22) ? (
                  <Field
                    label={"Duración"}
                    marginH={this.props.store.viewMobile && "4px"}
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                    weight="medium"
                  >
                    <TextInput
                      type="number"
                      name="duration"
                      onChange={this.handleChangeFiled}
                      minlength={0}
                      maxlength={500}
                      value={this.song.duration}
                      autocomplete="nope"
                      backgroundColor="blackDark"
                      borderColor="black"
                      className="is-fullwidth my-1"
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      paddingInput={this.props.store.viewMobile && "20px"}
                    />
                  </Field>
                ) : null}
                {this.props.store.loggedInUser.checkPermission(12) ? (
                  this.exists(statusOptions, this.song.status) ? (
                    <Field
                      label={"Estado"}
                      marginH={this.props.store.viewMobile && "4px"}
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      noteSize={this.props.store.viewMobile ? "sm" : "xs"}
                      weight="medium"
                    >
                      <Select
                        isMobile={!this.props.store.viewMobile}
                        name={"selectStatus"}
                        onChange={this.handleSelectChange}
                        options={this.getArrayStatus(
                          statusOptions,
                          rolId,
                          this.song.status
                        )}
                        defaultValue={
                          statusOptions[
                            statusOptions
                              .map(function (e) {
                                return e.value;
                              })
                              .indexOf(this.song.status)
                          ]
                        }
                        placeholder={this.props.store.language.label385}
                        backgroundColor="black"
                        separatorColor="black"
                        className="my-1"
                      />
                    </Field>
                  ) : null
                ) : null}
              </Column>
            </Columns>
            <Field
              label={"Comentario"}
              marginH={this.props.store.viewMobile && "4px"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                name="commentary"
                onChange={this.handleChangeFiled}
                value={this.song.commentary}
                autocomplete="nope"
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={this.props.store.viewMobile && "20px"}
              />
            </Field>
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
        {showOpendSingerModal && (
          <SingerModal
            singer={this.song.singer}
            onClose={this.closeSingerModel}
            onSave={this.assignSingerModel}
          />
        )}
        {showOpendRecordLabelModal && (
          <RecordLabelModal
            recordLabel={this.song.record_label}
            onClose={this.closeRecordLabelModel}
            onSave={this.assignRecordLabelModel}
          />
        )}
        {showOpendProviderModal && (
          <ProviderModal
            provider={this.song.provider}
            onClose={this.closeProviderModel}
            onSave={this.assignProviderModel}
          />
        )}
      </React.Fragment>
    );
  }
}

SongEditModal.propTypes = {
  song: PropTypes.object,
  language: PropTypes.object,
  onClose: PropTypes.func,
};

SongEditModal.defaultProps = {
  song: null,
  language: null,
  onClose: null,
};

export default withToastManager(withStore(SongEditModal));
