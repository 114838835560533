import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  LayoutSideMenuLink,
  Text,
  SidebarMenu,
  SidebarMenuLink,
  MobileLayoutMenuLink,
} from "ks_storybook";
import withStore from "../../hocs/withStore";

class ContentManagerBottomMenu extends Component {
  state = {
    value: "",
    show: true,
  };

  handleChangeShow = (val) => {
    if (this.state.value !== val) this.setState({ value: val });
    else this.setState({ value: "" });
  };

  getPathMusic = () => {
    let path = "";

    if (this.props.store.loggedInUser.checkPermission(48)) {
      path = "categories";
    } else if (this.props.store.loggedInUser.checkPermission(49)) {
      path = "playlists";
    } else if (this.props.store.loggedInUser.checkPermission(3)) {
      path = "songs";
    } else if (this.props.store.loggedInUser.checkPermission(4)) {
      path = "interpreters";
    } else if (this.props.store.loggedInUser.checkPermission(5)) {
      path = "estilos_musicales";
    }
    return path;
  };

  getPathCompanies = () => {
    let path = "";

    if (this.props.store.loggedInUser.checkPermission(6)) {
      path = "providers";
    } else if (this.props.store.loggedInUser.checkPermission(7)) {
      path = "lists";
    } else if (this.props.store.loggedInUser.checkPermission(8)) {
      path = "record_label";
    }
    return path;
  };

  renderMultiUserMenu = () => {
    const { handleChangeShow } = this;
    const { value } = this.state;
    const path = this.props.match.path;
    const location = this.props.location.pathname;

    let pathMusic = this.getPathMusic();
    let pathCompanies = this.getPathCompanies();

    return [
      <React.Fragment>
        {this.props.store.loggedInUser.checkPermission(1) ? (
          <MobileLayoutMenuLink
            title={"Procesos"}
            to={`${path}processes`}
            titleClick={() => handleChangeShow("procesos")}
            show={value == "procesos"}
            icon="spinner"
            activation
          ></MobileLayoutMenuLink>
        ) : null}

        <MobileLayoutMenuLink
          title={"Etiquetas"}
          to={`${path}userstags`}
          titleClick={() => handleChangeShow("tags")}
          show={value == "tags"}
          icon="tag"
          activation
        ></MobileLayoutMenuLink>

        <MobileLayoutMenuLink
          title={"Musica"}
          to={`${path}${pathMusic}`}
          titleClick={() => handleChangeShow(pathMusic)}
          show={value == pathMusic}
          iconKasimu="playlist-solid"
          activation
        ></MobileLayoutMenuLink>

        <MobileLayoutMenuLink
          title={"Compañias"}
          to={`${path}${pathCompanies}`}
          titleClick={() => handleChangeShow(pathCompanies)}
          show={value == pathCompanies}
          icon="building"
          activation
        ></MobileLayoutMenuLink>

        {this.props.store.loggedInUser.checkPermission(2) ? (
          <MobileLayoutMenuLink
            title={"Generar actualización"}
            to={`${path}generate_update`}
            titleClick={() => handleChangeShow("generar_actualizacion")}
            show={value == "generar_actualizacion"}
            iconKasimu="download"
            activation
          ></MobileLayoutMenuLink>
        ) : null}
      </React.Fragment>,
    ];
  };

  render() {
    const { className, style } = this.props;
    var menu = [];
    menu.push(this.renderMultiUserMenu());

    return (
      <div className={className} style={style}>
        {menu}
      </div>
    );
  }
}

export default withStore(withRouter(ContentManagerBottomMenu));
