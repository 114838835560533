import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import {
  Text,
  Button,
  Panel,
  Loader,
  DateTimePicker,
  Columns,
  Column,
} from "ks_storybook";
import { EmptyContent } from "../../../Components/Common";
import moment from "moment";

@observer
class ProcessesListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessLists: false,
      isProcessUso: false,
      process_lists: this.getLastProcessHistory(1),
      process_use: this.getLastProcessHistory(2),
      process: {
        lists_date_from: moment(new Date(), "YYYY-MM-DD").toDate(),
        lists_date_to: moment(new Date(), "YYYY-MM-DD").toDate(),
        uso_date_from: moment(new Date(), "YYYY-MM-DD").toDate(),
        uso_date_to: moment(new Date(), "YYYY-MM-DD").toDate(),
      },
    };
  }

  componentDidMount() {
    this.props.store.menuTitle = "Procesos previos";
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  getLastProcessHistory = (type_process) => {
    return this.props.store.processHistory.fetchLast(type_process);
  };

  handleChangeDate = (sender, value, name, validation) => {
    const newProcess = this.state.process;
    switch (name) {
      case "lists_date_from":
        newProcess.lists_date_from = value;
        this.setState({ process: newProcess });
        break;
      case "lists_date_to":
        newProcess.lists_date_to = value;
        this.setState({ process: newProcess });
        break;
      case "uso_date_from":
        newProcess.uso_date_from = value;
        this.setState({ process: newProcess });
        break;
      case "uso_date_to":
        newProcess.uso_date_to = value;
        this.setState({ process: newProcess });
        break;
      default:
        break;
    }
  };

  reload = () => {
    this.setState({
      isProcessLists: false,
      isProcessUso: false,
      process_lists: this.getLastProcessHistory(1),
      process_use: this.getLastProcessHistory(2),
    });
  };

  generateProcess = (type_process) => {
    const { toastManager } = this.props;
    const { process } = this.state;
    const params = {
      type_process: type_process,
      date_from:
        type_process == 1
          ? moment(process.lists_date_from).format("YYYY-MM-DD")
          : moment(process.uso_date_from).format("YYYY-MM-DD"),
      date_to:
        type_process == 1
          ? moment(process.lists_date_to).format("YYYY-MM-DD")
          : moment(process.uso_date_to).format("YYYY-MM-DD"),
    };
    this.setState(
      {
        isProcessLists: type_process == 1 ? true : false,
        isProcessUso: type_process == 2 ? true : false,
      },
      () => {
        this.props.store.kasimuAPIClient
          .post("/process_history/process_create", params)
          .then(
            (res) => {
              toastManager.add("Proceso realizado con exito.", {
                appearance: "success",
                autoDismiss: true,
              });
              this.reload();
            },
            (error) => {
              toastManager.add(
                "Error, algo salio mal al procesar o no hay datos en ese rango de fechas.",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
              this.setState({ isProcessLists: false, isProcessUso: false });
            }
          )
          .catch((error) => {
            toastManager.add("Error, algo salio mal al procesar.", {
              appearance: "error",
              autoDismiss: true,
            });
          });
      }
    );
  };

  getNameProcess = (type_process) => {
    const NAMES = {
      1: "Listas",
      2: "Uso",
    };
    return NAMES[type_process];
  };

  getPanelList = (process_last) => {
    return (
      <Panel
        color="gray"
        padding={this.props.store.viewMobile ? "30px 35px" : "25px 22px"}
      >
        <Text
          size={this.props.store.viewMobile ? "md" : "sm"}
          lead
          weight="black"
          lineBreak
        >
          {this.getNameProcess(1)}
        </Text>
        <div className="mt-2 my-1">
          <div className="is-row" style={{ alignItems: "center" }}>
            <div style={{ minWidth: "60px" }}>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                lineBreak
              >
                {"Desde"}
              </Text>
            </div>
            <DateTimePicker
              name="lists_date_from"
              value={moment(
                this.state.process.lists_date_from,
                "YYYY-MM-DD"
              ).toDate()}
              dateFormat="yyyy-MM-dd"
              onChange={this.handleChangeDate}
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="arrow-down"
              backgroundColor="gray3"
              paddingInput={this.props.store.viewMobile && "20px"}
              borderColor="gray3"
              reversed
              fullWidth
            />
          </div>
          <div className="my-1"></div>
          <div className="is-row" style={{ alignItems: "center" }}>
            <div style={{ minWidth: "60px" }}>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                lineBreak
              >
                {"Hasta"}
              </Text>
            </div>
            <DateTimePicker
              name="lists_date_to"
              value={moment(
                this.state.process.lists_date_to,
                "YYYY-MM-DD"
              ).toDate()}
              dateFormat="yyyy-MM-dd"
              onChange={this.handleChangeDate}
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="arrow-down"
              backgroundColor="gray3"
              paddingInput={this.props.store.viewMobile && "20px"}
              borderColor="gray3"
              reversed
              fullWidth
            />
          </div>
        </div>

        <div className="flex flex-wrap">
          {process_last.length > 0 && (
            <div style={{ flex: "auto" }}>
              <Text
                className="mt-2"
                size={this.props.store.viewMobile ? "lg" : "md"}
                lineBreak
              >
                {"Procesado el: " +
                  moment(process_last[0].created_at, "YYYY-MM-DD")
                    .format("YYYY-MM-DD")
                    .toString()}
              </Text>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} lineBreak>
                {"Rango: desde " +
                  process_last[0].date_from +
                  " hasta " +
                  process_last[0].date_to}
              </Text>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} lineBreak>
                {"Resultado: " + process_last[0].result}
              </Text>
            </div>
          )}

          {this.state.isProcessLists ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3 mt-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{"Procesando..."}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3 mt-2"
              onClick={() => this.generateProcess(1)}
            >
              <strong>{"Procesar"}</strong>
            </Button>
          )}
        </div>
      </Panel>
    );
  };

  getPanelUso = (process_last) => {
    return (
      <Panel
        color="gray"
        padding={this.props.store.viewMobile ? "30px 35px" : "25px 22px"}
      >
        <Text
          size={this.props.store.viewMobile ? "md" : "sm"}
          lead
          weight="black"
          lineBreak
        >
          {this.getNameProcess(2)}
        </Text>
        <div className="mt-2 my-1">
          <div className="is-row" style={{ alignItems: "center" }}>
            <div style={{ minWidth: "60px" }}>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                lineBreak
              >
                {"Desde"}
              </Text>
            </div>
            <DateTimePicker
              name="uso_date_from"
              value={moment(
                this.state.process.uso_date_from,
                "YYYY-MM-DD"
              ).toDate()}
              dateFormat="yyyy-MM-dd"
              onChange={this.handleChangeDate}
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="arrow-down"
              backgroundColor="gray3"
              paddingInput={this.props.store.viewMobile && "20px"}
              borderColor="gray3"
              reversed
              fullWidth
            />
          </div>
          <div className="my-1"></div>
          <div className="is-row" style={{ alignItems: "center" }}>
            <div style={{ minWidth: "60px" }}>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                lineBreak
              >
                {"Hasta"}
              </Text>
            </div>
            <DateTimePicker
              name="uso_date_to"
              value={moment(
                this.state.process.uso_date_to,
                "YYYY-MM-DD"
              ).toDate()}
              dateFormat="yyyy-MM-dd"
              onChange={this.handleChangeDate}
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="arrow-down"
              backgroundColor="gray3"
              paddingInput={this.props.store.viewMobile && "20px"}
              borderColor="gray3"
              reversed
              fullWidth
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          {process_last.length > 0 && (
            <div style={{ flex: "auto" }}>
              <Text
                className="mt-2"
                size={this.props.store.viewMobile ? "lg" : "md"}
                lineBreak
              >
                {"Procesado el: " +
                  moment(process_last[0].created_at, "YYYY-MM-DD")
                    .format("YYYY-MM-DD")
                    .toString()}
              </Text>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} lineBreak>
                {"Rango: desde " +
                  process_last[0].date_from +
                  " hasta " +
                  process_last[0].date_to}
              </Text>
              <Text size={this.props.store.viewMobile ? "lg" : "md"} lineBreak>
                {"Resultado: " + process_last[0].result}
              </Text>
            </div>
          )}

          {this.state.isProcessUso ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3 mt-2"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{"Procesando..."}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3 mt-2"
              onClick={() => this.generateProcess(2)}
            >
              <strong>{"Procesar"}</strong>
            </Button>
          )}
        </div>
      </Panel>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderHeader = () => {
    return this.renderProcessLists();
  };

  renderBody = () => {
    const { process_lists, process_use } = this.state;
    let arrayProcessLists = process_lists.toArray();
    let arrayProcessUse = process_use.toArray();

    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="mb-2"
                weight="black"
                lineBreak
                lead
              >
                {"Procesos previos"}
              </Text>
            )}

            <Columns isCentered>
              <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
                {this.getPanelList(arrayProcessLists)}
              </Column>
              <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
                {this.getPanelUso(arrayProcessUse)}
              </Column>
            </Columns>
          </Column>
        </Columns>
      </React.Fragment>
    );
  };

  renderProcessLists = () => {
    const { process_lists } = this.state;
    if (!process_lists) return this.renderEmptyContent();
    return !process_lists.isOk()
      ? this.renderLoader()
      : this.renderProcessUse();
  };

  renderProcessUse = () => {
    const { process_use } = this.state;
    if (!process_use) return this.renderEmptyContent();
    return !process_use.isOk() ? this.renderLoader() : this.renderBody();
  };

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>;
  }
}

export default withToastManager(withStore(ProcessesListPage));
