import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Field,
  Button,
  Checkbox,
  Columns,
  Column,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import moment from "moment";

@observer
class CSVModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGen: false,
      columns: {
        id: false,
        name: false,
        status: false,
        deleted: false,
        created_at: false,
        updated_at: false,
      },
      columnsLists: [],
    };
  }

  getIndex = (column) => {
    return this.state.columnsLists.indexOf(column);
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleChange = (sender, value, name) => {
    let columns = this.state.columns;
    let columnsLists = this.state.columnsLists;
    columns[name] = value;
    if (value) {
      columnsLists.push(name);
    } else {
      columnsLists = columnsLists.filter((i) => name !== i);
    }
    this.setState({ columnsLists: columnsLists, columns: columns });
  };

  downloadCSV = () => {
    const { columnsLists } = this.state;
    const { toastManager, filters, orderList, search } = this.props;
    this.setState({ isGen: true }, () => {
      this.props.store.kasimuAPIClient
        .getCSV(
          "/singers/get_csv/?filters=" +
            JSON.stringify(filters) +
            "&orders=" +
            JSON.stringify(orderList) +
            "&columns=" +
            JSON.stringify(columnsLists) +
            "&search=" +
            search
        )
        .then((result) => {
          if (result.status == 200) {
            result.content.then((res) => {
              const exportName =
                "Singers_" +
                  moment(new Date()).format("DD-MM-YYYY HH:mm:ss") +
                  ".csv" || "export.csv";
              const blob = new Blob([res], { type: "text/csv;charset=ascii;" });
              if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, exportName);
              } else {
                const link = document.createElement("a");
                if (link.download !== undefined) {
                  const url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", exportName);
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }
              toastManager.add("CSV generado y descargado exitosamente.", {
                appearance: "success",
                autoDismiss: true,
              });
              this.handleClose();
            });
          } else {
            toastManager.add(
              "Error, algo salió mal generando y descargando el csv.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            this.setState({ isGen: false });
          }
        })
        .catch((error) => {
          toastManager.add(
            "Error, algo salió mal generando y descargando el csv.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          this.setState({ isGen: false });
        });
    });
  };

  renderButtons = () => {
    const { columns } = this.state;
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isGen ? (
            <Button
              className="is-pulled-right px-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong>{"Descargando..."}</strong>
            </Button>
          ) : (
            <Button
              icon={"file-download"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={
                !(
                  columns.id ||
                  columns.name ||
                  columns.status ||
                  columns.deleted ||
                  columns.created_at ||
                  columns.updated_at
                )
              }
              className="is-pulled-right px-2"
              onClick={this.downloadCSV}
            >
              <strong>{"Descargar csv"}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { columns } = this.state;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "250px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            weight="black"
            lead
          >
            {"Exportar csv"}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Field
            size="xl"
            label={"Columnas"}
            NoteSize="xs"
            labelNote={"Obligatorio seleccionar al menos una columna."}
          >
            <Columns className="my-1" isMultiline isGapless>
              <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
                <Checkbox
                  invert
                  key={"id"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.id}
                  value={"id"}
                  className="items-center"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.id ? this.getIndex("id") + 1 + " - " + "Id" : "Id"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"name"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.name}
                  value={"name"}
                  className="items-center"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.name
                      ? this.getIndex("name") + 1 + " - " + "Nombre"
                      : "Nombre"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"status"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.status}
                  value={"status"}
                  className="items-center"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.status
                      ? this.getIndex("status") + 1 + " - " + "Estado"
                      : "Estado"}
                  </Text>
                </Checkbox>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 6, desktop: 6 }}>
                <Checkbox
                  invert
                  key={"deleted"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.deleted}
                  value={"deleted"}
                  className="items-center"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.deleted
                      ? this.getIndex("deleted") + 1 + " - " + "Borrado"
                      : "Borrado"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"created_at"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.created_at}
                  value={"created_at"}
                  className="items-center"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.created_at
                      ? this.getIndex("created_at") +
                        1 +
                        " - " +
                        "Fecha de creación"
                      : "Fecha de creación"}
                  </Text>
                </Checkbox>
                <Checkbox
                  invert
                  key={"updated_at"}
                  size="lg"
                  onChange={this.handleChange}
                  isChecked={columns.updated_at}
                  value={"updated_at"}
                  className="items-center"
                >
                  <Text
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="medium"
                  >
                    {columns.updated_at
                      ? this.getIndex("updated_at") +
                        1 +
                        " - " +
                        "Fecha de modificación"
                      : "Fecha de modificación"}
                  </Text>
                </Checkbox>
              </Column>
            </Columns>
          </Field>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

CSVModal.propTypes = {
  onClose: PropTypes.func,
};

CSVModal.defaultProps = {
  onClose: null,
};

export default withToastManager(withStore(CSVModal));
