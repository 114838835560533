import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Field,
  Panel,
} from "ks_storybook";
import { Select } from "../../Components/Select";
import { withToastManager } from "react-toast-notifications";

@observer
class AssignSongsUserStylesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAssign: false,
      optionsUserStyles: null,
      messageError: null,
    };

    this.newParams = this.params();
    this.search = null;
  }

  params = () => {
    const attrs = {
      tag_id: null,
      apip_user_id: null,
      apip_username: null,
      apip_song_styles_id: null,
      apip_song_styles_name: null,
      apip_free_rights: null,
    };
    return attrs;
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.newParams[name] = value;
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;
    switch (sender.name) {
      case "selectMyTags":
        this.newParams["tag_id"] = val;
        break;
      case "selectUserStyles":
        this.newParams["apip_song_styles_id"] = val;
        this.newParams["apip_song_styles_name"] = value.label;
        this.newParams["apip_user_id"] = value.user_id;
        this.newParams["apip_free_rights"] = value.free_rights;
        this.setState({ messageError: null });
        break;
      default:
        break;
    }
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleAssign = () => {
    const { toastManager } = this.props;
    this.setState({ isAssign: true }, () => {
      this.props.store.kasimuAPIClient
        .post(
          "/songs_assigned_user_styles/assign_songs_to_style",
          this.newParams
        )
        .then(
          (res) => {
            toastManager.add(
              "Se asignaron " +
                res.results.count +
                " canciones al estilo (" +
                this.newParams.apip_song_styles_name +
                ") correctamente.",
              {
                appearance: "success",
                autoDismiss: true,
              }
            );
            this.props.onReload && this.props.onReload();
          },
          (error) => {
            toastManager.add(
              "Error algo salio mal asiganado canciones al estilo (" +
                this.newParams.apip_song_styles_name +
                ")",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            this.setState({ isAssign: false });
          }
        )
        .catch((error) => {
          toastManager.add(
            "Error algo salio mal asiganado canciones al estilo (" +
              this.newParams.apip_song_styles_name +
              ")",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        });
    });
  };

  handleChangeSearch = (sender, value, name) => {
    if (value === "")
      this.setState({ optionsUserStyles: null, messageError: null });
    this.search = value;
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  getOptionsUserStyles = (obj) => {
    return obj.map((o) => ({
      value: o.style_id,
      label: o.name,
      user_id: o.user_id,
      free_rights: o.free_rights,
    }));
  };

  getErrorMessager = (codError) => {
    const messager = {
      1: "Error, Usuario sin estilos.",
      2: "Error. Usuario no encontrado.",
      3: "Error. Al comunicarse con apip.",
    };
    return messager[codError];
  };

  getSearchUserStylesAPIP = (search) => {
    this.props.store.kasimuAPIClient
      .search("/apip_history_consulted_users/get_search_user_styles_apip", {
        per_page: 1000,
        name: search,
      })
      .then((res) => {
        if (res.results.Error) {
          this.setState({
            optionsUserStyles: null,
            messageError: this.getErrorMessager(res.results.codError),
          });
        } else {
          let optionsUserStyles = this.getOptionsUserStyles(res.results);
          this.newParams["apip_username"] = search;
          this.setState({
            optionsUserStyles: optionsUserStyles,
            messageError: null,
          });
        }
      });
  };

  onSearch = () => {
    if (this.search && this.search != " ") {
      this.getSearchUserStylesAPIP(this.search);
    }
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft />
        <LevelRight>
          {this.state.isAssign ? (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="is-pulled-right px-3"
              disabled
              icon="spinner"
              pulse
            >
              <strong>{"Asignando..."}</strong>
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={
                !(this.newParams.tag_id && this.newParams.apip_song_styles_id)
              }
              className="is-pulled-right px-3"
              onClick={this.handleAssign}
            >
              <strong>{"Asignar"}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { optionsUserStyles, messageError } = this.state;
    const { myTagsOptions } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "640px" : "90%"}
          height={this.props.store.viewMobile ? "340px" : "auto"}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
              className="mb-1"
            >
              {"Asignar canciones"}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Field
              label={"Mis Etiquetas"}
              labelNote={this.props.store.language.label76}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              NoteSize="xs"
              marginH={this.props.store.viewMobile && "4px"}
              weight="medium"
            >
              <Select
                isMobile={!this.props.store.viewMobile}
                name={"selectMyTags"}
                onChange={this.handleSelectChange}
                options={myTagsOptions}
                placeholder={"Elegir un etiqueta..."}
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="is-fullwidth my-1"
                backgroundColor="black"
              />
            </Field>
            <Field
              label={"Nombre del usuario a consultar"}
              labelNote={this.props.store.language.label76}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              NoteSize="xs"
              marginH={this.props.store.viewMobile && "4px"}
              weight="medium"
            >
              <TextInput
                name="description"
                autocomplete="nope"
                onKeyPress={this.handleKeyPress}
                onChange={this.handleChangeSearch}
                placeholder={
                  "Escribe aquí el nombre del usuario a consultar (ENTER)..."
                }
                value={this.search && this.search}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                backgroundColor="blackDark"
                borderColor="black"
                paddingInput={this.props.store.viewMobile && "20px"}
                className="is-fullwidth my-1"
              />
            </Field>
            {optionsUserStyles && (
              <Field
                label={"Estilos del Usuario"}
                labelNote={this.props.store.language.label76}
                size={this.props.store.viewMobile ? "xl" : "lg"}
                NoteSize="xs"
                marginH={this.props.store.viewMobile && "4px"}
                weight="medium"
              >
                <Select
                  isMobile={!this.props.store.viewMobile}
                  name={"selectUserStyles"}
                  onChange={this.handleSelectChange}
                  options={optionsUserStyles}
                  placeholder={this.props.store.language.label385}
                  backgroundColor="black"
                  separatorColor="black"
                  className="my-1"
                />
              </Field>
            )}
            {messageError && (
              <Panel color="error" className="mt-2" invert>
                <Text
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  multiline
                >
                  {messageError}
                </Text>
              </Panel>
            )}
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

AssignSongsUserStylesModal.propTypes = {
  onClose: PropTypes.func,
  onReload: PropTypes.func,
};

AssignSongsUserStylesModal.defaultProps = {
  onClose: null,
  onReload: null,
};

export default withToastManager(withStore(AssignSongsUserStylesModal));
