import { action, observable, computed } from "mobx";
import { RESTClient, LocalStorageClient } from "ks_storybook";
import {
  UIStore,
  UsersStore,
  CategoriesStore,
  CategoriesPlaylistsStore,
  UserGroupStore,
  PlaylistsStore,
  SongLogStore,
  SongsStore,
  SongStylesStore,
  SongMoodsStore,
  SingersStore,
  RecordLabelStore,
  MusicListsStore,
  MusicListsContentsStore,
  ProcessHistoryStore,
  SongLanguageStore,
  ProvidersSongsStore,
  ReportsSongsStore,
  ReportsSingersStore,
  ReportsSongStylesStore,
  VersionUpdatedStore,
  CatalogsStore,
  UsersListenedSongsStore,
  UsersTagStore,
  UsersTaggedSongsStore,
  TranslationCategoriesStore,
  AppLanguagesStore,
  TranslationCategoriesPlaylistsStore,
  BlocksStore,
  TranslationSongStylesStore,
  ApipHistoryConsultedUsersStore,
  SongsAssignedUserStylesStore,
} from ".";
import es_ES from "../lang/es_ES.js";
import en_EN from "../lang/en_EN.js";
import fr_FR from "../lang/fr_FR.js";

import pkg from "../../package.json";

export default class AppStore {
  @observable isLoading = true;
  @observable loggedInUser = null;
  @observable serviceErrors = [];
  @observable lang = 1;
  @observable language = es_ES;
  @observable langSeeable = true;
  @observable viewMobile = false;
  @observable urlBucketAws = "https://bucket-kasimu.s3.amazonaws.com/";
  @observable toggleMenu = false;
  @observable version = `v ${pkg.version}`;
  @observable menuTitle = "";

  api_token_key = `ks_${process.env.REACT_APP_KASIMU_API_URL}_token`;
  logged_user_key = `ks_${process.env.REACT_APP_KASIMU_API_URL}_user`;

  settings = {
    refreshRate: 5, // in minutes
  };

  constructor() {
    const storedToken = localStorage.getItem(this.api_token_key);
    const storedUser = localStorage.getItem(this.logged_user_key);
    const storedLang = localStorage.getItem("currentLang");

    // create adapters
    this.kasimuAPIClient = new RESTClient(
      process.env.REACT_APP_KASIMU_API_URL,
      storedToken
    );
    this.localStorageClient = new LocalStorageClient("ks");

    // initialize stores
    this.stores = new Map();

    // Domain stores
    this.stores.set("users", new UsersStore(this.kasimuAPIClient, this));
    this.stores.set(
      "categories",
      new CategoriesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "categoriesPlaylists",
      new CategoriesPlaylistsStore(this.kasimuAPIClient, this)
    );
    this.stores.set("groups", new UserGroupStore(this.kasimuAPIClient, this));
    this.stores.set(
      "playlists",
      new PlaylistsStore(this.kasimuAPIClient, this)
    );
    this.stores.set("songLog", new SongLogStore(this.kasimuAPIClient, this));
    this.stores.set("songs", new SongsStore(this.kasimuAPIClient, this));
    this.stores.set(
      "songStyles",
      new SongStylesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "songMoods",
      new SongMoodsStore(this.kasimuAPIClient, this)
    );
    this.stores.set("singers", new SingersStore(this.kasimuAPIClient, this));
    this.stores.set(
      "recordLabel",
      new RecordLabelStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "musicLists",
      new MusicListsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "musicListsContents",
      new MusicListsContentsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "processHistory",
      new ProcessHistoryStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "songLanguage",
      new SongLanguageStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "providersSongs",
      new ProvidersSongsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "reportsSongs",
      new ReportsSongsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "reportsSingers",
      new ReportsSingersStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "reportsSongStyles",
      new ReportsSongStylesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "versionUpdated",
      new VersionUpdatedStore(this.kasimuAPIClient, this)
    );
    this.stores.set("catalogs", new CatalogsStore(this.kasimuAPIClient, this));
    this.stores.set(
      "usersListenedSongs",
      new UsersListenedSongsStore(this.kasimuAPIClient, this)
    );
    this.stores.set("usersTag", new UsersTagStore(this.kasimuAPIClient, this));
    this.stores.set(
      "usersTaggedSongs",
      new UsersTaggedSongsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "translationCategories",
      new TranslationCategoriesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "appLanguages",
      new AppLanguagesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "translationCategoriesPlaylists",
      new TranslationCategoriesPlaylistsStore(this.kasimuAPIClient, this)
    );
    this.stores.set("blocks", new BlocksStore(this.kasimuAPIClient, this));
    this.stores.set(
      "translationSongStyles",
      new TranslationSongStylesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "apipHistoryConsultedUsers",
      new ApipHistoryConsultedUsersStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "songsAssignedUserStyles",
      new SongsAssignedUserStylesStore(this.kasimuAPIClient, this)
    );

    // UI stores
    this.stores.set("ui", new UIStore(this.localStorageClient, this));

    // create easy stores getters
    this.stores.forEach((store, key) => {
      Object.defineProperty(this, key, {
        get: (v) => store,
      });

      store.updateThreshold = this.settings.refreshRate;
    });

    // is already a session open?
    if (storedUser > 0) {
      this.users.get(storedUser).andThen((res, err) => {
        if (err) {
          // something went terrible wrong....
          this.signOut();
          this.isLoading = false;
        } else {
          this.setCurrentUser(res);
          this.isLoading = false;
          this.langSeeable = false;
          this.lang = storedLang;
          this.getLanguage(storedLang);
        }
      });
    } else {
      this.signOut();
      this.isLoading = false;
    }
  }

  @action
  signOut() {
    this.langSeeable = true;
    if (this.loggedInUser != null) {
      //Guardar connection
    }

    this.kasimuAPIClient.token = null;
    this.loggedInUser = null;
    this.ui.signOut();
    this.stores.forEach((store, key) => {
      store.clear();
    });

    localStorage.setItem(this.api_token_key, null);
    localStorage.setItem(this.logged_user_key, null);
    localStorage.setItem("currentLang", 1);
  }

  @action
  signIn(user, password) {
    localStorage.setItem("currentLang", this.lang);
    return this.kasimuAPIClient
      .authenticate(user, password, "", "/login_webpanel")
      .then((res) => {
        if (res.codError === 3) {
          this.setCurrentUser(this.users.store(res.user));
          // save the info in storage
          localStorage.setItem(this.api_token_key, this.kasimuAPIClient.token);
          localStorage.setItem(this.logged_user_key, this.loggedInUser.id);
          //Guardar connection
          this.langSeeable = false;
        }
      });
  }

  @action
  setCurrentUser(user) {
    this.loggedInUser = user;
  }

  @computed
  get isLoggedIn() {
    const loggedInUser = this.loggedInUser; // I need to do this for MOBX to catch it....
    return this.kasimuAPIClient.token != null && loggedInUser != null;
  }

  @computed
  get isLoggingIn() {
    const loggedInUser = this.loggedInUser; // I need to do this for MOBX to catch it....
    return this.kasimuAPIClient.token != null && loggedInUser == null;
  }

  @computed
  get loggedInUserKey() {
    if (this.isLoggedIn) {
      return this.loggedInUser.id;
    }

    return null;
  }

  getCurrentTheme() {
    return "kasimu";
  }

  @action
  getLanguage(val) {
    if (val == 0) return (this.language = en_EN);
    if (val == 1) return (this.language = es_ES);
    if (val == 2) return (this.language = fr_FR);
  }

  currentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    var hour = today.getHours();
    var minutes = today.getMinutes();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd + " " + hour + ":" + minutes;
    return today;
  }

  arrayObjToCsv(ar, name) {
    //comprobamos compatibilidad
    if (window.Blob && (window.URL || window.webkitURL)) {
      var contenido = "",
        d = new Date(),
        blob,
        reader,
        save,
        clicEvent;
      //creamos contenido del archivo
      for (var i = 0; i < ar.length; i++) {
        //construimos cabecera del csv
        if (i == 0) contenido += Object.keys(ar[i]).join(";") + "\n";
        //resto del contenido
        contenido +=
          Object.keys(ar[i])
            .map(function (key) {
              return ar[i][key];
            })
            .join(";") + "\n";
      }
      //creamos el blob
      blob = new Blob(["\ufeff", contenido], { type: "text/csv" });
      //creamos el reader
      var reader = new FileReader();
      reader.onload = function (event) {
        //escuchamos su evento load y creamos un enlace en dom
        save = document.createElement("a");
        save.href = event.target.result;
        save.target = "_blank";
        //aquí le damos nombre al archivo
        save.download =
          name +
          "_" +
          d.getDate() +
          "_" +
          (d.getMonth() + 1) +
          "_" +
          d.getFullYear() +
          ".csv";
        try {
          //creamos un evento click
          clicEvent = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
          });
        } catch (e) {
          //si llega aquí es que probablemente implemente la forma antigua de crear un enlace
          clicEvent = document.createEvent("MouseEvent");
          clicEvent.initEvent("click", true, true);
        }
        //disparamos el evento
        save.dispatchEvent(clicEvent);
        //liberamos el objeto window.URL
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      };
      //leemos como url
      reader.readAsDataURL(blob);
    } else {
      //el navegador no admite esta opción
      alert("Su navegador no permite esta acción");
    }
  }

  convertToCSV(objArray) {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += ";";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportCSVFile(headers, items, fileName) {
    if (headers) {
      items.unshift(headers);
    }
    const jsonObject = JSON.stringify(items);
    const csv = this.convertToCSV(jsonObject);
    const exportName = fileName + ".csv" || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
