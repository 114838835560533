import React, { Component } from "react";
import { Panel, TextInput, Text, Button, Img, Form } from "ks_storybook";
import withStore from "../../hocs/withStore";
import { Columns, Column } from "bloomer";
import fullLogo from "../../assets/images/logo-kasimu.png";

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      password: "",
      signingIn: false,
      signingError: null,
      eye: false,
    };

    this.signIn = this.signIn.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  signIn(sender, formData) {
    var pos = this.state.user.indexOf("@");
    if (pos === -1) {
      this.setState({ signingIn: true });
      this.props.store
        .signIn(this.state.user, this.state.password)
        .catch((err) => {
          let str = err.toString();
          if (str === "TypeError: Failed to fetch") {
            this.setState({
              signingIn: false,
              signingError: this.getErrorMessager(7),
            });
          } else {
            str = str.replace("Error: ", "");
            let mydatas = JSON.parse(str);
            this.setState({
              signingIn: false,
              signingError: this.getErrorMessager(mydatas["codError"]),
            });
          }
        });
    } else {
      this.setState({
        signingIn: false,
        signingError: this.getErrorMessager(2),
      });
    }
  }

  handleInputChange(sender, value, name) {
    let newState = {};
    newState[name] = value;

    this.setState(newState);
  }

  getErrorMessager(codError) {
    const messager = {
      1: this.props.store.language.label10,
      2: this.props.store.language.label11,
      4: this.props.store.language.label12,
      5: this.props.store.language.label13,
      6: this.props.store.language.label14,
      7: this.props.store.language.label15,
    };
    return messager[codError];
  }

  render() {
    return (
      <Columns
        isMarginless
        isVCentered
        isCentered
        style={{ height: "100vh", overflow: "auto" }}
      >
        <Column
          className="has-text-centered px-2"
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
          style={{
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Img src={fullLogo} className="my-3" />
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                lead
                weight="strong"
                lineBreak
                upper
              >
                {this.props.store.language.label6}
              </Text>
              <br />
              <div style={{ maxWidth: "545px", width: "100%" }}>
                <Form onSubmit={this.signIn} className="is-fullwidth">
                  <TextInput
                    name="user"
                    size="md"
                    lead
                    iconKasimu="user"
                    placeholder={this.props.store.language.label7}
                    type="text"
                    className="is-fullwidth my-2"
                    value={this.state.user}
                    disabled={this.state.signingIn}
                    onChange={this.handleInputChange}
                    backgroundColor="blackDark"
                    color="white"
                    borderColor="white"
                    borderRadius="20px"
                    placeholderColor="white"
                    paddingInput="30px 50px !important"
                    textAlign="center"
                  />

                  <TextInput
                    name="password"
                    double
                    iconDouble={this.state.eye ? "eye-slash" : "eye"}
                    size="md"
                    lead
                    iconKasimu="lock"
                    placeholder={this.props.store.language.label8}
                    type={this.state.eye ? "text" : "password"}
                    className="is-fullwidth my-2"
                    value={this.state.password}
                    disabled={this.state.signingIn}
                    onChange={this.handleInputChange}
                    backgroundColor="blackDark"
                    color="white"
                    borderColor="white"
                    borderRadius="20px"
                    placeholderColor="white"
                    paddingInput="30px !important"
                    textAlign="center"
                    onClickIcon={() => this.setState({ eye: !this.state.eye })}
                  />

                  {this.state.signingError && (
                    <Panel color="error" className="mt-2" invert>
                      <Text multiline>{this.state.signingError}</Text>
                    </Panel>
                  )}

                  {!this.state.signingIn ? (
                    <Button
                      size="xl"
                      upper
                      className="is-fullwidth mt-3 mb-2 py-2"
                      borderRadius="20px"
                      isDefault
                    >
                      {this.props.store.language.label9}
                    </Button>
                  ) : (
                    <Button
                      size="xl"
                      upper
                      className="is-fullwidth mt-3 mb-2 py-2"
                      borderRadius="20px"
                      icon="spinner"
                      pulse
                      disabled
                    />
                  )}
                </Form>
                {/*<Button kind="link" className="is-fullwidth mt-1 mb-2">¿Olvidaste tu contraseña?</Button>*/}
              </div>
            </div>
            <div
              className="flex pb-2"
              style={{
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Text
                weight="bold"
                size={this.props.store.viewMobile ? "xl" : "lg"}
              >
                {this.props.store.version}
              </Text>
            </div>
          </div>
        </Column>
      </Columns>
    );
  }
}

export default withStore(SignIn);
