import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Level, LevelRight, LevelLeft } from "bloomer";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Img,
  Columns,
  Column,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { Select, SelectIcons } from "../Select";
import iconsOptions from "../Iconos/Icons.js";

@observer
class EditCategoriesPlaylistsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.currentCategoriesPlaylists = props.currentCategoriesPlaylists.clone();
  }

  handleChangeFiled = (sender, value, name, validation) => {
    this.currentCategoriesPlaylists[name] = value;
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    let result = this.currentCategoriesPlaylists.icon_path.replace(
      "https://bucket-kasimu.s3.amazonaws.com/images/",
      ""
    );
    this.currentCategoriesPlaylists.icon_path = result;
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .putParams(
          "/categories_playlist/put_categories_playlists",
          this.currentCategoriesPlaylists
        )
        .then(
          (res) => {
            toastManager.add("La categoría playlist se editó exitosamente.", {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onSave && this.props.onSave();
          },
          (error) => {
            toastManager.add(
              "Error, algo salió mal al editar al editar la categoría playlist.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            this.setState({ isSaving: false });
          }
        );
    });
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;
    switch (sender.name) {
      case "selectIcons":
        this.currentCategoriesPlaylists.icon_path = value.icon;
        break;
      case "selectCategories":
        this.currentCategoriesPlaylists.category_id = val;
        break;
      default:
        break;
    }
  };

  renderButtons = () => {
    return (
      <Level className="mb-2" isMobile>
        <LevelLeft></LevelLeft>
        <LevelRight>
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-2"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              <strong>{this.props.store.language.label81}</strong>
            </Button>
          ) : (
            <Button
              icon={"save"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={
                this.currentCategoriesPlaylists.alias === "" ||
                this.currentCategoriesPlaylists.description === ""
              }
              className="is-pulled-right px-2"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </LevelRight>
      </Level>
    );
  };

  render() {
    const { categoriesOptions } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "450px" : "auto"}
      >
        <ModalHeader>
          <div className="flex items-center mb-2">
            <Img
              height={this.props.store.viewMobile ? "140px" : "100px"}
              width={this.props.store.viewMobile ? "140px" : "100px"}
              src={this.currentCategoriesPlaylists.icon_path}
            />
            <Text
              className="ml-1"
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
            >
              {this.props.store.language.label140}
              {"\n"}
              {" Id: " + this.currentCategoriesPlaylists.id}
              {"\n"}
              {this.currentCategoriesPlaylists.alias}
            </Text>
          </div>
        </ModalHeader>
        <ModalContent>
          <Field
            label={this.props.store.language.label53}
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            <TextInput
              name="alias"
              onChange={this.handleChangeFiled}
              value={this.currentCategoriesPlaylists.alias}
              autocomplete="nope"
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={this.props.store.viewMobile && "20px"}
            />
          </Field>
          <Field
            label={"Descripción"}
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            <TextInput
              name="description"
              onChange={this.handleChangeFiled}
              value={this.currentCategoriesPlaylists.description}
              autocomplete="nope"
              backgroundColor="blackDark"
              borderColor="black"
              className="is-fullwidth my-1"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              paddingInput={this.props.store.viewMobile && "20px"}
            />
          </Field>
          <Field
            label={"Categoría"}
            marginH={this.props.store.viewMobile && "4px"}
            size={this.props.store.viewMobile ? "xl" : "lg"}
            noteSize={this.props.store.viewMobile ? "sm" : "xs"}
            weight="medium"
          >
            <Select
              isMobile={!this.props.store.viewMobile}
              name={"selectCategories"}
              onChange={this.handleSelectChange}
              options={categoriesOptions}
              defaultValue={
                categoriesOptions[
                  categoriesOptions
                    .map(function (e) {
                      return e.value;
                    })
                    .indexOf(this.currentCategoriesPlaylists.category_id)
                ]
              }
              placeholder={this.props.store.language.label385}
              backgroundColor="black"
              separatorColor="black"
              className="my-1"
            />
          </Field>
          <Field marginH="10px" label={"Icono"} size="xl" NoteSize="xs" upper>
            <SelectIcons
              isMobile={!this.props.store.viewMobile}
              name="selectIcons"
              onChange={this.handleSelectChange}
              placeholder={"Icons"}
              options={iconsOptions}
              defaultValue={
                iconsOptions[
                  iconsOptions
                    .map(function (e) {
                      return e.icon;
                    })
                    .indexOf(this.currentCategoriesPlaylists.icon_path)
                ]
              }
              backgroundColor="black"
              separatorColor="black"
              className="my-1"
            />
          </Field>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

EditCategoriesPlaylistsModal.propTypes = {
  currentCategoriesPlaylists: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

EditCategoriesPlaylistsModal.defaultProps = {
  currentCategoriesPlaylists: null,
  onSave: null,
  onClose: null,
};

export default withToastManager(withStore(EditCategoriesPlaylistsModal));
