import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Loader,
  Button,
  Panel,
} from "ks_storybook";
import moment from "moment";
import { withToastManager } from "react-toast-notifications";
import { EmptyContent, Pagination } from "../Common";

@observer
class ReportsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRestore: false,
      reports: this.getReportsAll(5, props.singer.id),
      per_page: 5,
      page: 1,
    };
    this.singer = props.singer.clone();
  }

  getReportsAll = (per_page, singer_id) => {
    return this.props.store.reportsSingers.fetchSearch(per_page, singer_id);
  };

  onPageChange = (page) => {
    const { per_page } = this.state;
    this.setState({
      reports: this.props.store.reportsSingers.movePage(
        per_page,
        page,
        this.props.singer.id
      ),
      page: page,
    });
  };

  handleRestore = (restore) => {
    const { toastManager } = this.props;
    this.singer["name"] = restore.name;
    this.singer["deleted"] = restore.deleted;

    this.singer.save().andThen((res, responseError) => {
      if (responseError) {
        toastManager.add(
          "Error, algo salió mal al restaurar los datos del intérprete.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      } else {
        toastManager.add("El intérprete se restauro exitosamente.", {
          appearance: "success",
          autoDismiss: true,
        });
      }
      this.handleClose();
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "16px",
  });

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        backgroundColor="transparent"
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  renderPaginator = (reports) => {
    if (reports.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div>
          <Pagination
            totalPages={reports.totalPages}
            currentPage={reports.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { reports } = this.state;
    if (!reports) return this.renderEmptyContent();
    return !reports.isOk()
      ? this.renderLoader()
      : this.renderReports(reports.toArray());
  };

  getGen = (key) => {
    const gen = { 0: "No", 1: "Si" };
    return gen[key];
  };

  renderReports = (reports) => {
    return (
      <React.Fragment>
        <br />
        <div style={this.getContainerStyle()}>
          {reports.length > 0
            ? reports.map((obj) => (
                <div
                  style={{
                    lineBreak: "anywhere",
                    marginBottom: !this.props.store.viewMobile && "3px",
                  }}
                  className={this.props.store.viewMobile && "mb-1"}
                >
                  <Panel
                    color={"gray"}
                    padding={
                      this.props.store.viewMobile ? "16px 30px" : "14px 10px"
                    }
                    style={{
                      justifyContent: "center",
                      backgroundColor: this.state.open && "#D33F20",
                      borderRadius: !this.props.store.viewMobile && "5px",
                    }}
                  >
                    <div
                      className="flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        lineBreak
                      >
                        {"Usuario: " +
                          obj.user.username +
                          " | " +
                          obj.name +
                          " | Eliminada: " +
                          this.getGen(obj.deleted) +
                          " | Fecha de creación: " +
                          moment(obj.created_at).format("DD-MM-YYYY HH:mm:ss")}
                      </Text>
                      <Button
                        className="is-pulled-right ml-1"
                        icon="undo-alt"
                        kind="link"
                        color="basic"
                        size="lg"
                        lead
                        space
                        onClick={() => {
                          this.handleRestore(obj);
                        }}
                      />
                    </div>
                  </Panel>
                </div>
              ))
            : this.renderEmptyContent()}
        </div>

        <Text className="is-pulled-right mt-1" size="xl" lineBreak>
          {"Resultado: " + this.state.reports.count}
        </Text>

        <br />
        {this.renderPaginator(this.state.reports)}
      </React.Fragment>
    );
  };

  render() {
    const { singer } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "750px" : "90%"}
        height={this.props.store.viewMobile ? "550px" : "auto"}
      >
        <ModalHeader>
          <Text
            size={this.props.store.viewMobile ? "md" : "sm"}
            weight="black"
            lineBreak
            lead
          >
            {"Reportes de modificaciones"}
            {"\n"}
            {" Id: " + singer.id}
            {"\n"}
            {singer.name}
          </Text>
        </ModalHeader>
        <ModalContent>{this.renderHeader()}</ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

ReportsModal.propTypes = {
  singer: PropTypes.object,
  onClose: PropTypes.func,
};

ReportsModal.defaultProps = {
  singer: null,
  onClose: null,
};

export default withToastManager(withStore(ReportsModal));
